import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const ModalNegociacaoGeracao = ({ setters, states, action }) => {

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [height, setHeight] = useState(false)
  const [style, setStyle] = useState('')
  const [errorDate, setErrorDate] = useState(false)
  const [errorDateMsg, setErrorDateMsg] = useState('Campo obrigatórios')

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (selectedDate === '') {
      setErrorDate(true)
      return
    }
    if (!moment(selectedDate).isValid()) {
      setErrorDate(true)
      setErrorDateMsg('Data não válida')
      return;
    }
    if (!moment(selectedDate).isSameOrAfter(moment().format('YYYY-MM-DD'))) {
      setErrorDate(true)
      setErrorDateMsg("Data não pode ser no passado")
      return;
    }
    setErrorDate(false)
    setErrorDateMsg("Campo obrigatório")

    action(selectedDate)
  }


  const style1 = "w-[50%] h-[70%]  transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
  const style2 = "w-[50%] h-[40%]  transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"

  const changeValueDate = (date) => {
    setHeight(false)
    const dateFormat = moment(date).format('YYYY-MM-DD')
    setSelectedDate(date)


  }

  useEffect(() => {
    height == true && setStyle(style1)
    height == false && setStyle(style2)

  }, [height])

  useEffect(() => {
    if (!states.modalDebitos) {
      setErrorDate(false)
      setErrorDateMsg('Campo obrigatório')
      setSelectedDate('')
    }
  }, [states.modalDebitos])

  return (
    <Transition.Root show={states.modalDebitos} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setters.setModalDebitos(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex h-full w-[100%]  itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}

              {/* <Dialog.Panel className="w-[40%] h-[50%]  transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"> */}
              <Dialog.Panel className={style}>
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => setters.setModalDebitos(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Gerar Negociação</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center">
                    Associado: {states?.Debitos?.name}
                  </p>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center">
                    Valor: {(states?.Debitos?.total_value / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                  </p>
                </div>

                <form className='flex flex-col mt-4 w-[100%]' onSubmit={handleFormSubmit}>

                  <div className='flex flex-col mt-4 w-[100%]'>
                    <p className='text-gray-500 font-semibold text-base'>Digite a data que deseja para a negociação desse valor</p>
                    <input
                      type="date"
                      className="mt-2 w-full h-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={selectedDate}
                      onChange={(e) => changeValueDate(e.target.value)}
                    />
                    {
                      errorDate && <p className='text-red-500'>{errorDateMsg}</p>
                    }
                  </div>


                  <div className="mt-12 flex items-center justify-center gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => setters.setModalDebitos(false)}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Registrar
                    </button>
                  </div>

                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


export default ModalNegociacaoGeracao;