import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import Arquivo from '../../UploadJuridico'

export default function ModalAtualizacaoProcesso(props) {



    const [idDeferimento, setIdDeferimento] = useState('')
    const [form, setForm] = useState({
        title: '',
        send: '',
        description: '',
        notes: '',
        isPublic: '',
        process_id: '',
        file: null
    })
    const [errorForm, setErrorForm] = useState({
        title: false,
        description: false,
        send: false,
        notes: false,
        process_id: false,
        isPublic: false
    })
    const [dataSelect, setDataSelect] = useState([])






    const onSubmitForm = async (e) => {
        e.preventDefault()
        let error = false;
        for (const item of Object.keys(form)) {
            if (form[item] == '' || form[item] == ' ' || form[item] == undefined) {
                if (item == 'file' || item == 'fileName' || item == 'isDeleted') continue;
                error = true
                setErrorForm(e => {
                    return { ...e, [item]: true }
                })
            } else {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (error) {
            error = false
            return;
        }
        let obj;
        const isPublic = form.isPublic == 'true' ? 1 : 0
        const send = form.send == 'true' ? 1 : 0
        obj = new FormData();
        if (form.file !== null && Object.keys(form.file).length == 0) {
            obj.append('file', form.file);
        }
        obj.append('send', send);
        obj.append('title', form.title);
        obj.append('description', form.description);
        obj.append('notes', form.notes);
        obj.append('isPublic', isPublic);
        obj.append('process_id', form.process_id);
        if (props.states.isEditJuri == true) {
            obj.append('id', props.states.JuriProcessoAtualizacoes.id);
        }

        if (props.states.isEditJuri) {
            if (!checkFile && (props.states.JuriProcessoAtualizacoes.filename !== null && props.states.JuriProcessoAtualizacoes.filename !== undefined)) {
                obj.append('deleteFile', true);
            }
            props.action(obj, idDeferimento)
            return;
        }

        props.action(obj)


    }


    const [checkFile, setCheckFile] = useState(false)
    const saveFile = (file) => {
        setForm(() => {
            return { ...form, file: file }
        })
    }

    useEffect(() => {
        if (props.states.modalAcoesProcessoAtualizacoes === false) {
            Object.keys(form).map(item => {
                setForm(e => {
                    return { ...e, [item]: '' }
                })
            })
            Object.keys(errorForm).map(item => {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            })
            setCheckFile(false)
            props.setters.setIsEditJuri(false)
            props.setters.setJuriAcoesProcessoAtualizacoes({})
            props.setters.setDataJuriAcoesProcessoForm([])
            props.setters.setOffsetJuriAcoesProcesso(0)
            setForm({
                title: '',
                send: '',
                description: '',
                notes: '',
                isPublic: '',
                process_id: '',
                file: null
            })
            setDataSelect([])

        } else {
            //Deve realizar o push da acao no selectData! E na edicao, não pode alterar
            if (Object.keys(props.states.JuriAcoesProcessoAtualizacoes).length > 0) {
                if (props.states.JuriAcoesProcessoAtualizacoes.filename) setCheckFile(true)
                setIdDeferimento(props.states.JuriAcoesProcessoAtualizacoes.id)
                setForm(() => {
                    return {
                        title: props.states.JuriAcoesProcessoAtualizacoes.title,
                        description: props.states.JuriAcoesProcessoAtualizacoes.description,
                        notes: props.states.JuriAcoesProcessoAtualizacoes.notes,
                        process_id: props.states.JuriAcoesProcessoAtualizacoes.process_id,
                        send: props.states.JuriAcoesProcessoAtualizacoes.send == 1 ? "true" : "false",
                        isPublic: props.states.JuriAcoesProcessoAtualizacoes.isPublic == 1 ? "true" : "false",
                        file: props.states.JuriAcoesProcessoAtualizacoes.file ? { name: props.states.JuriAcoesProcessoAtualizacoes.filename } : null
                    }
                })
            }
            setDataSelect(props.states.dataJuriAcoesProcessoForm)
        }
        //Limpeza do modal ao fechar
    }, [props.states.modalAcoesProcessoAtualizacoes]);





    useEffect(() => {
        if (checkFile == false) {
            setForm(() => {
                return { ...form, file: null }
            })
        }
    }, [checkFile])

    useEffect(() => {
        if (form.process_id === 'more') {
            props.setters.setOffsetJuriAcoesProcesso(props.states.offsetJuriAcoesProcesso + props.states.limitJuriAcoesProcesso)
            setForm(() => {
                return { ...form, process_id: '' }
            })
        }

    }, [form.process_id])

    useEffect(() => {
        setDataSelect(props.states.dataJuriAcoesProcessoForm)

    }, [props.states.dataJuriAcoesProcessoForm])

    return (
        <Transition.Root show={props.states.modalAcoesProcessoAtualizacoes} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalAcoesProcessoAtualizacoes(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalAcoesProcessoAtualizacoes(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <div className='p-3'>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                                            {props.states.isEditJuri === false ? 'Cadastrar' : 'Editar'} Atualização
                                        </h2>
                                        <p className="mt-2 text-sm leading-6 text-gray-600">
                                            Preencha os campos abaixo para cadastrar uma nova atualização para o processo.
                                        </p>
                                    </div>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            {/* LINHA 1 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-6">
                                                    {
                                                        !props.states.isEditJuri &&
                                                        <>

                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                                Selecione o Processo
                                                            </label>
                                                            <div className="mt-2">
                                                                <div
                                                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                                    <select
                                                                        onChange={e => setForm(() => {
                                                                            return { ...form, process_id: e.target.value }
                                                                        })}
                                                                        value={form?.process_id}
                                                                        className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6">
                                                                        <option value=''>Selecione</option>
                                                                        {
                                                                            dataSelect.length > 0 && dataSelect.map(item => {
                                                                                return <option value={item.id}>{item.number} - {item.caseTitle}</option>
                                                                            })
                                                                        }
                                                                        {
                                                                            !props.states.limitSearchJuriAcoesProcesso &&
                                                                            <option value='more'>Mostrar Mais</option>
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {errorForm.process_id === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                É obrigatório a seleção do processo
                                                            </p>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        props.states.isEditJuri &&
                                                        <div className="mt-2">
                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                                Processo
                                                            </label>
                                                            <p className="mt-2 ml-2 text-gray-900 text-sm">
                                                                {props.states.JuriAcoesProcessoAtualizacoes.process_number} - {props.states.JuriAcoesProcessoAtualizacoes.process_caseTitle}
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="sm:col-span-6">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tipo de Atualização
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, title: e.target.value }
                                                                })}
                                                                value={form?.title}
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6">
                                                                <option value=''>Selecione</option>
                                                                <option value='Andamento'>Andamento</option>
                                                                <option value='Decisão'>Decisão</option>
                                                                <option value='Despacho'>Despacho</option>
                                                                <option value='Petição'>Petição</option>
                                                                <option value='Recurso'>Recurso</option>
                                                                <option value='Sentença'>Sentença</option>
                                                                <option value='Outros'>Atualização</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.title === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o tipo da atualização
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 4 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-6">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Descrição da atualização
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, description: e.target.value }
                                                                })}
                                                                value={form?.description}
                                                                placeholder='Digite a descrição do atualização'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    {errorForm.description === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da descrição da atualização.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-6">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Notas da atualização
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, notes: e.target.value }
                                                                })}
                                                                value={form?.notes}
                                                                placeholder='Digite as notas da atualização'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    {errorForm.notes === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento das notas da atualização.
                                                    </p>
                                                    }
                                                </div>
                                            </div>

                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-6 flex w-full">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Visibilidade da atualização
                                                    </label>
                                                    <div className="mt-1 ml-4">
                                                        {/* Checkbox */}
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5 ">
                                                                <select onChange={e => setForm(() => {
                                                                    return { ...form, isPublic: e.target.value }
                                                                })}
                                                                    value={form?.isPublic}
                                                                    className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6">
                                                                    <option value=''>Selecione</option>
                                                                    <option value='true'>Público</option>
                                                                    <option value='false'>Privado</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {
                                                            errorForm.isPublic === true && <p class="mt-3 ml-2 text-pink-600 text-sm">
                                                                É obrigatório o preenchimento da visibilidade da atualização.
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-6 flex w-full">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Enviar para associados vinculados ao processo?
                                                    </label>
                                                    <div className="mt-1 ml-4">
                                                        {/* Checkbox */}
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5 ">
                                                                <select onChange={e => setForm(() => {
                                                                    return { ...form, send: e.target.value }
                                                                })}
                                                                    disabled={props.states.isEditJuri}
                                                                    value={form?.send}
                                                                    className={props.states.isEditJuri ? "pl-2 bg-gray-5000 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6" : "pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"}>
                                                                    <option value=''>Selecione</option>
                                                                    <option value='true'>Enviar</option>
                                                                    <option value='false'>Não enviar</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {
                                                            errorForm.send === true && <p class="mt-3 ml-2 text-pink-600 text-sm">
                                                                É obrigatório o preenchimento da visibilidade do deferimento.
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12 flex w-full">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Para inserir um arquivo, clique ao lado
                                                    </label>
                                                    <div className="mt-1 ml-4">
                                                        {/* Checkbox */}
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5">
                                                                <input
                                                                    id="comments"
                                                                    aria-describedby="comments-description"
                                                                    name="comments"
                                                                    value={checkFile}
                                                                    checked={checkFile === true ? true : false}
                                                                    onChange={e => setCheckFile(e.target.checked)}
                                                                    type="checkbox"
                                                                    className="focus:ring-blue-600 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Pergnuta sobre inserção de arquivo */}
                                            {
                                                checkFile === true && (
                                                    <>
                                                        <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                            <div className="sm:col-span-12">
                                                                <Arquivo file={saveFile} />
                                                            </div>
                                                        </div>
                                                        {
                                                            form.file && (
                                                                <p className="mt-2 ml-2 text-green-600 text-sm">
                                                                    Arquivo inserido com sucesso! {form.file.name || form.fileName}
                                                                </p>
                                                            )}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => props.setters.setModalAcoesProcessoAtualizacoes(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
