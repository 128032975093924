
import NavBar from "../../../Components/Navbar/navbar";
import NavBarPanel from "../../../Components/navMenu/juridico";
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../Context";
import { AuthContextJuri } from "../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import ModalConfirm from '../../../Components/Modal/Confirm'
import Footer from "../../../Components/Footer/footer";
//Tem que mudar 
import Table from '../../../Components/Tabela/TabelaTribunalJuridico'
import ModalTribunal from '../../../Components/Modal/TribunalJuri'
import ModalAcaoTribunal from '../../../Components/Modal/TribunaisAcoes'


const TribunaisJuridico = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        dataSystemTribunais,
        dataTribunal,
        getTribualQuery,
        createRegisterTribunal,
        updateRegisterTribunal,
        deleteRegisterTribunal,
        getLegalActionTribunal,
    } = useContext(AuthContextJuri);


    const [search, setSearch] = useState('')


    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }

    const loadData = async () => {
        setters.setLimitSearchJuriLawyer(false)
        setters.setOffsetJuriLawyer(0)
        setters.setDataJuriLawyer([])
        setters.setModalJuriLegalProcess(false)
        setters.setModalJuri(false)
        await dataSystemTribunais()
        await dataTribunal()
    }

    //NO DO USE EFFECT DEVE SER VERIFICADO CASO A DATA TENHA DADO JA PARA NAO CHAMAR DUAS VEZES


    const openModal = async () => {
        setters.setIsEditJuri(false)
        setters.setModalJuri(true)
    }

    const openModalEdit = async (data) => {
        setters.setIsEditJuri(true)
        setters.setJuriTribunais(data)
        setters.setModalJuri(true)
    }

    const actionModal = async (data) => {
        if (states.isEditJuri) {
            await updateRegisterTribunal(data)
        } else {
            await createRegisterTribunal(data)
        }
        loadData()
    }

    const searchLoadData = async () => {
        if (search == '') {
            await dataTribunal()
        } else {
            await getTribualQuery(search)
        }
    }

    const getLawyerSearch = async () => {
        if (search == '') {
            searchLoadData()
        } else {
            setters.setLimitSearchJuriTribunais(false)
            setters.setOffsetJuriTribunais(0)
            await getTribualQuery(search)
        }
    }

    const painelTribunal = async data => {
        hist(`/juridicoIndex/tribunais/${data.id}/varas`)
    }


    //Tem que mudar
    const headers = ['', 'Nome', 'Região', 'Data de cricação', 'Data de atualização']
    const [titleConfirm, setTitleConfirm] = useState('')
    const [descriptionConfirm, setDescriptionConfirm] = useState('')

    const deleteLawyer = async (data) => {
        setTitleConfirm('Excluir tribunal')
        setDescriptionConfirm(`Deseja excluir o tribunal ${data.name}?`)
        setters.setIsEditJuri(false)
        setters.setJuriOutSourced(data)
        setters.setConfirmModal(true)
    }

    const actionModalConfirm = async () => {
        await deleteRegisterTribunal(states.JuriOutSourced.id)
    }


    const next = async () => {
        if (states.limitSearchJuriTribunais && states.offsetJuriTribunais > 0) {
            return;
        } else {
            setters.setOffsetJuriTribunais(states.offsetJuriTribunais + states.limitJuriTribunais)
        }
    };

    const previous = async () => {
        if (states.limitSearchJuriTribunais && states.offsetJuriTribunais <= 0) {
            states.offsetJuriTribunais < 0 && setters.setOffsetJuriTribunais(0)
            return;
        } else {
            setters.setOffsetJuriTribunais(states.offsetJuriTribunais - states.limitJuriTribunais)
        }
    };



    const searchCourtAction = async (data) => {
        setters.setJuriTribunais(data)
        setters.setDataJuriAcoes([])
        setters.setOffsetJuriAcoes(0)
        setters.setLimitSearchJuriAcoes(false)
        const resp = await getLegalActionTribunal(data.id)
        if (resp == true) {
            setters.setModalAcoes(true)
        }
    }

    useEffect(() => {
        if (states.dataJuriTribunais.length == 0) loadData()
        checkTokenLoad()

        return () => {
            setters.setDataJuriTribunais([])
            setters.setOffsetJuriTribunais(0)
            setters.setLimitSearchJuriTribunais(false)
            setters.setModalJuriLegalProcess(false)
            setters.setModalJuri(false)
            setters.setJuriTribunais({})
            setters.setDataJuriLegalProcess([])
            setters.setOffsetJuriLegalProcess(0)
            setters.setLimitSearchJuriLegalProcess(false)
        }
    }, [])


    useEffect(() => {
        if (states.offsetJuriTribunais >= 0 && states.dataJuriTribunais.length > 0) {
            setters.setDataJuriOutSourced([])
            setters.setOfsettJuriOutSourced(0)
            searchLoadData()
        }
    }, [states.offsetJuriTribunais])




    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarPanel />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Tribunais</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os tribunais cadastrados.
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2  mt-4">
                                {states.dataSystemJuriTribunais?.map((card, index) => {
                                    return (
                                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                        }

                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            {
                                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                            }

                                                            <dd>
                                                                {
                                                                    card.value > 0 && index === -1 ?
                                                                        (<div className="text-lg font-medium text-red-500">
                                                                            {card.value}
                                                                        </div>) : (
                                                                            <div className="text-lg font-medium text-gray-900">
                                                                                {card.value}
                                                                            </div>)
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por tribunal</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setSearch(e.target.value)}
                                        value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getLawyerSearch()
                                            }
                                        }}
                                    />
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={getLawyerSearch}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Table add={openModal} search={searchCourtAction} edit={openModalEdit} deleteLaw={deleteLawyer} painel={painelTribunal}
                                title='Tribunais cadastrados' description='Registro dos tribunais cadastrados no jurídico' next={next} previous={previous} states={states}
                                headers={headers} infoTable={{ offset: states.offsetJuriTribunais, limit: states.limitJuriTribunais }} dataTable={states.dataJuriTribunais} />
                        </div>
                        <ModalAcaoTribunal states={states} setters={setters} data={[]} get={getLegalActionTribunal} />
                        <ModalConfirm states={states} action={actionModalConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
                        <ModalTribunal action={actionModal} states={states} setters={setters} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default TribunaisJuridico;