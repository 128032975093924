import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-toastify';




export default function ModalAcaoTrocaTribunal({ states, setters, action, tribunaisMore, varas }) {




    const [tribunal, setTribunal] = useState('')
    const [erroTribunal, setErroTribunal] = useState(false)
    const [msgErroTribunal, setMsgErroTribunal] = useState('')

    const [processos, setProcessos] = useState([])
    const [errorProcessos, setErrorProcessos] = useState([])

    const [dataVaras, setDataVaras] = useState([])
    const [dataTribunal, setDataTribunal] = useState([])

    const submit = async (e) => {
        e.preventDefault()
        let error = false

        if (tribunal === states.JuriAcoes.court_id) {
            setErroTribunal(true)
            setMsgErroTribunal('A ação já está cadastrada neste tribunal')
            error = true
        }

        if (tribunal === '') {
            setErroTribunal(true)
            setMsgErroTribunal('Selecione o tribunal para onde deseja transferir a ação')
            error = true
        } else {
            setErroTribunal(false)
            setMsgErroTribunal('')
        }

        if (states.JuriAcoes?.legalProcess?.length > 0) {
            const lengthProcessos = states.JuriAcoes?.legalProcess?.length
            let newErrorProcessos = []
            for (let i = 0; i < lengthProcessos; i++) {
                if (processos[i] === undefined || processos[i] === '') {
                    error = true
                    newErrorProcessos.push(true)
                } else {
                    newErrorProcessos.push(false)
                }
            }
            setErrorProcessos(newErrorProcessos)


        }
        if (error === true) {
            error = false
            return
        }

        let obj = {}
        if (states.JuriAcoes?.legalProcess?.length > 0) {
            obj = {
                court_id: tribunal,
                legalProcess: processos
            }
        } else {
            obj = {
                court_id: tribunal
            }
        }


        action(obj, states.JuriAcoes.id)
    }

    const loadVaras = async (index) => {
        if (states.JuriAcoes?.legalProcess?.length > 0) {
            setDataVaras([])
            setProcessos([])
            const resp = await varas(tribunal)
            if (!resp) {
                setTribunal('')
                toast.warning('O tribunal selecionado não possui varas cadastradas')
            }
        }

    }

    const changeVara = (index, value, id) => {
        if (value === 'more') {
            let newProcessos = [...processos]
            newProcessos[index] = 'more'
            setProcessos(newProcessos)
            return
        } else if (value === '') {
            let newProcessos = [...processos]
            newProcessos[index] = ''
            setProcessos(newProcessos)
            return
        }
        let newProcessos = [...processos]
        newProcessos[index] = { process_id: id, courtSection: value }
        setProcessos(newProcessos)
    }




    useEffect(() => {
        if (states.modalTrocaDeTribunal === false) {
            setTribunal('')
            setErroTribunal(false)
            setMsgErroTribunal('')
            setDataTribunal([])
            setDataVaras([])
            setProcessos([])
            setErrorProcessos([])
            setErrorProcessos([])
            setters.setOffsetJuriVaras(0)
            setters.setLimitSearchJuriVaras(false)
            setters.setDataJuriVaras([])

            setters.setOffsetJuriTribunais(0)
            setters.setLimitSearchJuriTribunais(false)
            setters.setDataJuriTribunais([])
        }
    }, [states.modalTrocaDeTribunal]);

    useEffect(() => {
        if (tribunal === 'more') {
            tribunaisMore()
            setTribunal('')
        } else {
            if (tribunal !== '') {
                loadVaras()
                setErroTribunal(false)
            } else {
                setDataVaras([])
                setters.setOffsetJuriVaras(0)
                setters.setLimitSearchJuriVaras(false)
                setters.setDataJuriVaras([])
            }
        }
    }, [tribunal])

    useEffect(() => {
        if (processos.length > 0) {
            let haveMore = false
            processos.map(item => {
                if (item === 'more') {
                    haveMore = true
                    item = ''
                }
            })
            if (haveMore === true) {
                const filterOutMore = processos.filter(item => item !== 'more')
                setProcessos(filterOutMore)
                setters.setOffsetJuriVaras(states.offsetJuriVaras + states.limitJuriVaras)

            }
        }
    }, [processos])

    useEffect(() => {
        // setDataTribunal(states.dataJuriTribunais)
        const tribunalNaoSelecionado = states.dataJuriTribunais.filter(item => item.id !== states.JuriAcoes.court_id)
        setDataTribunal(tribunalNaoSelecionado)
    }, [states.dataJuriTribunais])

    useEffect(() => {
        setDataVaras(states.dataJuriVaras)
    }, [states.dataJuriVaras])

    useEffect(() => {
        if (states.modalTrocaDeTribunal) varas(tribunal)
    }, [states.offsetJuriVaras])



    return (
        <Transition.Root show={states.modalTrocaDeTribunal} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setters.setModalTrocaDeTribunal(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[85%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalTrocaDeTribunal(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Ação</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Informações da ação
                                    </p>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={submit}>
                                    <div className="space-y-12">
                                        {/* Ação e o tribunal */}
                                        <div
                                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                                    Troca de tribunal:
                                                </h2>
                                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                                    Ação: {states.JuriAcoes.name}
                                                </p>
                                            </div>
                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                                <div className="sm:col-span-6">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Selecione o Tribunal para onde deseja transferir a ação
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={tribunal}
                                                                onChange={e => setTribunal(e.target.value)}
                                                            >
                                                                <option value="">Selecione</option>
                                                                {
                                                                    dataTribunal.map(item => {
                                                                        return (
                                                                            <option value={item.id}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    states.limitSearchJuriTribunais === false &&
                                                                    <option value="more">Carregar mais</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {erroTribunal === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        {msgErroTribunal}
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* Processos e varas */}
                                        {
                                            (states.JuriAcoes?.legalProcess?.length > 0 && (tribunal !== "" && tribunal !== 'more')) &&
                                            <div
                                                className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                                        Alteração de vara:
                                                    </h2>
                                                    <p className="mt-2 text-sm leading-6 text-gray-600">
                                                        Troque as varas dos processos vinculados a esta ação
                                                    </p>
                                                </div>
                                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                                    {
                                                        states.JuriAcoes?.legalProcess?.map((item, index) => {
                                                            return (
                                                                <div className="sm:col-span-3">
                                                                    <label
                                                                        htmlFor="title"
                                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                                        Número do processo: {item.number}
                                                                    </label>
                                                                    <div className="mt-2">
                                                                        <div
                                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                                            <select className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                                value={processos[index]?.courtSection}
                                                                                onChange={e =>
                                                                                    changeVara(index, e.target.value, item.id)}
                                                                            >
                                                                                <option value="">Selecinoe a Vara</option>
                                                                                {
                                                                                    dataVaras.map(item => {
                                                                                        return (
                                                                                            <option value={item.id}>{item.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {
                                                                                    states.limitSearchJuriVaras === false &&
                                                                                    <option value="more">Carregar mais</option>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        {
                                                                            errorProcessos[index] === true &&
                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                Selecione a vara para o processo
                                                                            </p>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalTrocaDeTribunal(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
