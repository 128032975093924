
import NavBar from "../../../../Components/Navbar/navbar";
import SpinnerOverlay from "../../../../Components/SpinnerOverlay";
import { useNavigate, useParams } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../../Context";
import { AuthContextJuri } from "../../../../Context/juri";
import { useContext, useEffect, useState } from "react";

import Cookies from 'js-cookie'
import ModalConfirm from '../../../../Components/Modal/Confirm'
import Footer from "../../../../Components/Footer/footer";
//Tem que mudar 
import Table from '../../../../Components/Tabela/TabelaTribunalVarasJuridico'

import ModalVaraProcessos from '../../../../Components/Modal/VaraProcessos'

import ModalVara from '../../../../Components/Modal/VaraJuri'


const TribunalVaras = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        dataSystemTribunal,
        dataTribunalId,
        dataVara,
        getVaraQuery,
        createVara,
        updateVara,
        deleteVara,
        getLegalProcessVara
    } = useContext(AuthContextJuri);

    const { id } = useParams()

    const [search, setSearch] = useState('')


    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }

    const loadData = async () => {
        Promise.all([
            dataSystemTribunal(id),
            dataTribunalId(id),
            dataVara(id)
        ])
    }




    //Tem que mudar
    const headers = ['', 'Nome', 'Data de cricação', 'Data de atualização']
    const [titleConfirm, setTitleConfirm] = useState('')
    const [descriptionConfirm, setDescriptionConfirm] = useState('')


    const next = async () => {
        if (states.limitSearchJuriVaras && states.offsetJuriVaras > 0) {
            return;
        } else {
            setters.setOffsetJuriVaras(states.offsetJuriVaras + states.limitJuriVaras)
        }
    };

    const previous = async () => {
        if (states.limitSearchJuriVaras && states.offsetJuriVaras <= 0) {
            states.offsetJuriVaras < 0 && setters.setOffsetJuriVaras(0)
            return;
        } else {
            setters.setOffsetJuriVaras(states.offsetJuriVaras - states.limitJuriVaras)
        }
    };

    const loadDataSearch = async () => {
        if (search === '') {
            await dataVara(id)
        } else {
            const resp = await getVaraQuery(id, search)
            if (!resp) {
                setSearch('')
            }
        }
    }

    const searchAction = async () => {
        if (search === '') {
            return;
        } else {
            setters.setLimitSearchJuriVaras(false)
            setters.setOffsetJuriVaras(0)
            const resp = await getVaraQuery(id, search)
            if (!resp) {
                setSearch('')
            }
        }
    }

    const openModalAdd = () => {
        setters.setModalJuriVara(true)
        setters.setIsEditJuri(false)
    }

    const openModalEdit = (obj) => {
        setters.setModalJuriVara(true)
        setters.setIsEditJuri(true)
        setters.setJuriVaras(obj)
    }

    const actionModal = async (obj) => {
        if (states.isEditJuri) {
            await updateVara(obj, obj.id)
        } else {
            await createVara(obj)
        }
    }

    const deleteVaraModal = async (obj) => {
        setters.setConfirmModal(true)
        setters.setJuriVaras(obj)
        setTitleConfirm('Deletar Vara')
        setDescriptionConfirm(`Deseja deletar a vara ${obj.name} ?`)
    }
    const deleteVaraAction = async () => {
        setters.setConfirmModal(false)
        await deleteVara(id, states.JuriVaras.id)
    }

    const openModalVaraProcessos = async (obj) => {
        setters.setModalJuriVaraProcesso(true)
        setters.setJuriVaras(obj)
        await getLegalProcessVara(obj.id)
    }


    useEffect(() => {
        loadData()
        checkTokenLoad()
        return () => {
            setters.setDataJuriVaras([])
            setters.setJuriVaras({})
            setters.setOffsetJuriVaras(0)
            setters.setLimitSearchJuriVaras(false)
            setters.setJuriTribunais({})
            setters.setDataJuriVaraProcesso([])
            setters.setOffsetJuriVaraProcesso(0)
            setters.setLimitSearchJuriVaraProcesso(false)
            setters.setModalJuriVaraProcesso(false)
        }
    }, [])

    useEffect(() => {
        if (states.dataJuriVaras.length > 0) {
            loadDataSearch()
        }
    }, [states.offsetJuriVaras])




    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="flex items-start  justify-between mb-5 ">
                                <button onClick={() => hist('/juridicoIndex/tribunais')} className="text-blue-500 font-semibold hover:text-blue-700 mt-4 border px-4 py-2 rounded-[10px] hover:shadow">Voltar</button>
                            </div>
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Página do tribunal {states.JuriTribunais?.name}</h1>
                            <div className="p-3 border rounded-[10px] mt-4">
                                <h4 className="text-base font-semibold leading-6 text-gray-900">Informações do tribunal</h4>
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 mt-4">
                                    <div>
                                        <h1 className="text-sm font-semibold text-gray-500">Região</h1>
                                        <p className="text-sm font-semibold text-gray-900">{states.JuriTribunais?.region}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 mt-4">
                                {states.dataSystemJuriTribunal?.map((card, index) => {
                                    return (
                                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                        }

                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            {
                                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                            }

                                                            <dd>
                                                                {
                                                                    card.value > 0 && index === -1 ?
                                                                        (<div className="text-lg font-medium text-red-500">
                                                                            {card.value}
                                                                        </div>) : (
                                                                            <div className="text-lg font-medium text-gray-900">
                                                                                {card.value}
                                                                            </div>)
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por Vara</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o nome da vara que deseja' type="text" onChange={e => setSearch(e.target.value)}
                                        value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                searchAction()
                                            }
                                        }}
                                    />
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={searchAction}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Table title='Varas cadastradas' description={`Registro das varas cadastradas no tribunal ${states.JuriTribunais.name}`}
                                next={next} previous={previous} states={states} headers={headers} add={openModalAdd} edit={openModalEdit}
                                infoTable={{ offset: states.offsetJuriVaras, limit: states.limitJuriVaras }} deleteVara={deleteVaraModal} search={openModalVaraProcessos}
                                dataTable={states.dataJuriVaras} />
                        </div>
                        <ModalVaraProcessos states={states} setters={setters} data={[]} get={getLegalProcessVara} />
                        <ModalConfirm states={states} setters={setters} title={titleConfirm} description={descriptionConfirm} action={deleteVaraAction} />
                        <ModalVara states={states} setters={setters} action={actionModal} court_id={id} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default TribunalVaras;