import { toast } from 'react-toastify'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'


import Navbar from '../../../Components/Navbar/navbar'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Table from '../../../Components/Tabela/TabelaAssociadosStatus'
import NavbarMenu from '../../../Components/navMenu/contabil'
import Modal from '../../../Components/Modal/ModalAssociadoExportacao'


Chart.register(...registerables);

const Debitos = () => {

    const { states, setters } = useContext(AuthContext)
    const {
        dataSystemAssociateStatus,
        getASsociateStatus,
        getASsociateStatusQuery,
        dataAllFundo,
        exportAssociate
    } = useContext(AuthContextApi)
    const [querySearch, setQuerySearch] = useState('')
    const [status, setStatus] = useState('all')


    // const titleConfirm = 'Exclusão de terceirizado.'
    // const descriptionConfirm = 'Ao realizar essa ação, o terceirizado será excluído, confirma exclusão?'
    const headers = ['Status', 'Cpf', 'Nome', 'Email', 'Empresa', 'Fundo']



    const load = async () => {
        await dataSystemAssociateStatus()
        await getASsociateStatus(status)
    }

    const next = async () => {
        if (states.limitSearchAssociateStatus && states.offsetAssociateStatus > 0) {
            return;
        } else {
            setters.setOffsetAssociateStatus(states.offsetAssociateStatus + states.limitAssociateStatus)
        }
    };

    const previous = async () => {
        if ((states.offsetAssociateStatus - states.limitAssociateStatus) < 0) {
            states.offsetAssociateStatus < 0 && setters.setOffsetAssociateStatus(0)
            return;
        } else {
            setters.setOffsetAssociateStatus(states.offsetAssociateStatus - states.limitAssociateStatus)
        }
    };

    const dataSearch = async () => {
        if (querySearch === '') {
            await getASsociateStatus(status)
        } else {
            await getASsociateStatusQuery(status, querySearch)
        }

    }

    const openModal = () => {
        setters.setModalAssociateStatus(true)
    }

    const loadFundos = async () => {
        await dataAllFundo()
    }

    const actionModal = async (data, status) => {
        const response = await exportAssociate(data, status)
        if (response === true) {
            setters.setModalAssociateStatus(false);
        }
    }



    const data = {
        labels: states.dataSystemAssociateStatus?.graphic?.filiate?.map(elements => elements.month),
        datasets: [
            {
                label: 'Associados do SINPREV',
                data: states.dataSystemAssociateStatus?.graphic?.filiate?.map(elements => elements.total),
                backgroundColor: 'rgba(63, 81, 181, 0.5)',
                borderColor: 'rgba(63, 81, 181, 1)',
                pointBackgroundColor: 'rgba(63, 81, 181, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value) => {
                        return `${value}`;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y || 0;
                        return `${label}: ${value}`;
                    },
                },
            },
        },
    };

    const dataAssociateFlow = {
        labels: states.dataSystemAssociateStatus?.graphic?.filiate?.map(elements => elements.month),
        datasets: [
            {
                label: 'Filiados',
                data: states.dataSystemAssociateStatus?.graphic?.filiate?.map(elements => elements.total),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Desfiliados',
                data: states.dataSystemAssociateStatus?.graphic?.unfiliate?.map(elements => elements.total),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };



    useEffect(() => {
        load()

        // const [dataSystemAssociateStatus, setDataSystemAssociateStatus] = useState([])
        // const [dataAssociateStatus, setDataAssociateStatus] = useState([])
        // const [AssociateStatus, setAssociateStatus] = useState({})
        // const [offsetAssociateStatus, setOffsetAssociateStatus] = useState(0)
        // const [limitAssociateStatus, setLimitAssociateStatus] = useState(jsonConfig.limitShow)
        // const [limitSearchAssociateStatus, setLimitSearchAssociateStatus] = useState(false)
        // const [modalAssociateStatus, setModalAssociateStatus] = useState(false)
        return () => {
            setters.setDataSystemAssociateStatus([])
            setters.setDataAssociateStatus([])
            setters.setDataFundo([])
            setters.setAssociateStatus({})
            setters.setOffsetAssociateStatus(0)
            setters.setLimitSearchAssociateStatus(false)
            setters.setModalAssociateStatus(false)
            setQuerySearch('')
            setStatus('all')
        }
    }, [])

    useEffect(() => {
        if (states.dataAssociateStatus?.length > 0) {
            dataSearch()
        }
    }, [states.offsetAssociateStatus])


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarMenu />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Associados</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os associados do SINPREV
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                                {states.dataSystemAssociateStatus?.statistic?.map((card, index) => (
                                    <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    {
                                                        card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                    }

                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        {
                                                            card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                        }

                                                        <dd>
                                                            {
                                                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                            }
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}

                            </div>

                            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                                <div class="flex flex-col items-center p-6 mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <h2 class="text-xl font-bold">Evolução dos novos filiados</h2>
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <Line data={data} options={options} />
                                    </div>
                                </div>

                                <div class="flex flex-col items-center p-6 mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <h2 class="text-xl font-bold">Fluxo de filiados/desfiliados</h2>
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <Line data={dataAssociateFlow} options={options} />
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-8">
                                <h1 className='text-xl font-semibold'>Busca por associado</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <select className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500 w-[85%] sm:w-[25%] mr-4" onChange={(e) => setStatus(e.target.value)}>
                                        <option value='all'>Todos</option>
                                        <option value='active'>Ativos</option>
                                        <option value='inactive'>Inativos</option>
                                    </select>

                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setQuerySearch(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                                        value={querySearch} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[85%] sm:w-[45%] sm:mt-0 mt-4" />

                                    <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0 mt-4 sm:mt-0">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataSearch()}>
                                            Aplicar
                                        </button>
                                        <button className="ml-5 bg-green-500 hover:bg-green-300 text-white font-bold py-1 px-8 rounded" onClick={openModal} >
                                            Relatório
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <Table title='Associados' description='Registro dos associados presentes no SINPREV' next={next} previous={previous} negotiation={openModal}
                                states={states} setters={setters} headers={headers} infoTable={{ offset: states.offsetAssociateStatus, limit: states.limitAssociateStatus }} dataTable={states.dataAssociateStatus} />
                        </div>
                    </div>
                </div>
            </div >
            <Modal states={states} setters={setters} action={actionModal} fundos={loadFundos} />
        </>
    )
}


export default Debitos