
import NavBar from "../../../Components/Navbar/navbar";
import NavBarPanel from "../../../Components/navMenu/juridico";
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../Context";
import { AuthContextJuri } from "../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import ModalConfirm from '../../../Components/Modal/Confirm'
import Footer from "../../../Components/Footer/footer";
//Tem que mudar 
import Table from '../../../Components/Tabela/TabelaEscritorioJuridico'
import ModalAdvogado from '../../../Components/Modal/EscritorioJuri'
import ModalAdvogadoProcesso from '../../../Components/Modal/EscritorioProcesso'


const EscritoriosJuridico = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        loadOutSourcedCompany,
        dataSystemEscritorio,
        dataEscritorio,
        getEscritoriorQuery,
        createRegisterEscritorio,
        updateRegisterEscritorio,
        deleteRegisterEscritorio,
        getLegalActionsFirm } = useContext(AuthContextJuri);


    const [search, setSearch] = useState('')


    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }

    const loadData = async () => {
        setters.setLimitSearchJuriLawyer(false)
        setters.setOffsetJuriLawyer(0)
        setters.setDataJuriLawyer([])
        setters.setModalJuriLegalProcess(false)
        setters.setModalJuri(false)
        await dataSystemEscritorio()
        await dataEscritorio()
    }

    //NO DO USE EFFECT DEVE SER VERIFICADO CASO A DATA TENHA DADO JA PARA NAO CHAMAR DUAS VEZES


    const openModal = async () => {
        setters.setLimitSearchJuriOutSourced(false)
        setters.setOfsettJuriOutSourced(0)
        setters.setDataJuriOutSourced([])
        const check = await loadOutSourcedCompany(true)
        if (check) {
            setters.setIsEditJuri(false)
            setters.setModalJuri(true)
        }
    }

    const openModalEdit = async (data) => {
        await loadOutSourcedCompany(true)
        setters.setIsEditJuri(true)
        setters.setJuriOutSourced(data)
        setters.setModalJuri(true)
    }

    const actionModal = async (data) => {
        if (states.isEditJuri) {
            await updateRegisterEscritorio(data)
        } else {
            await createRegisterEscritorio(data)
        }
        loadData()
    }

    const getLawyerSearch = async () => {
        if (search == '') {
            loadData()
        } else {
            await getEscritoriorQuery(search)
        }
    }


    //Tem que mudar
    const headers = ['', 'Nome', 'CPF/CNPJ', 'Email', 'Email do sistema', 'Criador', 'Data de criação', 'Data de atualização']
    const [titleConfirm, setTitleConfirm] = useState('')
    const [descriptionConfirm, setDescriptionConfirm] = useState('')

    const deleteLawyer = async (data) => {
        setTitleConfirm('Excluir advogado')
        setDescriptionConfirm(`Deseja excluir o escritório ${data.name}?`)
        setters.setIsEditJuri(false)
        setters.setJuriOutSourced(data)
        setters.setConfirmModal(true)
    }

    const actionModalConfirm = async () => {
        await deleteRegisterEscritorio(states.JuriOutSourced.id)
        loadData()
    }


    const next = async () => {
        if (states.limitSearchJuriEscritorio && states.offsetJuriEscritorio > 0) {
            return;
        } else {
            setters.setOffsetJuriEscritorio(states.offsetJuriEscritorio + states.limitJuriEscritorio)
        }
    };

    const previous = async () => {
        if (states.limitSearchJuriEscritorio && states.offsetJuriEscritorio <= 0) {
            states.offsetJuriEscritorio < 0 && setters.setOffsetJuriEscritorio(0)
            return;
        } else {
            setters.setOffsetJuriEscritorio(states.offsetJuriEscritorio - states.limitJuriEscritorio)
        }
    };

    const moreOutSourced = async () => {
        if (states.limitSearchJuriOutSourced && states.offsetJuriOutSourced > 0) {
            return;
        } else {
            setters.setOfsettJuriOutSourced(states.offsetJuriOutSourced + states.limitJuriOutSourced)
        }
    };

    const dataOutSourced = async () => {
        await loadOutSourcedCompany(true)
    }

    const searchlawyer = async (data) => {
        //Resetar os offset, e os dados do processo aqui 
        setters.setJuriEscritorio(data)
        setters.setDataJuriLegalProcess([])
        setters.setOffsetJuriLegalProcess(0)
        setters.setLimitSearchJuriLegalProcess(false)
        const resp = await getLegalActionsFirm(data.id)
        if (resp == true) {
            setters.setModalJuriLegalProcess(true)
        }
    }

    useEffect(() => {
        if (states.dataJuriEscritorio.length == 0) loadData()
        checkTokenLoad()

        return () => {
            setters.setDataJuriEscritorio([])
            setters.setOffsetJuriEscritorio(0)
            setters.setLimitSearchJuriEscritorio(false)
            setters.setModalJuriLegalProcess(false)
            setters.setModalJuri(false)
            setters.setJuriEscritorio({})
            setters.setDataJuriLegalProcess([])
            setters.setOffsetJuriLegalProcess(0)
            setters.setLimitSearchJuriLegalProcess(false)
        }
    }, [])


    useEffect(() => {
        if (states.offsetJuriEscritorio >= 0 && states.dataJuriEscritorio.length > 0) {
            setters.setDataJuriOutSourced([])
            setters.setOfsettJuriOutSourced(0)
            loadData()
        }
    }, [states.offsetJuriEscritorio])


    useEffect(() => {
        if (states.offsetJuriOutSourced > 0 && states.dataJuriOutSourced.length > 0) {
            dataOutSourced()
        }
    }, [states.offsetJuriOutSourced])


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarPanel />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Escritórios</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os escritórios cadastrados.
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-4">
                                {states.dataSystemJuriEscritorio?.map((card, index) => {
                                    return (
                                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                        }

                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            {
                                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                            }

                                                            <dd>
                                                                {
                                                                    card.value > 0 && index === -1 ?
                                                                        (<div className="text-lg font-medium text-red-500">
                                                                            {card.value}
                                                                        </div>) : (
                                                                            <div className="text-lg font-medium text-gray-900">
                                                                                {card.value}
                                                                            </div>)
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por escritório</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setSearch(e.target.value)}
                                        value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getLawyerSearch()
                                            }
                                        }}
                                    />
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={getLawyerSearch}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Table add={openModal} search={searchlawyer} edit={openModalEdit} deleteLaw={deleteLawyer} title='Escritórios cadastrados' description='Registro dos escritórios cadastrados no jurídico' next={next} previous={previous} states={states} headers={headers} infoTable={{ offset: states.offsetJuriEscritorio, limit: states.limitJuriEscritorio }} dataTable={states.dataJuriEscritorio} />
                        </div>
                        <ModalAdvogadoProcesso states={states} setters={setters} data={[]} get={getLegalActionsFirm} />
                        <ModalConfirm states={states} action={actionModalConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
                        <ModalAdvogado action={actionModal} states={states} setters={setters} more={moreOutSourced} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default EscritoriosJuridico;