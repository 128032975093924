import { toast } from 'react-toastify'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'


import Navbar from '../../../Components/Navbar/navbar'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Table from '../../../Components/Tabela/TabelaAssociadosStatus'
import NavbarMenu from '../../../Components/navMenu/contabil'
import Modal from '../../../Components/Modal/ModalFaturasExportacao'
import moment from 'moment'


Chart.register(...registerables);

const Faturas = () => {

    const { states, setters } = useContext(AuthContext)
    const {
        dataSystemBalanco,
        exportBalanco
    } = useContext(AuthContextApi)

    const [stap1, setStap1] = useState(true)
    const [stap2, setStap2] = useState(false)
    const [errorInitialDate, setErrorInitialDate] = useState(false)
    const [errorFinalDate, setErrorFinalDate] = useState(false)






    const data = {
        labels: states.dataSystemBalanco?.graphic?.map(elements => elements.month),
        datasets: [
            {
                label: 'Entradas do SINPREV',
                data: states.dataSystemBalanco?.graphic?.map(elements => elements.total_entries / 100),
                backgroundColor: 'rgba(63, 81, 181, 0.5)',
                borderColor: 'rgba(63, 81, 181, 1)',
                pointBackgroundColor: 'rgba(63, 81, 181, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value) => {
                        return `${value}`;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y || 0;
                        return `${label}: ${value}`;
                    },
                },
            },
        },
    };

    const dataAssociateFlow = {
        labels: states.dataSystemBalanco?.graphic?.map(elements => elements.month),
        datasets: [
            {
                label: 'Entradas',
                data: states.dataSystemBalanco?.graphic?.map(elements => elements.total_entries / 100),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Saídas',
                data: states.dataSystemBalanco?.graphic?.map(elements => elements.total_exits / 100),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };


    const actionSearch = async () => {
        let error = false;
        if (states.initial_dateBalanco === '') {
            setErrorInitialDate(true)
            error = true
        } else {
            setErrorInitialDate(false)
        }
        if (states.final_dateBalanco === '') {
            setErrorFinalDate(true)
            error = true
        } else {
            setErrorFinalDate(false)
        }

        if (error) {
            error = false
            return;
        }

        const response = await dataSystemBalanco()
        if (response) {
            setStap1(false)
            setStap2(true)
        }
    }

    const novaBusca = () => {
        setters.setDataSystemBalanco([])
        setters.setInitial_dateBalanco('')
        setters.setFinal_dateBalanco('')
        setters.setModalBalanco(false)
        setStap1(true)
        setStap2(false)
        setErrorFinalDate(false)
        setErrorInitialDate(false)
    }

    const exportData = async () => {
        setters.setModalBalanco(true)
    }

    const actionModal = async (data) => {
        const obj = {
            ...data,
            initial_date: states.initial_dateBalanco,
            final_date: states.final_dateBalanco
        }
        const response = await exportBalanco(obj)
        if (response) {
            setters.setModalBalanco(false)
        }
    }



    useEffect(() => {
        // const [dataSystemBalanco, setDataSystemBalanco] = useState([])
        // const [modalBalanco, setModalBalanco] = useState(false)
        // const [initial_dateBalanco, setInitial_dateBalanco] = useState('')
        // const [final_dateBalanco, setFinal_dateBalanco] = useState('')
        if (states.initial_dateBalanco !== '' && states.final_dateBalanco !== '') {
            actionSearch()
        }

        return () => {
            setters.setDataSystemBalanco([])
            setters.setInitial_dateBalanco('')
            setters.setFinal_dateBalanco('')
            setters.setModalBalanco(false)
            setStap1(true)
            setStap2(false)
            setErrorFinalDate(false)
            setErrorInitialDate(false)
        }
    }, [])



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarMenu />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Faturas</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as faturas do SINPREV
                            </p>
                            {
                                (!stap1 && stap2) && (
                                    <>
                                        <button className="ml-1 mt-6 bg-green-500 hover:bg-green-300 text-white font-bold py-1 px-8 rounded" onClick={novaBusca} >
                                            Nova busca
                                        </button>
                                        <button className="ml-1 mt-6 bg-blue-500 hover:bg-blue-300 text-white font-bold py-1 px-8 rounded" onClick={exportData} >
                                            Exportar Faturas
                                        </button>
                                    </>
                                )
                            }

                            {
                                (stap1 && !stap2) && (
                                    <>
                                        <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-8 hover:shadow">
                                            <h1 className='text-xl font-semibold'>Selecione as datas para o análise</h1>
                                            <div className='flex lg:flex-row flex-col items-center justify-center mt-4 w-full'>
                                                <div className="flex sm:flex-row flex-col w-full items-center md:mr-8 ml-4 mb-4 md:mb-0 mt-4 sm:mt-0 ">
                                                    <div className="flex flex-col items-start justify-start w-full ">
                                                        <p className='text-gray-500 font-semibold text-base'>Data inicial</p>
                                                        <input
                                                            type="date"
                                                            className="mt-2 w-full h-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                                            value={states.initial_dateBalanco}
                                                            onChange={(e) => setters.setInitial_dateBalanco(e.target.value)}
                                                            max={moment().subtract(1, 'month').format('YYYY-MM-DD')}
                                                        />
                                                        {
                                                            errorInitialDate && <p className='text-red-500'>Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    <div className="flex flex-col items-start justify-start w-full mt-4 sm:mt-0 ml-0 sm:ml-4">
                                                        <p className='text-gray-500 font-semibold text-base'>Data final</p>
                                                        <input
                                                            type="date"
                                                            className="mt-2 w-full h-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                                            value={states.final_dateBalanco}
                                                            onChange={(e) => setters.setFinal_dateBalanco(e.target.value)}
                                                            max={moment().format('YYYY-MM-DD')}
                                                        />
                                                        {
                                                            errorFinalDate && <p className='text-red-500'>Campo obrigatório</p>
                                                        }
                                                    </div>
                                                </div>
                                                <button className="ml-5 mt-6 bg-green-500 hover:bg-green-300 text-white font-bold py-1 px-8 rounded" onClick={() => actionSearch(false)} >
                                                    Continuar
                                                </button>
                                            </div>
                                        </div>

                                    </>
                                )
                            }

                            {
                                (!stap1 && stap2) && (
                                    <>
                                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                                            {states.dataSystemBalanco?.statistic?.map((card, index) => (
                                                <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                                    <div className="p-5">
                                                        <div className="flex items-center">
                                                            <div className="flex-shrink-0">
                                                                {
                                                                    card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                                }

                                                            </div>
                                                            <div className="ml-5 w-0 flex-1">
                                                                <dl>
                                                                    {
                                                                        card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                                    }

                                                                    <dd>
                                                                        {
                                                                            card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                                        }
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                                            <div class="flex flex-col items-center p-6 mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <h2 class="text-xl font-bold">Evolução das Entradas</h2>
                                                <div style={{ height: '100%', width: '100%' }}>
                                                    <Line data={data} options={options} />
                                                </div>
                                            </div>

                                            <div class="flex flex-col items-center p-6 mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <h2 class="text-xl font-bold">Fluxo de Caixa</h2>
                                                <div style={{ height: '100%', width: '100%' }}>
                                                    <Line data={dataAssociateFlow} options={options} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }



                        </div>
                    </div>
                </div>
            </div >
            <Modal states={states} setters={setters} action={actionModal} />
        </>
    )
}


export default Faturas;