import { toast } from 'react-toastify'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'


import Navbar from '../../../Components/Navbar/navbar'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Table from '../../../Components/Tabela/TabelaDebitos'
import NavbarMenu from '../../../Components/navMenu/contabil'
import ModalNegociacao from '../../../Components/Modal/NegociacaoData'


const Debitos = () => {

    const { states, setters } = useContext(AuthContext)
    const {
        dataSystemDebitos,
        getDebitos,
        getDebitosQuery,
        generateNegotiation,
        exportDataDebits
    } = useContext(AuthContextApi)
    const [querySearch, setQuerySearch] = useState('')
    const [relatorio, setRelatorio] = useState(false)


    // const titleConfirm = 'Exclusão de terceirizado.'
    // const descriptionConfirm = 'Ao realizar essa ação, o terceirizado será excluído, confirma exclusão?'
    const headers = ['Cpf', 'Nome', 'Email', 'Total', '']

    // const [dataSystemDebitos, setDataSystemDebitos] = useState([])
    // const [dataDebitos, setDataDebitos] = useState([])
    // const [Debitos, setDebitos] = useState({})
    // const [offsetDebitos, setOffsetDebitos] = useState(0)
    // const [limitDebitos, setLimitDebitos] = useState(jsonConfig.limitShow)
    // const [limitSearchDebitos, setLimitSearchDebitos] = useState(false)

    const load = async () => {
        await dataSystemDebitos()
        await getDebitos()
    }

    const next = async () => {
        if (states.limitSearchDebitos && states.offsetDebitos > 0) {
            return;
        } else {
            setters.setOffsetDebitos(states.offsetDebitos + states.limitDebitos)
        }
    };

    const previous = async () => {
        if ((states.offsetDebitos - states.limitDebitos) < 0) {
            states.offsetDebitos < 0 && setters.setOffsetDebitos(0)
            return;
        } else {
            setters.setOffsetDebitos(states.offsetDebitos - states.limitDebitos)
        }
    };

    const dataSearch = async () => {
        if (querySearch === '') {
            await getDebitos()
        } else {
            await getDebitosQuery(querySearch)
        }

    }

    const openModal = (data) => {
        setters.setModalDebitos(true)
        setters.setDebitos(data)
    }

    const actionModal = async (data) => {
        setters.setModalDebitos(false)
        await generateNegotiation({ associate_id: states.Debitos.id, experies_at: data })
        setters.setDebitos({})
    }

    const actionExportData = async () => {
        setRelatorio(true)
        await exportDataDebits()
    }

    useEffect(() => {
        load()
        return () => {
            setters.setDataSystemDebitos([])
            setters.setDataDebitos([])
            setters.setDebitos({})
            setters.setOffsetDebitos(0)
            setters.setLimitSearchDebitos(false)
            setters.setModalDebitos(false)
            setQuerySearch('')
        }
    }, [])

    useEffect(() => {
        if (states.dataDebitos.length > 0) {
            dataSearch()
        }
    }, [states.offsetDebitos])

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarMenu />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Débitos</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os associados com débitos no SINPREV
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                                {states.dataSystemDebitos.map((card, index) => (
                                    <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    {
                                                        card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                    }

                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        {
                                                            card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                        }

                                                        <dd>
                                                            {
                                                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                            }
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}

                            </div>


                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por associado</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setQuerySearch(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                                        value={querySearch} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                                    <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataSearch()}>
                                            Aplicar
                                        </button>
                                        {
                                            (localStorage.getItem('permission') === '0' || localStorage.getItem('permission') === '1') &&
                                            <button className="ml-5 bg-green-500 hover:bg-green-300 text-white font-bold py-1 px-8 rounded" onClick={() => actionExportData()} disabled={relatorio}>
                                                Gerar Relatório
                                            </button>
                                        }
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Table title='Débitos' description='Registro dos débitos presentes no SINPREV' next={next} previous={previous} negotiation={openModal}
                                states={states} setters={setters} headers={headers} infoTable={{ offset: states.offsetDebitos, limit: states.limitDebitos }} dataTable={states.dataDebitos} />
                        </div>
                    </div>
                </div>
            </div>
            <ModalNegociacao states={states} setters={setters} action={actionModal} />
        </>
    )
}


export default Debitos