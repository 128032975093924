import { Pie } from 'react-chartjs-2'
import { toast } from 'react-toastify'
import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import moment from 'moment'
import Navbar from '../../../Components/Navbar/navbar'
import ModalConfirm from '../../../Components/Modal/Confirm/index'
import ModalConfirmUnfiliate from '../../../Components/Modal/ConfirmUnfiliate'
import Table from '../../../Components/Tabela/TabelaAssociados'
import TableFilter from '../../../Components/Tabela/TabelaAssociadosFiltro'
import ModalShow from '../../../Components/Modal/ShowAssociado'
import ModalShowTable from '../../../Components/Modal/ShowTable'
import NavbarMenu from '../../../Components/navMenu/administrativo'
import Modal from '../../../Components/Modal/Associados/modalAssociados'
import ModalFiltro from '../../../Components/Modal/ModalFiltrosAssociados'

Chart.register(...registerables);


export default function Associados() {

  const { states, setters } = useContext(AuthContext)
  const { getDebit, dataAllFundo, dataAssociados, dataSystemAssociate, unfiliateAssociate, createReportAssociado, dataAssociadosQuery, buscarAssociadoPorCpf, createReportAssociadoForm } = useContext(AuthContextApi)

  const [dataTable, setDataTable] = useState([])
  const [idSelecionado, setIdSelecionado] = useState(0)
  const [dataFiltro, setDataFiltro] = useState({})

  const callData = async () => {
    await dataAssociados()
  }

  const load = async () => {
    setters.setDataTableFiltroAssociados([])
    let promises = [];
    if (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') {
      promises = [
        dataSystemAssociate(),
        dataAssociados(),
        dataAllFundo()
      ];

    } else {
      promises = [
        dataSystemAssociate(),
        dataAssociados()
      ];
    }

    await Promise.all(promises);
  }

  useEffect(() => {
    load()
    return () => {
      setters.setDataAssociadoss([])
      setters.setDataTableFiltroAssociados([])
      setters.setQuerySearchAssociados('')
      setters.setOffsetAssociadoss(0)
      setters.setLimitSearchAssociadoss(false)
      setters.setModalShowTecnico(false)
      setters.setSystemAssociado([])
      setters.setDataFundo([])
      setters.setDataQuery([])
    }
  }, []);

  useEffect(() => {
    if (states.dataTableFiltroAssociados.length == 0 && states.offsetAssociadoss > 0) {
      callData()
    }

  }, [states.offsetAssociadoss])



  useEffect(() => {
    if (Object.keys(dataFiltro).length == 0) {
      return
    } else {
      acaoFiltros(dataFiltro)
    }
  }, [states.offsetFiltroAssociados])



  const abrirModal = (id) => {
    setIdSelecionado(id)
    setters.setModalIsOpen(true);
  };

  const next = async () => {
    if (states.dataQuery.length > 0) {

      if (states.dataAssociadoss.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetAssociadoss(states.offsetAssociadoss + states.limitAssociadoss)
        const sumOff = states.offsetAssociadoss + states.limitAssociadoss
        const limit = states.limitAssociadoss + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataAssociadoss(newData)
      }
    } else {
      if (states.limitSearchAssociadoss) {
        return
      } else {
        setters.setOffsetAssociadoss(states.offsetAssociadoss + states.limitAssociadoss)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAssociadoss.length === 0 && states.offsetAssociadoss <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetAssociadoss > 0 && setters.setOffsetAssociadoss(states.offsetAssociadoss - states.limitAssociadoss)
        states.offsetAssociadoss < 0 && setters.setOffsetAssociadoss(0)
        const sumOff = states.offsetAssociadoss > 0 ? states.offsetAssociadoss - states.limitAssociadoss : 0;
        const limit = sumOff + 10 > states.limitAssociadoss ? sumOff + 10 : states.limitAssociadoss;
        // 
        const newData = data.slice(sumOff, limit)
        setters.setDataAssociadoss(newData)
      }
    } else {
      if (states.offsetAssociadoss <= 0) {
        states.offsetAssociadoss < 0 && setters.setOffsetAssociadoss(0)
        return
      } else {
        if (states.limitSearchAssociadoss) setters.setLimitSearchAssociadoss(false)
        setters.setOffsetAssociadoss(states.offsetAssociadoss - states.limitAssociadoss)
      }
    }
  };

  const nextFilters = async () => {
    if (states.limitSearchFiltroAssociados) {
      return
    } else {
      setters.setOffsetFiltroAssociados(states.offsetFiltroAssociados + states.limitFiltroAssociados)
    }


  };

  const previousFilters = async () => {
    if (states.offsetFiltroAssociados <= 0) {
      states.offsetFiltroAssociados < 0 && setters.setOffsetFiltroAssociados(0)
      return
    } else {
      if (states.limitSearchFiltroAssociados) setters.setLimitSearchFiltroAssociados(false)
      setters.setOffsetFiltroAssociados(states.offsetFiltroAssociados - states.limitFiltroAssociados)
    }

  };

  const openModalInfo = (associado) => {
    const { ...newJson } = associado;
    const updatedJson = { ...newJson, };
    setters.setAssociadoss(updatedJson);
    setters.setModalShowTecnico(true);

  }

  const closeModalShow = () => {
    setters.setAssociadoss({});
    setters.setModalShowTecnico(false);
  }

  const data = {
    labels: states.dataGraficAssociadosFundo?.map((item) => item.fundo),
    datasets: [
      {
        data: states.dataGraficAssociadosFundo?.map((item) => item.items),
        backgroundColor: [
          '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
          '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
          '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
        ],
        hoverBackgroundColor: [
          '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
          '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
          '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
        hoverBorderColor: '#ffffff',
      },
    ]
  };

  const data1 = {
    labels: states.dataAssociadosAposentado?.map(item => item.Aposentado),
    datasets: [
      {
        data: states.dataAssociadosAposentado?.map(item => item.items),
        backgroundColor: ['#66BB6A', '#FF5252', '#42A5F5'],
        hoverBackgroundColor: ['#66BB6A', '#FF5252', '#42A5F5'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255, 255, 255, 0.8)',
        fontSize: 12,
        padding: 20,
      },
    },
  };

  const add = () => {
    setters.setModalIsOpen(true);
  }

  const dataFilter = async () => {
    await dataAssociados()
  }

  const report = () => {
    setters.setTitleReport('Relatório de Associados')
    setters.setDescriptionReport('Relatório de Associados na data: ' + moment().format('DD/MM/YYYY'))
  }

  const createReport = async () => {
    await createReportAssociado()
  }

  useEffect(() => {
    if (states.titleReport !== '' && states.descriptionReport !== '') createReport()
  }, [states.titleReport, states.descriptionReport])

  const changeFiliate = async (value) => {
    if (value === 'true' || value === 'false') setters.setIsFiliate(value === 'true' ? true : false)
  }

  const dataSearch = async () => {
    if (states.querySearchAssociados !== '') {
      const regex = /[-\/]/;
      const isData = regex.test(states.querySearchAssociados)
      if (isData) {
        toast.error('Não é possível pesquisar por data')
      } else {
        setters.setDataTableFiltroAssociados([])
        setters.setOffsetAssociadoss(0)
        setters.setOffsetFiltroAssociados(0)
        // await searchQueryAssociados()
        await buscarAssociadoPorCpf()
      }
    } else {
      setters.setOffsetAssociadoss(0)
      setters.setOffsetFiltroAssociados(0)
      setDataFiltro({})
      setters.setDataTableFiltroAssociados([])
      await dataAssociados()
    }
  }

  const sendFormFiliate = async (data) => {
    setters.setAssociadoss(data)
    setters.setConfirmModal(true)
  }

  const actionConfirm = async () => {
    setters.setConfirmModal(false)
    await createReportAssociadoForm()
  }


  const getDebitAction = async (data) => {
    setters.setAssociadoss(data)
    await getDebit(data)
  }

  const abrirFiltros = () => {
    setters.setModalFiltro(true)
    setters.setModalIsOpen(false)
    setters.setModalShowTecnico(false)
    setters.setModalTable(false)
    setters.setConfirmModal(false)
    setDataFiltro({})
    setters.setOffsetFiltroAssociados(0)
  }
  const acaoFiltros = async (data) => {
    setters.setModalFiltro(false)
    await dataAssociadosQuery(data)
    setDataFiltro(data)

  }

  const openUnfiliate = (data) => {
    setters.setAssociadoss(data)
    setters.setConfirmUnfiliate(true)
  }

  //COLOCAR UM BOTAO PARA LIMPAR O FILTRO E AO LIMPAR, VOLTAR PARA A TABELA ORIGINAL

  const actionUnfiliate = async () => {
    setters.setConfirmUnfiliate(false)
    await unfiliateAssociate()

  }

  const titleConfirm = 'Envio de ficha do associado.'
  const descriptionConfirm = 'Ao realizar essa ação, o associado receberá um email com a ficha de filiação. Confirma o envio da ficha?'

  const headers = ['Matrícula', 'Nome', 'CPF', 'Email', 'Data de filiação', 'Filiado', 'Aposentado', '']
  const headersShow = ['Email', 'CPF', 'Data de filiação', 'Data de Criação', 'Data de Atualização']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />

        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarMenu />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Associados</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre os associados.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.systemAssociado.map((card, index) => (
                  <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>

                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {
                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                          }

                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            {
                              card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                            }

                            <dd>
                              {
                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                              }
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>

              <div className='lg:flex'>
                <div className="lg:mr-2 flex lg:w-[80%] flex-col items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                  <h2 className="text-xl font-bold">Associados por fundo</h2>
                  <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                  <div style={{ height: '100%', width: '100%' }}>
                    <Pie data={data} options={options} />
                  </div>
                </div>

                <div className="lg:ml-2 flex flex-col lg:w-[80%] items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                  <h2 className="text-xl font-bold">Associados aposentados</h2>
                  <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                  <div style={{ height: '100%', width: '100%' }}>
                    <Pie data={data1} options={options} />
                  </div>
                </div>
              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Intervalo dos associados</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2'>
                  <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                    <select className="border border-gray-300 rounded-md   focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 "
                      value={states.isFiliate}
                      onChange={e => changeFiliate(e.target.value)}
                    >
                      <option>Selecione</option>
                      <option value={true}>Filiado</option>
                      <option value={false}>Não filiado</option>
                    </select>
                  </div>
                  <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                    <label htmlFor="start-date" className="mr-2">Data de início:</label>
                    <input id="start-date" type="date" onChange={e => setters.setInitialDataAssociados(e.target.value)} value={states.initialDataAssociados} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="end-date" className="mr-2">Data de término:</label>
                    <input id="end-date" type="date" onChange={e => setters.setFinalDataAssociados(e.target.value)} value={states.finalDataAssociados} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                  </div>
                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataFilter()}>
                      Aplicar
                    </button>
                    {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') &&
                      <button className="ml-5 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-8 rounded" onClick={() => report()}>
                        Gerar relatório
                      </button>
                    }
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por associado</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => {
                    const numericValue = e.target.value.replace(/\D/g, '');
                    setters.setQuerySearchAssociados(numericValue);
                  }} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                    value={states.querySearchAssociados} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-green-500  hover:bg-green-600 text-white py-1 font-bold px-8 rounded" onClick={() => abrirFiltros()}>
                      Filtros
                    </button>
                  </div>
                </div>
              </div>
              {
                states.dataTableFiltroAssociados.length > 0 &&
                <TableFilter unfiliate={openUnfiliate} debit={getDebitAction} add={add} edit={abrirModal} form={sendFormFiliate} title='Associados - Filtros ativos' description='Registro dos associados do SINPREV' next={nextFilters} previous={previousFilters} states={states} setters={setters} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetFiltroAssociados, limit: states.limitFiltroAssociados }} dataTable={states.dataTableFiltroAssociados} />
              }
              {
                states.dataTableFiltroAssociados.length == 0 &&
                <Table unfiliate={openUnfiliate} debit={getDebitAction} add={add} edit={abrirModal} form={sendFormFiliate} title='Associados' description='Registro dos associados do SINPREV' next={next} previous={previous} states={states} setters={setters} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetAssociadoss, limit: states.limitAssociadoss }} dataTable={states.dataAssociadoss} />
              }


            </div>
          </div>

        </div>
        <ModalConfirmUnfiliate states={states} action={actionUnfiliate} setters={setters} />
        <ModalFiltro states={states} setters={setters} acao={acaoFiltros} />
        <Modal states={states} setters={setters} id={idSelecionado} setid={setIdSelecionado} />
        <ModalShow states={states} setters={setters} closeModal={closeModalShow} data={states.Associadoss} title='Visualização ampla' />
        <ModalShowTable states={states} setters={setters} closeModal={closeModalShow} headers={headersShow}
          data={dataTable} title='Visualiazação associado' description='Exibição de associados' />
        <ModalConfirm states={states} action={actionConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
      </div>

    </>
  )
}