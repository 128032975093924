import { useRef, useState} from 'react'
import {toast} from 'react-toastify'

export default function DragDropFile({states, setters, file}) {
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);
    
    function handleFile(files) {
        if (files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files[0].type === "application/pdf" || files[0].type === "application/msword" || files[0].name.endsWith(".pdf") || files[0].name.endsWith(".docx") || files[0].name.endsWith(".doc")) {
            file(files[0])
            inputRef.current.value = '';
        } else {
            // Display an error message for non-CSV files
            // console.error("Apenas arquivos CSV são aceitos");
            toast.error('Apenas arquivos .PDF e .DOCX são aceitos')
        }
    }

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        const file = e.target.files[0];
        if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/pdf" || file.type === "application/msword" || file.name.endsWith(".pdf") || file.name.endsWith(".docx") || file.name.endsWith(".doc")) {
            handleFile(e.target.files);
        } else {
            // Display an error message for non-CSV files
            // console.error("Apenas arquivos CSV são aceitos");
            toast.error('Apenas arquivos .PDF e .DOCX são aceitos')
        }
    };
  

    return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} />

        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span class="font-semibold">
                    Arraste e solte o arquivo aqui
                </span>
            </p>
            <p class="text-xs text-gray-500 dark:text-gray-400">Somente arquivos .PDF ou .DOCX</p>
        </div>
        </label>
        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>
    );
};
