import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';




const ModalExportacaoAssociado = ({ states, setters, action: acao, fundos }) => {


    //Controle de vizibilidade 
    const [filiateDateView, setFiliateDateView] = useState(false);
    const [unfiliateDateView, setUnfiliateDateView] = useState(false);
    const [filtersView, setFiltersView] = useState(false);

    //Estados para inputs 
    const [filiateDate, setFiliateDate] = useState('');
    const [filiateField, setFiliateField] = useState('');
    const [unfiliateDate, setUnfiliateDate] = useState('');
    const [unfiliateField, setUnfiliateField] = useState('');
    const [filters, setFilters] = useState('');
    const [filtersField, setFiltersField] = useState('');

    const [fieldsExport, setFieldsExport] = useState([]);
    const [errorFieldsExport, setErrorFieldsExport] = useState(false);

    const [statusAssociates, setStatusAssociates] = useState('');
    const [errorStatusAssociates, setErrorStatusAssociates] = useState(false);


    const [errorFiliateDate, setErrorFiliateDate] = useState(false);
    const [errorFiliateDateMsg, setErrorFiliateDateMsg] = useState('Campo obrigatório');
    const [errorFiliateField, setErrorFiliateField] = useState(false);
    const [errorUnfiliateDate, setErrorUnfiliateDate] = useState(false);
    const [errorUnfiliateDateMsg, setErrorUnfiliateDateMsg] = useState('Campo obrigatório');
    const [errorUnfiliateField, setErrorUnfiliateField] = useState(false);
    const [errorFilters, setErrorFilters] = useState(false);
    const [errorFiltersField, setErrorFiltersField] = useState(false);



    const action = () => {
        let error = false;
        if (fieldsExport.length === 0) {
            setErrorFieldsExport(true);
            error = true;
        } else {
            setErrorFieldsExport(false);
        }

        if (statusAssociates === '') {
            setErrorStatusAssociates(true);
            error = true;
        } else {
            setErrorStatusAssociates(false);
        }
        if (filiateDateView) {
            if (filiateField === '') {
                setErrorFiliateField(true);
                error = true;
            } else {
                setErrorFiliateField(false);
            }
            if (filiateDate === '') {
                setErrorFiliateDate(true);
                error = true;
            } else {
                setErrorFiliateDate(false);
            }
        }
        if (unfiliateDateView) {
            if (unfiliateField === '') {
                setErrorUnfiliateField(true);
                error = true;
            } else {
                setErrorUnfiliateField(false);
            }
            if (unfiliateDate === '') {
                setErrorUnfiliateDate(true);
                error = true;
            } else {
                setErrorUnfiliateDate(false);
            }
        }
        if (filtersView) {
            if (filtersField === '') {
                setErrorFiltersField(true);
                error = true;
            } else {
                setErrorFiltersField(false);
            }
            if (filtersField !== '') {
                if (filters === '') {
                    setErrorFilters(true);
                    error = true;
                } else {
                    setErrorFilters(false);
                }
            }
        }

        if (error) {
            error = false;
            return;
        }

        const fields = fieldsExport;
        const status = statusAssociates;
        const filter = filtersField;
        const valueFilter = filters;
        let dateUnfiliate = [];
        let dateFiliate = [];

        if (filiateDateView) {
            dateFiliate = [filiateField, filiateDate];
        }
        if (unfiliateDateView) {
            dateUnfiliate = [unfiliateField, unfiliateDate];
        }
        const body = {
            fields,
            filter,
            valueFilter,
            dateUnfiliate,
            dateFiliate
        }
        acao(body, status);
    }

    const close = () => {
        setters.setModalAssociateStatus(false);
    }

    const fundosLoad = async () => {
        await fundos();
    }

    const brazilStates = [
        { id: 1, name: 'Acre', uf: 'AC' },
        { id: 2, name: 'Alagoas', uf: 'AL' },
        { id: 3, name: 'Amapá', uf: 'AP' },
        { id: 4, name: 'Amazonas', uf: 'AM' },
        { id: 5, name: 'Bahia', uf: 'BA' },
        { id: 6, name: 'Ceará', uf: 'CE' },
        { id: 7, name: 'Distrito Federal', uf: 'DF' },
        { id: 8, name: 'Espírito Santo', uf: 'ES' },
        { id: 9, name: 'Goiás', uf: 'GO' },
        { id: 10, name: 'Maranhão', uf: 'MA' },
        { id: 11, name: 'Mato Grosso', uf: 'MT' },
        { id: 12, name: 'Mato Grosso do Sul', uf: 'MS' },
        { id: 13, name: 'Minas Gerais', uf: 'MG' },
        { id: 14, name: 'Pará', uf: 'PA' },
        { id: 15, name: 'Paraíba', uf: 'PB' },
        { id: 16, name: 'Paraná', uf: 'PR' },
        { id: 17, name: 'Pernambuco', uf: 'PE' },
        { id: 18, name: 'Piauí', uf: 'PI' },
        { id: 19, name: 'Rio de Janeiro', uf: 'RJ' },
        { id: 20, name: 'Rio Grande do Norte', uf: 'RN' },
        { id: 21, name: 'Rio Grande do Sul', uf: 'RS' },
        { id: 22, name: 'Rondônia', uf: 'RO' },
        { id: 23, name: 'Roraima', uf: 'RR' },
        { id: 24, name: 'Santa Catarina', uf: 'SC' },
        { id: 25, name: 'São Paulo', uf: 'SP' },
        { id: 26, name: 'Sergipe', uf: 'SE' },
        { id: 27, name: 'Tocantins', uf: 'TO' }
    ]

    const fieldsCheckBox = [
        { id: 1, label: 'Nome', haveFormat: false, value: 'name' },
        { id: 2, label: 'Email', haveFormat: false, value: 'email' },
        { id: 3, label: 'CPF', haveFormat: true, value: 'cpf' },
        { id: 4, label: 'Telefone', haveFormat: true, value: 'phone' },
        { id: 5, label: 'Endereço', haveFormat: false, value: 'address' },
        { id: 6, label: 'Data de filiação', haveFormat: true, value: 'dateFiliate' },
        { id: 7, label: 'Data de desfiliação', haveFormat: true, value: 'dateUnfiliate' },
        { id: 8, label: 'Fundo', haveFormat: false, value: 'fundo' },
        { id: 9, label: 'Empresa', haveFormat: false, value: 'company' },
        { id: 10, label: 'Matricula', haveFormat: false, value: 'registration' },
        { id: 11, label: 'Estado Civil', haveFormat: false, value: 'civilState' },
        { id: 12, label: 'Escolaridade', haveFormat: false, value: 'escolarchip' },
        { id: 13, label: 'Cidade Natal', haveFormat: false, value: 'hometown' },
        { id: 14, label: 'Estado natal', haveFormat: false, value: 'homeState' },
        { id: 15, label: 'Rg', haveFormat: false, value: 'rg' },
        { id: 16, label: 'Emissor do Rg', haveFormat: false, value: 'emissorEntityRg' },
        { id: 17, label: 'Expedição Rg', haveFormat: false, value: 'dateRg' },
        { id: 18, label: 'Nome do Pai', haveFormat: false, value: 'fathername' },
        { id: 19, label: 'Nome da Mãe', haveFormat: false, value: 'mothername' },
        { id: 20, label: 'Número da casa', haveFormat: false, value: 'houseNumber' },
        { id: 21, label: 'Cidade', haveFormat: false, value: 'city' },
        { id: 22, label: 'Estado', haveFormat: false, value: 'stateAcronym' },
        { id: 23, label: 'Bairro', haveFormat: false, value: 'neighborhood' },
        { id: 24, label: 'Complemento', haveFormat: false, value: 'complement' },
        { id: 25, label: 'Data de aniversário', haveFormat: false, value: 'birthDate' },
        { id: 26, label: 'CEP', haveFormat: false, value: 'cep', format: e => e.replace(/(\d{5})(\d{3})/, "$1-$2") },
        { id: 27, label: 'Data de Criação', haveFormat: false, value: 'createdAt' },
    ]

    useEffect(() => {
        if (!filiateDateView) {
            setFiliateDate('');
            setErrorFiliateDate(false);
            setErrorFiliateDateMsg('Campo obrigatório');
            setFiliateField('');
            setErrorFiliateField(false);
        } else {
            if (unfiliateDateView) {
                setUnfiliateDate('');
                setErrorUnfiliateDate(false);
                setErrorUnfiliateDateMsg('Campo obrigatório');
                setUnfiliateField('');
                setErrorUnfiliateField(false);
                setUnfiliateDateView(false);
            }
        }

    }, [filiateDateView])

    useEffect(() => {
        if (!unfiliateDateView) {
            setUnfiliateDate('');
            setErrorUnfiliateDate(false);
            setErrorUnfiliateDateMsg('Campo obrigatório');
            setUnfiliateField('');
            setErrorUnfiliateField(false);
        } else {
            if (filiateDateView) {
                setFiliateDate('');
                setErrorFiliateDate(false);
                setErrorFiliateDateMsg('Campo obrigatório');
                setFiliateField('');
                setErrorFiliateField(false);
                setFiliateDateView(false);
            }
        }

    }, [unfiliateDateView])

    useEffect(() => {
        if (!filtersView) {
            setFilters('');
            setErrorFilters(false);
            setFiltersField('');
            setErrorFiltersField(false);
        } else {

        }
    }, [filtersView])

    useEffect(() => {
        if (filtersField === 'fundo' && states.dataFundo.length === 0) fundosLoad();
    }, [filtersField])


    useEffect(() => {
        if (!states.modalAssociateStatus) {
            setFiliateDateView(false);
            setUnfiliateDateView(false);
            setFiltersView(false);
            setFieldsExport([]);
            setErrorFieldsExport(false);
            setStatusAssociates('');
            setErrorStatusAssociates(false);
            setFiliateDate('');
            setFiliateField('');
            setErrorFiliateDate(false);
            setErrorFiliateDateMsg('Campo obrigatório');
            setErrorFiliateField(false);
            setUnfiliateDate('');
            setUnfiliateField('');
            setErrorUnfiliateDate(false);
            setErrorUnfiliateDateMsg('Campo obrigatório');
            setErrorUnfiliateField(false);
            setFilters('');
            setFiltersField('');
            setErrorFilters(false);
            setErrorFiltersField(false);
        }

    }, [states.modalAssociateStatus])



    return (
        <Transition.Root show={states.modalAssociateStatus} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={setters.setModalAssociateStatus}>
                <div className="flex min-h-screen items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-4"
                    >
                        <Dialog.Panel className="w-full max-w-6xl p-4 rounded-lg bg-white shadow-xl border-2">
                            <div className='flex flex-col items-center p-5'>
                                <h1 className="text-2xl font-bold">Exportação de dados</h1>
                                <p className="ml-2 text-gray-500">Preencha os campos abaixo para realizar a exportação dos dados</p>
                            </div>
                            <div className='flex flex-col  p-8 rounded-[15px] shadow-sm hover:shadow-md  '>


                                {/* HEADERS PARA DEFINICAO DE FILTROS */}
                                <div className="flex sm:flex-row flex-col items-between justify-between">
                                    <div className="flex flex-col mt-4 sm:mt-0">
                                        <div className="flex flex-row">
                                            <label className="text-sm font-semibold">Status dos associados</label>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <select className="mt-2"
                                                onChange={(e) => {
                                                    setStatusAssociates(e.target.value);
                                                }}
                                                value={statusAssociates}
                                            >
                                                <option value="">Selecione um campo</option>
                                                <option value="all">Todos</option>
                                                <option value="active">Ativos</option>
                                                <option value="inactive">Inativos</option>
                                            </select>
                                            {
                                                errorStatusAssociates && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row">
                                            <label className="text-sm font-semibold">Data de Filiação</label>
                                            <input type='checkbox' className="mt-1 ml-4" checked={filiateDateView} onChange={() => setFiliateDateView(!filiateDateView)} />
                                        </div>
                                        {
                                            filiateDateView && (
                                                <div className="flex flex-col mt-2">
                                                    <select className="mt-2"
                                                        onChange={(e) => {
                                                            setFiliateField(e.target.value);
                                                        }}
                                                        value={filiateField}
                                                    >
                                                        <option value="">Selecione um campo</option>
                                                        <option value="=">Igual</option>
                                                        <option value=">">Maior</option>
                                                        <option value="<">Menor</option>
                                                        <option value=">=">Maior ou Igual</option>
                                                        <option value="<=">Menor ou Igual</option>
                                                    </select>
                                                    {
                                                        errorFiliateField && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                                    }
                                                    <input type='date' className="mt-2" value={filiateDate} onChange={(e) => {
                                                        const dateNow = moment().format('YYYY-MM-DD');
                                                        if (moment(e.target.value).isAfter(dateNow)) {
                                                            setErrorFiliateDate(true);
                                                            setErrorFiliateDateMsg('Data maior que a data atual');
                                                        } else {
                                                            setErrorFiliateDate(false);
                                                        }
                                                        setFiliateDate(e.target.value);
                                                    }} max={moment().format('YYYY-MM-DD')} />
                                                    {
                                                        errorFiliateDate && <p className="text-red-500 text-sm mt-2">{errorFiliateDateMsg}</p>
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="flex flex-col mt-4 sm:mt-0">
                                        <div className="flex flex-row">
                                            <label className="text-sm font-semibold">Data de Desfiliação</label>
                                            <input type='checkbox' className="mt-1 ml-4" checked={unfiliateDateView} onChange={() => setUnfiliateDateView(!unfiliateDateView)} />
                                        </div>
                                        {
                                            unfiliateDateView && (
                                                <div className="flex flex-col mt-2">
                                                    <select className="mt-2"
                                                        onChange={(e) => {
                                                            setUnfiliateField(e.target.value);
                                                        }}
                                                        value={unfiliateField}
                                                    >
                                                        <option value="">Selecione um campo</option>
                                                        <option value="=">Igual</option>
                                                        <option value=">">Maior</option>
                                                        <option value="<">Menor</option>
                                                        <option value=">=">Maior ou Igual</option>
                                                        <option value="<=">Menor ou Igual</option>
                                                    </select>
                                                    {
                                                        errorUnfiliateField && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                                    }
                                                    <input type='date' className="mt-2" value={unfiliateDate} onChange={(e) => {
                                                        const dateNow = moment().format('YYYY-MM-DD');
                                                        if (moment(e.target.value).isAfter(dateNow)) {
                                                            setErrorUnfiliateDate(true);
                                                            setErrorUnfiliateDateMsg('Data maior que a data atual');
                                                        } else {
                                                            setErrorFiliateDate(false);
                                                        }
                                                        setUnfiliateDate(e.target.value);
                                                    }} max={moment().format('YYYY-MM-DD')} />
                                                    {
                                                        errorUnfiliateDate && <p className="text-red-500 text-sm mt-2">{errorUnfiliateDateMsg}</p>
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="flex flex-col mt-4 sm:mt-0">
                                        <div className="flex flex-row">
                                            <label className="text-sm font-semibold">Adicionar Filtros</label>
                                            <input type='checkbox' className="mt-1 ml-4" checked={filtersView} onChange={() => setFiltersView(!filtersView)} />
                                        </div>
                                        {
                                            filtersView && (
                                                <div className="flex flex-col mt-2">
                                                    <select className="mt-2"
                                                        onChange={(e) => {
                                                            setFiltersField(e.target.value);
                                                        }}
                                                        value={filtersField}
                                                    >
                                                        <option value="">Selecione um campo</option>
                                                        <option value="stateAcronym">Estado</option>
                                                        <option value="city">Cidade</option>
                                                        <option value="company">Empresa</option>
                                                        <option value="fundo">fundo</option>
                                                    </select>
                                                    {
                                                        errorFiltersField && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                                    }
                                                    {
                                                        filtersField !== '' && (
                                                            <>
                                                                {
                                                                    filtersField === 'fundo' ? (
                                                                        <>
                                                                            <select className="mt-2"
                                                                                onChange={(e) => {
                                                                                    setFilters(e.target.value);
                                                                                }}
                                                                                value={filters}
                                                                            >
                                                                                <option value="">Selecione um fundo</option>
                                                                                {
                                                                                    states.dataFundo.map((fundo) => (
                                                                                        <option key={fundo.id} value={fundo.id}>{fundo.name}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            {
                                                                                errorFilters && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                                                            }
                                                                        </>
                                                                    ) : filtersField === 'stateAcronym' ? (
                                                                        <>
                                                                            <select className="mt-2"
                                                                                onChange={(e) => {
                                                                                    setFilters(e.target.value);
                                                                                }}
                                                                                value={filters}
                                                                            >
                                                                                <option value="">Selecione um estado</option>
                                                                                {
                                                                                    brazilStates.map((state) => (
                                                                                        <option key={state.id} value={state.uf}>{state.name}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            {
                                                                                errorFilters && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <input type='text' className="mt-2" value={filters} onChange={(e) => setFilters(e.target.value)} placeholder='Digite o valor do filtro' />
                                                                            {
                                                                                errorFilters && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                {/* AREA DE DEFINICAO DOS FILTROS QUE QUER PARA EXPORTACAO */}
                                <div className="flex sm:flex-row flex-col items-between justify-between mt-8 border-t border-gray-200 pt-4">
                                    <div className="flex flex-col">
                                        <p className="text-sm font-semibold">Selecione os campos que deseja exportar</p>
                                        {
                                            errorFieldsExport && <p className="text-red-500 text-sm mt-2">Obrigatório selecionar pelo menos um campo</p>
                                        }
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
                                        {
                                            fieldsCheckBox.map((field) => (
                                                <div key={field.id} className="flex items-center">
                                                    <input type="checkbox" className="mt-1" checked={fieldsExport.includes(field.value)} onChange={() => {
                                                        if (fieldsExport.includes(field.value)) {
                                                            setFieldsExport(fieldsExport.filter((item) => item !== field.value));
                                                        } else {
                                                            setFieldsExport([...fieldsExport, field.value]);
                                                        }
                                                    }} />
                                                    <label className="ml-2">{field.label}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>


                                <div className="mt-4 flex justify-end">
                                    <button
                                        className={"ml-2 bg-blue-600 text-white px-4 py-2 rounded"}
                                        onClick={() => action()}
                                    >
                                        Gerar exportação
                                    </button>
                                    <button
                                        className=" ml-2 bg-red-600 text-white px-4 py-2 rounded"
                                        onClick={() => close()}
                                    >
                                        Fechar
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div >
            </Dialog >
        </Transition.Root >
    );
}

export default ModalExportacaoAssociado;
