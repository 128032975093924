import { BrowserRouter } from 'react-router-dom';
import Router from './Routes';
import AuthProvider from './Context/index';
import AuthProviderApi from './Context/api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalInformacao from './Components/Modal/InformacaoModal'
import ModalInformacao2 from './Components/Modal/MsgData'



function App() {

    return (
        <div className='notranslate'>
            <AuthProvider>
                <AuthProviderApi>
                    <BrowserRouter>
                        <ToastContainer />
                        <ModalInformacao2 />
                        <ModalInformacao />
                        <Router />
                    </BrowserRouter>
                </AuthProviderApi>
            </AuthProvider>
        </div>

    );
}

export default App;