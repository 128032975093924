
import NavBar from "../../../Components/Navbar/navbar";
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../Context";
import { AuthContextJuri } from "../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import moment from "moment";
import { EyeIcon, EyeSlashIcon, PlusIcon, XMarkIcon, PencilIcon, ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline'
import AdicaoSecao from "../../../Components/juridico/adicaoSecao";
import AdicaoAutorizacao from "../../../Components/juridico/adicaoAutorizacao";
import { toast } from 'react-toastify'
import Footer from "../../../Components/Footer/footer";



const ProcessosJuridico = () => {
    const { createLegalProcessComplete } = useContext(AuthContextJuri);

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        getProcessosById
    } = useContext(AuthContextJuri);
    const { id } = useParams()




    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
        const resp = await getProcessosById(id)
        if (resp == false) {
            hist('/juridicoIndex/processos')
        }
        if (resp.complete) {
            hist('/juridicoIndex/processos')
            toast.error('Processo já foi concluído')
        }
    }


    useEffect(() => {
        checkTokenLoad()
        return () => {
            setters.setJuriLegalProcess({})
        }
    }, [])


    const [openInfoProcess, setOpenInfoProcess] = useState(false)
    const [openProcessResponse, setOpenProcessResponse] = useState(false)
    const [openSections, setOpenSections] = useState(false)
    const [sections, setSections] = useState([])
    const [isEditSection, setIsEditSection] = useState(false)
    const [section, setSection] = useState({})
    const [openInsertSection, setOpenInsertSection] = useState(false)

    const [openAuthorization, setOpenAuthorization] = useState(false)
    const [authorization, setAuthorization] = useState({})

    const addSecao = e => {
        sections.push(e)
        setOpenSections(false)
    }

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const editSecao = e => {
        setIsEditSection(true)
        setSection(e)
        setOpenSections(true)
    }

    const editSection = e => {
        const indexSection = sections.findIndex(e => e.id === section.id)
        sections[indexSection] = e
        setSection({})
        setOpenSections(false)
    }

    const deleteSection = e => {
        const newArray = sections.filter(sec => sec.id !== e.id)
        setSections(newArray)
    }

    const addAutorizacao = e => {
        setAuthorization(e)
        setOpenAuthorization(false)
    }

    const deleteAutorizacao = e => {
        setAuthorization({})
    }

    const completeProcess = async () => {
        const formData = new FormData()
        const sectionsOutFileAndId = sections.map((e, index) => {
            return {
                process_id: id,
                title: e.title,
                text: e.text,
                isPublic: e.isPublic,
                index: index,
                haveFile: e.file ? true : false
            }
        })
        sections.forEach(sec => {
            sec.file && formData.append('fileSection', sec.file)
        })
        formData.append('sections', JSON.stringify(sectionsOutFileAndId))
        const authorizationOutFile = {
            process_id: id,
            title: authorization.title,
            description: authorization.description,
            email: authorization.email
        }
        formData.append('authorization', JSON.stringify(authorizationOutFile))
        formData.append('fileAuthorization', authorization.file)
        const resp = await createLegalProcessComplete(formData)
        if (resp == true) {
            setAuthorization({})
            setSections([])
            hist('/juridicoIndex/processos')
        }
    }

    const [isEditAuthorization, setIsEditAuthorization] = useState(false)
    const updateAuthorization = e => {
        setAuthorization(e)
        setIsEditAuthorization(true)
        setOpenAuthorization(true)
    }

    const editAutorizacao = e => {
        setAuthorization(e)
        setIsEditAuthorization(false)
        setOpenAuthorization(false)
    }

    const returnPage = () => {
        hist('/juridicoIndex/processos')
    }


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2 items-center'>
                    <div className="flex mt-4 flex-col items-center justify-center border-[2px] p-5 w-[98%] rounded-[10px] ">
                        <div className="flex flex-row w-full items-center justify-between">
                            <button onClick={() => returnPage()} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                Voltar
                            </button>
                        </div>
                        <div className="flex items-center justify-center border-[2px] p-4 w-[98%] rounded-[10px] mt-4">
                            {/* AQUI DEVE TER A VALIDACAO CASO O PROCESSO JA ESTEJA CONCLUIDO */}
                            <div className="flex flex-col w-full items-center justify-center">
                                <div className="flex flex-col sm:flex-row w-full items-center justify-between">
                                    <p className="text-2xl font-bold">Cadastramento de processo</p>
                                    {
                                        Object.keys(authorization).length > 0 && sections.length > 0 && (
                                            <button onClick={() => completeProcess()} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M10.707 3.293a1 1 0 010 1.414L7.414 9H13a7 7 0 110 14H7a7 7 0 110-14h6.586l-3.293 3.293a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414l-5-5a1 1 0 01-.001-.001z" clipRule="evenodd" />
                                                </svg>
                                                Cadastrar
                                            </button>
                                        )
                                    }
                                    {
                                        Object.keys(authorization).length == 0 && sections.length == 0 && (
                                            <p className="text-red-500">Preencha as seções e a autorização para concluir o processo</p>
                                        )
                                    }
                                </div>
                                {/* Instrucoes para o preenchimento */}
                                <hr className="w-full mt-2" />
                                <p className=" mt-4">Preencha os campos abaixo para cadastrar um novo processo</p>
                            </div>
                        </div>
                        {/* Painel de informacoes de processo -> cadastro das secoes e autorizacao  */}
                        <div className="flex flex-col w-full">
                            <div className="flex flex-row w-full items-between justify-between p-4">
                                <div>
                                    <p className="font-bold mb-2">Informações principais processo</p>
                                    {
                                        !openInfoProcess && (<p className="font-classic ">Clique no botão de olho para vizualizar as informacoes</p>)
                                    }
                                </div>
                                <button onClick={() => setOpenInfoProcess(!openInfoProcess)} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                    {
                                        openInfoProcess ? <EyeSlashIcon className="h-10 w-6 " /> : <EyeIcon className="h-5 w-5 " />
                                    }
                                </button>
                            </div>
                            {// git add . && git commit -m "Painel personalizado" && git push
                                openInfoProcess && (
                                    <>
                                        <div className="flex flex-col sm:flex-row w-full items-between justify-between p-1">
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Número:</p>
                                                <p className="font-bold text-center">{states.JuriLegalProcess.number}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Nome do processo:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.caseTitle}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Descrição:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.caseDescription}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Tipo:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.caseType}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col sm:flex-row w-full items-between justify-between p-1">
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Sujeiro passivo:</p>
                                                <p className="font-bold text-center">{states.JuriLegalProcess.defendant}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Sujeiro ativo:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.prosecutor}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Tribunal:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.court_name}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Juíz:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.judge}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col sm:flex-row w-full items-between justify-between p-1">
                                            <div className="flex flex-col w-[100%] sm:w-[50%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Status:</p>
                                                <p className="font-bold text-center">{
                                                    states.JuriLegalProcess.status === 1 ? 'Aberto' : 'Encerrado'
                                                }</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Data de início:</p>
                                                <p className="font-bold text-start">{moment(states.JuriLegalProcess.filingDate).format('DD/MM/YYYY')}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[25%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Próxima audiência:</p>
                                                <p className="font-bold text-start">{moment(states.JuriLegalProcess.nextFilingDate).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <hr className="w-full mt-2" />
                        {/* PAINEL REFERENTE A QUEM CUIDA DO PROCESSO */}
                        <div className="flex flex-col w-full">
                            <div className="flex flex-row w-full items-between justify-between p-4">
                                <div>
                                    <p className="font-bold mb-2">Informações dos responsáveis pelo processo</p>
                                    {
                                        !openProcessResponse && (<p className="font-classic ">Clique no botão de olho para vizualizar as informacoes</p>)
                                    }
                                </div>
                                <button onClick={() => setOpenProcessResponse(!openProcessResponse)} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                    {
                                        openProcessResponse ? <EyeSlashIcon className="h-10 w-6 " /> : <EyeIcon className="h-5 w-5 " />
                                    }
                                </button>
                            </div>
                            {
                                openProcessResponse && (
                                    <>
                                        <div className="flex flex-col sm:flex-row w-full items-between justify-between p-1">
                                            <div className="flex flex-col w-[100%] sm:w-[33%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Nome do Advogado responsável:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.lawyer_name}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[33%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">E-mail do Advogado responsável:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.lawyer_email}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[33%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">OAB do Advogado responsável:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.lawyer_oab}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col sm:flex-row w-full items-between justify-between p-1">
                                            <div className="flex flex-col w-[100%] sm:w-[50%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">Nome do escritório responsáel:</p>
                                                <p className="font-bold text-center">{states.JuriLegalProcess.lawFirm_name}</p>
                                            </div>
                                            <div className="flex flex-col w-[100%] sm:w-[50%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                                <p className="font-bold">E-mail do escritório responsáel:</p>
                                                <p className="font-bold text-start">{states.JuriLegalProcess.lawFirm_email}</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <hr className="w-full mt-2" />
                        {/* PAINEL para cadastramento de seções */}
                        <div className="flex flex-col w-full">
                            <div className="flex flex-col w-full items-between justify-between p-4">
                                <div className="flex flex-row w-full items-between justify-between">
                                    <p className="font-bold mb-2">Seções do processo</p>
                                    {
                                        sections.length > 0 && (
                                            <button onClick={() => {
                                                setSection({})
                                                setOpenInsertSection(!openInsertSection)
                                            }} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                {
                                                    openInsertSection ? <ArrowUpCircleIcon className="h-10 w-6 " /> : <ArrowDownCircleIcon className="h-5 w-5 " />
                                                }
                                            </button>
                                        )
                                    }
                                    <button onClick={() => {
                                        setOpenSections(!openSections)
                                        setIsEditSection(false)
                                    }} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                        {
                                            openSections ? <XMarkIcon className="h-10 w-6 " /> : <PlusIcon className="h-5 w-5 " />
                                        }
                                    </button>
                                </div>
                                <div>
                                    {
                                        !openSections && (<p className={sections.length === 0 ? "font-classic text-red-500" : "font-classic text-green-500"}>
                                            {
                                                sections.length === 0 ? 'Nenhuma seção cadastrada, obrigatório pelo menos uma seção' : sections.length > 0 ? !openInsertSection && (

                                                    <div className="flex flex-col">
                                                        <div className="grid sm:grid-cols-3 grid-cols-1  gap-4 mt-4">
                                                            {
                                                                sections.map(e => {
                                                                    return (
                                                                        <div className="flex flex-col w-full items-between justify-between p-4 border rounded-[10px]">
                                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                                <PencilIcon className="h-5 w-5 text-blue-500" onClick={() => editSecao(e)} />
                                                                                <XMarkIcon className="h-5 w-5 text-red-500" onClick={() => deleteSection(e)} />
                                                                            </div>
                                                                            <span className="font-bold mt-2" />
                                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                                <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Seção: {truncateText(e.title, 15)}</p>
                                                                            </div>
                                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                                <p className="font-bold mb-2 " style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Texto: {truncateText(e.text, 15)}</p>
                                                                            </div>
                                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                                <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Publica: {e.publica ? 'Sim' : 'Não'}</p>
                                                                            </div>
                                                                            {
                                                                                e.file && (
                                                                                    <div className="flex flex-row w-full items-between justify-between">
                                                                                        <p className="font-bold mb-2">Arquivo: {e.file.name}</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                ) : (<p className="font-classic text-red-500">Clique no botão de + para adicionar uma seção</p>)

                                            }
                                        </p>)
                                    }
                                </div>
                            </div>
                            {
                                // area de criacao e vizualizacao das secoes
                                openSections && (
                                    <>
                                        <AdicaoSecao states={states} setters={setters} adicao={e => addSecao(e)} isEdit={isEditSection} section={section} edit={editSection} delete={deleteSection} sections={sections} />
                                    </>
                                )
                            }
                        </div>
                        <hr className="w-full mt-2" />
                        {/* PAINEL para cadastramento de autorização */}
                        <div className="flex flex-col w-full">
                            <div className="flex flex-col w-full items-between justify-between p-4">
                                <div className="flex flex-row w-full items-between justify-between">
                                    <p className="font-bold mb-2">Autorização de inclusão do processo</p>
                                    {
                                        Object.keys(authorization).length == 0 && (

                                            <button onClick={() => {
                                                setOpenAuthorization(!openAuthorization)
                                            }} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                {
                                                    openAuthorization ? <XMarkIcon className="h-10 w-6 " /> : <PlusIcon className="h-5 w-5 " />
                                                }
                                            </button>
                                        )
                                    }
                                </div>
                                <div>
                                    {
                                        !openAuthorization && (<p className={Object.keys(authorization).length === 0 ? "font-classic text-red-500" : "font-classic text-green-500"}>
                                            {
                                                Object.keys(authorization).length == 0 ? 'Nenhuma autorização cadastrada, obrigatório pelo menos uma autorização' : Object.keys(authorization).length > 0 && (

                                                    <div className="flex flex-col">
                                                        <div className="grid grid-cols-1 gap-4 mt-4">
                                                            <div className="flex flex-col w-full items-between justify-between p-4 border rounded-[10px]">
                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                    <XMarkIcon className="h-5 w-5 text-red-500" onClick={() => deleteAutorizacao()} />
                                                                    <PencilIcon className="h-5 w-5 text-green-500" onClick={() => updateAuthorization(authorization)} />
                                                                </div>
                                                                <span className="font-bold mt-2" />
                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                    <p className="font-bold mb-2">Seção: {truncateText(authorization.title, 15)}</p>
                                                                </div>
                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                    <p className="font-bold mb-2 ">Descrição: {truncateText(authorization.description, 15)}</p>
                                                                </div>
                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                    <p className="font-bold mb-2">E-mail de envio: {authorization.email}</p>
                                                                </div>
                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                    <p className="font-bold mb-2">Arquivo: {authorization.file.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )

                                            }
                                        </p>)
                                    }
                                </div>
                            </div>
                            {
                                // area de criacao e vizualizacao das secoes
                                openAuthorization && (
                                    <>
                                        <AdicaoAutorizacao states={states} setters={setters} adicao={e => addAutorizacao(e)} isEdit={isEditAuthorization} authorization={authorization} edit={editAutorizacao} sections={sections} />
                                    </>
                                )
                            }
                        </div>


                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default ProcessosJuridico;