
import { useEffect, useState } from "react";
import Table from '../../Tabela/TabelaArquivosProcessoJuridico'

const DeferimentoProcesso = ({
    getData,
    deleteData,
    reprocess,
    states, setters
}) => {


    const headers = ['', 'Versão', 'Processamento', 'Requerente', 'Data de criação', 'Data de atualização']



    const next = async () => {
        if (states.limitSearchJuriAcoesArquivos && states.offsetJuriAcoesArquivos > 0) {
            return;
        } else {
            setters.setOffsetJuriAcoesArquivos(states.offsetJuriAcoesArquivos + states.limitJuriAcoesArquivos)
        }
    };

    const previous = async () => {
        const offset = states.offsetJuriAcoesArquivos - states.limitJuriAcoesArquivos
        if (offset < 0) {
            states.offsetJuriAcoesArquivos < 0 && setters.setOffsetJuriAcoesArquivos(0)
            return;
        } else {
            setters.setOffsetJuriAcoesArquivos(states.offsetJuriAcoesArquivos - states.limitJuriAcoesArquivos)
        }
    };




    const loadData = async () => {
        await getData(states.JuriAcoes.id)

    }


    const deleteFile = async (data) => {
        setters.setIsEditJuri(false)
        setters.setJuriProcessoDeferimento(data)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja excluir o arquivo da versão ${data.version}?`)
        setters.setDescModalMsg(`Após a confirmação a arquivo será excluída da ação.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await deleteData(states.JuriAcoes.id, data.id)
            setters.setModalMsg(false)
            if (response) {
                loadData()
            }
        })
        setters.setFunctionBtnModalMsg(() => async () => {
            setters.setModalMsg(false)
        })
    }

    const reprocessFile = async (data) => {
        setters.setIsEditJuri(false)
        setters.setJuriProcessoDeferimento(data)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja reprocessar o arquivo da versão ${data.version}?`)
        setters.setDescModalMsg(`Após a confirmação o arquivo será reprocessado.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await reprocess(states.JuriAcoes.id, data.id)
            setters.setModalMsg(false)
            if (response) {
                loadData()
            }
        })
        setters.setFunctionBtnModalMsg(() => async () => {
            setters.setModalMsg(false)
        })
    }



    useEffect(() => {
        loadData()


        // const [dataJuriAcoesArquivos, setDataJuriAcoesArquivos] = useState([])
        // const [JuriAcoesArquivos, setJuriAcoesArquivos] = useState({})
        // const [offsetJuriAcoesArquivos, setOffsetJuriAcoesArquivos] = useState(0)
        // const [limitJuriAcoesArquivos, setLimitJuriAcoesArquivos] = useState(jsonConfig.limitShow)
        // const [limitSearchJuriAcoesArquivos, setLimitSearchJuriAcoesArquivos] = useState(false)

        return () => {
            setters.setDataJuriAcoesArquivos([]);
            setters.setJuriAcoesArquivos({});
            setters.setOffsetJuriAcoesArquivos(0);
            setters.setLimitSearchJuriAcoesArquivos(false);
        }

    }, [])

    useEffect(() => {
        if (states.dataJuriAcoesArquivos.length > 0) loadData()

    }, [states.offsetJuriAcoesArquivos])



    return (
        <>
            <Table deleteDefer={deleteFile} title={`Arquivos gerados da ação`}
                description={`Registro das ações registrados da ação`} next={next} previous={previous} states={states} headers={headers} reprocess={reprocessFile}
                infoTable={{ offset: states.offsetJuriAcoesArquivos, limit: states.limitJuriAcoesArquivos }} dataTable={states.dataJuriAcoesArquivos} />
        </>
    )
}



export default DeferimentoProcesso;