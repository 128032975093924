import axios from 'axios'

import { AuthContext } from './index'
import { toast } from 'react-toastify'
import { jsonConfig } from '../Config/index'
import { createContext, useContext } from "react"
import Cookies from 'js-cookie'


export const AuthContextApi = createContext();

const AuthProviderApi = ({ children }) => {

    const { states, setters } = useContext(AuthContext)

    const checkLogin = async () => {
        const req = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "proxyauthorization": jsonConfig.API_KEY,
            },
        };
        try {
            await axios.get(`${jsonConfig.urlApi}/user/verify`, req);
            return true;
        } catch (err) {
            if (err.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }

                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const login = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/user/login`, { email: states.email, password: states.password }, req)
            localStorage.setItem('funcionario', response.data.data.isEmployee ? 'true' : 'false')
            localStorage.setItem('diaAberto', response.data.data.haveDayOpen ? 'true' : 'false')
            localStorage.setItem('email', response.data.data.user)
            localStorage.setItem('token', response.data.data.token)
            localStorage.setItem('panelJuri', response.data.data.juridico ? 'true' : 'false')
            localStorage.setItem('permission', response.data.data.role)
            setters.setIsLoading(false)

            setters.setEmail(response.data.data.user)
            setters.setPermission(response.data.data.role)
            setters.setToken(response.data.data.token)
            return { status: true, permission: response.data.data.role }

        } catch (err) {
            setters.setIsLoading(false)
            if (err.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const forgotPassword = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/user/forgotPassword`, { email: states.email }, req)
            toast.success(response.data.msg)
            setters.setIsLoading(false)

        } catch (err) {
            setters.setIsLoading(false)

            if (err.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                toast.error(err.response.data.err)
            } else {
                toast.error(err.response.data.err)
            }
        }
    }

    const forgotChangePass = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/user/forgotChangePass`, {
                password: states.password,
                token: states.token
            }, req)
            toast.success(response.data.msg)
            setters.setIsLoading(false)

        } catch (err) {
            setters.setIsLoading(false)

            if (err.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                toast.error(err.response.data.err)
            } else {
                toast.error(err.response.data.err)
            }
        }
    }

    const logout = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/user/logOut`, req)
            localStorage.removeItem('token')
            if (Cookies.get('tokenJuri') !== undefined) {
                Cookies.remove('tokenJuri')
            }
            if (Cookies.get('roleJuri') !== undefined) {
                Cookies.remove('roleJuri')
            }
            localStorage.removeItem('datasystemalerttec')
            localStorage.removeItem('permisssion')
            setters.setSigned(false)
            setters.setToken('')
            localStorage.removeItem('token')
            if (Cookies.get('tokenJuri') !== undefined) {
                Cookies.remove('tokenJuri')
            }
            if (Cookies.get('roleJuri') !== undefined) {
                Cookies.remove('roleJuri')
            }
            localStorage.removeItem('permission')
            localStorage.removeItem('dataSystemHooks')
            localStorage.removeItem('dataSystemAlertTec')

            return { status: true }

        } catch (err) {

            if (err.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    //#region DataSystem

    const dataSystem = async () => {
        // await dataSystemFinancial()
        // await dataSystemCobranca()
        // await dataSystemConciliacao()
        // await dataSystemRemessa()
        // await dataSystemRemessaAssociado()
        // await dataSystemRemessaAssociadoTransaction()
        // await dataSystemSignatureFuncef()
        // await dataSystemAssociate()
        // await dataSystemAssinatura()
        // await dataSystemPlans()
        // await dataSystemNegociacao()
        // await dataSystemTerceirizado()
        // await dataSystemAprove()
        // await dataSystemRequestAssinatura()
        // await dataSystemRequestFatura()
        // await dataSystemPagamento()
        // await dataSystemTemp()
        // await dataSystemHome()
    }

    const dataSystemHome = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/homePage`, req)
            setters.setDataSystemHome(response.data.data)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemUsers = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/users`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setDataSystemUsers(response.data.data)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemTemp = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/tempSign`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setDataSystemAssinaturaTemporaria(response.data.data)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemPagamento = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/invoicesPayment`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))

            setters.setDataGraphicPagamento(response.data.data.graphic.dataPaidGraphic)
            setters.setDataGraphicPagamentoNotPaid(response.data.data.graphic.dataNotPaidGraphic)
            setters.setDataSystemPagamento(response.data.data.statistic)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemRequestFatura = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/authorizationInvoice`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setSystemRequestFatura(response.data.data)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemRequestAssinatura = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/authorizationSignature`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setSystemRequestAssinatura(response.data.data)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemAprove = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/aprove`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setSystemAprove(response.data.data)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemTerceirizado = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/outsourced`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setSystemTerceirizado(response.data.data)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemNegociacao = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/negotiation`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setSystemNegoaciacao(response.data.data.statistic)
            setters.setDataGraphicNegociacao(response.data.data.graphic)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemPlans = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/plans`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setSystemPlans(response.data.data)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemAssociate = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/associate`, req)
            setters.setDataGraficAssociadosFundo(response.data.data.graphic.retiredMonth)
            setters.setDataAssociadosAposentado(response.data.data.graphic.retiredNumber)
            setters.setSystemAssociado(response.data.data.statistic)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemAssinatura = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/signature`, req)
            //localStorage.setItem('dataSystemAssociate', JSON.stringify(response.data.data))
            // 
            setters.setSystemAssinatura(response.data.data.statistic)
            setters.setDataGraficAssinaturaMethod(response.data.data.graphic.paymentNumber)
            setters.setDataAssinaturaStatus(response.data.data.graphic.dataStatus)
            return true

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemHooks = async () => {
        setters.setDataSystemHooks([])
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/hooks`, req)

            localStorage.setItem('dataSystemHooks', JSON.stringify(response.data.data.hooks))
            setters.setDataSystemHooks(response.data.data.hooks)

            return true

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }

    }

    const dataSystemTecAlert = async () => {
        setters.setSystemTecAlert([])
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/alertTec`, req)
            localStorage.setItem('dataSystemAlertTec', JSON.stringify(response.data.data))
            setters.setSystemTecAlert(response.data.data)

            return true

        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }

    }

    const dataSystemFinancial = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/financial`, req)
            setters.setDataSystemAccount(response.data.data.statistic.account)
            setters.setDataGraphicAccount(response.data.data.graphic.data)
            setters.setDataGraphicSaidas(response.data.data.graphic.data)
            setters.setDataGraphicEntradas(response.data.data.graphic.data)

            return true

        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }

    }

    const dataSystemCobranca = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/invoices`, req)

            setters.setDataSystemCobranca(response.data.data.statistic)

            setters.setDataGraphicCobranca(response.data.data.graphic.dataPaidGraphic)
            setters.setDataGraphicCobrancaNotPaid(response.data.data.graphic.dataNotPaidGraphic)

            return true

        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }

    }


    const dataSystemRemessa = async () => {
        setters.setSystemFolha([])
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/remittence`, req)

            setters.setSystemFolha(response.data.data.statistic)

            setters.setDataGraphicRemessa(response.data.data.graphic.data)

            return true

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }

    }

    const dataSystemRemessaAssociado = async () => {
        setters.setSystemFolha([])
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/remittenceAssociate`, req)
            setters.setSystemFolha(response.data.data.statistic)
            setters.setDataGraphicRemessaAssociado(response.data.data.graphic.data)

            return true

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }

    }

    const dataSystemRemessaAssociadoTransaction = async () => {
        setters.setSystemFolha([])
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/remittenceAssociateTransaction`, req)
            setters.setSystemFolha(response.data.data.statistic)
            setters.setDataGraphicRemessaAssociadoTransacaoSolved(response.data.data.graphic.dataValueBetweenPaidSolved)
            setters.setDataGraphicRemessaAssociadoTransacaoUnsolved(response.data.data.graphic.dataValueBetweenPaidNotSolved)

            return true

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }

    }

    const dataSystemSignatureFuncef = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/funcefSignature`, req)

            setters.setSystemAssinaturaFuncef(response.data.data)

            return true

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }

    }

    const dataSystemReport = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/report`, req)
            setters.setDataSystemRelatorio(response.data.data)

            return true

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemImport = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/import`, req)
            setters.setDataSystemImport(response.data.data.statistic)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemImportFaturas = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/importInvoice`, req)
            setters.setDataSystemImportFaturas(response.data.data.statistic)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSystemHealth = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/health`, req)
            setters.setDataSystemHealth(response.data.data)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    //#endregion

    //#region Logs 

    const dataAssinaturaTemporaria = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            // 
            const response = await axios.get(`${jsonConfig.urlApi}/tempSignature/getWithLimit/${states.limitAssinaturaTemporaria}&${states.offsetAssinaturaTemporaria}`, req)
            setters.setDataAssinaturaTemporaria(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setLimitSearchAssinaturaTemporaria(true)
                setters.setIsLoading(false)
                setters.setOffsetAssinaturaTemporaria(0)
            } else {

                setters.setIsLoading(false)
            }


        }

    }

    const deleteAssinaturaTemporaria = async () => {
        setters.setIsLoading(true)
        setters.setDataAssinaturaTemporaria([])
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/tempSignature/${states.AssinaturaTemporaria.id}`, req)
            // 
            setters.setIsLoading(false)
            dataAssinaturaTemporaria()
            dataSystemTemp()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryAssinaturaTemporaria = async () => {
        setters.setIsLoading(true)
        setters.setOffsetAssinaturaTemporaria(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/tempSignature/${states.querySearchAssinaturaTemporaria}`, req)

            setters.setDataQuery(response.data.data)
            setters.setDataAssinaturaTemporaria(response.data.data.slice(0, states.limitAssinaturaTemporaria))
            setters.setIsLoading(false)

            return true
        } catch (err) {

            setters.setQuerySearchAssinaturaTemporaria('')
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataSystemTemp()
                dataAssinaturaTemporaria()
                setters.setIsLoading(false)
                states.offsetAssinaturaTemporaria > 0 && setters.setOffsetAssinaturaTemporaria(states.offsetAssinaturaTemporaria - states.limitAssinaturaTemporaria)
                states.offsetAssinaturaTemporaria < 0 && setters.setOffsetAssinaturaTemporaria(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const dataRelatorio = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            // 
            const response = await axios.get(`${jsonConfig.urlApi}/report/${states.limitRelatorio}&${states.offsetRelatorio}`, req)
            setters.setDataRelatorio(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            // 
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setLimitSearchRelatorio(true)
                setters.setIsLoading(false)
                setters.setOffsetRelatorio(0)
            } else {
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }


        }

    }


    const dataLogsAssociate = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/logsSystem/getWithLimit/${states.limitLog}&${states.offsetLog}`, req)
            setters.setLogData(response.data.data)
            setters.setTotalLogs(response.data.total)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setLimitSearchLog(true)
                setters.setIsLoading(false)
                states.offsetLog > 0 && setters.setOffsetLog(states.offsetLog - states.limitLog)
                states.offsetLog < 0 && setters.setOffsetLog(0)
            } else {
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }


        }

    }

    const dataLogsJuridico = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/judicial/logsSystem/getWithLimit/${states.limitLog}&${states.offsetLog}`, req)
            setters.setLogData(response.data.data)
            setters.setTotalLogs(response.data.total)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setLimitSearchLog(true)
                setters.setIsLoading(false)
                states.offsetLog > 0 && setters.setOffsetLog(states.offsetLog - states.limitLog)
                states.offsetLog < 0 && setters.setOffsetLog(0)
            } else {
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }


        }

    }

    const dataLogs = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/logsSystem/getWithLimit/${states.limitLog}&${states.offsetLog}`, req)
            setters.setLogData(response.data.data)
            setters.setTableLog(response.data)

            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setLimitSearchLog(true)
                setters.setIsLoading(false)
                states.offsetLog > 0 && setters.setOffsetLog(states.offsetLog - states.limitLog)
                states.offsetLog < 0 && setters.setOffsetLog(0)
            } else {
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }


        }

    }

    const deleteLogs = async () => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.delete(`${jsonConfig.urlApi}/logsSystem/${states.log.id}`, req)
            toast.success(response.data.msg)
            setters.setLogData([])
            setters.setLog({})
            setters.setIsLoading(false)
            dataSystemTecAlert()
            dataLogs()

        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    //#endregion

    //#region Cron Jobs

    const dataCron = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/cron/getWithLimit/${states.limitCron}&${states.offsetCron}`, req)

            setters.setCronData(response.data.data)
            setters.setTableCron(response.data)

            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                states.offsetCron > 0 && setters.setOffsetCron(states.offsetCron - states.limitCron)
                states.offsetCron < 0 && setters.setOffsetCron(0)
                setters.setLimitSearchCron(true)
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)

            } else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }


        }
    }

    const deleteCron = async () => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.delete(`${jsonConfig.urlApi}/cron/${states.cron.id}`, req)
            toast.success(response.data.msg)
            setters.setCronData([])
            setters.setCron({})
            setters.setIsLoading(false)
            dataSystemTecAlert()
            dataCron()

        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    //#endregion

    //#region Transação associado remessa

    const dataTransactionRemittenceAssociateAlert = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/alertTransactionRemittence/getWithLimit/${states.limittransactionAssociateRemittenceAler}&${states.offsettransactionAssociateRemittenceAler}`, req)
            setters.settransactionAssociateRemittenceAlerData(response.data.data)
            setters.settransactionAssociateRemittenceAler(response.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setLimitSearchtransactionAssociateRemittenceAler(true)
                states.offsettransactionAssociateRemittenceAler > 0 && setters.setOffsettransactionAssociateRemittenceAler(states.offsettransactionAssociateRemittenceAler - states.limittransactionAssociateRemittenceAler)
                states.offsettransactionAssociateRemittenceAler < 0 && setters.setOffsettransactionAssociateRemittenceAler(0)
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)

            } else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }


        }
    }

    const deleteTransactionRemittenceAssociateAlert = async () => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.delete(`${jsonConfig.urlApi}/alertTransactionRemittence/${states.transactionAssociateRemittenceAler.id}`, req)
            toast.success(response.data.msg)
            setters.settransactionAssociateRemittenceAlerData([])
            setters.settransactionAssociateRemittenceAler({})
            setters.setIsLoading(false)
            setters.setOffsettransactionAssociateRemittenceAler(0)
            dataSystemTecAlert()
            dataTransactionRemittenceAssociateAlert()

        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    //#endregion

    //#region Alertas 

    const dataAlert = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/alert/getWithLimit/${states.limitAlert}&${states.offsetAlert}`, req)
            setters.setAlertData(response.data.data)
            setters.setTableAlert(response.data)

            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setLimitSearchAlert(true)
                states.offsetAlert < 0 && setters.setOffsetAlert(0)
                states.offsetAlert > 0 && setters.setOffsetAlert(states.offsetAlert - states.limitAlert)
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)

            } else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    const deleteAlert = async () => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.delete(`${jsonConfig.urlApi}/alert/${states.alert.id}`, req)
            toast.success(response.data.msg)
            setters.setAlertData([])
            setters.setAlert({})
            setters.setIsLoading(false)
            setters.setOffsetAlert(0)
            dataSystemTecAlert()
            dataAlert()

        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    //#endregion

    //#region AlertRemittence

    const dataAlertRemittence = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/alertRemittence/getWithLimit/${states.limitAlertRemittence}&${states.offsetAlertRemittence}`, req)
            setters.setAlertRemittenceData(response.data.data)
            setters.setTableAlertRemittence(response.data)

            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            if (err.response.status === 400) {
                setters.setLimitSearchAlertRemittence(true)
                states.offsetAlertRemittence > 0 && setters.setOffsetAlertRemittence(states.offsetAlertRemittence - states.limitAlertRemittence)
                states.offsetAlertRemittence < 0 && setters.setOffsetAlertRemittence(0)
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)

            } else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    const deleteAlertRemittence = async () => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.delete(`${jsonConfig.urlApi}/alertRemittence/${states.alertRemittence.id}`, req)
            toast.success(response.data.msg)
            setters.setAlertRemittenceData([])
            setters.setAlertRemittence({})
            setters.setIsLoading(false)
            setters.setOffsetAlertRemittence(0)
            dataSystemTecAlert()
            dataAlertRemittence()

        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    //Account 



    const dataGraficAccount = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateGraphicAccount,
                finalDate: states.finalDateGraphicAccount
            }
            const response = await axios.post(`${jsonConfig.urlApi}/account/dataGrafic`, body, req)

            setters.setDataGraphicAccount(response.data.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataExtratoConc = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateExtrato,
                finalDate: states.finalDateExtrato
            }
            const response = await axios.post(`${jsonConfig.urlApi}/account/statement/consciliate/${states.limitContaExtrato}&${states.offsetContaExtrato}`, body, req)
            if (response.data.dataInput.length === 0 && response.data.dataOutput.length === 0) {
                setters.setLimitSearchContaExtrato(true)
                setters.setOffsetContaExtrato(states.offsetContaExtrato - states.limitContaExtrato)
                setters.setIsLoading(false)
                toast.error('Fim dos dados de extrato')
            } else {
                const data = []
                response.data.dataInput.forEach((item) => {
                    data.push({ ...item, input: true })
                })
                response.data.dataOutput.forEach((item) => {
                    data.push({ ...item, input: false })
                })
                data.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date)
                });
                dataSystemFinancial()
                setters.setDataContaExtrato(data)
                setters.setTableContaExtrato(response.data)
                setters.setIsLoading(false)
                return true
            }

        } catch (err) {
            console.log(err.response)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    //REPOT 

    const createReportFinancial = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateExtrato,
                finalDate: states.finalDateExtrato,
                title: states.titleReport,
                description: states.descriptionReport
            }
            // 
            const response = await axios.post(`${jsonConfig.urlApi}/report/financial`, body, req)
            setters.setModalInfo(true)
            setters.setTitleReport('')
            setters.setDescriptionReport('')
            setters.setModalInfoTitle('Sucesso')
            setters.setModalInfoMessage(response.data.msg)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }

    }

    const createReportFinancialResult = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateExtrato,
                finalDate: states.finalDateExtrato,
                title: states.titleReport,
                description: states.descriptionReport
            }
            // 
            const response = await axios.post(`${jsonConfig.urlApi}/report/financialMonth`, body, req)
            setters.setModalInfo(true)
            setters.setTitleReport('')
            setters.setDescriptionReport('')
            setters.setModalInfoTitle('Sucesso')
            setters.setModalInfoMessage(response.data.msg)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const createReportFinancialExpenses = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDataCustos,
                finalDate: states.finalDataCustos,
                title: states.titleReport,
                description: states.descriptionReport
            }
            // 
            const response = await axios.post(`${jsonConfig.urlApi}/report/expenses`, body, req)
            setters.setModalInfo(true)
            setters.setTitleReport('')
            setters.setDescriptionReport('')
            setters.setModalInfoTitle('Sucesso')
            setters.setModalInfoMessage(response.data.msg)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const createReportFinancialExpensesExtract = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDataCustos,
                finalDate: states.finalDataCustos,
                title: states.titleReport,
                description: states.descriptionReport
            }
            // 
            const response = await axios.post(`${jsonConfig.urlApi}/report/expensesExtract`, body, req)
            setters.setModalInfo(true)
            setters.setTitleReport('')
            setters.setDescriptionReport('')
            setters.setModalInfoTitle('Sucesso')
            setters.setModalInfoMessage(response.data.msg)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }

    const createReportAssociado = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDataAssociados,
                finalDate: states.finalDataAssociados,
                title: states.titleReport,
                description: states.descriptionReport
            }
            const response = await axios.post(`${jsonConfig.urlApi}/report/filiate`, body, req)
            setters.setModalInfo(true)
            setters.setModalInfoTitle('Sucesso')
            setters.setModalInfoMessage(response.data.msg)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }

    const createReportAssociadoForm = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/report/filiateFile/${states.Associadoss.id}`, {}, req)
            setters.setModalInfo(true)
            setters.setModalInfoTitle('Sucesso')
            setters.setModalInfoMessage(response.data.msg)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }

    //Entradas

    const getDataGraphicInput = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateEntradas,
                finalDate: states.finalDateEntradas
            }
            const response = await axios.post(`${jsonConfig.urlApi}/financialEntry/dataGrafic`, body, req)

            setters.setDataGraphicEntradas(response.data.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const dataEntradas = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateEntradas,
                finalDate: states.finalDateEntradas
            }
            // // 
            const response = await axios.post(`${jsonConfig.urlApi}/financialEntry/getWithLimit/${states.limitEntradas}&${states.offsetEntradas}`, body, req)

            setters.setDataEntradas(response.data.data)
            setters.setTableEntradas(response.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {

            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchEntradas(true)
                states.offsetEntradas < 0 && setters.setOffsetEntradas(states.offsetEntradas - states.limitEntradas)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    //Saidas

    const getDataGraphicSaidas = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateSaidas,
                finalDate: states.finalDateSaidas
            }
            const response = await axios.post(`${jsonConfig.urlApi}/financialOutPut/dataGrafic`, body, req)

            setters.setDataGraphicSaidas(response.data.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataSaidas = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateSaidas,
                finalDate: states.finalDateSaidas
            }
            const response = await axios.post(`${jsonConfig.urlApi}/financialOutPut/getWithLimit/${states.limitSaidas}&${states.offsetSaidas}`, body, req)

            setters.setDataSaidas(response.data.data)
            setters.setTableSaidas(response.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchSaidas(true)
                states.offsetSaidas > 0 && setters.setOffsetSaidas(states.offsetSaidas - states.limitSaidas)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    //Faturas 

    const getDataGraphicFaturas = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateCobranca,
                finalDate: states.finalDateCobranca
            }
            const response = await axios.post(`${jsonConfig.urlApi}/invoices/dataGrafic`, body, req)
            setters.setDataGraphicCobranca(response.data.dataPaidGraphic)
            setters.setDataGraphicCobrancaNotPaid(response.data.dataNotPaidGraphic)
            // // 
            //setters.setDataGraphicCobranca({paid: response.data.dataPaidGraphic, unpaid: response.data.dataNotPaidGraphic})
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }


    // const [offsetFiltroCobrancas, setOffsetFiltroCobrancas] = useState(0)
    // const [limitFiltroCobrancas, setLimitFiltroCobrancas] = useState(10)
    // const [dataTableFiltroCobrancas, setDataTableFiltroCobrancas] = useState([]) 
    // const [limitSearchFiltroCobrancas, setLimitSearchFiltroCobrancas] = useState(false)
    // const [infoFilterCobranca, setInfoFilterCobranca] = useState({})   

    const dataCobrancasQuery = async (data) => {
        setters.setIsLoading(true)
        //states.dataQuery.length > 0 && setters.setOffsetAssociadoss(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {

            const url = Object.keys(data).map((key) => {
                return `${key}=${data[key]}`
            }).join('&')
            const response = await axios.get(`${jsonConfig.urlApi}/query/allData/invoices/${states.limitFiltroCobrancas}&${states.offsetFiltroCobrancas}?${url}`, req)
            setters.setIsLoading(false)
            setters.setDataTableFiltroCobrancas(response.data.data)
            setters.setInfoFilterCobranca({ pages: response.data.pages, total: response.data.totalRegisters })

            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchFiltroCobrancas(true)
                states.offsetFiltroCobrancas > 0 && setters.setOffsetFiltroCobrancas(states.offsetFiltroCobrancas - states.limitFiltroCobrancas)
                states.offsetFiltroCobrancas < 0 && setters.setOffsetFiltroCobrancas(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }


    const dataCobranca = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateCobranca,
                finalDate: states.finalDateCobranca,
                status: states.statusCobranca
            }

            const response = await axios.post(`${jsonConfig.urlApi}/invoices/getWithLimit/${states.limitCobranca}&${states.offsetCobranca}`, body, req)
            setters.setDataCobranca(response.data.data)
            setters.setTableCobranca(response.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchCobranca(true)
                setters.setOffsetCobranca(states.offsetCobranca - states.limitCobranca)
                toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }


    const createCobranca = async (body) => {
        setters.setIsLoading(true)
        setters.setModalIsOpen(false)
        setters.setAssociadoFatura({})
        setters.setTerceirizadoFatura({})
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/invoices`, body, req)

            dataCobranca()
            dataSystemCobranca()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }



    const cancelarFatura = async () => {
        ///canceled/invoices/:id
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/canceled/invoices/${states.Cobranca.id}`, {}, req)

            dataCobranca()
            dataSystemCobranca()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const mudarDataFatura = async () => {
        ///canceled/invoices/:id
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/changeDate/invoices/${states.Cobranca.id}`, states.Cobranca, req)
            dataCobranca()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const reembolso = async () => {
        ///refound/invoices/:id
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/refound/invoices/${states.Cobranca.id}`, {}, req)

            dataCobranca()
            dataSystemCobranca()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const checkFaturaVindi = async (data) => {
        ///refound/invoices/:id
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/invoices/checkInvoice/${data.id}`, {}, req)
            // 
            dataCobranca()
            dataSystemCobranca()
            setters.setIsLoading(false)
            setters.setModalInfo(true)
            setters.setModalInfoTitle('Sucesso')
            setters.setModalInfoMessage(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const baixaFatura = async () => {
        ///invoice/writtenOff
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/invoice/writtenOff`, states.Cobranca, req)
            // 
            dataCobranca()
            dataSystemCobranca()
            setters.setIsLoading(false)
            toast.success(response.data.response.msg)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                setters.setModalInfo(true)
                setters.setModalInfoMessage(err.response.data.response.msg)
                setters.setModalInfoTitle('Erro')
                setters.setError(true)
                toast.error(err.response.data.msg)
            }
        }
    }

    const searchQueryCobranca = async () => {
        setters.setIsLoading(true)
        setters.setOffsetRequestFatura(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/invoices/${states.queryCobranca}`, req)
            setters.setDataQuery(response.data.data)
            setters.setDataCobranca(response.data.data.slice(0, states.limitCobranca))
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setQueryCobranca('')
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataSystemCobranca()
                dataCobranca()
                setters.setIsLoading(false)
                states.offsetCobranca > 0 && setters.setOffsetCobranca(states.offsetCobranca - states.limitCobranca)
                states.offsetCobranca < 0 && setters.setOffsetCobranca(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    //PAGAMENTO 

    const getDataGraphicFaturasPagamento = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDatePagamento,
                finalDate: states.finalDatePagamento
            }
            const response = await axios.post(`${jsonConfig.urlApi}/invoicesPayment/dataGrafic`, body, req)
            setters.setDataGraphicPagamento(response.data.dataPaidGraphic)
            setters.setDataGraphicPagamentoNotPaid(response.data.dataNotPaidGraphic)
            setters.setIsLoading(false)

            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataPagamento = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDatePagamento,
                finalDate: states.finalDatePagamento
            }
            const response = await axios.post(`${jsonConfig.urlApi}/invoicePayment/getWithLimit/${states.limitPagamento}&${states.offsetPagamento}`, body, req)

            setters.setDataPagamento(response.data.data)
            setters.setTablePagamento(response.data)
            setters.setIsLoading(false)

            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchPagamento(true)
                states.offsetPagamento > 0 && setters.setOffsetPagamento(states.offsetPagamento - states.limitPagamento)
                states.offsetPagamento < 0 && setters.setOffsetPagamento(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    //AQUI TEM A QUESTAO DE SER COM ARQUIVO OU SEM ARQUIVO
    const createPagamento = async (body) => {
        setters.setIsLoading(true)
        setters.setModalIsOpen(false)
        setters.setAssociadoFatura({})
        setters.setTerceirizadoFatura({})
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/invoicePayment/notFile`, body, req)

            dataPagamento()
            dataSystemPagamento()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const createPagamentoFile = async (body) => {
        setters.setIsLoading(true)
        setters.setIsEdit(false)
        setters.setIsLoading(true)
        setters.setModalIsOpen(false)
        setters.setAssociadoFatura({})
        setters.setTerceirizadoFatura({})
        const req = {
            headers: {
                'Content-Type': 'multipart/form-data',
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {

            const response = await axios.post(`${jsonConfig.urlApi}/invoicePayment/file`, body, req)
            // 
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            dataPagamento()
            getDataGraphicFaturasPagamento()
            dataSystemPagamento()
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const cancelarPagamento = async () => {
        ///canceled/invoices/:id
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/invoicePayment/canceled/${states.Pagamento.id}`, {}, req)

            dataPagamento()
            dataSystemPagamento()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const baixaFaturaPagamento = async () => {
        ///invoice/writtenOff
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/invoicePayment/writtenOff`, states.Pagamento, req)
            dataPagamento()
            dataSystemPagamento()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                setters.setModalInfo(true)
                setters.setModalInfoMessage(err.response.data.msg)
                setters.setModalInfoTitle('Erro')
                setters.setError(true)
                toast.error(err.response.data.msg)
            }
        }
    }

    const searchQueryPagamento = async () => {
        setters.setIsLoading(true)
        setters.setOffsetRequestFatura(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/invoicesPayment/${states.queryPagamento}`, req)
            setters.setDataQuery(response.data.data)
            setters.setDataPagamento(response.data.data.slice(0, states.limitPagamento))
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setQueryPagamento('')
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataSystemPagamento()
                dataPagamento()
                setters.setIsLoading(false)
                states.offsetPagamento > 0 && setters.setOffsetPagamento(states.offsetPagamento - states.limitPagamento)
                states.offsetPagamento < 0 && setters.setOffsetPagamento(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    //EXPENSES

    const getAllExpenses = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/expenses`, req)
            setters.setDataCustos(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }


    const dataCustos = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDataCustos,
                finalDate: states.finalDataCustos
            }
            const response = await axios.post(`${jsonConfig.urlApi}/expenses/getWithLimit/${states.limitCustos}&${states.offsetCustos}`, body, req)
            /*const response = { "status": true, "data": [{ "id": "b338f16c-8f1b-45d6-9e56-038a311f69fe", "type": "Despesas com Eventos", "budget": 42300000,
             "created_at": "2023-08-21T02:03:40.000Z", "updated_at": "2023-08-21T02:03:58.000Z", "isDeleted": 0, "usedValue": 6514416 }, { "id": "73268ca9-7a42-4bbe-81dd-a35f75788839", 
             "type": "Despesas com Pessoal", "budget": 41200000, "created_at": "2023-08-20T22:45:22.000Z", "updated_at": "2023-08-21T02:03:49.000Z", "isDeleted": 0, "usedValue": 47188493 }, 
             { "id": "6daca1ce-6cd1-4232-999e-5881ffe0eb45", "type": "Despesas Administrativas", "budget": 46000000, "created_at": "2023-08-19T15:10:42.000Z", "updated_at": "2023-08-21T02:02:48.000Z", 
             "isDeleted": 0, "usedValue": 42005404 }], "msg": "Expenses encontrados com sucesso!" }
            */
            const dataNotDuplicate = response.data.data.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.id === item.id
                ))
            )
            setters.setDataCustos(dataNotDuplicate)
            // setters.setDataCustos(response.data.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchCustos(true)
                states.offsetCustos > 0 && setters.setOffsetCustos(states.offsetCustos - states.limitCustos)
                states.offsetCustos < 0 && setters.setOffsetCustos(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }
        }
    }


    const dataCustosFaturas = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/expenses/invoice/${id}/${states.limitCustosFatura}&${states.offsetCustosFatura}`, req)
            // 
            setters.setDataCustosFatura(response.data.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchCustosFatura(true)
                states.offsetCustosFatura > 0 && setters.setOffsetCustosFatura(states.offsetCustosFatura - states.limitCustosFatura)
                states.offsetCustosFatura < 0 && setters.setOffsetCustosFatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    const postExpenses = async (body) => {
        setters.setOffsetCustos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/expenses`, body, req)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            await dataCustos()
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const putExpenses = async (body) => {
        setters.setOffsetCustos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/expenses/${states.Custos.id}`, body, req)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            await dataCustos()
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const deleteExpenses = async () => {
        setters.setOffsetCustos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/expenses/${states.Custos.id}`, req)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            await dataCustos()
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }
    //INVESTIMENTOS 

    const dataInvestimentos = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/investment/getWithLimit/${states.limitInvestimentos}&${states.offsetInvestimentos}`, req)
            // 
            if (states.offsetInvestimentos != 0) {

                //Adicionar somente a que nao existe 
                const dataNotDuplicate = response.data.data.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === item.id
                    ))
                )
                const newData = [...states.dataInvestimentos, ...dataNotDuplicate]
                setters.setDataInvestimentos(newData)
            } else {
                setters.setDataInvestimentos(response.data.data)
            }
            setters.setIsLoading(false)

            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchInvestimentos(true)
                states.offsetInvestimentos > 0 && setters.setOffsetInvestimentos(states.offsetInvestimentos - states.limitInvestimentos)
                states.offsetInvestimentos < 0 && setters.setOffsetInvestimentos(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }


    const postInvestimento = async (body) => {
        setters.setOffsetInvestimentos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/investment`, body, req)
            setters.setDataInvestimentos([])
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            await dataInvestimentos()
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }
    const putInvestimento = async (body) => {
        setters.setOffsetInvestimentos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/investment/${body.id}`, body, req)
            toast.success(response.data.msg)
            setters.setDataInvestimentos([])
            setters.setIsLoading(false)
            await dataInvestimentos()
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }
    const deleteInvestimento = async () => {
        setters.setOffsetInvestimentos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/investment/${states.Investimentos.id}`, req)
            toast.success(response.data.msg)
            setters.setDataInvestimentos([])
            setters.setIsLoading(false)
            await dataInvestimentos()
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    /////APORTTES

    const dataAport = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/financialInvestment/getWithLimit/${id}/${states.limitAport}&${states.offsetAport}`, req)
            setters.setDataAport(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAport(true)
                states.offsetAport > 0 && setters.setOffsetAport(states.offsetAport - states.limitAport)
                states.offsetAport < 0 && setters.setOffsetAport(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }


    const postAport = async (body) => {
        setters.setOffsetInvestimentos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/financialInvestment`, body, req)

            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const putAport = async (body, id) => {
        setters.setOffsetInvestimentos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/financialInvestment/${body.id}`, { ...body, investment_id: id }, req)
            toast.success(response.data.msg)
            setters.setDataAport([])
            setters.setIsLoading(false)
            await dataAport(id)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const aproveAport = async (id) => {
        setters.setOffsetInvestimentos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/financialInvestment/approved/${states.Aport.id}`, {}, req)
            toast.success(response.data.msg)
            setters.setDataAport([])
            setters.setIsLoading(false)
            await dataAport(id)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const unproveAport = async (id) => {
        setters.setOffsetInvestimentos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/financialInvestment/unproved/${states.Aport.id}`, {}, req)
            toast.success(response.data.msg)
            setters.setDataAport([])
            setters.setIsLoading(false)
            await dataAport(id)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const endInvest = async (id) => {
        setters.setOffsetInvestimentos(0)
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/financialInvestment/closeinvestMent/${states.Aport.id}`, {}, req)
            toast.success(response.data.msg)
            setters.setDataAport([])
            setters.setIsLoading(false)
            await dataAport(id)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    //EXTRATO DE APORTE

    const dataExtrato = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/extract/getWithLimit/${id}/${states.limitExtrato}&${states.offsetExtrato}`, req)
            setters.setDataExtrato(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchExtrato(true)
                states.offsetExtrato > 0 && setters.setOffsetExtrato(states.offsetExtrato - states.limitExtrato)
                states.offsetExtrato < 0 && setters.setOffsetExtrato(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    //BUSCA DE ASSOCIADO OU TERCEIRIZADO 

    const buscarAssociadoCpf = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            const Result = await axios.get(`${jsonConfig.urlApi}/associate/cpf/${states.codeRegister}`, req);
            // // 
            setters.setAssociadoFatura(Result.data.associate)
            setters.setCodeRegister('')
            setters.setIsLoading(false)
            //
            setters.setTerceirizadoFatura({})
            setters.setCodeRegister('')
        }
        catch (err) {
            setters.setAssociadoFatura({})
            setters.setTerceirizadoFatura({})
            setters.setCodeRegister('')
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const buscarTerceirizadoCodigo = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            const Result = await axios.get(`${jsonConfig.urlApi}/registerCode/outsourced/${states.codeRegister}`, req);
            // // 
            setters.setCodeRegister('')
            setters.setTerceirizadoFatura(Result.data.data)
            setters.setAssociadoFatura({})
            setters.setCodeRegister('')
            setters.setIsLoading(false)
            //
        }
        catch (err) {
            setters.setAssociadoFatura({})
            setters.setTerceirizadoFatura({})
            setters.setCodeRegister('')
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }







    //folha

    const getDataGraphicRemessa = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateRemessa,
                finalDate: states.finalDateRemessa
            }
            const response = await axios.post(`${jsonConfig.urlApi}/remittence/graphic`, body, req)

            setters.setDataGraphicRemessa(response.data.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataRemessa = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateRemessa,
                finalDate: states.finalDateRemessa
            }
            const response = await axios.post(`${jsonConfig.urlApi}/remittence/getWithLimit/${states.limitRemessa}&${states.offsetRemessa}`, body, req)

            setters.setDataRemessa(response.data.data)
            setters.setTableRemessa(response.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchRemessa(true)
                states.offsetRemessa > 0 && setters.setOffsetRemessa(states.offsetRemessa - states.limitRemessa)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    const postRemessa = async () => {
        setters.setIsLoading(true)
        setters.setPostRemessa(false)
        setters.setModalRemessa(false)
        const req = {
            headers: {
                'Content-Type': 'multipart/form-data',
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                file: states.RemessaFile
            }
            const response = await axios.post(`${jsonConfig.urlApi}/remittence`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            dataRemessa()
            getDataGraphicRemessa()
            dataSystemRemessa()
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const putRemessa = async () => {
        setters.setIsLoading(true)
        setters.setPostRemessa(false)
        setters.setModalRemessa(false)
        const req = {
            headers: {
                'Content-Type': 'multipart/form-data',
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                file: states.RemessaFile
            }
            const response = await axios.put(`${jsonConfig.urlApi}/remittence/${states.Remessa.id}`, body, req)

            setters.setIsLoading(false)
            dataRemessa()
            getDataGraphicRemessa()
            dataSystemRemessa()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }
    //folhaAssociado

    const getDataGraphicRemessaAssociado = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateRemessaAssociado,
                finalDate: states.finalDateRemessaAssociado
            }
            const response = await axios.post(`${jsonConfig.urlApi}/remittenceAssociate/dataGraphic`, body, req)

            setters.setDataGraphicRemessaAssociado(response.data.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataRemessaAssociado = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateRemessaAssociado,
                finalDate: states.finalDateRemessaAssociado
            }
            const response = await axios.post(`${jsonConfig.urlApi}/remittenceAssociate/getWithLimit/${states.limitRemessaAssociado}&${states.offsetRemessaAssociado}`, body, req)

            setters.setDataRemessaAssociado(response.data.data)
            setters.setTableRemessaAssociado(response.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchRemessaAssociado(true)
                states.offsetRemessaAssociado > 0 && setters.setOffsetRemessaAssociado(states.offsetRemessaAssociado - states.limitRemessaAssociado)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    const searchQueryRemessaAssociado = async () => {
        setters.setIsLoading(true)
        setters.setOffsetRemessaAssociado(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/remittenceAssociate/${states.queryRemessaAssociado}`, req)
            // 
            setters.setDataQuery(response.data.data)
            setters.setDataRemessaAssociado(response.data.data.slice(0, states.limitRemessaAssociado))
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                dataRemessaAssociado()
                states.offsetRemessaAssociado > 0 && setters.setOffsetRemessaAssociado(states.offsetRemessaAssociado - states.limitRemessaAssociado)
                states.offsetRemessaAssociado < 0 && setters.setOffsetRemessaAssociado(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    //FOLHA ASSOCIADO TRANSACAO
    const getDataGraphicRemessaAssociadoTransacao = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateRemessaAssociadoTransacao,
                finalDate: states.finalDateRemessaAssociadoTransacao
            }
            const response = await axios.post(`${jsonConfig.urlApi}/remittenceAssociateTransaction/dataGraphic`, body, req)

            setters.setDataGraphicRemessaAssociadoTransacaoSolved(response.data.data.dataValueBetweenPaidSolved)
            setters.setDataGraphicRemessaAssociadoTransacaoUnsolved(response.data.data.dataValueBetweenPaidNotSolved)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const dataRemessaAssociadoTransacao = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateRemessaAssociadoTransacao,
                finalDate: states.finalDateRemessaAssociadoTransacao
            }
            const response = await axios.post(`${jsonConfig.urlApi}/remittenceAssociateTransaction/getWithLimit/${states.limitRemessaAssociadoTransacao}&${states.offsetRemessaAssociadoTransacao}`, body, req)

            setters.setDataRemessaAssociadoTransacao(response.data.data)
            setters.setTableRemessaAssociadoTransacao(response.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchRemessaAssociadoTransacao(true)
                states.offsetRemessaAssociadoTransacao > 0 && setters.setOffsetRemessaAssociadoTransacao(states.offsetRemessaAssociadoTransacao - states.limitRemessaAssociadoTransacao)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }


        }
    }

    const searchQueryAssociadoTransacao = async () => {
        setters.setIsLoading(true)
        setters.setOffsetRemessaAssociadoTransacao(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/remittenceAssociateTransaction/${states.querySearchRemessaAssociadoTransacao}`, req)
            setters.setDataQuery(response.data.data)
            setters.setDataRemessaAssociadoTransacao(response.data.data.slice(0, states.limitRemessaAssociadoTransacao))
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                dataRemessaAssociadoTransacao()
                states.offsetRemessaAssociadoTransacao > 0 && setters.setOffsetRemessaAssociadoTransacao(states.offsetRemessaAssociadoTransacao - states.limitRemessaAssociadoTransacao)
                states.offsetRemessaAssociadoTransacao < 0 && setters.setOffsetRemessaAssociadoTransacao(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }



    //Assinatura Funcef

    const searchQueryAssinaturaFuncef = async () => {
        setters.setIsLoading(true)
        setters.setOffsetFuncefAssinatura(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/signatureFuncefAssociate/${states.querySearchFuncefAssinatura}`, req)
            setters.setDataQuery(response.data.data)
            setters.setDataFuncefAssinatura(response.data.data.slice(0, states.limitFuncefAssinatura))
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                dataFuncefAssinatura()
                states.offsetFuncefAssinatura > 0 && setters.setOffsetFuncefAssinatura(states.offsetFuncefAssinatura - states.limitFuncefAssinatura)
                states.offsetFuncefAssinatura < 0 && setters.setOffsetFuncefAssinatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }



    const dataFuncefAssinatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/signatureFuncefAssociate/getWithLimit/${states.limitFuncefAssinatura}&${states.offsetFuncefAssinatura}`, req)

            setters.setDataFuncefAssinatura(response.data.data)
            setters.setTableFuncefAssinatura(response.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchFuncefAssinatura(true)
                states.offsetFuncefAssinatura > 0 && setters.setOffsetFuncefAssinatura(states.offsetFuncefAssinatura - states.limitFuncefAssinatura)
                states.offsetFuncefAssinatura < 0 && setters.setOffsetFuncefAssinatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const aproveSigature = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            // // 
            const response = await axios.put(`${jsonConfig.urlApi}/funcefSignature/active/${states.FuncefAssinatura.signature_id}`, {}, req)
            setters.setIsLoading(false)

            dataFuncefAssinatura()
            dataSystemSignatureFuncef()
            toast.success(response.data.msg)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const returnSigatureFuncef = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            // // 
            const response = await axios.put(`${jsonConfig.urlApi}/singature/exitFuncef/${states.FuncefAssinatura.signature_id}/${states.FuncefAssinatura.plan_id}`, {}, req)
            setters.setIsLoading(false)
            // 
            setters.setFuncefAssinatura({})
            setters.setAssociadoFatura({})
            dataFuncefAssinatura()
            dataSystemSignatureFuncef()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const createFuncefAssinatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            // // 
            const response = await axios.post(`${jsonConfig.urlApi}/funcefSignature`, states.FuncefAssinatura, req)
            setters.setIsLoading(false)
            // 
            setters.setFuncefAssinatura({})
            setters.setAssociadoFatura({})
            dataFuncefAssinatura()
            dataSystemSignatureFuncef()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    //ASSOCIADOS

    const dataAssociadosGrafico = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataGraphic/associate`, req)
            setters.setDataAssociadosAposentado(response.data.retiredNumber)
            setters.setDataGraficAssociadosFundo(response.data.fundoNumber)
            setters.setIsLoading(false)

            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAssociadoss(true)
                states.offsetAssociadoss > 0 && setters.setOffsetAssociadoss(states.offsetAssociadoss - states.limitAssociadoss)
                states.offsetAssociadoss < 0 && setters.setOffsetAssociadoss(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const dataAssociados = async () => {
        setters.setIsLoading(true)
        states.dataQuery.length > 0 && setters.setOffsetAssociadoss(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDataAssociados,
                finalDate: states.finalDataAssociados,
                filiate: states.isFiliate
            }
            const response = await axios.post(`${jsonConfig.urlApi}/associate/getWithLimit/${states.limitAssociadoss}&${states.offsetAssociadoss}`, body, req)
            setters.setDataAssociadoss(response.data.data)
            setters.setTableAssociadoss(response.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAssociadoss(true)
                states.offsetAssociadoss > 0 && setters.setOffsetAssociadoss(states.offsetAssociadoss - states.limitAssociadoss)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const dataAssociadosQuery = async (data) => {
        setters.setIsLoading(true)
        //states.dataQuery.length > 0 && setters.setOffsetAssociadoss(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {

            const url = Object.keys(data).map((key) => {
                return `${key}=${data[key]}`
            }).join('&')
            const response = await axios.get(`${jsonConfig.urlApi}/query/allData/associate/${states.limitFiltroAssociados}&${states.offsetFiltroAssociados}?${url}`, req)
            setters.setIsLoading(false)
            setters.setDataTableFiltroAssociados(response.data.data)
            setters.setInfoFilterAssociado({ pages: response.data.pages, total: response.data.totalRegisters })

            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchFiltroAssociados(true)
                states.offsetFiltroAssociados > 0 && setters.setOffsetFiltroAssociados(states.offsetFiltroAssociados - states.limitFiltroAssociados)
                states.offsetFiltroAssociados < 0 && setters.setOffsetFiltroAssociados(0)
                toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const dataAssociadoFatura = async (id) => {


        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/invoices/${id}/getWithLimit/${states.limitAssociadoss}&${states.offsetAssociadoss}`, req)
            if (states.dataAssociadossFatura.data != undefined) {
                const diferElement = []
                response.data.data.data.forEach((item) => {
                    const element = states.dataAssociadossFatura.data.find((element) => element.id === item.id)
                    if (element === undefined) {
                        diferElement.push(item)
                    }
                })
                if (diferElement.length > 0) {
                    setters.setDataAssociadossFatura({ data: [...states.dataAssociadossFatura.data, ...diferElement], total: response.data.data.totalValue })
                }
            } else {
                setters.setDataAssociadossFatura({ data: response.data.data.data, total: response.data.data.totalValue })
            }
            setters.setIsLoading(false)

            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.clear()
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAssociadoss(true)
                states.offsetAssociadoss > 0 && setters.setOffsetAssociadoss(states.offsetAssociadoss - states.limitAssociadoss)
                toast.error(err.response.data.msg)
                if (states.dataAssociadossFatura.data === undefined) {
                    return false
                }
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const getDebit = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetAssociadoss(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/associate/getDebit/${data.id}`, req)
            setters.setModalInfo(true)
            setters.setModalInfoTitle('Débito encontrado!')
            setters.setModalInfoMessage(response.data.msg)
            setters.setIsLoading(false)
            setters.setAssociadoss({})
            return true
        } catch (err) {
            setters.setAssociadoss({})
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAssociadoss(true)
                states.offsetAssociadoss > 0 && setters.setOffsetAssociadoss(states.offsetAssociadoss - states.limitAssociadoss)
                states.offsetAssociadoss < 0 && setters.setOffsetAssociadoss(0)
                setters.setModalInfo(true)
                setters.setModalInfoTitle('Débito não encontrado!')
                setters.setModalInfoMessage(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryAssociados = async () => {
        setters.setIsLoading(true)
        setters.setOffsetAssociadoss(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/associate/${states.querySearchAssociados}`, req)
            setters.setDataQuery(response.data.data)
            setters.setDataAssociadoss(response.data.data.slice(0, states.limitAssociadoss))
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                dataAssociados()
                states.offsetAssociadoss > 0 && setters.setOffsetAssociadoss(states.offsetAssociadoss - states.limitAssociadoss)
                states.offsetAssociadoss < 0 && setters.setOffsetAssociadoss(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const getCreditCard = async (id) => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/associate/card/${id}`, req)

            setters.setDataAssociadossCartoes(response.data.data)
            setters.setIsLoading(false)

            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
                return false
            }

        }
    }

    const deleteCreditCard = async (data) => {
        setters.setIsLoading(true)

        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };

        try {
            const response = await axios.put(`${jsonConfig.urlApi}/associate/delete/card/${data.idAssociate}`, { id: data.id }, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            await getCreditCard(data.idAssociate)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('dataSystemAlertTec')
                localStorage.removeItem('permisssion')
                // setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const unfiliateAssociate = async (data) => {
        setters.setIsLoading(true)

        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };

        try {
            setters.setOffsetAssociadoss(0)
            const response = await axios.put(`${jsonConfig.urlApi}/associate/unfiliate/${states.Associadoss.id}`, {}, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            await dataAssociados()
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('dataSystemAlertTec')
                localStorage.removeItem('permisssion')
                // setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const createCreditCard = async (data) => {
        setters.setIsLoading(true)

        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };

        try {
            const response = await axios.post(`${jsonConfig.urlApi}/associate/addCard/${data.idAssociate}`, { ...data }, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)

            setters.setError(false)
            setters.setModalInfo(true)
            setters.setModalInfoTitle('Adição de cartão - Mensagem')
            setters.setModalInfoMessage('Cartão adicionado com sucesso!')

            await getCreditCard(data.idAssociate)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('dataSystemAlertTec')
                localStorage.removeItem('permisssion')
                // setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
                setters.setError(true)
                setters.setModalInfo(true)
                setters.setModalInfoTitle('Erro ao adicionar o cartão de crédito')
                setters.setModalInfoMessage(err.response.data.msg)
            }
        }
    }

    //Assinaturas -> Realizar aqui a busca ampla de todas as assinaturas

    const dataAssinaturaGrafico = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataGrafic/signature`, req)
            setters.setDataGraficAssinaturaMethod(response.data.paymentNumber)
            setters.setDataAssinaturaStatus(response.data.dataStatus)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAssinatura(true)
                states.offsetAssinatura > 0 && setters.setOffsetAssinatura(states.offsetAssinatura - states.limitAssinatura)
                states.offsetAssinatura < 0 && setters.setOffsetAssinatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const dataAssinatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDataAssinatura,
                finalDate: states.finalDataAssinatura,
                filiate: states.isFiliate
            }
            const response = await axios.post(`${jsonConfig.urlApi}/signature/getWithLimit/${states.limitAssinatura}&${states.offsetAssinatura}`, body, req)
            setters.setDataAssinatura(response.data.data)
            setters.setTableAssinatura(response.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAssinatura(true)
                states.offsetAssinatura > 0 && setters.setOffsetAssinatura(states.offsetAssinatura - states.limitAssinatura)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }


    const dataAssinaturaCard = async (data) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/signature/params/${data}/${states.limitAssinaturaCard}&${states.offsetAssinaturaCard}`, req)
            if (response.data.totalByFundo) {
                setters.setDebitByFundo(response.data.totalByFundo)
            }
            setters.setDataAssinaturaCard(response.data.response)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAssinaturaCard(true)
                states.offsetAssinaturaCard > 0 && setters.setOffsetAssinaturaCard(states.offsetAssinaturaCard - states.limitAssinaturaCard)
                toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const dataAssinaturaExtrato = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/signature/extract/${id}/${states.limitAssinaturaExtrato}&${states.offsetAssinaturaExtrato}`, req)
            setters.setDataAssinaturaExtrato(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }
            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAssinaturaExtrato(true)
                states.offsetAssinaturaExtrato > 0 && setters.setOffsetAssinaturaExtrato(states.offsetAssinaturaExtrato - states.limitAssinaturaExtrato)
                // toast.error(err.response.data.msg)
            }
            setters.setIsLoading(false)
            return false;


        }
    }

    const dataAssinaturaQuery = async (data) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const url = Object.keys(data).map((key) => {
                return `${key}=${data[key]}`
            }).join('&')
            const response = await axios.get(`${jsonConfig.urlApi}/query/allData/signature/${states.limitFiltroAssinaturas}&${states.offsetFiltroAssinaturas}?${url}`, req)
            setters.setIsLoading(false)
            setters.setDataTableFiltroAssinaturas(response.data.data)
            setters.setInfoFilterAssinatura({ pages: response.data.pages, total: response.data.totalRegisters })

            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchFiltroAssinaturas(true)
                states.offsetFiltroAssinaturas > 0 && setters.setOffsetFiltroAssinaturas(states.offsetFiltroAssinaturas - states.limitFiltroAssinaturas)
                states.offsetFiltroAssinaturas < 0 && setters.setOffsetFiltroAssinaturas(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryAssinatura = async () => {
        setters.setIsLoading(true)
        setters.setOffsetAssinatura(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/signature/${states.querySearchAssinatura}`, req)
            setters.setDataQuery(response.data.data)
            setters.setDataAssinatura(response.data.data.slice(0, states.limitAssinatura))
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                dataAssinatura()
                states.offsetAssinatura > 0 && setters.setOffsetAssinatura(states.offsetAssinatura - states.limitAssinatura)
                states.offsetAssinatura < 0 && setters.setOffsetAssinatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const createAssinatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/signature`, states.Assinatura, req)
            setters.setIsLoading(false)
            setters.setDataAssinatura([])
            setters.setAssociadoFatura({})
            dataSystemAssinatura()
            dataAssinatura()
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                // localStorage.clear()
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const suspendAssinatura = async () => {
        setters.setIsLoading(true)
        setters.setConfirmModal(false)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/signature/suspend/${states.Assinatura.id}`, {}, req)
            setters.setIsLoading(false)
            setters.setDataAssinatura([])
            setters.setAssociadoFatura({})
            dataSystemAssinatura()
            dataAssinatura()
            toast.success(response.data.msg)

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const activeAssinatura = async () => {
        setters.setIsLoading(true)
        setters.setConfirmModal(false)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/signature/active/${states.Assinatura.id}`, {}, req)
            // 
            setters.setIsLoading(false)
            dataSystemAssinatura()
            dataAssinatura()
            setters.setDataAssinatura([])
            setters.setAssociadoFatura({})
            toast.success(response.data.msg)

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const updateAssinatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/signature/${states.Assinatura.id}`, states.Assinatura, req)
            // 
            setters.setIsLoading(false)
            setters.setDataAssinatura([])
            setters.setAssociadoFatura({})
            dataSystemAssinatura()
            dataAssinatura()
            toast.success(response.data.msg)

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const changeFuncefAssinatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/singature/changeFuncef/${states.Assinatura.id}`, {}, req)
            // 
            setters.setIsLoading(false)
            setters.setDataAssinatura([])
            setters.setAssociadoFatura({})
            dataSystemAssinatura()
            dataAssinatura()
            toast.success(response.data.msg)

        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }


    //Planos


    const dataPlans = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/plans/getWithLimit/${states.limitPlans}&${states.offsetPlans}`, req)
            // 
            setters.setDataPlans(response.data.data)
            setters.setTablePlans(response.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchPlans(true)
                states.offsetPlans > 0 && setters.setOffsetPlans(states.offsetPlans - states.limitPlans)
                states.offsetPlans < 0 && setters.setOffsetPlans(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryPlans = async () => {
        setters.setIsLoading(true)
        setters.setOffsetPlans(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/plans/${states.querySearchPlans}`, req)

            // 
            setters.setDataQuery(response.data.data)
            setters.setDataPlans(response.data.data.slice(0, states.limitPlans))
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                dataPlans()
                states.offsetPlans > 0 && setters.setOffsetPlans(states.offsetPlans - states.limitPlans)
                states.offsetPlans < 0 && setters.setOffsetPlans(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const createPlan = async () => {
        // 

        setters.setIsLoading(true)
        setters.setOffsetPlans(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/plans`, states.Plans, req)
            setters.setModalIsOpen(false)
            dataSystemPlans()
            dataPlans()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // setters.setModalIsOpen(false)
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }
    const updatePlan = async () => {
        setters.setIsLoading(true)
        setters.setOffsetPlans(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/plans/${states.Plans.id}`, states.Plans, req)
            setters.setModalIsOpen(false)
            dataSystemPlans()
            dataPlans()
            setters.setPlans({})
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 
            setters.setModalIsOpen(false)
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }
    const deletePlans = async () => {
        setters.setIsLoading(true)
        setters.setOffsetPlans(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/plans/${states.Plans.id}`, req)
            setters.setConfirmModal(false)
            setters.setPlans({})
            dataSystemPlans()
            dataPlans()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setConfirmModal(false)
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }

    //NEGOCIAÇÕES

    const createNegociacao = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        // 
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/negotiation`, states.Negociacao, req);
            // 
            setters.setIsLoading(false)
            setters.setNegociacao({})
            dataSystemAssinatura()
            dataAssinatura()
            toast.success(response.data.msg)


        } catch (err) {
            setters.setConfirmModal(false)
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const dataNegociacao = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/negotiation/getWithLimit/${states.limitNegociacao}&${states.offsetNegociacao}/${states.initialDateNegociacao}/${states.finalDateNegociacao}`, req)
            setters.setDataNegociacao(response.data.data)
            setters.setTableNegociacao(response.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchNegociacao(true)
                states.offsetNegociacao > 0 && setters.setOffsetNegociacao(states.offsetNegociacao - states.limitNegociacao)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryNegociacao = async () => {
        setters.setIsLoading(true)
        setters.setOffsetNegociacao(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/negotiation/${states.querySearchNegociacao}`, req)
            // 
            setters.setDataQuery(response.data.data)
            setters.setDataNegociacao(response.data.data.slice(0, states.limitNegociacao))
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                dataNegociacao()
                states.offsetNegociacao > 0 && setters.setOffsetNegociacao(states.offsetNegociacao - states.limitNegociacao)
                states.offsetNegociacao < 0 && setters.setOffsetNegociacao(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const getDataGraphicNegociacao = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const body = {
                initialDate: states.initialDateNegociacao,
                finalDate: states.finalDateNegociacao
            }
            const response = await axios.post(`${jsonConfig.urlApi}/datagraphic/negotiation`, body, req)
            // 
            setters.setDataGraphicNegociacao(response.data)
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }


        }
    }






    //TERCEIRIZADOS


    const dataTerceirizado = async () => {
        setters.setIsLoading(true)
        states.dataQuery.length > 0 && setters.setOffsetTerceirizado(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/outsourced/getWithLimit/${states.limitTerceirizado}&${states.offsetTerceirizado}`, req)
            // 
            setters.setDataTerceirizado(response.data.data)
            setters.setTableTerceirizado(response.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchTerceirizado(true)
                states.offsetTerceirizado > 0 && setters.setOffsetTerceirizado(states.offsetTerceirizado - states.limitTerceirizado)
                states.offsetTerceirizado < 0 && setters.setOffsetTerceirizado(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryTerceirizado = async () => {
        setters.setIsLoading(true)
        setters.setOffsetTerceirizado(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/outsourced/${states.querySearchTerceirizado}`, req)
            // 
            setters.setDataQuery(response.data.data)
            setters.setDataTerceirizado(response.data.data.slice(0, states.limitTerceirizado))
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataTerceirizado()
                setters.setIsLoading(false)
                states.offsetTerceirizado > 0 && setters.setOffsetTerceirizado(states.offsetTerceirizado - states.limitTerceirizado)
                states.offsetTerceirizado < 0 && setters.setOffsetTerceirizado(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const createTerceirizado = async () => {
        setters.setIsLoading(true)
        setters.setOffsetTerceirizado(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/outsourced`, states.Terceirizado, req)
            setters.setModalIsOpen(false)
            dataSystemTerceirizado()
            dataTerceirizado()
            dataUsersLoadOutsourced()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }

    const updateTerceirizado = async () => {
        setters.setIsLoading(true)
        setters.setOffsetTerceirizado(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/outsourced/${states.Terceirizado.id}`, states.Terceirizado, req)
            setters.setModalIsOpen(false)
            dataSystemTerceirizado()
            dataTerceirizado()
            dataUsersLoadOutsourced()
            setters.setTerceirizado({})
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 
            setters.setModalIsOpen(false)
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }

    const deleteTerceirizado = async () => {
        setters.setIsLoading(true)
        setters.setOffsetTerceirizado(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/outsourced/${states.Terceirizado.id}`, req)
            setters.setConfirmModal(false)
            setters.setTerceirizado({})
            dataSystemTerceirizado()
            dataTerceirizado()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setConfirmModal(false)
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }

    }

    //Aprove 

    const dataAprove = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/aprove/getWithLimit/${states.limitAprove}&${states.offsetAprove}`, req)
            setters.setDataAprove(response.data.data)
            setters.setTableAprove(response.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchAprove(true)
                states.offsetAprove > 0 && setters.setOffsetAprove(states.offsetAprove - states.limitAprove)
                states.offsetAprove < 0 && setters.setOffsetAprove(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryAprove = async () => {
        setters.setIsLoading(true)
        setters.setOffsetAprove(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/aprove/${states.querySearchAprove}`, req)
            setters.setDataQuery(response.data.data)
            setters.setDataAprove((response.data.data.slice(0, states.limitAprove)))
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataAprove()
                setters.setIsLoading(false)
                states.offsetAprove > 0 && setters.setOffsetAprove(states.offsetAprove - states.limitAprove)
                states.offsetAprove < 0 && setters.setOffsetAprove(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    //Assinatura request 

    const dataRequestAssinatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/auth/signature/getWithLimit/${states.limitRequestAssinatura}&${states.offsetRequestAssinatura}`, req)
            // 
            setters.setDataRequestAssinatura(response.data.data)
            setters.setTableRequestAssinatura(response.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchRequestAssinatura(true)
                states.offsetRequestAssinatura > 0 && setters.setOffsetRequestAssinatura(states.offsetRequestAssinatura - states.limitRequestAssinatura)
                states.offsetRequestAssinatura < 0 && setters.setOffsetRequestAssinatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryRequestAssinatura = async () => {
        setters.setIsLoading(true)
        setters.setOffsetRequestAssinatura(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/authSignature/${states.querySearchRequestAssinatura}`, req)
            setters.setDataQuery(response.data.data)
            setters.setDataRequestAssinatura(response.data.data.slice(0, states.limitRequestAssinatura))
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            setters.setQuerySearchRequestAssinatura('')
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataRequestAssinatura()
                setters.setIsLoading(false)
                states.offsetRequestAssinatura > 0 && setters.setOffsetRequestAssinatura(states.offsetRequestAssinatura - states.limitRequestAssinatura)
                states.offsetRequestAssinatura < 0 && setters.setOffsetRequestAssinatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const responseRequestAssinatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/auth/auth/signature/`, states.RequestAssinatura, req)
            // 
            dataRequestAssinatura()
            dataSystemRequestAssinatura()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    //Fatura request 

    const dataRequestFatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/auth/invoices/getWithLimit/${states.limitRequestFatura}&${states.offsetRequestFatura}`, req)
            setters.setDataRequestFatura(response.data.data)
            setters.setTableRequestFatura(response.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchRequestFatura(true)
                states.offsetRequestFatura > 0 && setters.setOffsetRequestFatura(states.offsetRequestFatura - states.limitRequestFatura)
                states.offsetRequestFatura < 0 && setters.setOffsetRequestFatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryRequestFatura = async () => {
        setters.setIsLoading(true)
        setters.setOffsetRequestFatura(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/authInvoice/${states.querySearchRequestFatura}`, req)
            // 
            setters.setDataQuery(response.data.data)
            setters.setDataRequestFatura(response.data.data.slice(0, states.limitRequestFatura))
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            setters.setQuerySearchRequestFatura('')
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataRequestFatura()
                setters.setIsLoading(false)
                states.offsetRequestFatura > 0 && setters.setOffsetRequestFatura(states.offsetRequestFatura - states.limitRequestFatura)
                states.offsetRequestFatura < 0 && setters.setOffsetRequestFatura(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const responseRequestFatura = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/auth/auth/invoice/`, states.RequestFatura, req)
            dataRequestFatura()
            dataSystemRequestFatura()
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }






    //#endregion

    //#region Usuarios

    const blockUnlock = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/users/blockUnlock/${states.Users.id}`, {}, req)
            // 
            setters.setUsers({})
            dataUsers()
            dataSystemUsers()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchUsers(true)
                states.offsetUsers > 0 && setters.setOffsetUsers(states.offsetUsers - states.limitUsers)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const roleChange = async (role) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/users/role/${role.id}`, role, req)
            // 
            setters.setUsers({})
            dataUsers()
            dataSystemUsers()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchUsers(true)
                states.offsetUsers > 0 && setters.setOffsetUsers(states.offsetUsers - states.limitUsers)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const cadastrarUsuario = async (user) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/user/create`, { email: user.email, role: user.role }, req)
            dataUsers()
            dataSystemUsers()
            toast.success(response.data.msg)
        }
        catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const dataUsers = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/users/getWithLimit/${states.limitUsers}&${states.offsetUsers}`, req)
            // 
            setters.setDataUsers(response.data.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchUsers(true)
                states.offsetUsers > 0 && setters.setOffsetUsers(states.offsetUsers - states.limitUsers)
                states.offsetUsers < 0 && setters.setOffsetUsers(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const dataUsersLoadOutsourced = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/users/getAll`, req)
            setters.setDataUsers(response.data.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const dataEmployeeLoadOutsourced = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/employee/outsourced`, req)
            setters.setDataUsers(response.data.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryUsers = async () => {
        setters.setIsLoading(true)
        setters.setOffsetUsers(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/query/authInvoice/${states.queryUsers}`, req)
            // 
            setters.setDataQuery(response.data.data)
            setters.setDataUsers(response.data.data.slice(0, states.limitUsers))
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            setters.setQueryUsers('')
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataUsers()
                setters.setIsLoading(false)
                states.offsetUsers > 0 && setters.setOffsetUsers(states.offsetUsers - states.limitUsers)
                states.offsetUsers < 0 && setters.setOffsetUsers(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    //#endregion

    //#region Histôrico

    const dataHistorico = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/historyUser/getWithLimit/${states.limitHistorico}&${states.offsetHistorico}`, req)
            // 
            setters.setDataHistorico(response.data.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            // 
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchHistorico(true)
                states.offsetUsers > 0 && setters.setOffsetHistorico(states.offsetHistorico - states.limitHistorico)
                states.offsetUsers < 0 && setters.setOffsetHistorico(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const searchQueryHistorico = async () => {
        setters.setIsLoading(true)
        setters.setOffsetHistorico(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/email/historyUser/${states.querySearchHistorico}`, req)
            // 
            setters.setDataQuery(response.data.data)
            // )
            setters.setDataHistorico(response.data.data.slice(0, 10))
            setters.setIsLoading(false)
            //
            return true
        } catch (err) {
            setters.setQuerySearchHistorico('')
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                dataUsers()
                setters.setIsLoading(false)
                states.offsetHistorico > 0 && setters.setOffsetHistorico(states.offsetHistorico - states.setLimitHistorico)
                states.offsetHistorico < 0 && setters.setOffsetHistorico(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    //#endregion

    //#region Sistema

    const cadastrarCartaoSignature = async (cartao, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY
            },
        };
        try {
            await axios.put(`${jsonConfig.urlApi}/stepTwo/signature/${id}`, cartao, req)
            setters.setIsLoading(false)
            toast.success('Cartão adicionado com sucesso!')
            setters.setError(false)
            setters.setModalInfo(true)
            setters.setModalInfoTitle('Adição de cartão na assintura - Mensagem')
            setters.setModalInfoMessage('Cartão adicionado com sucesso!')
            return { status: true }
        } catch (err) {

            setters.setIsLoading(false)
            // toast.error(err.response.data.msg)
            setters.setModalInfoMessage(err.response.data.msg)
            setters.setModalInfoTitle('Erro ao cadastrar o cartão')
            setters.setModalInfo(true)
            setters.setError(true)
            return { status: false }
        }
    }

    const cadastrarCartao = async (cartao) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY
            },
        };
        try {
            await axios.post(`${jsonConfig.urlApi}/signature/stepTwo`, cartao, req)
            setters.setIsLoading(false)
            toast.success('Cartão adicionado com sucesso!')
            setters.setError(false)
            setters.setModalInfo(true)
            setters.setModalInfoTitle('Adição de cartão - Mensagem')
            setters.setModalInfoMessage('Cartão adicionado com sucesso!')
            return { status: true }
        } catch (err) {
            setters.setIsLoading(false)
            // toast.error(err.response.data.msg)
            setters.setModalInfoMessage(err.response.data.msg)
            setters.setModalInfoTitle('Erro ao cadastrar o cartão')
            setters.setModalInfo(true)
            setters.setError(true)
            return { status: false }
        }
    }

    const changeEmail = async (objEmail) => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            await axios.post(`${jsonConfig.urlApi}/changeemail`, objEmail, req)
            toast.success('Solicitação de mudança de email criada!')
            return { status: true }
        } catch (err) {
            // toast.error(err.response.data.msg)
            return { status: false }
        }
    }

    const confirmChangeEmail = async (token) => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            await axios.put(`${jsonConfig.urlApi}/changeemail/${token}`, req)
            toast.success('Email mudado com sucesso!')
            return { status: true }
        } catch (err) {
            // toast.error(err.response.data.msg)
            return { status: false }
        }
    }

    const changePassword = async (objPassword) => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            await axios.put(`${jsonConfig.urlApi}/user/changepassword`, objPassword, req)
            toast.success('Senha mudada com sucesso!')
            return { status: true }
        } catch (err) {
            // toast.error(err.response.data.msg)
            return { status: false }
        }
    }

    //#endregion

    //#region Associados    

    const getCommentsFileAssociate = async (id) => {
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/associateFileCommet/${id}`, req)
            setters.setAssociadoss(response.data.data)

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const postCommentsAssociate = async (comment) => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.post(`${jsonConfig.urlApi}/associate/comments/`, comment, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true


        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                return false
            } else {
                toast.error(err.response.data.msg)
                return false
            }

        }
    }

    const postFileAssociate = async (comment) => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'Content-Type': 'multipart/form-data',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.post(`${jsonConfig.urlApi}/associate/file/`, { title: comment.title, file: comment.file, associate_id: comment.associate_id }, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true


        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                return false
            } else {
                toast.error(err.response.data.msg)
                return false
            }

        }
    }

    const deleteFileAssociate = async (id) => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.delete(`${jsonConfig.urlApi}/associate/file/${id}`, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true

        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                return false
            } else {
                toast.error(err.response.data.msg)
                return false
            }

        }


    }

    const deleteCommentAssociate = async (id) => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.delete(`${jsonConfig.urlApi}/associate/comments/${id}`, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true

        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                return false
            } else {
                toast.error(err.response.data.msg)
                return false
            }

        }


    }

    //#endregion

    const dataHooks = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/hooks/getWithLimit/${states.limitHooks}&${states.offsetHooks}`, req)

            setters.setHooksData(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.clear()
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchHooks(true)
                states.offsetHooks > 0 && setters.setOffsetHooks(states.offsetHooks - states.limitHooks)
                states.offsetHooks < 0 && setters.setOffsetHooks(0)
                toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    const buscarAssociadoPorId = async (id) => {
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/associate/${id}`, req)
            return response.data
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    const buscarPlanoPorId = async (id) => {
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/plans/${id}`, req)
            return response.data
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    const buscarAssinaturaPorId = async (id) => {
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/signature/${id}`, req)
            return response.data
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    const buscarTerceirizadoPorId = async (id) => {
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/outsourced/${id}`, req)
            return response.data
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    const dataConfiguracaoPonto = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/employeesettings/getWithLimit/${states.limitConfiguracaoPonto}&${states.offsetConfiguracaoPonto}`, req)
            setters.setDataConfiguracaoPonto(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchConfiguracaoPonto(true)
                // states.offsetUsers > 0 && setters.setOffSetConfiguracaoPonto(states.offsetConfiguracaoPonto - states.limitConfiguracaoPonto)
                // states.offsetUsers < 0 && setters.setOffSetConfiguracaoPonto(0)
            }
        }
    }

    const dataPontosRegistrados = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/timerecords/getWithLimit/${states.limitPontosRegistrados}&${states.offsetPontosRegistrados}`, req)

            setters.setDataPontosRegistrados(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchConfiguracaoPonto(true)
                // states.offsetUsers > 0 && setters.setOffSetConfiguracaoPonto(states.offsetConfiguracaoPonto - states.limitConfiguracaoPonto)
                // states.offsetUsers < 0 && setters.setOffSetConfiguracaoPonto(0)
            }
        }
    }

    const dataDiasTrabalhados = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/employeeDay/getWithLimit/${states.limitDiasTrabalhados}&${states.offsetDiasTrabalhados}`, req)
            setters.setDataDiasTrabalhados(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                setters.setLimitSearchDiasTrabalhados(true)
                // states.offsetUsers > 0 && setters.setOffSetConfiguracaoPonto(states.offsetConfiguracaoPonto - states.limitConfiguracaoPonto)
            }
        }
    }

    const dataDiasTrabalhadosPerUser = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/employeeDay/getWithLimit/${id}/${states.limitDiasTrabalhados}&${states.offsetDiasTrabalhados}`, req)
            setters.setDataDiasTrabalhados(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                setters.setLimitSearchDiasTrabalhados(true)
                // states.offsetUsers > 0 && setters.setOffSetConfiguracaoPonto(states.offsetConfiguracaoPonto - states.limitConfiguracaoPonto)
                // states.offsetUsers < 0 && setters.setOffSetConfiguracaoPonto(0)
            }
        }
    }

    //AJUSTAR PARA ENCERRAR O DIA AQUI 
    const baterPonto = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            await axios.post(`${jsonConfig.urlApi}/timerecords`, {}, req)
            localStorage.setItem('diaAberto', "false")
            setters.setIsLoading(false)
            await dataUsuarioDiasDeTrabalho();
            return true
        } catch (err) {
            if (err.response.data.haveOpenDay) {
                localStorage.setItem('diaAberto', "true")
            }
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
            }
            return false
        }
    }

    //AJUSTAR PARA ENCERRAR O DIA AQUI 
    const encerrarDiaEmAberto = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            await axios.post(`${jsonConfig.urlApi}/timerecords/endday`, {}, req)
            localStorage.setItem('diaAberto', "false")
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
            }
            return false
        }
    }

    const dataUsuarioDiasDeTrabalho = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/employeeDay/user/${states.limitUsuarioDiasTrabalho}&${states.offsetUsuarioDiasTrabalho}`, req)
            setters.setDataUsuarioDiasTrabalho(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
            }
            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchUsuarioDiasTrabalho(true)
                states.offsetUsuarioDiasTrabalho > 0 && setters.setOffSetUsuarioDiasTrabalho(states.offsetUsuarioDiasTrabalho - states.limitUsuarioDiasTrabalho)
            }
            setters.setIsLoading(false)
            return false;


        }
    }


    const dataPontosUsuario = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/timerecords/day/user/${id}/${states.limitPontosUsuario}&${states.offsetPontosUsuario}`, req)
            setters.setDataPontosUsuario(response.data.data)
            setters.setIsLoading(false)

            return true
        } catch (err) {
            if (err.response.status === 403) {
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchPontosUsuario(true)
                states.offsetAssociadoss > 0 && setters.setOffSetPontosUsuario(states.offsetPontosUsuario - states.limitPontosUsuario)
            }
            else {
                setters.setIsLoading(false)
            }

        }
    }

    const dataPontosUsuarioAdmin = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/timerecords/day/getUser/${id}/${states.limitPontosUsuario}&${states.offsetPontosUsuario}`, req)
            setters.setDataPontosUsuario(response.data.data)
            setters.setIsLoading(false)

            return true
        } catch (err) {
            if (err.response.status === 403) {
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchPontosUsuario(true)
                states.offsetAssociadoss > 0 && setters.setOffSetPontosUsuario(states.offsetPontosUsuario - states.limitPontosUsuario)
            }
            else {
                setters.setIsLoading(false)
            }

        }
    }


    const dataDiasAtrasados = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/timebalance/getWithLimit/${states.limitDiasAtrasados}&${states.offsetDiasAtrasados}`, req)
            setters.setDataDiasAtrasados(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchDiasTrabalhados(true)
                // states.offsetUsers > 0 && setters.setOffSetConfiguracaoPonto(states.offsetConfiguracaoPonto - states.limitConfiguracaoPonto)
                // states.offsetUsers < 0 && setters.setOffSetConfiguracaoPonto(0)
            }
        }
    }

    const createConfigPonto = async (ponto) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/employeesettings`, ponto, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const editConfigPonto = async (ponto, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/employeesettings/${id}`, ponto, req)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const deleteAssinaturaFuncef = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/funcefSignature/cancel/${id}`, {}, req)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const dataFeriasFuncionarios = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/vacationEmployee/getWithLimit/${states.limitFeriasFuncionarios}&${states.offsetFeriasFuncionarios}`, req)
            setters.setDataFeriasFuncionarios(response.data.data)
            setters.setIsLoading(false)

            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchFeriasFuncionarios(true)
                states.offsetFeriasFuncionarios > 0 && setters.setOffsetFeriasFuncionarios(states.offsetFeriasFuncionarios - states.limitFeriasFuncionarios)
            }
            else {
                setters.setIsLoading(false)
            }

        }
    }

    const dataPainelFerias = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/vacation/getWithLimit/${states.limitPainelFerias}&${states.offsetPainelFerias}`, req)
            setters.setDataPainelFerias(response.data.data)
            setters.setIsLoading(false)

            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchPainelFerias(true)
                states.offsetPainelFerias > 0 && setters.setOffsetPainelFerias(states.offsetPainelFerias - states.limitPainelFerias)
            }
            else {
                setters.setIsLoading(false)
            }

        }
    }

    const dataPainelVendaFerias = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/vacationSale/getWithLimit/${states.limitPainelVendaFerias}&${states.offsetPainelVendaFerias}`, req)
            setters.setDataPainelVendaFerias(response.data.data)
            setters.setIsLoading(false)

            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchPainelVendaFerias(true)
                states.offsetPainelVendaFerias > 0 && setters.setOffsetPainelVendaFerias(states.offsetPainelVendaFerias - states.limitPainelVendaFerias)
            }
            else {
                setters.setIsLoading(false)
            }
        }
    }

    const createFeriasFuncionarios = async (ferias) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/vacationEmployee`, ferias, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

            return false
        }

    }

    const createFeriasPainel = async (ferias) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/vacation`, ferias, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

            return false
        }

    }

    const deleteFeriasFuncionarios = async (id) => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/vacationEmployee/${id}`, req)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const deletePainelFerias = async (id) => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/vacation/${id}`, req)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }

    }

    const getTerceirizado = async (id) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/outsourcedFileComment/${id}`, req)
            setters.setTerceirizados(response.data.data)
            setters.setIsLoading(false)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const getFaturasTerceirizado = async (id) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/invoicesPayment/outsourced/${id}`, req)
            setters.setDataTerceirizadosFatura(response.data.data)
            setters.setIsLoading(false)

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const addComentarioTerceirizado = async (comment) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            await axios.post(`${jsonConfig.urlApi}/outsourcedComment`, comment, req)
            setters.setIsLoading(false)
            toast.success('Comentário adicionado com sucesso')
            return true

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const deleteComentarioTerceirizado = async (id) => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            await axios.delete(`${jsonConfig.urlApi}/outsourcedComment/${id}`, req)
            setters.setIsLoading(false)
            toast.success('Comentário excluído com sucesso')
            return true

        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                return false
            } else {
                toast.error(err.response.data.msg)
                return false
            }

        }


    }

    const addArquivoTerceirizado = async (file) => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'Content-Type': 'multipart/form-data',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.post(`${jsonConfig.urlApi}/outsourced/file`, { title: file.title, file: file.file, outsourced_id: file.outsourced_id }, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true

        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                return false
            } else {
                toast.error(err.response.data.msg)
                return false
            }

        }
    }

    const deleteArquivoTerceirizado = async (id) => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            await axios.delete(`${jsonConfig.urlApi}/outsourced/file/${id}`, req)
            setters.setIsLoading(false)
            toast.success('Documento excluído com sucesso')
            return true

        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                return false
            } else {
                toast.error(err.response.data.msg)
                return false
            }

        }


    }

    const editarTerceirizado = async (id, terceirizado) => {
        try {
            setters.setIsLoading(true)
            const req = {
                headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem("token") },
            }

            await axios.put(`${jsonConfig.urlApi}/outsourced/${id}`, { ...terceirizado }, req)
            setters.setIsLoading(false)
            return true
        }
        catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }

            return false
        }
    }

    const buscarAssociadoPorCpf = async () => {
        setters.setIsLoading(true)
        setters.setOffsetAssociadoss(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/associate/cpf/${states.querySearchAssociados}`, req)
            let array = []
            array.push(response.data.associate)
            setters.setDataAssociadoss(array)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                dataAssociados()
                states.offsetAssociadoss > 0 && setters.setOffsetAssociadoss(states.offsetAssociadoss - states.limitAssociadoss)
                states.offsetAssociadoss < 0 && setters.setOffsetAssociadoss(0)
                // toast.error(err.response.data.msg)
            }
            else {
                setters.setIsLoading(false)
                // toast.error(err.response.data.msg)
            }

        }
    }

    const buscarFaturaPorCpf = async () => {
        setters.setIsLoading(true)
        setters.setOffsetRequestFatura(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/associate/invoices/${states.queryCobranca}/getWithLimit/${states.limitCobranca}&${states.offsetCobranca}`, req)
            setters.setDataCobranca(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setQueryCobranca('')
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }
            if (err.response.status === 400) {
                setters.setIsLoading(false)
                // states.offsetCobranca > 0 && setters.setOffsetCobranca(states.offsetCobranca - states.limitCobranca)
                // states.offsetCobranca <= 0 && setters.setOffsetCobranca(0)
                setters.setLimitSearchCobranca(true)
                // toast.error(err.response.data.msg)
            }
            setters.setIsLoading(false)

        }
    }

    const dataImport = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/import/getWithLimit/${states.limitImport}&${states.offsetImport}`, req)
            setters.setDataImport(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchImport(true)
                states.offsetImport > 0 && setters.setOffsetImport(states.offsetImport - states.limitImport)
                states.offsetImport < 0 && setters.setOffsetImport(0)
            }
        }
    }

    const createImport = async (file) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/import/invoices`, file, req)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            dataImport()
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
            }
            else {
                toast.error(err.response.data.msg)
            }
            setters.setIsLoading(false)
        }
    }

    const deleteImport = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/import/${id}`, req)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            dataImport()
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
            }
            else {
                toast.error(err.response.data.msg)
            }
            setters.setIsLoading(false)
        }
    }


    const dataImportFaturas = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/importInvoice/getWithLimit/${states.limitImportFaturas}&${states.offsetImportFaturas}`, req)
            setters.setDataImportFaturas(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchImportFaturas(true)
                states.offsetImportFaturas > 0 && setters.setOffsetImportFaturas(states.offsetImportFaturas - states.limitImportFaturas)
                states.offsetImportFaturas < 0 && setters.setOffsetImportFaturas(0)
            }
        }
    }

    const dataImportFaturasId = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/importInvoice/${id}`, req)
            setters.setImportFaturas(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchImportFaturas(true)
                states.offsetImportFaturas > 0 && setters.setOffsetImportFaturas(states.offsetImportFaturas - states.limitImportFaturas)
                states.offsetImportFaturas < 0 && setters.setOffsetImportFaturas(0)
            }
        }
    }


    const dataImportFalhas = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/importFail/getWithLimit/${states.limitImportFalhas}&${states.offsetImportFalhas}`, req)
            setters.setDataImportFalhas(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchImportFalhas(true)
                states.offsetImportFalhas > 0 && setters.setOffsetImportFalhas(states.offsetImportFalhas - states.limitImportFalhas)
                states.offsetImportFalhas < 0 && setters.setOffsetImportFalhas(0)
            }
        }
    }



    //Health

    const dataHealth = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/health/getWithLimit/${states.limitHealth}&${states.offsetHealth}`, req)
            setters.setDataHealth(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchHealth(true)
                states.offsetHealth > 0 && setters.setOffsetHealth(states.offsetHealth - states.limitHealth)
                states.offsetHealth < 0 && setters.setOffsetHealth(0)
            }
        }
    }

    const dataHealthRegister = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/healthRegister/${id}/getWithLimit/${states.limitHealthRegister}&${states.offsetHealthRegister}`, req)
            setters.setDataHealthRegister(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchHealthRegister(true)
                states.offsetHealthRegister > 0 && setters.setOffsetHealthRegister(states.offsetHealthRegister - states.limitImportHealthRegister)
                states.offsetHealthRegister < 0 && setters.setOffsetHealthRegister(0)
            }
            return false
        }
    }


    const postHealth = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/health`, {}, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            setters.setOffsetHealth(0)
            dataHealth()
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
            }
            toast.error(err.response.data.msg)
            setters.setIsLoading(false)


        }
    }


    const deleteHealth = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/health/${id}`, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            setters.setOffsetHealth(0)
            setters.setDataHealth([])
            dataHealth()
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }
            setters.setIsLoading(false)
        }
    }


    //Systemjuri send request 

    const sendRequestMail = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/twoStepVerificationProcessAsociate`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const checkCodeMail = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/twoStepVerificationProcessAsociate/verify`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return { status: true, token: response.data.token }
        } catch (err) {
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return { status: false }
        }
    }
    const sendFileAuth = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "Content-Type": "multipart/form-data"
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalProcessAssociate`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    //Fundos 
    // const [dataSystemFundo, setDataSystemFundo] = useState([])
    // const [dataFundo, setDataFundo] = useState([])
    // const [Fundo, setFundo] = useState({})
    // const [offsetFundo, setOffsetFundo] = useState(0)
    // const [limitFundo, setLimitFundo] = useState(30)
    // const [limitSearchFundo, setLimitSearchFundo] = useState(false)

    const dataAllFundo = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/fundos/getAll`, req)
            setters.setDataFundo(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
        }
    }

    const dataSystemFundos = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/fundos`, req)
            setters.setDataSystemFundo([response.data.data])
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const dataFundos = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/fundos/getWithLimit/${states.limitFundo}&${states.offsetFundo}`, req)
            setters.setDataFundo(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchFundo(true)
                states.offsetFundo > 0 && setters.setOffsetFundo(states.offsetFundo - states.limitFundo)
                states.offsetFundo < 0 && setters.setOffsetFundo(0)
            }
            return false
        }
    }

    const dataFundosQuery = async (query) => {
        setters.setIsLoading(true)
        setters.setOffsetFundo(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/fundos/query/${query}`, req)
            setters.setDataFundo(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }
            return false
        }
    }


    const postFundo = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/fundos`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            setters.setOffsetFundo(0)
            dataFundos()
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
            }
            toast.error(err.response.data.msg)
            setters.setIsLoading(false)


        }
    }

    const putFundo = async (body, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/fundos/${id}`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            setters.setOffsetFundo(0)
            dataFundos()
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
            }
            toast.error(err.response.data.msg)
            setters.setIsLoading(false)


        }
    }


    const deleteFundo = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/fundos/${id}`, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            setters.setOffsetFundo(0)
            setters.setFundo({})
            setters.setDataFundo([])
            dataFundos()
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
            }
            setters.setIsLoading(false)
        }
    }

    //COMUNICACAO NOTICIAS 

    const dataNoticias = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/news/getWithLimit/${states.limitNoticiasComunicacao}&${states.offsetNoticiasComunicacao}`, req)
            setters.setTotalNoticiasComunicacao(response.data.total)
            setters.setDataNoticiasComunicacao(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                setters.setSigned(false)
                setters.setIsLoading(false)
            }
            else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            }

            if (err.response.status === 400) {
                setters.setIsLoading(false)
                setters.setLimitSearchNoticiasComunicacao(true)
                states.offsetNoticiasComunicacao > 0 && setters.setOffsetNoticiasComunicacao(states.offsetNoticiasComunicacao - states.limitNoticiasComunicacao)
                states.offsetNoticiasComunicacao < 0 && setters.setOffsetNoticiasComunicacao(0)
            }
            return false
        }
    }

    const postNoticia = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "Content-Type": "multipart/form-data",
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/areaassociado/news`, body, req)
            setters.setIsLoading(false)
            setters.setOffsetNoticiasComunicacao(0)
            await dataNoticias()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            toast.error(err.response.data.msg)
            return false
        }

    }

    const putNoticia = async (body, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "Content-Type": "multipart/form-data",
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/areaassociado/news/${id}`, body, req)
            setters.setIsLoading(false)
            setters.setOffsetNoticiasComunicacao(0)
            await dataNoticias()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const deleteNoticia = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/areaassociado/news/${id}`, req)
            setters.setIsLoading(false)
            setters.setOffsetNoticiasComunicacao(0)
            await dataNoticias()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }


    ///RESTANTE DAS NOTICIAS ACIMA 

    const dataFaqs = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/faq`, req)
            setters.setTotalFaqsComunicacao(response.data.total)
            setters.setDataFaqsComunicacao(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const postFaq = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/areaassociado/faq`, body, req)
            setters.setIsLoading(false)
            await dataFaqs()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const putFaq = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/areaassociado/faq/${body.id}`, {
                question: body.question,
                answer: body.answer
            }, req)
            setters.setIsLoading(false)
            await dataFaqs()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const deleteFaq = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/areaassociado/faq/${id}`, req)
            setters.setIsLoading(false)
            await dataFaqs()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }


    //   const [dataAssociateComunicacao, setDataAssociateComunicacao] = useState([])
    //     const [AssociateComunicacao, setAssociateComunicacao] = useState({})
    //     const [offsetAssociateComunicacao, setOffsetAssociateComunicacao] = useState(0)
    //     const [limitAssociateComunicacao, setLimitAssociateComunicacao] = useState(30)
    //     const [limitSearchAssociateComunicacao, setLimitSearchAssociateComunicacao] = useState(false)

    const dataAssociateComunicacao = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/users/getWithLimit/${states.limitAssociateComunicacao}&${states.offsetAssociateComunicacao}`, req)
            setters.setTotalAssociateComunicacao(response.data.total)
            setters.setDataAssociateComunicacao(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                setters.setLimitSearchAssociateComunicacao(true)
                states.offsetAssociateComunicacao > 0 && setters.setOffsetAssociateComunicacao(states.offsetAssociateComunicacao - states.limitAssociateComunicacao)
                states.offsetAssociateComunicacao < 0 && setters.setOffsetAssociateComunicacao(0)
                return false
            }
        }
    }

    const dataAssociateComunicacaoCpf = async (cpf) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/users/${cpf}`, req)
            setters.setDataAssociateComunicacao(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                return false
            }
        }
    }

    const dataAssociateComunicacaoLogado = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/usersloged/getWithLimit/${states.limitAssociateComunicacao}&${states.offsetAssociateComunicacao}`, req)
            setters.setTotalAssociateComunicacao(response.data.total)
            setters.setDataAssociateComunicacao(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                setters.setLimitSearchAssociateComunicacao(true)
                states.offsetAssociateComunicacao > 0 && setters.setOffsetAssociateComunicacao(states.offsetAssociateComunicacao - states.limitAssociateComunicacao)
                states.offsetAssociateComunicacao < 0 && setters.setOffsetAssociateComunicacao(0)
                return false
            }
        }
    }

    const dataAssociateComunicacaoCpfLogado = async (cpf) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/usersloged/${cpf}`, req)
            setters.setDataAssociateComunicacao(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                return false
            }
        }
    }

    const dataAssociateComunicacaoForgot = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/usersforgot/getWithLimit/${states.limitAssociateComunicacao}&${states.offsetAssociateComunicacao}`, req)
            setters.setTotalAssociateComunicacao(response.data.total)
            setters.setDataAssociateComunicacao(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                setters.setLimitSearchAssociateComunicacao(true)
                states.offsetAssociateComunicacao > 0 && setters.setOffsetAssociateComunicacao(states.offsetAssociateComunicacao - states.limitAssociateComunicacao)
                states.offsetAssociateComunicacao < 0 && setters.setOffsetAssociateComunicacao(0)
                return false
            }
        }
    }

    const dataAssociateComunicacaoCpfForgot = async (cpf) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/areaassociado/usersforgot/${cpf}`, req)
            setters.setDataAssociateComunicacao(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                return false
            }
        }
    }

    /*
        Porject request
    */

    const dataSystemProjetos = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/projects`, req)
            setters.setDataSystemProjetos(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const getProjetos = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/project/getWithLimit/${states.limitProjetos}&${states.offsetProjetos}`, req)
            setters.setDataProjetos(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            setters.setLimitSearchProjetos(true)
            states.offsetProjetos > 0 && setters.setOffsetProjetos(states.offsetProjetos - states.limitProjetos)
            states.offsetProjetos < 0 && setters.setOffsetProjetos(0)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const getProjetosQuery = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const body = {
                query: states.queryProjetos
            }
            const response = await axios.post(`${jsonConfig.urlApi}/project/getWithLimit/${states.limitProjetos}&${states.offsetProjetos}/titleDesc`, body, req)
            setters.setDataProjetos(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            setters.setLimitSearchProjetos(true)
            states.offsetProjetos > 0 && setters.setOffsetProjetos(states.offsetProjetos - states.limitProjetos)
            states.offsetProjetos < 0 && setters.setOffsetProjetos(0)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const getProjetosDate = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const body = {
                initialDate: states.dataInicialProjetos,
                finalDate: states.dataFinalProjetos
            }
            const response = await axios.post(`${jsonConfig.urlApi}/project/getWithLimit/${states.limitProjetos}&${states.offsetProjetos}/date`, body, req)
            setters.setDataProjetos(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            setters.setLimitSearchProjetos(true)
            states.offsetProjetos > 0 && setters.setOffsetProjetos(states.offsetProjetos - states.limitProjetos)
            states.offsetProjetos < 0 && setters.setOffsetProjetos(0)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const postProject = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/project`, body, req)
            setters.setIsLoading(false)
            setters.setOffsetProjetos(0)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const putProject = async (data, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/project/${id}`, data, req)
            setters.setIsLoading(false)
            setters.setOffsetProjetos(0)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const deleteProject = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/project/${id}`, req)
            setters.setIsLoading(false)
            setters.setOffsetProjetos(0)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const approveProject = async (data, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/project/authorized/${id}`, data, req)
            setters.setIsLoading(false)
            setters.setOffsetProjetos(0)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const initProject = async (data, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/project/init/${id}`, data, req)
            setters.setIsLoading(false)
            setters.setOffsetProjetos(0)
            toast.success(response.data.msg)
            return { status: true, data: response.data }
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return { status: false }
        }

    }

    const getProjeto = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/project/${id}`, req)
            setters.setProjetos(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const endStep = async (id, idProject) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/project/step/end/${id}`, {}, req)
            setters.setIsLoading(false)
            await getProjeto(idProject)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const addStep = async (body, idProject) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/project/step/new`, body, req)
            setters.setIsLoading(false)
            await getProjeto(idProject)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const editStep = async (body, idStep, idProject) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/project/step/${idStep}`, body, req)
            setters.setIsLoading(false)
            await getProjeto(idProject)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }


    const endProject = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/project/finish/${id}`, {}, req)
            setters.setIsLoading(false)
            await getProjeto(id)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const approveConcludeProject = async (data, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/project/authConclude/${id}`, data, req)
            setters.setIsLoading(false)
            await getProjeto(id)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }



    const dataUsersLoadOutsourcedAndName = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/users/outsourced/getAll`, req)
            setters.setDataUsers(response.data.data)
            setters.setDataQuery([])
            setters.setIsLoading(false)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
                setters.setIsLoading(false)
            } else {
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }

        }
    }

    /*
        End porject request
    */

    //#region transferencia pagseguro 




    //#endregion


    //#region alteracao de ponto

    const editMyPonto = async (data, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/timerecords/my/${id}`, data, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const editPonto = async (data, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/timerecords/${id}`, data, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    //#endregion

    //#region requisicoes de troca de ponto 

    const dataSystemRequisicoesPontos = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/updatesTimeRecords`, req)
            setters.setDataSystemRequisicoesAtualizacaoPonto(response.data.data)
            return true

        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const getRequisicoesPontos = async () => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/updateRecordRequest/getWithLimit/${states.limitRequisicoesAtualizacaoPonto}&${states.offsetRequisicoesAtualizacaoPonto}`, req)
            setters.setDataRequisicoesAtualizacaoPonto(response.data.data)
            setters.setIsLoading(false)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                setters.setLimitSearchRequisicoesAtualizacaoPonto(true)
                states.offsetRequisicoesAtualizacaoPonto > 0 && setters.setOffsetRequisicoesAtualizacaoPonto(states.offsetRequisicoesAtualizacaoPonto - states.limitRequisicoesAtualizacaoPonto)
                states.offsetRequisicoesAtualizacaoPonto < 0 && setters.setOffsetRequisicoesAtualizacaoPonto(0)
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const getRequisicoesPontosUser = async (id) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/updateRecordRequest/user/${id}/getWithLimit/${states.limitRequisicoesAtualizacaoPonto}&${states.offsetRequisicoesAtualizacaoPonto}`, req)
            setters.setDataRequisicoesAtualizacaoPonto(response.data.data)
            setters.setIsLoading(false)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                setters.setLimitSearchRequisicoesAtualizacaoPonto(true)
                states.offsetRequisicoesAtualizacaoPonto > 0 && setters.setOffsetRequisicoesAtualizacaoPonto(states.offsetRequisicoesAtualizacaoPonto - states.limitRequisicoesAtualizacaoPonto)
                states.offsetRequisicoesAtualizacaoPonto < 0 && setters.setOffsetRequisicoesAtualizacaoPonto(0)
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }


    const approveRequesicaoPonto = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/updateRecordRequest/approve/${id}`, {}, req)
            setters.setIsLoading(false)
            await getRequisicoesPontos()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const reproveRequesicaoPonto = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApi}/updateRecordRequest/reprove/${id}`, {}, req)
            setters.setIsLoading(false)
            await getRequisicoesPontos()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const deleteRequesicaoPonto = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApi}/updateRecordRequest/${id}`, req)
            setters.setIsLoading(false)
            await getRequisicoesPontos()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    //#endregion


    //#region historico de troca de pontos



    const getHistoricoRequisicoesPontos = async () => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/historyRecords/getWithLimit/${states.limitHistoricoAtualizacaoPonto}&${states.offsetHistoricoAtualizacaoPonto}`, req)
            setters.setDataHistoricoAtualizacaoPonto(response.data.data)
            setters.setIsLoading(false)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                setters.setLimitSearchHistoricoAtualizacaoPonto(true)
                states.offsetHistoricoAtualizacaoPonto > 0 && setters.setOffsetHistoricoAtualizacaoPonto(states.offsetHistoricoAtualizacaoPonto - states.limitHistoricoAtualizacaoPonto)
                states.offsetHistoricoAtualizacaoPonto < 0 && setters.setOffsetHistoricoAtualizacaoPonto(0)
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const getHistoricoRequisicoesPontosUser = async (id) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/historyRecords/${id}/getWithLimit/${states.limitHistoricoAtualizacaoPonto}&${states.offsetHistoricoAtualizacaoPonto}`, req)
            setters.setDataHistoricoAtualizacaoPonto(response.data.data)
            setters.setIsLoading(false)
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                setters.setLimitSearchHistoricoAtualizacaoPonto(true)
                states.offsetHistoricoAtualizacaoPonto > 0 && setters.setOffsetHistoricoAtualizacaoPonto(states.offsetHistoricoAtualizacaoPonto - states.limitHistoricoAtualizacaoPonto)
                states.offsetHistoricoAtualizacaoPonto < 0 && setters.setOffsetHistoricoAtualizacaoPonto(0)
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion

    //#region debitos 

    // const [dataSystemDebitos, setDataSystemDebitos] = useState([])
    // const [dataDebitos, setDataDebitos] = useState([])
    // const [Debitos, setDebitos] = useState({})
    // const [offsetDebitos, setOffsetDebitos] = useState(0)
    // const [limitDebitos, setLimitDebitos] = useState(jsonConfig.limitShow)
    // const [limitSearchDebitos, setLimitSearchDebitos] = useState(false)


    const dataSystemDebitos = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/debits`, req)
            setters.setDataSystemDebitos(response.data.data)
            return true
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }


    const getDebitos = async () => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/debits/getWithLimit/${states.limitDebitos}&${states.offsetDebitos}`, req)
            setters.setDataDebitos(response.data.data)
            setters.setIsLoading(false)
        } catch (err) {

            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                setters.setLimitSearchDebitos(true)
                states.offsetDebitos > 0 && setters.setOffsetDebitos(states.offsetDebitos - states.limitDebitos)
                states.offsetDebitos < 0 && setters.setOffsetDebitos(0)
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const getDebitosQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/debits/${query}/getWithLimit/${states.limitDebitos}&${states.offsetDebitos}`, req)
            setters.setDataDebitos(response.data.data)
            setters.setIsLoading(false)
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                setters.setLimitSearchDebitos(true)
                states.offsetDebitos > 0 && setters.setOffsetDebitos(states.offsetDebitos - states.limitDebitos)
                states.offsetDebitos < 0 && setters.setOffsetDebitos(0)
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const generateNegotiation = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/negotiation`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const exportDataDebits = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/debits/export`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    //#endregion


    //#region debitos 

    // const [dataSystemAssociateStatus, setDataSystemAssociateStatus] = useState([])
    // const [dataAssociateStatus, setDataAssociateStatus] = useState([])
    // const [AssociateStatus, setAssociateStatus] = useState({})
    // const [offsetAssociateStatus, setOffsetAssociateStatus] = useState(0)
    // const [limitAssociateStatus, setLimitAssociateStatus] = useState(jsonConfig.limitShow)
    // const [limitSearchAssociateStatus, setLimitSearchAssociateStatus] = useState(false)
    // const [modalAssociateStatus, setModalAssociateStatus] = useState(false)


    const dataSystemAssociateStatus = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/associatesStatus`, req)
            setters.setDataSystemAssociateStatus(response.data.data)
            return true
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }


    const getASsociateStatus = async (status) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/associateStatus/getWithLimit/${states.limitAssociateStatus}&${states.offsetAssociateStatus}/${status}`, req)
            setters.setDataAssociateStatus(response.data.associate)
            setters.setIsLoading(false)
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                setters.setLimitSearchAssociateStatus(true)
                states.offsetAssociateStatus > 0 && setters.setOffsetAssociateStatus(states.offsetAssociateStatus - states.limitAssociateStatus)
                states.offsetAssociateStatus < 0 && setters.setOffsetAssociateStatus(0)
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }

    const getASsociateStatusQuery = async (status, query) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    "proxyauthorization": jsonConfig.API_KEY,
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            const response = await axios.get(`${jsonConfig.urlApi}/associateStatus/getWithLimit/${states.limitAssociateStatus}&${states.offsetAssociateStatus}/${status}/${query}`, req)
            setters.setDataAssociateStatus(response.data.associate)
            setters.setIsLoading(false)
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                setters.setIsLoading(false)
            } else {
                setters.setLimitSearchAssociateStatus(true)
                states.offsetAssociateStatus > 0 && setters.setOffsetAssociateStatus(states.offsetAssociateStatus - states.limitAssociateStatus)
                states.offsetAssociateStatus < 0 && setters.setOffsetAssociateStatus(0)
                setters.setIsLoading(false)
                toast.error(err.response.data.msg)
            }
        }
    }


    const exportAssociate = async (body, status) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem("token")
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/associateStatus/generateCsv/${status}`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    //#endregion

    //#region balanco faturas 

    const dataSystemBalanco = async () => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApi}/dataSystem/financialBalance/${states.initial_dateBalanco}/${states.final_dateBalanco}`, req)
            setters.setDataSystemBalanco(response.data.data)
            return true
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }


        }
    }

    const exportBalanco = async (data) => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApi}/account/export`, data, req)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion

    //#region Area do associado


    const loginAreaAssociado = async (data) => {
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            let url = null;
            if (localStorage.getItem('panelJuri') === 'true') {
                url = `/associateSandbox/register?fundo_id=${data}`
            } else {
                url = '/associateSandbox/register'
            }
            const response = await axios.get(`${jsonConfig.urlApi}/${url}`, req)
            toast.success(response.data.msg)
            return { status: true, data: response.data.data }
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                if (Cookies.get('tokenJuri') !== undefined) {
                    Cookies.remove('tokenJuri')
                }
                if (Cookies.get('roleJuri') !== undefined) {
                    Cookies.remove('roleJuri')
                }
                localStorage.removeItem('datasystemalerttec')
                localStorage.removeItem('permisssion')
                setters.setSigned(false)
                // toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }
            return { status: false }
        }
    }


    //#endregion

    return (
        <AuthContextApi.Provider
            value={{
                loginAreaAssociado,
                exportBalanco,
                dataSystemBalanco,
                exportAssociate,
                dataSystemAssociateStatus,
                getASsociateStatus,
                getASsociateStatusQuery,
                exportDataDebits,
                generateNegotiation,
                dataSystemDebitos,
                getDebitos,
                getDebitosQuery,
                getHistoricoRequisicoesPontos,
                getHistoricoRequisicoesPontosUser,
                deleteRequesicaoPonto,
                approveRequesicaoPonto,
                reproveRequesicaoPonto,
                dataSystemRequisicoesPontos,
                getRequisicoesPontos,
                getRequisicoesPontosUser,
                editPonto,
                editMyPonto,
                dataUsersLoadOutsourcedAndName,
                approveConcludeProject,
                endProject,
                editStep,
                addStep,
                endStep,
                getProjeto,
                initProject,
                approveProject,
                deleteProject,
                putProject,
                postProject,
                dataSystemProjetos,
                getProjetos,
                getProjetosQuery,
                getProjetosDate,
                deleteNoticia,
                putNoticia,
                dataLogsAssociate,
                dataLogsJuridico,
                dataAssociateComunicacaoForgot,
                dataAssociateComunicacaoCpfForgot,
                dataAssociateComunicacaoLogado,
                dataAssociateComunicacaoCpfLogado,
                dataAssociateComunicacao,
                dataAssociateComunicacaoCpf,
                deleteFaq,
                putFaq,
                postFaq,
                dataFaqs,
                postNoticia,
                dataNoticias,
                dataFundosQuery,
                dataSystemFundos,
                dataFundos,
                postFundo,
                putFundo,
                deleteFundo,
                dataAllFundo,
                sendFileAuth,
                checkCodeMail,
                sendRequestMail,
                deleteHealth,
                postHealth,
                dataHealthRegister,
                dataHealth,
                dataSystemHealth,
                dataImportFalhas,
                dataImportFaturasId,
                dataImportFaturas,
                deleteImport,
                createImport,
                dataImport,
                dataPontosUsuario,
                dataCobrancasQuery,
                dataAssinaturaQuery,
                dataAssociadosQuery,
                buscarFaturaPorCpf,
                buscarAssociadoPorCpf,
                editarTerceirizado,
                deleteArquivoTerceirizado,
                addArquivoTerceirizado,
                deleteComentarioTerceirizado,
                addComentarioTerceirizado,
                getFaturasTerceirizado,
                getTerceirizado,
                deletePainelFerias,
                deleteFeriasFuncionarios,
                createFeriasPainel,
                createFeriasFuncionarios,
                dataEmployeeLoadOutsourced,
                dataFeriasFuncionarios,
                dataPainelFerias,
                dataPainelVendaFerias,
                deleteAssinaturaFuncef,
                editConfigPonto,
                createConfigPonto,
                dataDiasAtrasados,
                dataUsuarioDiasDeTrabalho,
                baterPonto,
                dataDiasTrabalhados,
                dataPontosRegistrados,
                dataConfiguracaoPonto,
                dataHooks,
                createCreditCard,
                deleteCreditCard,
                getCreditCard,
                buscarTerceirizadoPorId,
                buscarAssinaturaPorId,
                buscarPlanoPorId,
                buscarAssociadoPorId,
                dataExtrato,
                endInvest,
                aproveAport,
                unproveAport,
                putAport,
                dataAport,
                postAport,
                deleteInvestimento,
                putInvestimento,
                dataInvestimentos,
                postInvestimento,
                checkFaturaVindi,
                createReportFinancialExpensesExtract,
                createReportFinancialResult,
                createReportFinancialExpenses,
                dataUsersLoadOutsourced,
                cadastrarCartaoSignature,
                deleteCommentAssociate,
                deleteFileAssociate,
                postFileAssociate,
                postCommentsAssociate,
                getCommentsFileAssociate,
                changePassword,
                confirmChangeEmail,
                changeEmail,
                searchQueryHistorico,
                dataHistorico,
                searchQueryUsers,
                roleChange,
                blockUnlock,
                dataUsers,
                dataSystemUsers,
                dataAssinaturaTemporaria,
                searchQueryAssinaturaTemporaria,
                dataSystemTemp,
                deleteAssinaturaTemporaria,
                searchQueryRemessaAssociado,
                searchQueryAssociadoTransacao,
                getDebit,
                dataAssociadosGrafico,
                login,
                checkLogin,
                dataSystem,
                dataLogs,
                cadastrarUsuario,
                deleteLogs,
                logout,
                dataCron,
                deleteCron,
                dataTransactionRemittenceAssociateAlert,
                deleteTransactionRemittenceAssociateAlert,
                dataAlert,
                deleteAlert,
                dataAlertRemittence,
                deleteAlertRemittence,
                dataGraficAccount,
                dataExtratoConc,
                createReportFinancial,
                getDataGraphicInput,
                dataEntradas,
                getDataGraphicSaidas,
                dataSaidas,
                getDataGraphicFaturas,
                dataCobranca,
                getDataGraphicRemessa,
                dataRemessa,
                postRemessa,
                putRemessa,
                dataRemessaAssociado,
                getDataGraphicRemessaAssociado,
                getDataGraphicRemessaAssociadoTransacao,
                dataRemessaAssociadoTransacao,
                dataFuncefAssinatura,
                aproveSigature,
                cancelarFatura,
                reembolso,
                baixaFatura,
                buscarAssociadoCpf,
                buscarTerceirizadoCodigo,
                createCobranca,
                dataAssociados,
                dataSystemTecAlert,
                dataSystemFinancial,
                dataSystemCobranca,
                dataSystemRemessa,
                dataSystemRemessaAssociado,
                dataSystemRemessaAssociadoTransaction,
                dataSystemSignatureFuncef,
                dataSystemAssociate,
                createReportAssociado,
                searchQueryAssociados,
                createReportAssociadoForm,
                dataAssinaturaGrafico,
                dataAssinatura,
                searchQueryAssinatura,
                dataSystemAssinatura,
                dataSystemPlans,
                searchQueryPlans,
                dataPlans,
                createPlan,
                updatePlan,
                deletePlans,
                createAssinatura,
                updateAssinatura,
                suspendAssinatura,
                activeAssinatura,
                changeFuncefAssinatura,
                createNegociacao,
                returnSigatureFuncef,
                createFuncefAssinatura,
                dataSystemNegociacao,
                dataNegociacao,
                searchQueryNegociacao,
                getDataGraphicNegociacao,
                dataSystemTerceirizado,
                dataTerceirizado,
                searchQueryTerceirizado,
                createTerceirizado,
                updateTerceirizado,
                deleteTerceirizado,
                dataSystemAprove,
                dataAprove,
                searchQueryAprove,
                dataSystemRequestAssinatura,
                dataRequestAssinatura,
                searchQueryRequestAssinatura,
                responseRequestAssinatura,
                dataSystemRequestFatura,
                dataRequestFatura,
                searchQueryRequestFatura,
                responseRequestFatura,
                searchQueryCobranca,
                dataSystemPagamento,
                getDataGraphicFaturasPagamento,
                dataPagamento,
                createPagamento,
                cancelarPagamento,
                baixaFaturaPagamento,
                searchQueryPagamento,
                createPagamentoFile,
                cadastrarCartao,
                searchQueryAssinaturaFuncef,
                dataSystemReport,
                dataRelatorio,
                dataSystemHome,
                forgotPassword,
                forgotChangePass,
                mudarDataFatura,
                getAllExpenses,
                postExpenses,
                dataCustos,
                putExpenses,
                deleteExpenses,
                dataCustosFaturas,
                dataAssociadoFatura,
                dataSystemHooks,
                unfiliateAssociate,
                encerrarDiaEmAberto,
                dataSystemImport,
                dataSystemImportFaturas,
                dataAssinaturaExtrato,
                dataAssinaturaCard,
                dataDiasTrabalhadosPerUser,
                dataPontosUsuarioAdmin

            }}>
            {children}
        </AuthContextApi.Provider>
    )
}

export default AuthProviderApi;