
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { AuthContextApi } from "../../../Context/api";
import { useContext, useEffect, useState } from "react";
import { jsonConfig } from "../../../Config";
import { useParams } from "react-router-dom";
import moment from 'moment'
import UploadWord from '../../../Components/UploadJuridicoAutorizacao'
import Cookies from "js-cookie";

const ProcessosChckJuridico = () => {

    const { checkCodeMail, sendFileAuth } = useContext(AuthContextApi)
    const { token, idstep } = useParams()
    const [code, setCode] = useState('')

    const [errorCode, setErrorCode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [error, setError] = useState(false)
    const [errorFile, setErrorFile] = useState(false)
    const [file, setFile] = useState('')
    const [completeAll, setCompleteAll] = useState(false)

    const sendEmail = async () => {
        if (!code) {
            setErrorCode(true)
            return
        }
        setErrorCode(false)


        setLoading(true)
        const response = await checkCodeMail({ idstep, token, code })
        setLoading(false)
        if (response.status == true) {
            //GenCookie with 5 hours
            Cookies.set('token', response.token, { expires: 5 / 24 })
            setCompleted(true)
            setError(false)
        } else {
            setCompleted(true)
            setError(true)
        }
    }

    const novaTentativa = () => {
        setCompleted(false)
        setError(false)
        setCode('')
    }


    //MODIFICAR PARA CASO JA TENHA O TOKEN
    useEffect(() => {
        const token = Cookies.get('token')
        if (token) {
            setCode('')
            setCompleted(true)
            setError(false)
            return
        }
        setCompleted(false)
        setLoading(false)
        setError(false)
        setCode('')
        setCompleteAll(false)

        return () => {
            setCode('')
            setCompleted(false)
            setError(false)
            setCompleteAll(false)
        }

    }, [])

    const actionFile = (file) => {
        setFile(file)
    }

    const sendFile = async () => {
        if (!file) {
            setErrorFile(true)
            return
        }
        const token = Cookies.get('token')
        if (!token) {
            window.location.href = 'https://sinprev.org.br'
            return;
        }
        setErrorFile(false)
        setLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        formData.append('token', token)
        const response = await sendFileAuth(formData)
        setLoading(false)
        if (response == true) {
            setError(false)
            setCompleteAll(true)
            setCompleted(false)
            Cookies.remove('token')
        } else {
            // Cookies.remove('token')
            setCompleted(true)
            setError(true)
        }
    }


    return (
        <>
            <div className="flex flex-1 flex-col  bg-white">
                {/* Header */}
                <header class="flex justify-center w-full bg-gradient-to-r from-blue-700 via-blue-400 to-blue-700 fixed " >
                    <div class="mx-auto max-w-7xl overflow-hidden p-5">
                        <img class="block h-16 w-auto" src={`${jsonConfig.urlAplication}/logo-sinprev-resp.png`} alt="Sinprev" />
                    </div>
                </header>
            </div>

            <div class="flex flex-col items-start justify-center  ml-4 border shadow p-5 mr-12 mt-32 rounded-[10px]">
                <p class="text-xl font-semibold text-gray-800">Seja bem-vindo(a)</p>
            </div>
            <div class="flex flex-col w-full items-center justify-center">
                <div class="flex flex-col items-start justify-center mt-16 border rounded-[10px] w-[95%] p-4 shadow ">
                    <p class="text-xl font-semibold text-gray-800">Insira abaixo o código enviado para o seu e-mail</p>
                    {
                        loading && <SpinnerOverlay />
                    }
                    {
                        !completeAll && !loading && !completed && (
                            <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                <div class="flex flex-col items-center justify-center w-full">
                                    <div class="flex flex-col items-start justify-center w-full">
                                        <label for="cpf" class="text-md font-semibold text-gray-800">Código</label>
                                        <input type="text" id="cpf" name="cpf" class="w-full border rounded-[10px] p-2" placeholder="Digite o código enviado por e-mail" value={code} onChange={(e) => {
                                            const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                                            setCode(onlyNums)
                                        }} />
                                        {
                                            errorCode && <p class="text-red-500 text-xs mt-2 ml-2">Código inválido</p>
                                        }
                                    </div>
                                    <div class="flex flex-col items-start justify-center w-[50%] mt-4">
                                        <button class="bg-blue-700 text-white font-semibold p-2 rounded-[10px] w-full hover:bg-blue-600" onClick={sendEmail}>Enviar</button>
                                    </div>
                                </div>

                            </div>
                        )
                    }
                    {
                        !completeAll && !loading && !error && completed && (
                            <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px]  p-8 shadow hover:shadow-md ">
                                <div class="flex flex-col items-center justify-center w-full">
                                    <p>Inclua abaixo o arquivo completo que recebeu no e-mail para análise</p>
                                    {
                                        file &&
                                        <button class="bg-red-700 text-white font-semibold p-2 rounded-[10px] w-full hover:bg-red-600 mt-2" onClick={() => setFile('')}>Excluir arquivo</button>
                                    }
                                    <span className="mt-3" />
                                    {/* botao de exclusao de arquivo */}
                                    <UploadWord file={actionFile} />
                                    {
                                        file && <p class="text-gray-800 text-sm mt-2">Arquivo: {file.name}</p>
                                    }
                                    {
                                        errorFile && <p class="text-red-500 text-xs mt-2 ml-2">Arquivo inválido</p>
                                    }
                                    <button class="bg-blue-700 text-white font-semibold p-2 rounded-[10px] w-full hover:bg-blue-600 mt-4" onClick={sendFile}>Enviar</button>
                                </div>


                            </div>
                        )
                    }
                    {
                        !completeAll && !loading && error && completed && (
                            <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                    <div class="flex flex-col items-center justify-center w-full">
                                        <p>Ops! Algo deu errado, tente novamente mais tarde</p>
                                    </div>
                                    <button class="bg-blue-700 text-white font-semibold p-2 rounded-[10px] w-full hover:bg-blue-600 mt-4" onClick={novaTentativa}>Nova solicitação</button>
                                </div>

                            </div>
                        )
                    }
                    {
                        completeAll && !loading && (
                            <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                    <div class="flex flex-col items-center justify-center w-full">
                                        <p>Solicitação de acesso aos documentos realizada com sucesso, você já está incluso no processo!</p>
                                    </div>
                                </div>

                            </div>
                        )
                    }
                </div>

            </div>


            <footer class="flex justify-center w-full bg-gradient-to-r from-blue-700 via-blue-400 to-blue-700 mt-[3.9em]">
                <div class="mx-auto max-w-7xl overflow-hidden">
                    <p class="text-center text-xs leading-5 text-black py-8">&copy; {moment().format('YYYY')} SINPREV. Todos os direitos reservados</p>
                </div>
            </footer>
        </>
    )
}



export default ProcessosChckJuridico;