
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { useContext, useEffect } from "react";
import { AuthContextApi } from "../../Context/api";
import { AuthContext } from "../../Context";
import { Link } from "react-router-dom";
import {
    ClipboardDocumentCheckIcon, BanknotesIcon, DocumentArrowDownIcon, DocumentArrowUpIcon, CreditCardIcon, ClipboardDocumentIcon, ChartBarSquareIcon,
    ArrowUpOnSquareIcon
} from '@heroicons/react/24/outline'

const FinanceiroIndex = () => {

    const { checkLogin } = useContext(AuthContextApi)
    const { setters } = useContext(AuthContext)

    async function isSigned() {
        const token = localStorage.getItem('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                localStorage.removeItem('token')
                setters.setSigned(false)
                return;
            }

        }
    }

    useEffect(() => {
        isSigned()
    }, [])

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                <h1 className="text-3xl text-center font-bold">Painel Financeiro</h1>
                                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso as áreas financeiras do sistema</p>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                                {/* Card */}
                                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') &&
                                    <>
                                        {
                                            (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1') &&
                                            <>

                                                <div className="flex items-center justify-center">
                                                    <Link to="/financeIndex/balanco" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                        <BanknotesIcon className="h-[30%] w-[30%] text-blue-600" />
                                                        <h2 className="text-xl font-bold">Conta</h2>
                                                        <p className="text-center">Clique nesse cartão para acessar a conta</p>
                                                    </Link>
                                                </div>

                                                <div className="flex items-center justify-center">
                                                    <Link to="/financeIndex/custos" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                        <CreditCardIcon className="h-[30%] w-[30%] text-blue-600" />
                                                        <h2 className="text-xl font-bold">Custos</h2>
                                                        <p className="text-center">Clique nesse cartão para acessar a área de custos</p>
                                                    </Link>
                                                </div>

                                                <div className="flex items-center justify-center">
                                                    <Link to="/financeIndex/entradas" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                        <DocumentArrowDownIcon className="h-[30%] w-[30%] text-blue-600" />
                                                        <h2 className="text-xl font-bold">Entradas</h2>
                                                        <p className="text-center">Clique nesse cartão para acessar a área de entradas financeiras</p>
                                                    </Link>
                                                </div>
                                            </>
                                        }
                                    </>
                                }

                                <div className="flex items-center justify-center">
                                    <Link to="/financeIndex/faturas" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <ClipboardDocumentIcon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold">Faturas</h2>
                                        <p className="text-center">Clique nesse cartão para acessar a área de faturas</p>
                                    </Link>
                                </div>

                                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') &&
                                    <>




                                        <div className="flex items-center justify-center">
                                            <Link to="/financeIndex/folhaPagamento" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <ClipboardDocumentCheckIcon className="h-[30%] w-[30%] text-blue-600" />
                                                <h2 className="text-xl font-bold">Folha de Pagamento</h2>
                                                <p className="text-center">Clique nesse cartão para acessar a área de folha de pagamento</p>
                                            </Link>
                                        </div>
                                        {
                                            (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1') &&
                                            <>
                                                <div className="flex items-center justify-center">
                                                    <Link to="/financeIndex/importacao" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                        <ArrowUpOnSquareIcon className="h-[30%] w-[30%] text-blue-600" />
                                                        <h2 className="text-xl font-bold text-center">Imoprtação financeira</h2>
                                                        <p className="text-center">Clique nesse cartão para acessar a área de importação financeira</p>
                                                    </Link>
                                                </div>
                                                <div className="flex items-center justify-center">
                                                    <Link to="/financeIndex/produtosinvestimento" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                        <ChartBarSquareIcon className="h-[30%] w-[30%] text-blue-600" />
                                                        <h2 className="text-xl font-bold text-center">Produtos de Investimento</h2>
                                                        <p className="text-center">Clique nesse cartão para acessar a área de produtos de investimento</p>
                                                    </Link>
                                                </div>
                                            </>
                                        }

                                    </>
                                }

                                {
                                    (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1') &&
                                    <>
                                        <div className="flex items-center justify-center">
                                            <Link to="/financeIndex/saidas" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <DocumentArrowUpIcon className="h-[30%] w-[30%] text-blue-600" />
                                                <h2 className="text-xl font-bold">Saídas</h2>
                                                <p className="text-center">Clique nesse cartão para acessar a área de saídas financeiras</p>
                                            </Link>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                        <div className="pt-8">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default FinanceiroIndex;
