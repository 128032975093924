
import { useEffect, useState } from "react";
import Table from '../../Tabela/TabelaSecoesAcoesJuridico'
import ModalSecao from "../../Modal/AcaoSecoesJuri";

const SecoesJurudicoAcao = ({
    createSecao, dataSecoes, updateSecao, deleteSecao,
    states, setters,
}) => {




    //NO DO USE EFFECT DEVE SER VERIFICADO CASO A DATA TENHA DADO JA PARA NAO CHAMAR DUAS VEZES





    const headers = ['', 'Título', 'Descrição', 'Público', 'Criador', 'Data de criação', 'Data de atualização']


    const next = async () => {
        if (states.limitSearchJuriAcoesProcessoSecoes && states.offsetJuriAcoesProcessoSecoes > 0) {
            return;
        } else {
            setters.setOffsetJuriAcoesProcessoSecoes(states.offsetJuriAcoesProcessoSecoes + states.limitJuriAcoesProcessoSecoes)
        }
    };

    const previous = async () => {
        const offset = states.offsetJuriAcoesProcessoSecoes - states.limitJuriAcoesProcessoSecoes
        if (offset < 0) {
            states.offsetJuriAcoesProcessoSecoes < 0 && setters.setOffsetJuriAcoesProcessoSecoes(0)
            return;
        } else {
            setters.setOffsetJuriAcoesProcessoSecoes(states.offsetJuriAcoesProcessoSecoes - states.limitJuriAcoesProcessoSecoes)
        }
    };


    const openModal = async () => {
        setters.setIsEditJuri(false)
        setters.setModalAcoesProcessoSecoes(true)
        setters.setJuriProcessoSecoes({})
    }


    const loadData = async () => {
        await dataSecoes(states.JuriAcoes.id)
    }


    const openDefired = async (data) => {
        setters.setJuriProcessoDeferimento(data)
        setters.setModalDeferimento(true)

    }



    const openModalEdit = async (data) => {
        setters.setIsEditJuri(true)
        setters.setJuriAcoesProcessoSecoes(data)
        setters.setModalAcoesProcessoSecoes(true)
    }

    const deleteProcess = async (data) => {
        setters.setIsEditJuri(false)
        setters.setJuriAcoesProcessoSecoes(data)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja excluir a seção: ${data.titleSection}?`)
        setters.setDescModalMsg(`Após a confirmação a seção será excluída da ação.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await deleteSecao(states.JuriAcoes.id, data.id)
            setters.setModalMsg(false)
            if (response) {
                loadData()
            }
        })
        setters.setFunctionBtnModalMsg(() => async () => {
            setters.setModalMsg(false)
        })
    }


    const actionModal = async (data, id) => {
        let resp;
        if (states.isEditJuri) {
            resp = await updateSecao(states.JuriAcoes.id, data, id)
        } else {
            resp = await createSecao(states.JuriAcoes.id, data)
        }
        if (resp) {
            loadData()
        }
        setters.setJuriAcoesProcessoSecoes({})
        setters.setModalAcoesProcessoSecoes(false)

    }

    useEffect(() => {
        loadData()

        return () => {
            setters.setDataJuriAcoesProcessoSecoes([]);
            setters.setJuriAcoesProcessoSecoes({});
            setters.setOffsetJuriAcoesProcessoSecoes(0);
            setters.setLimitSearchJuriAcoesProcessoSecoes(false);
            setters.setModalAcoesProcessoSecoes(false);
        }

    }, [])

    useEffect(() => {
        if (states.dataJuriAcoesProcessoSecoes.length > 0) loadData()

    }, [states.offsetJuriAcoesProcessoSecoes])


    return (
        <>
            <Table defired={openDefired} add={openModal} edit={openModalEdit} deleteSection={deleteProcess} title={`Seções cadastrados na ação`}
                description={`Registro dos seções que compõem a ação`} next={next} previous={previous} states={states} headers={headers}
                infoTable={{ offset: states.offsetJuriAcoesProcessoSecoes, limit: states.limitJuriAcoesProcessoSecoes }} dataTable={states.dataJuriAcoesProcessoSecoes} />
            <ModalSecao setters={setters} states={states} action={actionModal} />
            {/* <ModalConfirm setters={setters} states={states} title={titleConfirm} description={descriptionConfirm} action={actionModalDelete} /> */}
        </>
    )
}



export default SecoesJurudicoAcao;