import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import Table from '../../Tabela/TabelaShowModalEscritorioJuri'
import './index.css'


export default function ModalEscritorioProcessos({ states, setters, title, description, get, param }) {
    const [open, setOpen] = useState(true)

    const close = () => {
        setters.setModalJuriLegalProcess(false)
        setters.setDataJuriLegalProcess([])
        setters.setJuriEscritorio({})
        setters.setOffsetJuriLegalProcess(0)
        setters.setLimitSearchJuriLegalProcess(false)
    }

    // 
    const next = async () => {
        if (states.limitSearchJuriLegalProcess) {
            return
        } else {
            setters.setOffsetJuriLegalProcess(states.offsetJuriLegalProcess + states.limitJuriLegalProcess)
        }
    };
    const previous = async () => {
        if (states.offsetJuriLegalProcess <= 0) {
            states.offsetJuriLegalProcess < 0 && setters.setOffsetJuriLegalProcess(0)
            return
        } else {
            if (states.limitSearchJuriLegalProcess) setters.setLimitSearchJuriLegalProcess(false)
            setters.setOffsetJuriLegalProcess(states.offsetJuriLegalProcess - states.limitJuriLegalProcess)
        }

    };


    useEffect(() => {
        setters.setJuriEscritorio(states.JuriEscritorio)
    }, [])

    const load = async () => {
        await get(states.JuriEscritorio.id)
    }
    //git add . && git commit -m "Advogados ok" && git push 

    useEffect(() => {
        if (states.offsetJuriLegalProcess >= 0 && states.dataJuriLegalProcess.length > 0) {
            load()
        }
    }, [states.offsetJuriLegalProcess])

    const formatCpfCnpj = data => {
        if (data?.length === 11) {
            return data?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return data?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatPhone = data => {
        if (data?.length === 11) {
            return data?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
        } else {
            //Out ddd 
            return data?.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
        }
    }

    const headers = ['Nome da ação', 'Status de Cadastro', 'Tribunal', 'Escritório', 'Tipo da Ação', 'Ação interna', 'Restrita por EFPC', 'Vinculação Automática', 'E-mail criador', 'Data de criação', 'Data de atualização']

    return (
        <Transition.Root show={states.modalJuriLegalProcess} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className=" w-[90%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => close()}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Escritório</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        <span className="text-base font-semibold leading-6 text-gray-900">Processos do {states.JuriEscritorio?.name}</span>
                                    </p>
                                </div>

                                <div className='pt-10 pl-10 pr-10'>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos a assinatura.</p>
                                            </div>

                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 md:col-span-2">
                                                {/* {
                                                    props.states.dataAssinaturaExtrato.length > 0 && ( */}
                                                <>
                                                    <div className="sm:col-span-1">
                                                        <div className="flex items-center justify-start">
                                                            <dt className="text-sm font-medium text-gray-500">Nome: </dt>
                                                            <dd className="mt-1 text-sm text-gray-900 ml-2">{states.JuriEscritorio?.name}</dd>
                                                        </div>
                                                        <div className="flex items-center justify-start">
                                                            <dt className="text-sm font-medium text-gray-500">CNPJ: </dt>
                                                            <dd className="mt-1 text-sm text-gray-900 ml-2">{formatCpfCnpj(states.JuriEscritorio?.cpf_cnpj)}</dd>
                                                        </div>
                                                        <div className="flex items-center justify-start mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">Criação: </dt>
                                                            <dd className="mt-1 text-sm text-gray-900 ml-2">{moment(states.JuriEscritorio?.updated_at).format('DD/MM/YYYY HH:mm:ss')}</dd>
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <div className="flex items-center justify-start">
                                                            <dt className="text-sm font-medium text-gray-500">E-mail: </dt>
                                                            <dd className="mt-1 text-sm text-gray-900 ml-2">{states.JuriEscritorio?.emailOutSourced}</dd>
                                                        </div>
                                                        <div className="flex items-center justify-start">
                                                            <dt className="text-sm font-medium text-gray-500">Telefone: </dt>
                                                            <dd className="mt-1 text-sm text-gray-900 ml-2">{formatPhone(`${states.JuriEscritorio.prefix_phone ? states.JuriEscritorio.prefix_phone : '00'}${states.JuriEscritorio?.phone}`)}  </dd>
                                                        </div>
                                                        <div className="flex items-center justify-start">
                                                            <dt className="text-sm font-medium text-gray-500">Criador: </dt>
                                                            <dd className="mt-1 text-sm text-gray-900 ml-2">{states.JuriEscritorio?.created_by}</dd>
                                                        </div>
                                                        <div className="flex items-center justify-start ">
                                                            <dt className="text-sm font-medium text-gray-500">Atualização: </dt>
                                                            <dd className="mt-1 text-sm text-gray-900 ml-2">{moment(states.JuriEscritorio?.updated_at).format('DD/MM/YYYY HH:mm:ss')}</dd>
                                                        </div>
                                                    </div>
                                                </>
                                                {/* )
                                                } */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Table
                                        title='Ações vinculadas' description={`Registro das ações vinculados ao escritório ${states.JuriEscritorio.name}`} next={next} previous={previous} states={states} headers={headers} infoTable={{ offset: states.offsetJuriLegalProcess, limit: states.limitJuriLegalProcess }} dataTable={states.dataJuriLegalProcess} />
                                </div>
                                <div className="mt-5 flex items-center border-t border-gray-900/10 justify-end gap-x-6 mr-5">
                                    <button type="button" className="text-sm  mt-6 font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                        onClick={() => close()}>
                                        Fechar
                                    </button>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

