import { XMarkIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Arquivo from '../../UploadJuridico'
import moment from 'moment';

export default function ModalProcessos(props) {

    const { id } = useParams()


    const [idDeferimento, setIdDeferimento] = useState('')
    const [form, setForm] = useState({
        associate_id: '',
        cpf: '',
        file: null
    })
    const [errorForm, setErrorForm] = useState({
        associate_id: false,
        cpf: false,
        file: false,
    })



    const [dataSelect, setDataSelect] = useState([])
    const [dataSelectLawyer, setDataSelectLawyer] = useState([])





    const onSubmitForm = async (e) => {
        e.preventDefault()
        let error = false
        for (const item of Object.keys(form)) {
            if (form[item] == '' || form[item] == ' ' || form[item] == undefined) {
                setErrorForm(e => {
                    return { ...e, [item]: true }
                })
                error = true
            } else {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (error) {
            error = false
            return
        }
        let obj;
        obj = new FormData();
        obj.append('file', form.file);
        obj.append('associate_id', props.states.JuriAssociadoProcesso.id);
        obj.append('processAuthorization_id', idDeferimento);
        props.action(obj)

    }



    useEffect(() => {
        if (props.states.modalAssociadoProcesso === false) {
            Object.keys(form).map(item => {
                setForm(e => {
                    return { ...e, [item]: '' }
                })
            })
            Object.keys(errorForm).map(item => {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            })
            setCheckFile(false)
            props.setters.setJuriAssociadoProcesso({})
            setIdDeferimento('')
            return;
        }
        setIdDeferimento(props.states.JuriLegalProcess?.auth?.id)

        //Limpeza do modal ao fechar
    }, [props.states.modalAssociadoProcesso]);




    const [checkFile, setCheckFile] = useState(false)
    const saveFile = (file) => {
        setForm(() => {
            return { ...form, file: file }
        })
    }

    useEffect(() => {
        if (checkFile == false) {
            setForm(() => {
                return { ...form, file: null }
            })
        }
    }, [checkFile])

    const formatCpf = (cpf) => {
        return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    }


    //Deve esvaziar qnd abrir
    const searchAssociate = async () => {
        // buscarAssociadoPorCpf
        const resp = await props.associate(form?.cpf)
        if (!resp) {
            setForm(() => {
                return { ...form, associate_id: '', cpf: '' }
            })
        } else {
            setErrorForm(e => {
                return { ...e, cpf: false, associate_id: false }
            })
            setForm(() => {
                return { ...form, associate_id: props.states.JuriAssociadoProcesso.id }
            })
        }
    }

    const clearSelect = () => {
        props.setters.setJuriAssociadoProcesso({})
        setForm(() => {
            return { ...form, associate_id: '', cpf: '' }
        })
    }

    useEffect(() => {
        if (Object.keys(props.states.JuriAssociadoProcesso).length > 0) {
            setForm(() => {
                return { ...form, associate_id: props.states.JuriAssociadoProcesso.id, cpf: props.states.JuriAssociadoProcesso.cpf }
            })
        } else {
            setForm(() => {
                return { ...form, associate_id: '', cpf: '' }
            })
        }
    }, [props.states.JuriAssociadoProcesso])


    return (
        <Transition.Root show={props.states.modalAssociadoProcesso} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalAssociadoProcesso(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalAssociadoProcesso(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <div className='p-3'>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                                            Adicionar associado ao processo
                                        </h2>
                                        <p className="mt-2 text-sm leading-6 text-gray-600">
                                            Insira as informações necessárias para adicionar um associado ao processo.
                                        </p>
                                    </div>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            {/* LINHA 1 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <div className='flex flex-row'>
                                                        <div className='flex flex-col w-full'>
                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                                Digite o CPF do associado
                                                            </label>
                                                            <div className="mt-2">
                                                                <div
                                                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                                    <input
                                                                        type='text'
                                                                        className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                        placeholder='Digite o cpf do associado'
                                                                        onKeyDown={e => {
                                                                            if (e.key === 'Enter') {
                                                                                searchAssociate()
                                                                            }
                                                                        }}
                                                                        onChange={e => setForm(() => {
                                                                            const notChar = e.target.value.replace(/\D/g, '')
                                                                            if (notChar.length > 11) {
                                                                                return { ...form, cpf: form.cpf }
                                                                            } else {
                                                                                return { ...form, cpf: notChar }
                                                                            }
                                                                        })}
                                                                        value={formatCpf(form?.cpf)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errorForm.cpf === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                É obrigatório inserir o cpf do associado.
                                                            </p>
                                                            }
                                                            {errorForm.associate_id === true && Object.keys(props.states.JuriAssociadoProcesso).length === 0 && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                É obrigatório selecionar um associado.
                                                            </p>
                                                            }
                                                        </div>

                                                        <button type="button" onClick={searchAssociate}
                                                            className="h-12 mt-5  font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100">
                                                            <MagnifyingGlassCircleIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                    {
                                                        Object.keys(props.states.JuriAssociadoProcesso).length > 0 &&
                                                        <div className='flex flex-col w-full mt-5'>
                                                            <>
                                                                <div className="w-[70%] border shadow-md hover:shadow-lg rounded-[10px] flex-rw  items-center justify-start flex">
                                                                    <div className="p-5 ">
                                                                        Email: {props.states.JuriAssociadoProcesso.email || props.states.JuriAssociadoProcesso.emailAssociate} <br />
                                                                        CPF: {formatCpf(props.states.JuriAssociadoProcesso.cpf)} <br />
                                                                        Nome: {props.states.JuriAssociadoProcesso.name}
                                                                    </div>
                                                                    <div className="p-5 ">
                                                                        <XMarkIcon class="h-8 w-8 text-gray-400" aria-hidden="true" onClick={clearSelect} />

                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            {/* LINHA 4 */}

                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <Arquivo file={saveFile} />
                                                </div>
                                            </div>
                                            {
                                                form?.file && (
                                                    <p className="mt-2 ml-2 text-green-600 text-sm">
                                                        Arquivo inserido com sucesso! {form.file.name}
                                                    </p>
                                                )}
                                        </div>
                                        {
                                            errorForm.file === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                É obrigatório inserir o arquivo.
                                            </p>
                                        }
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => props.setters.setModalAssociadoProcesso(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
