import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Arquivo from '../../UploadJuridico'

export default function ModalProcessos(props) {

    const { id } = useParams()


    const [idDeferimento, setIdDeferimento] = useState('')
    const [form, setForm] = useState({
        titleSection: '',
        descriptionSection: '',
        isPublic: '',
        file: null
    })
    const [errorForm, setErrorForm] = useState({
        titleSection: false,
        descriptionSection: false,
        isPublic: false,
        file: false
    })



    const [dataSelect, setDataSelect] = useState([])
    const [dataSelectLawyer, setDataSelectLawyer] = useState([])
    const [checkFile, setCheckFile] = useState(false)





    const onSubmitForm = async (e) => {
        e.preventDefault()
        let error = false;
        for (const item of Object.keys(form)) {
            if (form[item] === '' || form[item] === ' ' || form[item] === undefined) {
                if (item === 'file' || item === 'path' || item === 'filename' || item === 'isDeleted') continue;
                error = true
                setErrorForm(e => {
                    return { ...e, [item]: true }
                })
            } else {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (error) {
            error = false
            return;
        }
        let obj;
        const isPublic = form.isPublic == 'true' ? true : false
        obj = new FormData();
        if (form.file !== null && Object.keys(form.file).length == 0) {
            obj.append('file', form.file);
        }
        obj.append('titleSection', form.titleSection);
        obj.append('descriptionSection', form.descriptionSection);
        obj.append('isPublic', isPublic);
        obj.append('action_id', id);
        if (props.states.isEditJuri == true) {
            obj.append('id', props.states.JuriAcoesProcessoSecoes.id);
        }
        if (props.states.isEditJuri) {
            if (!checkFile && props.states.JuriAcoesProcessoSecoes.filename !== null) {
                obj.append('deleteFile', true);
            }
            props.action(obj, idDeferimento)
            return;
        }
        props.action(obj)
    }

    const saveFile = (file) => {
        setForm(() => {
            return { ...form, file: file }
        })
    }



    useEffect(() => {
        if (props.states.modalAcoesProcessoSecoes == false) {
            Object.keys(form).map(item => {
                setForm(e => {
                    return { ...e, [item]: '' }
                })
            })
            Object.keys(errorForm).map(item => {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            })
            setCheckFile(false)
            setForm({
                titleSection: '',
                descriptionSection: '',
                isPublic: '',
                file: null
            })
            props.setters.setIsEditJuri(false)
            props.setters.setJuriAcoesProcessoSecoes({})
        } else {
            if (Object.keys(props.states.JuriAcoesProcessoSecoes).length > 0) {
                if (props.states.JuriAcoesProcessoSecoes.filename) setCheckFile(true)
                setIdDeferimento(props.states.JuriAcoesProcessoSecoes.id)
                setForm(() => {
                    return {
                        ...props.states.JuriAcoesProcessoSecoes,
                        isPublic: props.states.JuriAcoesProcessoSecoes.isPublic == 1 ? "true" : "false",
                        file: props.states.JuriAcoesProcessoSecoes.filename ? { name: props.states.JuriAcoesProcessoSecoes.filename } : null
                    }
                })
            }
        }
        //Limpeza do modal ao fechar
    }, [props.states.modalAcoesProcessoSecoes]);



    useEffect(() => {
        if (checkFile == false) {
            setForm(() => {
                return { ...form, file: null }
            })
        }
    }, [checkFile])

    return (
        <Transition.Root show={props.states.modalAcoesProcessoSecoes} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalAcoesProcessoSecoes(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalAcoesProcessoSecoes(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <div className='p-3'>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                                            {props.states.isEditJuri === false ? 'Cadastrar' : 'Editar'} Atualização
                                        </h2>
                                        <p className="mt-2 text-sm leading-6 text-gray-600">
                                            Preencha os campos abaixo para cadastrar uma nova atualização para o processo.
                                        </p>
                                    </div>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            {/* LINHA 1 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Título da Seção
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o título da seção'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, titleSection: e.target.value }
                                                                })}
                                                                value={form?.titleSection}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.titleSection === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o do título da seção.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 4 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Descrição da seção
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, descriptionSection: e.target.value }
                                                                })}
                                                                value={form?.descriptionSection}
                                                                placeholder='Digite a descrição do seção'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    {errorForm.descriptionSection === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da descrição do seção.
                                                    </p>
                                                    }
                                                </div>
                                            </div>

                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12 flex w-full">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Visibilidade da seção
                                                    </label>
                                                    <div className="mt-1 ml-4">
                                                        {/* Checkbox */}
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5 ">
                                                                <select onChange={e => setForm(() => {
                                                                    return { ...form, isPublic: e.target.value }
                                                                })}
                                                                    value={form?.isPublic}
                                                                    className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6">
                                                                    <option value=''>Selecione</option>
                                                                    <option value='true'>Público</option>
                                                                    <option value='false'>Privado</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {
                                                            errorForm.isPublic === true && <p class="mt-3 ml-2 text-pink-600 text-sm">
                                                                É obrigatório o preenchimento da visibilidade do seção.
                                                            </p>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12 flex w-full">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Para inserir um arquivo, clique ao lado
                                                    </label>
                                                    <div className="mt-1 ml-4">
                                                        {/* Checkbox */}
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5">
                                                                <input
                                                                    id="comments"
                                                                    aria-describedby="comments-description"
                                                                    name="comments"
                                                                    value={checkFile}
                                                                    checked={checkFile === true ? true : false}
                                                                    onChange={e => setCheckFile(e.target.checked)}
                                                                    type="checkbox"
                                                                    className="focus:ring-blue-600 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Pergnuta sobre inserção de arquivo */}
                                            {
                                                checkFile === true && (
                                                    <>
                                                        <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                            <div className="sm:col-span-12">
                                                                <Arquivo file={saveFile} />
                                                            </div>
                                                        </div>
                                                        {
                                                            form.file && (
                                                                <p className="mt-2 ml-2 text-green-600 text-sm">
                                                                    Arquivo inserido com sucesso! {form.file.name || form.fileName}
                                                                </p>
                                                            )}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => props.setters.setModalAcoesProcessoSecoes(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
