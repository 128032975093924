import { AuthContext } from "../../Context"
import { AuthContextApi } from '../../Context/api'
import { useContext, useEffect, useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import SpinnerOverlay from "../../Components/SpinnerOverlay"
import Navbar from '../../Components/Navbar/navbar'
import { jsonConfig } from "../../Config"



export default function AreaAssociado() {
    const { states, setters } = useContext(AuthContext)
    const { checkLogin, dataAllFundo, loginAreaAssociado } = useContext(AuthContextApi)

    const [cumprimento, setCumprimento] = useState('')
    const [fundo, setFundo] = useState('')
    const [errorFundo, setErrorFundo] = useState(false)



    const load = async () => {
        await checkLogin()
        if (localStorage.getItem('panelJuri') === 'true') {
            await dataAllFundo()
        }
    }

    const login = async () => {
        if (localStorage.getItem('panelJuri') === 'true') {
            if (fundo === '0' || fundo === '') {
                setErrorFundo(true)
                return;
            }
            const resp = await loginAreaAssociado(fundo)
            if (resp.status) {
                setFundo('')
                setErrorFundo(false)
                window.open(`${jsonConfig.urlSandBox}/${resp.data.token}/${resp.data.haveJuri}`, '_blank')
            }
        } else {
            const resp = await loginAreaAssociado()
            if (resp.status) {
                setFundo('')
                setErrorFundo(false)
                window.open(`${jsonConfig.urlSandBox}/${resp.data.token}/${resp.data.haveJuri}`, '_blank')
            }
        }
    }



    useEffect(() => {
        load()
        const dataHora = new Date()
        const hora = dataHora.getHours()
        if (hora >= 1 && hora <= 11) {
            setCumprimento('Bom dia')
        }
        else if (hora >= 12 && hora <= 17) {
            setCumprimento('Boa tarde')
        }
        else if (hora >= 18 && hora <= 23) {
            setCumprimento('Boa noite')
        }
        else {
            setCumprimento('Olá')
        }
        return () => {
            setFundo('')
            setErrorFundo(false)

        }

    }, []);





    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">

                            <div className="py-2 flow-root mb-4">
                                <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                            <div className='p-5'>
                                                <div className="space-y-12 sm:space-y-16">
                                                    <div>
                                                        <div className="rounded-md flex justify-between items-center bg-white">
                                                            <div className="flex flex-1 justify-between items-center">
                                                                <div className="flex flex-col">
                                                                    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                                                        {cumprimento}, {localStorage.getItem('email')}
                                                                    </h1>
                                                                    <div className="flex items-center">
                                                                        <CheckCircleIcon
                                                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Usuário verificado
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2 border rouded-[10px] flex flex-col items-center justify-center">

                                <p className="text-xl font-sans text-gray-900">Acesse aqui á área do associado</p>

                                <div className="flex sm:flex-col flex-col items-center justify-center mt-4  w-[50%]">

                                    {
                                        localStorage.getItem('panelJuri') === 'true' &&
                                        <div className="flex items-start justify-center flex-col mb-4">
                                            <select className="ml-5 p-2 border border-gray-200 rounded-md sm:mt-0 mt-8"
                                                value={fundo}
                                                onChange={(e) => {
                                                    setFundo(e.target.value)
                                                    if (e.target.value === '0') {
                                                        setErrorFundo(true)
                                                    }
                                                    else {
                                                        setErrorFundo(false)
                                                    }
                                                }}
                                            >
                                                <option value="0">Selecione um fundo</option>
                                                {
                                                    states.dataFundo?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {
                                                errorFundo &&
                                                <p className="text-red-500 ml-6 mt-2">É necessário selecionar um fundo</p>
                                            }
                                        </div>
                                    }
                                    <button className="mt-6 w-64 p-2 border border-gray-200 rounded-md hover:bg-gray-200" onClick={login}>Acessar</button>

                                </div>

                            </div>


                        </div>
                    </div>
                </div>

                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}