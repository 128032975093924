import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { AuthContextApi } from "../../Context/api";
import { AuthContext } from "../../Context";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserIcon, PencilSquareIcon, CalculatorIcon, ClipboardDocumentCheckIcon, UserGroupIcon, FlagIcon, ClockIcon, BuildingOfficeIcon, ArrowPathIcon } from '@heroicons/react/24/outline'





const AdminIndex = () => {

    const { checkLogin } = useContext(AuthContextApi)
    const { setters } = useContext(AuthContext)

    async function isSigned() {
        const token = localStorage.getItem('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                localStorage.removeItem('token')
                setters.setSigned(false)
            }

        }
    }

    useEffect(() => {
        isSigned()
    }, [])


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                <h1 className="text-3xl text-center font-bold">Painel Administrativo</h1>
                                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso as áreas administrativas do sistema</p>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3' || localStorage.getItem('permission') == '5') &&
                                    <>
                                        <div className="flex items-center justify-center">
                                            <Link to="/adminIndex/assinaturas" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <PencilSquareIcon className="h-[30%] w-[30%] text-blue-600" />
                                                <h2 className="text-xl font-bold">Assinaturas</h2>
                                                <p className="text-center">Clique nesse cartão para acessar a área de assinaturas</p>
                                            </Link>
                                        </div>

                                        <div className="flex items-center justify-center">
                                            <Link to="/adminIndex/tempSign" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <ClockIcon className="h-[30%] w-[30%] text-blue-600" />
                                                <h2 className="text-xl font-bold text-center">Assinaturas Temporárias</h2>
                                                <p className="text-center">Clique nesse cartão para acessar as assinaturas temporárias</p>
                                            </Link>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <Link to="/adminIndex/associados" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <UserIcon className="h-[30%] w-[30%] text-blue-600" />
                                                <h2 className="text-xl font-bold">Associados</h2>
                                                <p className="text-center">Clique nesse cartão para acessar a área de associados</p>
                                            </Link>
                                        </div>

                                    </>
                                }
                                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') &&
                                    <div className="flex items-center justify-center">
                                        <Link to="/adminIndex/fundos" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <BuildingOfficeIcon className="h-[30%] w-[30%] text-blue-600" />
                                            <h2 className="text-xl font-bold">Fundos</h2>
                                            <p className="text-center">Clique nesse cartão para acessar a área dos fundos de pensão</p>
                                        </Link>
                                    </div>
                                }
                                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') &&
                                    <div className="flex items-center justify-center">
                                        <Link to="/adminIndex/acoesIndex" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <UserGroupIcon className="h-[30%] w-[30%] text-blue-600" />
                                            <h2 className="text-xl font-bold">Ações</h2>
                                            <p className="text-center">Clique nesse cartão para acessar a área de ações</p>
                                        </Link>
                                    </div>
                                }

                                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') &&
                                    <>
                                        <div className="flex items-center justify-center">
                                            <Link to="/adminIndex/negociacoes" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <CalculatorIcon className="h-[30%] w-[30%] text-blue-600" />
                                                <h2 className="text-xl font-bold">Negociações</h2>
                                                <p className="text-center">Clique nesse cartão para acessar a área de negociações</p>
                                            </Link>
                                        </div>
                                    </>
                                }

                                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '1') &&
                                    <div className="flex items-center justify-center">
                                        <Link to="/adminIndex/planos" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <ClipboardDocumentCheckIcon className="h-[30%] w-[30%] text-blue-600" />
                                            <h2 className="text-xl font-bold">Planos</h2>
                                            <p className="text-center">Clique nesse cartão para acessar a área de planos</p>
                                        </Link>
                                    </div>
                                }

                                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1') &&
                                    <>
                                        <div className="flex items-center justify-center">
                                            <Link to="/adminIndex/report" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <FlagIcon className="h-[30%] w-[30%] text-blue-600" />
                                                <h2 className="text-xl font-bold">Relatórios</h2>
                                                <p className="text-center">Clique nesse cartão para acessar a área relatórios</p>
                                            </Link>
                                        </div>

                                        <div className="flex items-center justify-center">
                                            <Link to="/adminIndex/terceirizados" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <UserGroupIcon className="h-[30%] w-[30%] text-blue-600" />
                                                <h2 className="text-xl font-bold">Terceirizados</h2>
                                                <p className="text-center">Clique nesse cartão para acessar a área de terceirizados</p>
                                            </Link>
                                        </div>
                                    </>}
                            </div>
                        </div>
                        <div className="pt-8">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AdminIndex;
