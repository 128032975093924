
import { useEffect, useState } from "react";
import Table from '../../Tabela/TabelaAtualizacoesAcoesJuridico'
import ModalAtualizacao from "../../Modal/AtualizacoesAcao";

const AtualizacaoesJurudicoAcao = ({
    createAtualizacao, dataAtualizacoes, updateAtualizacao,
    deleteAtualizacao, dataAtualizacoesQuery,
    states, setters, dataAcaoProcesso
}) => {


    const headers = ['', 'Tipo', 'Descrição', 'Visibilidade', 'Envio por e-mail', 'Número do processo', 'Nome do processo', 'Escritório Responsável', 'Criador', 'Data de criação', 'Data de atualização']
    const [search, setSearch] = useState('')


    const next = async () => {
        if (states.limitSearchJuriAcoesProcessoAtualizacoes && states.offsetJuriAcoesProcessoAtualizacoes > 0) {
            return;
        } else {
            setters.setOffsetJuriAcoesProcessoAtualizacoes(states.offsetJuriAcoesProcessoAtualizacoes + states.limitJuriAcoesProcessoAtualizacoes)
        }
    };

    const previous = async () => {
        const offset = states.offsetJuriAcoesProcessoAtualizacoes - states.limitJuriAcoesProcessoAtualizacoes
        if (offset < 0) {
            states.offsetJuriAcoesProcessoAtualizacoes < 0 && setters.setOffsetJuriAcoesProcessoAtualizacoes(0)
            return;
        } else {
            setters.setOffsetJuriAcoesProcessoAtualizacoes(states.offsetJuriAcoesProcessoAtualizacoes - states.limitJuriAcoesProcessoAtualizacoes)
        }
    };


    const openModal = async () => {
        await dataAcaoProcesso(states.JuriAcoes.id)
        setters.setIsEditJuri(false)
        setters.setModalAcoesProcessoAtualizacoes(true)
        setters.setJuriProcessoAtualizacoes({})
    }


    const loadData = async () => {
        if (search !== '') {
            await dataAtualizacoesQuery(states.JuriAcoes.id, search)
        } else {

            await dataAtualizacoes(states.JuriAcoes.id)
        }
    }


    const openDefired = async (data) => {
        setters.setJuriProcessoDeferimento(data)
        setters.setModalDeferimento(true)

    }



    const openModalEdit = async (data) => {
        setters.setIsEditJuri(true)
        setters.setJuriAcoesProcessoAtualizacoes(data)
        setters.setModalAcoesProcessoAtualizacoes(true)
    }

    const deleteProcess = async (data) => {
        setters.setIsEditJuri(false)
        setters.setJuriAcoesProcessoAtualizacoes(data)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja excluir a seção: ${data.titleSection}?`)
        setters.setDescModalMsg(`Após a confirmação a seção será excluída da ação.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await deleteAtualizacao(states.JuriAcoes.id, data.id)
            setters.setModalMsg(false)
            if (response) {
                loadData()
            }
        })
        setters.setFunctionBtnModalMsg(() => async () => {
            setters.setModalMsg(false)
        })
    }


    const actionModal = async (data, id) => {
        let resp;
        if (states.isEditJuri) {
            resp = await updateAtualizacao(states.JuriAcoes.id, data, id)
        } else {
            resp = await createAtualizacao(states.JuriAcoes.id, data)
        }
        if (resp) {
            loadData()
        }
        setters.setJuriAcoesProcessoAtualizacoes({})
        setters.setModalAcoesProcessoAtualizacoes(false)

    }

    const loadSearch = async () => {
        if (search === '') {
            loadData()
            return;
        }
        setters.setLimitSearchJuriAcoesProcessoAtualizacoes(false)
        setters.setOffsetJuriAcoesProcessoAtualizacoes(0)
        await dataAtualizacoesQuery(states.JuriAcoes.id, search)
    }

    const loadForm = async () => {
        await dataAcaoProcesso(states.JuriAcoes.id)
    }


    // const [dataJuriAcoesProcessoAtualizacoes, setDataJuriAcoesProcessoAtualizacoes] = useState([])
    // const [JuriAcoesProcessoAtualizacoes, setJuriAcoesProcessoAtualizacoes] = useState({})
    // const [offsetJuriAcoesProcessoAtualizacoes, setOffsetJuriAcoesProcessoAtualizacoes] = useState(0)
    // const [limitJuriAcoesProcessoAtualizacoes, setLimitJuriAcoesProcessoAtualizacoes] = useState(jsonConfig.limitShow)
    // const [limitSearchJuriAcoesProcessoAtualizacoes, setLimitSearchJuriAcoesProcessoAtualizacoes] = useState(false)
    // const [modalAcoesProcessoAtualizacoes, setModalAcoesProcessoAtualizacoes] = useState(false)

    useEffect(() => {
        loadData()

        return () => {
            setSearch('')
            setters.setDataJuriAcoesProcessoForm([]);
            setters.setDataJuriAcoesProcessoAtualizacoes([]);
            setters.setJuriAcoesProcessoAtualizacoes({});
            setters.setOffsetJuriAcoesProcessoAtualizacoes(0);
            setters.setLimitSearchJuriAcoesProcessoAtualizacoes(false);
            setters.setModalAcoesProcessoAtualizacoes(false);
        }

    }, [])

    useEffect(() => {
        if (states.dataJuriAcoesProcessoAtualizacoes.length > 0) loadData()

    }, [states.offsetJuriAcoesProcessoAtualizacoes])

    useEffect(() => {
        if (states.dataJuriAcoesProcessoForm.length > 0) loadForm()
    }, [states.offsetJuriAcoesProcesso])


    return (
        <>
            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por atualizações</h1>
                <div className='flex  flex-col items-center justify-center mt-2 w-full'>
                    <input placeholder='Digite dado da atualização para realizar a busca' type="text" onChange={e => setSearch(e.target.value)}
                        value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                loadSearch()
                            }
                        }}
                    />
                    <div className="flex items-center md:ml-5 p-2">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={loadSearch}>
                            Aplicar
                        </button>
                        {
                            states.isLoading && (
                                <div className="flex items-center justify-center ml-8">
                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Table defired={openDefired} add={openModal} edit={openModalEdit} deleteSection={deleteProcess} title={`Atualizações cadastrados na ação`}
                description={`Registro das atualizações que compõem a ação`} next={next} previous={previous} states={states} headers={headers}
                infoTable={{ offset: states.offsetJuriAcoesProcessoAtualizacoes, limit: states.limitJuriAcoesProcessoAtualizacoes }} dataTable={states.dataJuriAcoesProcessoAtualizacoes} />
            <ModalAtualizacao setters={setters} states={states} action={actionModal} />
        </>
    )
}



export default AtualizacaoesJurudicoAcao;