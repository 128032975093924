const jsonConfig = {

    urlApi: 'https://app.sinprev.org.br/financeiro',
    urlApiJuri: 'https://app.sinprev.org.br/juridico',
    urlAplication: 'https://financeiro.sinprev.org.br',
    urlJuridico: 'http://localhost:3000',
    urlAssociate: 'https://areadoassociado.sinprev.org.br',
    API_KEY: 'Bearer eyJhbGciOiJIUzI1NiJ9.TEFTaGRvYXNuaGRvaXVhc2RvQSY2c2Q5JsOCUypkbmFvc2RodW1waXNhZMOhc2RsYXVzZComOTc2OTdhc2Rhc3VkaDDDoXNkw4RTRE1hczh5blBBc2RQQSZzeWRtcGFzaGQsYXNvZGFzZG5hc2lQQTh1c2RwYXN1PkFzZC5Bc2pkYXNwZCxdfX1dKX19XSlsa2FqaHNnbmRPKiYoJiooN21hc2Q.iVHGvPh9Ch7UyOvd2kd0kfrFnc5mVLLUoCiIEygY5mU',
    token: localStorage.getItem('token'),
    limitShow: 30,
    urlSandBox: "https://sandbox.sinprev.org.br"
}

export { jsonConfig }
