

import { Link } from "react-router-dom";
import { BuildingOffice2Icon, UserGroupIcon, AcademicCapIcon, ArchiveBoxIcon, CheckCircleIcon, InformationCircleIcon, ChevronDoubleUpIcon, BuildingLibraryIcon, InboxStackIcon, ScaleIcon, UsersIcon } from '@heroicons/react/24/outline'
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import moment from 'moment'

const PanelJuri = ({ states, setters, data: dataLoad, role }) => {
    const permission = Cookies.get('roleJuri')
    const [cumprimento, setCumprimento] = useState('')
    const [arrowButton, setArrowButton] = useState(false)


    //git add . && git commit -m "HomePanelJuri" && git push
    useEffect(() => {
        setters.setEmail(localStorage.getItem('email'))
        setters.setPermission(localStorage.getItem('permission'))
        const dataHora = new Date()
        const hora = dataHora.getHours()
        if (hora >= 1 && hora <= 11) {
            setCumprimento('Bom dia')
        }
        else if (hora >= 12 && hora <= 17) {
            setCumprimento('Boa tarde')
        }
        else if (hora >= 18 && hora <= 23) {
            setCumprimento('Boa noite')
        }
        else {
            setCumprimento('Olá')
        }
    }, []);






    return (
        <>
            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow">
                <div className="flex flex-col  w-full">
                    <div className="flex items-center">
                        <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                            {cumprimento}, {states.email}
                        </h1>
                    </div>
                    <div className="mt-3 w-full flex justify-start items-start text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                        <CheckCircleIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                            aria-hidden="true"
                        />
                        <p className="text-gray-900"> Seja bem vindo ao painel jurídico - Permissão <span className="text-blue-500">{Cookies.get('roleJuri') === 'ADMIN' ? 'Administrador' : Cookies.get('roleJuri') === 'LAWYER' ? 'Advogado' : Cookies.get('roleJuri') === 'AUXLAWYER' ? 'Auxiliar Jurídico' : 'Usuário'}</span></p>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {states.dataSystemJuriHome?.statistic?.home?.map((card, index) => {
                    return (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }

                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ?
                                                        (<div className="text-lg font-medium text-red-500">
                                                            {card.value}
                                                        </div>) : (
                                                            <div className="text-lg font-medium text-gray-900">
                                                                {card.value}
                                                            </div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="border mt-5 p-4 rouded-[10px] hover:shadow">
                <div className="rounded-md flex justify-between items-center bg-white">
                    <div className="flex flex-1 justify-between items-center">
                        <div className="flex flex-col">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Paineis do sistema juridico</h2>
                            <div className="flex items-center">
                                <p className="max-w-2xl text-sm leading-6 text-gray-600">
                                    Aqui você encontrará os paineis do sistema jurídico
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex-1 flex bg-white justify-end items-center'>
                        <button
                            className="flex items-center text-gray-600 hover:text-gray-800"
                            onClick={() => setArrowButton(!arrowButton)}
                        >
                            <ChevronDoubleUpIcon
                                className={`${arrowButton ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                            />
                        </button>
                    </div>
                </div>

                {/* {(permission == 'ADMIN' || permission == 'LAWYER') } */}
                {!arrowButton && (
                    <>
                        {
                            Cookies.get('roleJuri') === 'ADMIN' ?
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-5 border">
                                    {/*  */}
                                    <div className="flex items-center justify-center flex-col">
                                        <Link to="/juridicoIndex/usuarios" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                            <UserGroupIcon className="h-[120px] w-[350px] text-blue-600" />
                                        </Link>
                                        <p className="text-center mt-2">Usuarios</p>
                                    </div>
                                    <div className="flex items-center justify-center flex-col">
                                        <Link to="/juridicoIndex/escritorios" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                            <BuildingOffice2Icon className="h-[120px] w-[350px] text-blue-600" />
                                        </Link>
                                        <p className="text-center mt-2">Escritórios</p>
                                    </div>
                                    <div className="flex items-center justify-center flex-col">
                                        <Link to="/juridicoIndex/tribunais" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                            <BuildingLibraryIcon className="h-[120px] w-[350px] text-blue-600" />
                                        </Link>
                                        <p className="text-center mt-2">Tribunais</p>
                                    </div>
                                    <div className="flex items-center justify-center flex-col">
                                        <Link to="/juridicoIndex/acoes" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                            <ArchiveBoxIcon className="h-[120px] w-[350px] text-blue-600" />
                                        </Link>
                                        <p className="text-center mt-2">Ações</p>
                                    </div>
                                </div> : (Cookies.get('roleJuri') === 'LAWYER' || Cookies.get('roleJuri') === 'AUXLAWYER') ?
                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-5 border">
                                        <div className="flex items-center justify-center flex-col">
                                            <Link to="/juridicoIndex/escritorios" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                <BuildingOffice2Icon className="h-[120px] w-[350px] text-blue-600" />
                                            </Link>
                                            <p className="text-center mt-2">Escritórios</p>
                                        </div>
                                        <div className="flex items-center justify-center flex-col">
                                            <Link to="/juridicoIndex/tribunais" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                <BuildingLibraryIcon className="h-[120px] w-[350px] text-blue-600" />
                                            </Link>
                                            <p className="text-center mt-2">Tribunais</p>
                                        </div>
                                        <div className="flex items-center justify-center flex-col">
                                            <Link to="/juridicoIndex/acoes" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                <ArchiveBoxIcon className="h-[120px] w-[350px] text-blue-600" />
                                            </Link>
                                            <p className="text-center mt-2">Ações</p>
                                        </div>
                                    </div> :
                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-2 mt-5 border">
                                        <div className="flex items-center justify-center flex-col">
                                            <Link to="/juridicoIndex/tribunais" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                <ScaleIcon className="h-[120px] w-[350px] text-blue-600" />
                                            </Link>
                                            <p className="text-center mt-2">Tribunais</p>
                                        </div>
                                        <div className="flex items-center justify-center flex-col">
                                            <Link to="/juridicoIndex/acoes" className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                <ArchiveBoxIcon className="h-[120px] w-[350px] text-blue-600" />
                                            </Link>
                                            <p className="text-center mt-2">Ações</p>
                                        </div>
                                    </div>
                        }

                    </>
                )}

            </div>


        </>
    )

}

export default PanelJuri;
