import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import Table from '../../Tabela/TabelaShowModalVaraJuri'
import './index.css'


export default function ModalShow({ states, setters, get }) {
    const [open, setOpen] = useState(true)

    // const [dataJuriVaraProcesso, setDataJuriVaraProcesso] = useState([])
    // const [offsetJuriVaraProcesso, setOffsetJuriVaraProcesso] = useState(0)
    // const [limitJuriVaraProcesso, setLimitJuriVaraProcesso] = useState(jsonConfig.limitShow)
    // const [limitSearchJuriVaraProcesso, setLimitSearchJuriVaraProcesso] = useState(false)
    // const [modalJuriVaraProcesso, setModalJuriVaraProcesso] = useState(false)

    const close = () => {
        setters.setJuriVaras({})
        setters.setDataJuriVaraProcesso([])
        setters.setOffsetJuriVaraProcesso(0)
        setters.setLimitSearchJuriVaraProcesso(false)
        setters.setModalJuriVaraProcesso(false)
        setters.setLimitSearchJuriVaraProcesso(false)
    }

    // 
    const next = async () => {
        if (states.limitSearchJuriVaraProcesso) {
            return
        } else {
            setters.setOffsetJuriVaraProcesso(states.offsetJuriVaraProcesso + states.limitJuriVaraProcesso)
        }
    };
    const previous = async () => {
        if (states.offsetJuriVaraProcesso <= 0) {
            states.offsetJuriVaraProcesso < 0 && setters.setOffsetJuriVaraProcesso(0)
            return
        } else {
            if (states.limitSearchJuriVaraProcesso) setters.setLimitSearchJuriVaraProcesso(false)
            setters.setOffsetJuriVaraProcesso(states.offsetJuriVaraProcesso - states.limitJuriVaraProcesso)
        }

    };


    const load = async () => {
        await get(states.JuriVaras.id)
    }

    useEffect(() => {
        if (states.offsetJuriVaraProcesso >= 0 && states.dataJuriVaraProcesso.length > 0) {
            load()
        }
    }, [states.offsetJuriVaraProcesso])


    const headers = ['Número do processo', 'Nome do Processo', 'Nome da Ação', 'Sujeito Ativo', 'Sujeito Passivo', 'Juíz', 'Escritório responsável', 'Data de criação', 'Data de atualização']

    return (
        <Transition.Root show={states.modalJuriVaraProcesso} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className=" w-[90%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => close()}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Tribunal</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        <span className="text-base font-semibold leading-6 text-gray-900">Ações do tribunal  {states.JuriTribunais?.name}</span>
                                    </p>
                                </div>

                                <div className='pt-10 pl-10 pr-10'>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos ao tribual.</p>
                                            </div>
                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 md:col-span-2">
                                                <div className="sm:col-span-1">
                                                    <div className="flex items-center justify-start">
                                                        <dt className="text-sm font-medium text-gray-500">Nome: </dt>
                                                        <dd className="mt-1 text-sm text-gray-900 ml-2">{states.JuriTribunais?.name}</dd>
                                                    </div>
                                                    <div className="flex items-center justify-start mt-5">
                                                        <dt className="text-sm font-medium text-gray-500">Criação: </dt>
                                                        <dd className="mt-1 text-sm text-gray-900 ml-2">{moment(states.JuriEscritorio?.updated_at).format('DD/MM/YYYY HH:mm:ss')}</dd>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Table
                                        title='Processos inclusos' description={`Registro dos processos vinculadas ao tribunal ${states.JuriTribunais?.name} - na vara ${states.JuriVaras.name}`} next={next} previous={previous} states={states} headers={headers} infoTable={{ offset: states.offsetJuriVaraProcesso, limit: states.limitJuriVaraProcesso }} dataTable={states.dataJuriVaraProcesso} />
                                </div>
                                <div className="mt-5 flex items-center border-t border-gray-900/10 justify-end gap-x-6 mr-5">
                                    <button type="button" className="text-sm  mt-6 font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                        onClick={() => close()}>
                                        Fechar
                                    </button>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

