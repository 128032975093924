import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'



export default function ModalShowAcao(props) {

    const hist = useNavigate()

    useEffect(() => {
        if (props.states.modalAcoes === false) {
            props.setters.setJuriAcoes({})
        }
    }, [props.states.modalAcoes]);

    const goProcessPage = (id) => {
        props.setters.setModalAcoes(false)
        hist(`/juridicoIndex/processo/${id}`)
    }



    return (
        <Transition.Root show={props.states.modalAcoes} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalAcoes(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalAcoes(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Ação</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Informações da ação
                                    </p>
                                </div>
                                <div className='flex flex-col justify-between w-full  mt-5 '>
                                    <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                        <p className="text-sm text-gray-500">Nome:</p>
                                        <p className="text-sm text-gray-900">{props.states.JuriAcoes.name}</p>
                                    </div>

                                    <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] mt-2'>
                                        <p className="text-sm text-gray-500">Ação coletiva:</p>
                                        <p className="text-sm text-gray-900">{
                                            props.states.JuriAcoes.isColetive === 1 ?
                                                <span className='text-green-500'>Sim</span> : <span className='text-red-500'>Não</span>
                                        }</p>
                                    </div>
                                    <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] mt-2'>
                                        <p className="text-sm text-gray-500">Ação Restrita:</p>
                                        <p className="text-sm text-gray-900">{
                                            props.states.JuriAcoes.isRestricted === 1 ?
                                                <span className='text-green-500'>Sim</span> : <span className='text-red-500'>Não</span>
                                        }</p>
                                    </div>

                                    <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] mt-2'>
                                        <p className="text-sm text-gray-500">Criador:</p>
                                        <p className="text-sm text-gray-900">{props.states.JuriAcoes.email}</p>
                                    </div>
                                    {
                                        props.states.JuriAcoes.fundos?.length > 0 && (
                                            <div className='flex flex-col w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] mt-2'>
                                                <p className="text-sm text-gray-500">Fundos:</p>
                                                {
                                                    props.states.JuriAcoes.fundos.map((fundo, index) => {
                                                        return (
                                                            <p key={index} className="text-sm text-gray-900 mt-1">{fundo.name}</p>
                                                        )
                                                    })
                                                }
                                            </div>

                                        )

                                    }
                                    {
                                        props.states.JuriAcoes.legalProcess?.length > 0 && (
                                            <div className='flex flex-col w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] mt-2'>
                                                <p className="text-sm text-gray-500">Processos:</p>
                                                {
                                                    props.states.JuriAcoes.legalProcess.map((fundo, index) => {
                                                        if (fundo !== null) {
                                                            return (
                                                                <div className='flex'>

                                                                    <p key={index} className="text-sm text-gray-900 mt-1">{fundo.name} - {fundo.number}</p>
                                                                    <button onClick={() => goProcessPage(fundo.id)} className='ml-4 text-sm text-blue-500 mt-1 hover:underline'>
                                                                        <MagnifyingGlassIcon className="h-4 w-4 inline" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>

                                        )

                                    }



                                </div>



                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
