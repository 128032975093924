import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'

export default function ModalVaraJuri({ states, setters, action, court_id }) {



    const [name, setName] = useState('')
    const [id, setId] = useState('')

    const [erroName, setErroName] = useState(false)








    const submit = async (e) => {
        e.preventDefault()
        let error = false
        if (name === '') {
            setErroName(true)
            error = true
        } else {
            setErroName(false)
        }
        if (error === true) {
            error = false
            return
        }
        const obj = {
            name: name,
            court_id: court_id
        }
        if (states.isEditJuri === true) {
            obj.id = id
        }
        action(obj)
    }


    useEffect(() => {
        if (states.modalJuriVara === false) {
            setName('')
            setErroName(false)
            setId('')
        } else {
            //JuriTribunais
            if (states.isEditJuri === true) {
                setName(states.JuriVaras?.name)
                setId(states.JuriVaras?.id)
            }

        }
        //Limpeza do modal ao fechar
    }, [states.modalJuriVara]);



    return (
        <Transition.Root show={states.modalJuriVara} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setters.setModalJuriVara(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel
                                className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalJuriVara(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Vara</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Informações da vara, do tribunal - {states.JuriTribunais?.name}
                                    </p>
                                    <p className="flex flex-col items-center mt-1 text-sm text-gray-500 justify-center mb-5">
                                        {states.JuriOutSourced?.emailOutsourced} <br />
                                    </p>

                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={submit}>
                                    <div className="space-y-12">
                                        <div
                                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                                    {states.isEditJuri === false ? 'Cadastrar' : 'Editar'} vara
                                                </h2>
                                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                                    {states.isEditJuri === false ? 'Preencha os campos abaixo para cadastrar uma nova vara' : 'Preencha os campos abaixo para editar a vara cadastrada'}
                                                </p>
                                            </div>

                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                                <div className="sm:col-span-6">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Nome
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={name}
                                                                onChange={e => setName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {erroName === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do nome da vara
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalJuriVara(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            {states.isEditJuri === false ? 'Cadastrar' : 'Editar'}
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
