import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from './index'
import { toast } from 'react-toastify'
import { jsonConfig } from '../Config/index'
import { createContext, useContext } from "react"
import Cookies from 'js-cookie'

export const AuthContextJuri = createContext();

const AuthProviderApiJuri = ({ children }) => {
    const hist = useNavigate()

    const { states, setters } = useContext(AuthContext);

    const sendCode = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/checkAndLog`, req)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            toast.success(response.data.msg)
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
            }
            toast.error(err.response.data.msg)
            setters.setIsLoading(false)
            return false;
        }
    }

    const checkToken = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            await axios.get(`${jsonConfig.urlApiJuri}/checktoken`, req)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            // toast.success(response.data.msg)
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
                setters.setIsSignedJuri(false)
                setters.setInsertCode(false)
                setters.setIsLoading(false)
            }
            toast.error(err.response.data.msg)
            setters.setIsLoading(false)
            return false;
        }
    }

    const checkCode = async (code) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        const body = {
            token: code
        }
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/verify`, body, req)
            Cookies.set('tokenJuri', response.data.token, { expires: 1 })
            Cookies.set('roleJuri', response.data.role, { expires: 1 })
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            toast.success(response.data.msg)
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }

    }

    //Registros de usuarios
    //#region

    const dataSystemUsers = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/users`, req)
            setters.setDataSystemJuriUser(response.data.data.users)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriUser.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const dataUsers = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/registerJudicial/getWithLimit/${states.limitJuriUser}&${states.offsetJuriUser}`, req)
            setters.setDataJuriUser(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriUser.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriUser(false)
                states.offsetJuriUser > 0 && setters.setOffsetJuriUser(states.offsetJuriUser - states.limitJuriUser)
                states.offsetJuriUser < 0 && setters.setOffsetJuriUser(states.offsetJuriUser + states.limitJuriUser)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const loadUsers = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/allUserFinance/registerJudicial`, req)
            setters.setDataJuriUsers(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriUsers.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const createRegister = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/registerJudicial`, body, req)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const getUserRegiserEmail = async (email) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/registerJudicial/${email}`, req)
            setters.setDataJuriUser(response.data.data)
            setters.setModalJuri(false)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const editRegister = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/registerJudicial/${body.id}`, body, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const blockUnlockRegister = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/blockUnlock/registerJudicial//${id}`, {}, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            setters.setIsLoading(false)
            setters.setJuriUser({})
            setters.setConfirmModal(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setConfirmModal(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    //#endregion

    //Advogados 
    //#region
    const dataSystemLawyer = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/lawyer`, req)
            setters.setDataSystemJuriLawyer(response.data.data.lawyer)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriLawyer.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const dataLawyer = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/lawyer/getWithLimit/${states.limitJuriLawyer}&${states.offsetJuriLawyer}`, req)
            setters.setDataJuriLawyer(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriLawyer.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriLawyer(false)
                states.offsetJuriLawyer > 0 && setters.setOffsetJuriLawyer(states.offsetJuriLawyer - states.limitJuriLawyer)
                states.offsetJuriLawyer < 0 && setters.setOffsetJuriLawyer(states.offsetJuriLawyer + states.limitJuriLawyer)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const dataLawyerForm = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/lawyer/getWithLimit/${states.limitJuriLawyer}&${states.offsetJuriLawyer}`, req)
            if (states.dataJuriLawyer.length > 0) {
                setters.setDataJuriLawyer([...states.dataJuriLawyer, ...response.data.data])
            } else {
                setters.setDataJuriLawyer(response.data.data)
            }
            setters.setIsLoading(false)
            states.dataJuriLawyer.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriLawyer(false)
                states.offsetJuriLawyer > 0 && setters.setOffsetJuriLawyer(states.offsetJuriLawyer - states.limitJuriLawyer)
                states.offsetJuriLawyer < 0 && setters.setOffsetJuriLawyer(states.offsetJuriLawyer + states.limitJuriLawyer)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const getLawyerQuery = async (query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/query/lawyer/${query}`, req)
            setters.setDataJuriLawyer(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const loadOutSourcedCompany = async (empresa) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/outsouced/lawyer/${states.limitJuriOutSourced}&${states.offsetJuriOutSourced}/${empresa}`, req)
            if (states.dataJuriOutSourced.length > 0) {
                setters.setDataJuriOutSourced([...states.dataJuriOutSourced, ...response.data.data])
            } else {
                setters.setDataJuriOutSourced(response.data.data)
            }
            // toast.success(response.data.msg)
            setters.setIsLoading(false)
            states.dataJuriOutSourced.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 404) {
                setters.setLimitSearchJuriOutSourced(true)
                if (states.offsetJuriOutSourced > 0) {
                    setters.setDataJuriOutSourced(states.offsetJuriOutSourced - states.limitJuriOutSourced)
                }
                if (states.offsetJuriOutSourced < 0) {
                    setters.setDataJuriOutSourced(states.offsetJuriOutSourced + states.limitJuriOutSourced)
                }
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }


    const createRegisterLawyer = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/lawyer`, body, req)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const updateRegisterLawyer = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/lawyer/${body.id}`, body, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const deleteRegisterLawyer = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/lawyer/${id}`, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            setters.setConfirmModal(false)
            setters.setJuriOutSourced({})
            toast.success(response.data.msg)
        } catch (err) {
            setters.setJuriOutSourced({})
            setters.setConfirmModal(false)
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    const getLegalProcessLawyer = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/lawyer/legalProcesses/getWithLimit/${id}/${states.limitJuriLegalProcess}&${states.offsetJuriLegalProcess}`, req)

            setters.setDataJuriLegalProcess(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriLegalProcess.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) { // Trocar para o 404
                setters.setLimitSearchJuriLegalProcess(false)
                states.offsetJuriLegalProcess > 0 && setters.setOffsetJuriLegalProcess(states.offsetJuriLegalProcess - states.limitJuriLegalProcess)
                states.offsetJuriLegalProcess < 0 && setters.setOffsetJuriLegalProcess(states.offsetJuriLegalProcess + states.limitJuriLegalProcess)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    //#endregion

    //Escritorios
    //#region

    const dataSystemEscritorio = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/lawFirmsRegistry`, req)
            setters.setDataSystemJuriEscritorio(response.data.data.lawFirmsRegistry)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriEscritorio.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const dataEscritorio = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/lawFirmsRegistry/getWithLimit/${states.limitJuriEscritorio}&${states.offsetJuriEscritorio}`, req)
            setters.setDataJuriEscritorio(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriEscritorio.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriEscritorio(false)
                states.offsetJuriEscritorio > 0 && setters.setOffsetJuriEscritorio(states.offsetJuriEscritorio - states.limitJuriEscritorio)
                states.offsetJuriEscritorio < 0 && setters.setOffsetJuriEscritorio(states.offsetJuriEscritorio + states.limitJuriEscritorio)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const dataEscritorioForm = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/lawFirmsRegistry/getWithLimit/${states.limitJuriEscritorio}&${states.offsetJuriEscritorio}`, req)
            if (states.dataJuriEscritorio.length > 0) {
                const dataOutArray = response.data.data.filter((item) => {
                    return states.dataJuriEscritorio.find((item2) => item2.id === item.id) === undefined
                })
                if (dataOutArray.length > 0) {
                    setters.setDataJuriEscritorio([...states.dataJuriEscritorio, ...dataOutArray])
                }
            } else {
                setters.setDataJuriEscritorio(response.data.data)
            }
            setters.setIsLoading(false)
            states.dataJuriEscritorio.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriEscritorio(true)
                states.offsetJuriEscritorio > 0 && setters.setOffsetJuriEscritorio(states.offsetJuriEscritorio - states.limitJuriEscritorio)
                states.offsetJuriEscritorio < 0 && setters.setOffsetJuriEscritorio(states.offsetJuriEscritorio + states.limitJuriEscritorio)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const getEscritoriorQuery = async (query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/query/lawFirmsRegistry/${query}`, req)
            setters.setDataJuriEscritorio(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    const createRegisterEscritorio = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/lawFirmsRegistry`, body, req)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const updateRegisterEscritorio = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/lawFirmsRegistry/${body.id}`, body, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const deleteRegisterEscritorio = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/lawFirmsRegistry/${id}`, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            setters.setConfirmModal(false)
            setters.setJuriOutSourced({})
            toast.success(response.data.msg)
        } catch (err) {
            setters.setJuriOutSourced({})
            setters.setConfirmModal(false)
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    const getLegalActionsFirm = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/firm/${id}/getWithLimit/${states.limitJuriLegalProcess}&${states.offsetJuriLegalProcess}`, req)
            setters.setDataJuriLegalProcess(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriLegalProcess.length === 0 && toast.success(response.data.msg)
            return true;
        } catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) { // Trocar para o 404
                setters.setLimitSearchJuriLegalProcess(false)
                states.offsetJuriLegalProcess > 0 && setters.setOffsetJuriLegalProcess(states.offsetJuriLegalProcess - states.limitJuriLegalProcess)
                states.offsetJuriLegalProcess < 0 && setters.setOffsetJuriLegalProcess(states.offsetJuriLegalProcess + states.limitJuriLegalProcess)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //Processos
    //#region


    const dataSystemProcessos = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/process`, req)
            setters.setDataSystemJuriProcessos(response.data.data.process)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriProcessos.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const dataProcessos = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcesses/getWithLimit/${states.limitJuriProcessos}&${states.offsetJuriProcessos}`, req)
            setters.setDataJuriProcessos(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriProcessos.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriProcessos(false)
                states.offsetJuriProcessos > 0 && setters.setOffsetJuriProcessos(states.offsetJuriProcessos - states.limitJuriProcessos)
                states.offsetJuriProcessos < 0 && setters.setOffsetJuriProcessos(states.offsetJuriProcessos + states.limitJuriProcessos)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const getProcessosrQuery = async (query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcesses/query/${query}`, req)
            setters.setDataJuriProcessos(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const getProcessosById = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcesses/${id}`, req)
            setters.setJuriLegalProcess(response.data.data)
            setters.setIsLoading(false)
            //toast.success(response.data.msg)
            return { status: true, complete: response.data.data.complete }
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const getProcessosShowById = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcesses/showProcess/${id}`, req)
            setters.setJuriLegalProcess(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const createLegalProcess = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalProcesses`, body, req)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return response.data.identifier
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }
    }

    const updateLegalProcess = async (body, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalProcesses/${id}`, body, req)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const lockUnlockLegalProcess = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/visibility/legalProcesses/${id}`, {}, req)
            setters.setModalJuri(false)
            setters.setConfirmModal(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const deleteLegalProcess = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/legalProcesses/${id}`, req)
            setters.setModalJuri(false)
            setters.setConfirmModal(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const createLegalProcessComplete = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'multipart/form-data'
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalProcesses/create`, body, req)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            // return response.data.identifier
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const createLegalProcessCompleteAction = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'multipart/form-data'
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalProcesses/createAction`, body, req)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }


    //#endregion

    //Home
    //#region


    const dataSystemHome = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/home`, req)
            setters.setDataSystemJuriHome(response.data.data)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriProcessos.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    //#endregion

    //ProcessPanel 
    //#region

    const dataSystemHomeProcess = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/processDetail/${id}`, req)
            setters.setDataSystemJuriProcess(response.data.data)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriProcessos.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    //#endregion

    //Deferimentos do processo 
    //#region

    //REMOVER????


    const dataProcessoAssociados = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcessAssociate/getWithLimit/${id}/${states.limitJuriProcessoAssociate}&${states.offsetJuriProcessoAssociate}`, req)
            setters.setDataJuriProcessoAssociate(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriProcessoAssociate.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriProcessoAssociate(false)
                states.offsetJuriProcessoAssociate > 0 && setters.setOffsetJuriProcessoAssociate(states.offsetJuriProcessoAssociate - states.limitJuriProcessoAssociate)
                states.offsetJuriProcessoAssociate < 0 && setters.setOffsetJuriProcessoAssociate(states.offsetJuriProcessoAssociate + states.limitJuriProcessoAssociate)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    const getProcessosAssociado = async (id, query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcessAssociate/${id}/${query}`, req)
            setters.setDataJuriProcessoAssociate(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }



    const dataProcessoSecoes = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcessesSection/getWithLimit/${id}/${states.limitJuriProcessoSecoes}&${states.offsetJuriProcessoSecoes}`, req)
            setters.setDataJuriProcessoSecoes(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriProcessoSecoes.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriProcessoSecoes(false)
                states.offsetJuriProcessoSecoes > 0 && setters.setOffsetJuriProcessoSecoes(states.offsetJuriProcessoSecoes - states.limitJuriProcessoSecoes)
                states.offsetJuriProcessoSecoes < 0 && setters.setOffsetJuriProcessoSecoes(states.offsetJuriProcessoSecoes + states.limitJuriProcessoSecoes)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    const createSecao = async body => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem('token'),
                "authorizationjuri": "Bearer " + Cookies.get('tokenJuri'),
                "Content-Type": "multipart/form-data"
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalProcessesSection`, body, req)
            setters.setIsLoading(false)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }


    const updateSecao = async (body, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem('token'),
                "authorizationjuri": "Bearer " + Cookies.get('tokenJuri'),
                "Content-Type": "multipart/form-data"
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalProcessesSection/${id}`, body, req)
            setters.setIsLoading(false)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const deleteSecao = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                "authorization": "Bearer " + localStorage.getItem('token'),
                "authorizationjuri": "Bearer " + Cookies.get('tokenJuri'),
                "Content-Type": "multipart/form-data"
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/legalProcessesSection/${id}`, req)
            setters.setIsLoading(false)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    //#endregion

    //Autorizacao
    //#region

    const updateAuthorization = async (body, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'multipart/form-data'
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalProcessesAuthorization/${id}`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }


    //#endregion

    //Tribunais
    //#region




    const dataSystemTribunais = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/tribunais`, req)
            setters.setDataSystemJuriTribunais(response.data.data.statistic.court)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriTribunais.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response?.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response?.data.msg)
        }

    }

    const dataSystemTribunal = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/tribunal/${id}`, req)
            setters.setDataSystemJuriTribunal(response.data.data.statistic.court)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriTribunal.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response?.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response?.data.msg)
        }

    }

    const dataTribunal = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/court/getWithLimit/${states.limitJuriTribunais}&${states.offsetJuriTribunais}`, req)
            setters.setDataJuriTribunais(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriTribunais.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriTribunais(false)
                states.offsetJuriTribunais > 0 && setters.setOffsetJuriTribunais(states.offsetJuriTribunais - states.limitJuriTribunais)
                states.offsetJuriTribunais < 0 && setters.setOffsetJuriTribunais(states.offsetJuriTribunais + states.limitJuriTribunais)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const getTribualQuery = async (query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/query/court/${query}/${states.limitJuriTribunais}&${states.offsetJuriTribunais}`, req)
            setters.setDataJuriTribunais(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriTribunais(false)
                states.offsetJuriTribunais > 0 && setters.setOffsetJuriTribunais(states.offsetJuriTribunais - states.limitJuriTribunais)
                states.offsetJuriTribunais < 0 && setters.setOffsetJuriTribunais(states.offsetJuriTribunais + states.limitJuriTribunais)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const dataTribunalId = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/court/${id}`, req)
            setters.setJuriTribunais(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriTribunais(false)
                states.offsetJuriTribunais > 0 && setters.setOffsetJuriTribunais(states.offsetJuriTribunais - states.limitJuriTribunais)
                states.offsetJuriTribunais < 0 && setters.setOffsetJuriTribunais(states.offsetJuriTribunais + states.limitJuriTribunais)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const createRegisterTribunal = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/court`, body, req)
            setters.setModalJuri(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const updateRegisterTribunal = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/court/${body.id}`, body, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const deleteRegisterTribunal = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/court/${id}`, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            setters.setConfirmModal(false)
            setters.setJuriOutSourced({})
            await dataTribunal();
            toast.success(response.data.msg)
        } catch (err) {
            setters.setJuriOutSourced({})
            setters.setConfirmModal(false)
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    const getLegalActionTribunal = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/${id}/getWithLimit/${states.limitJuriAcoes}&${states.offsetJuriAcoes}`, req)
            setters.setDataJuriAcoes(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoes.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriTribunais(false)
                states.offsetJuriAcoes > 0 && setters.setOffsetJuriAcoes(states.offsetJuriAcoes - states.limitJuriAcoes)
                states.offsetJuriAcoes < 0 && setters.setOffsetJuriAcoes(states.offsetJuriAcoes + states.limitJuriAcoes)
                setters.setIsLoading(false)
                toast.error('Dados não encontrados e/ou limite de busca atingido')
                return true

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }


    const dataTribunalForm = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/court/getWithLimit/${states.limitJuriTribunais}&${states.offsetJuriTribunais}`, req)
            if (states.dataJuriTribunais.length > 0) {
                const dataOut = response.data.data.filter(item => states.dataJuriTribunais.find(item2 => item2.id === item.id) === undefined)
                if (dataOut.length > 0) {
                    setters.setDataJuriTribunais([...states.dataJuriTribunais, ...dataOut])
                }
            } else {
                setters.setDataJuriTribunais(response.data.data)
            }
            setters.setIsLoading(false)
            states.dataJuriTribunais.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriTribunais(true)
                states.offsetJuriTribunais > 0 && setters.setOffsetJuriTribunais(states.offsetJuriTribunais - states.limitJuriTribunais)
                states.offsetJuriTribunais < 0 && setters.setOffsetJuriTribunais(states.offsetJuriTribunais + states.limitJuriTribunais)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }



    const dataVara = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/courtSection/getWithLimit/${id}/${states.limitJuriVaras}&${states.offsetJuriVaras}`, req)
            setters.setDataJuriVaras(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriVaras.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriVaras(true)
                states.offsetJuriVaras > 0 && setters.setOffsetJuriVaras(states.offsetJuriVaras - states.limitJuriVaras)
                states.offsetJuriVaras < 0 && setters.setOffsetJuriVaras(states.offsetJuriVaras + states.limitJuriVaras)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const dataVaraAcaoCadastro = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/courtSection/getWithLimit/${id}/${states.limitJuriVaras}&${states.offsetJuriVaras}`, req)
            setters.setDataJuriVaras(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriVaras(true)
                states.offsetJuriVaras > 0 && setters.setOffsetJuriVaras(states.offsetJuriVaras - states.limitJuriVaras)
                states.offsetJuriVaras < 0 && setters.setOffsetJuriVaras(states.offsetJuriVaras + states.limitJuriVaras)

            }
            setters.setIsLoading(false)
            return false
        }
    }

    const dataVaraForm = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/courtSection/getWithLimit/${id}/${states.limitJuriVaras}&${states.offsetJuriVaras}`, req)
            if (states.dataJuriVaras.length > 0) {
                const dataOut = response.data.data.filter(item => states.dataJuriVaras.find(item2 => item2.id === item.id) === undefined)
                if (dataOut.length > 0) {
                    setters.setDataJuriVaras([...states.dataJuriVaras, ...dataOut])
                }
            } else {
                setters.setDataJuriVaras(response.data.data)
            }
            setters.setIsLoading(false)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriVaras(true)
                states.offsetJuriVaras > 0 && setters.setOffsetJuriVaras(states.offsetJuriVaras - states.limitJuriVaras)
                states.offsetJuriVaras < 0 && setters.setOffsetJuriVaras(states.offsetJuriVaras + states.limitJuriVaras)

            }
            setters.setIsLoading(false)
            return false
        }
    }

    const getVaraQuery = async (id, query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/courtSection/getWithLimit/${id}/${query}/${states.limitJuriVaras}&${states.offsetJuriVaras}`, req)
            setters.setDataJuriVaras(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {

            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriVaras(true)
                states.offsetJuriVaras > 0 && setters.setOffsetJuriVaras(states.offsetJuriVaras - states.limitJuriVaras)
                states.offsetJuriVaras < 0 && setters.setOffsetJuriVaras(states.offsetJuriVaras + states.limitJuriVaras)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }



    const getLegalProcessVara = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcesses/${id}/getWithLimit/${states.limitJuriVaraProcesso}&${states.offsetJuriVaraProcesso}`, req)
            setters.setDataJuriVaraProcesso(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriVaraProcesso.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriTribunais(false)
                states.offsetJuriVaraProcesso > 0 && setters.setOffsetJuriVaraProcesso(states.offsetJuriVaraProcesso - states.limitJuriAcoes)
                states.offsetJuriVaraProcesso < 0 && setters.setOffsetJuriVaraProcesso(states.offsetJuriVaraProcesso + states.limitJuriAcoes)
                setters.setIsLoading(false)
                toast.error('Dados não encontrados e/ou limite de busca atingido')
                return true

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const createVara = async (body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/courtSection`, body, req)
            setters.setModalJuriVara(false)
            await dataVara(body.court_id)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const updateVara = async (body, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/courtSection/${id}`, body, req)
            setters.setModalJuriVara(false)
            await dataVara(body.court_id)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const deleteVara = async (court_id, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/courtSection/${id}`, req)
            await dataVara(court_id)
            setters.setJuriAcoes({})
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    //#endregion

    //Ações
    //#region


    const dataSystemAcoes = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/acoes`, req)
            setters.setDataSystemJuriAcoes(response.data.data.statistic.acoes)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriAcoes.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response?.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response?.data.msg)
        }

    }

    const dataAcoes = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/getWithLimit/${states.limitJuriAcoes}&${states.offsetJuriAcoes}`, req)
            setters.setDataJuriAcoes(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoes.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoes(false)
                states.offsetJuriAcoes > 0 && setters.setOffsetJuriAcoes(states.offsetJuriAcoes - states.limitJuriAcoes)
                states.offsetJuriAcoes < 0 && setters.setOffsetJuriAcoes(states.offsetJuriAcoes + states.limitJuriAcoes)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const dataAcoesForm = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/getWithLimit/${states.limitJuriAcoes}&${states.offsetJuriAcoes}`, req)
            if (states.dataJuriAcoes.length > 0) {
                setters.setDataJuriAcoes([...states.dataJuriAcoes, ...response.data.data])

            } else {
                setters.setDataJuriAcoes(response.data.data)
            }
            setters.setIsLoading(false)
            states.dataJuriAcoes.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoes(false)
                states.offsetJuriAcoes > 0 && setters.setOffsetJuriAcoes(states.offsetJuriAcoes - states.limitJuriAcoes)
                states.offsetJuriAcoes < 0 && setters.setOffsetJuriAcoes(states.offsetJuriAcoes + states.limitJuriAcoes)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const dataAcoesQuery = async (query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/query/${query}/getWithLimit/${states.limitJuriAcoes}&${states.offsetJuriAcoes}`, req)
            setters.setDataJuriAcoes(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoes(false)
                states.offsetJuriAcoes > 0 && setters.setOffsetJuriAcoes(states.offsetJuriAcoes - states.limitJuriAcoes)
                states.offsetJuriAcoes < 0 && setters.setOffsetJuriAcoes(states.offsetJuriAcoes + states.limitJuriAcoes)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const dataAcaoById = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/${id}`, req)
            setters.setJuriAcoes(response.data.data)
            setters.setIsLoading(false)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const createAcao = async (body) => {
        setters.setIsLoading(true)
        setters.setOffsetJuriAcoes(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalactions`, body, req)
            setters.setModalJuri(false)
            dataAcoes()
            toast.success(response.data.msg)
            return { status: true, data: response.data.data }
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return { status: false }
        }

    }

    const completeAcao = async (body, id) => {
        setters.setIsLoading(true)
        setters.setOffsetJuriAcoes(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'form-data'
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalactions/complete/${id}`, body, req)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const updateAcao = async (body, id) => {
        setters.setIsLoading(true)
        setters.setOffsetJuriAcoes(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalactions/${id}`, body, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            dataAcoes()
            toast.success(response.data.msg)
        } catch (err) {
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const trocaVaraAcao = async (body, id) => {
        setters.setIsLoading(true)
        setters.setOffsetJuriAcoes(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalactions/changeCourt/${id}`, body, req)
            setters.setModalTrocaDeTribunal(false)
            setters.setIsEditJuri(false)
            dataAcaoById(id)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const deleteAcao = async (id) => {
        setters.setIsLoading(true)
        setters.setOffsetJuriAcoes(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/legalactions/${id}`, req)
            setters.setModalJuri(false)
            setters.setIsEditJuri(false)
            setters.setConfirmModal(false)
            setters.setJuriAcoes({})
            dataAcoes()
            toast.success(response.data.msg)
        } catch (err) {
            setters.setConfirmModal(false)
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    //#endregion

    //#region AcaoProcesso 


    const dataSystemAcaoProcesso = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/acao/${id}`, req)
            setters.setDataSystemJuriAcoesProcesso(response.data.data.statistic.acao)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriAcoesProcesso.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response?.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response?.data.msg)
        }

    }

    const dataAcaoProcesso = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcesses/action/${id}/getWithLimit/${states.limitJuriAcoesProcesso}&${states.offsetJuriAcoesProcesso}`, req)
            setters.setDataJuriAcoesProcesso(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoesProcesso.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoesProcesso(true)
                states.offsetJuriAcoesProcesso > 0 && setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcesso - states.limitJuriAcoesProcesso)
                states.offsetJuriAcoesProcesso < 0 && setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcesso + states.limitJuriAcoesProcesso)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const dataAcaoProcessoForm = async (id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcesses/action/${id}/getWithLimit/${states.limitJuriAcoesProcesso}&${states.offsetJuriAcoesProcesso}`, req)
            if (states.dataJuriAcoesProcessoForm.length > 0) {
                const dataOut = response.data.data.filter(item => states.dataJuriAcoesProcessoForm.find(item2 => item2.id === item.id) === undefined)
                if (dataOut.length > 0) {
                    setters.setDataJuriAcoesProcessoForm([...states.dataJuriAcoesProcessoForm, ...dataOut])
                }
            } else {
                setters.setDataJuriAcoesProcessoForm(response.data.data)
            }
            setters.setIsLoading(false)
            states.dataJuriAcoesProcessoForm.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoesProcesso(true)
                states.offsetJuriAcoesProcesso > 0 && setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcesso - states.limitJuriAcoesProcesso)
                states.offsetJuriAcoesProcesso < 0 && setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcesso + states.limitJuriAcoesProcesso)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    //#endregion

    //#region AcaoProcessoAssociados


    const dataAcaoProcessoAssociados = async (action_id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/associates/${action_id}/getWithLimit/${states.limitJuriAcoesProcessoAssociados}&${states.offsetJuriAcoesProcessoAssociados}`, req)
            setters.setDataJuriAcoesProcessoAssociados(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoesProcessoAssociados.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoesProcessoAssociados(true)
                states.offsetJuriAcoesProcessoAssociados > 0 && setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcessoAssociados - states.limitJuriAcoesProcessoAssociados)
                states.offsetJuriAcoesProcessoAssociados < 0 && setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcessoAssociados + states.limitJuriAcoesProcessoAssociados)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const dataAcaoProcessoAssociadosQuery = async (action_id, query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/associates/${action_id}/${query}/getWithLimit/${states.limitJuriAcoesProcessoAssociados}&${states.offsetJuriAcoesProcessoAssociados}`, req)
            setters.setDataJuriAcoesProcessoAssociados(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoesProcessoAssociados.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoesProcessoAssociados(true)
                states.offsetJuriAcoesProcessoAssociados > 0 && setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcessoAssociados - states.limitJuriAcoesProcessoAssociados)
                states.offsetJuriAcoesProcessoAssociados < 0 && setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcessoAssociados + states.limitJuriAcoesProcessoAssociados)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    //#endregion

    //#region SecaoProcesso

    const dataAcaoSecao = async (action_id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalActionSection/getWithLimit/${action_id}/${states.limitJuriAcoesProcessoSecoes}&${states.offsetJuriAcoesProcessoSecoes}`, req)
            setters.setDataJuriAcoesProcessoSecoes(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoesProcessoSecoes.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoesProcessoSecoes(true)
                states.offsetJuriAcoesProcessoSecoes > 0 && setters.setOffsetJuriAcoesProcessoSecoes(states.offsetJuriAcoesProcessoSecoes - states.limitJuriAcoesProcessoSecoes)
                states.offsetJuriAcoesProcessoSecoes < 0 && setters.setOffsetJuriAcoesProcessoSecoes(states.offsetJuriAcoesProcessoSecoes + states.limitJuriAcoesProcessoSecoes)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const createAcaoSecao = async (action_id, data) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'form-data'
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalActionSection`, data, req)
            await dataAcaoSecao(action_id)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const updateAcaoSecao = async (action_id, data, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'form-data'
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalActionSection/${id}`, data, req)
            await dataAcaoSecao(action_id)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const deleteAcaoSecao = async (action_id, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'form-data'
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/legalActionSection/${id}`, req)
            await dataAcaoSecao(action_id)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    //#endregion

    //#region AtualizacaoAcaoProcesso

    const dataAcaoAtualizacao = async (action_id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcessesUpdate/getWithLimit/action/${action_id}/${states.limitJuriAcoesProcessoAtualizacoes}&${states.offsetJuriAcoesProcessoAtualizacoes}`, req)
            setters.setDataJuriAcoesProcessoAtualizacoes(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoesProcessoAtualizacoes.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoesProcessoSecoes(true)
                states.offsetJuriAcoesProcessoSecoes > 0 && setters.setOffsetJuriAcoesProcessoSecoes(states.offsetJuriAcoesProcessoSecoes - states.limitJuriAcoesProcessoSecoes)
                states.offsetJuriAcoesProcessoSecoes < 0 && setters.setOffsetJuriAcoesProcessoSecoes(states.offsetJuriAcoesProcessoSecoes + states.limitJuriAcoesProcessoSecoes)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const dataAcaoAtualizacaoQuery = async (action_id, query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalProcessesUpdate/action/${action_id}/${query}/${states.limitJuriAcoesProcessoAtualizacoes}&${states.offsetJuriAcoesProcessoAtualizacoes}`, req)
            setters.setDataJuriAcoesProcessoAtualizacoes(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoesProcessoAtualizacoes.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoesProcessoSecoes(true)
                states.offsetJuriAcoesProcessoSecoes > 0 && setters.setOffsetJuriAcoesProcessoSecoes(states.offsetJuriAcoesProcessoSecoes - states.limitJuriAcoesProcessoSecoes)
                states.offsetJuriAcoesProcessoSecoes < 0 && setters.setOffsetJuriAcoesProcessoSecoes(states.offsetJuriAcoesProcessoSecoes + states.limitJuriAcoesProcessoSecoes)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const createAcaoAtualizacao = async (action_id, data) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'form-data'
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalProcessesUpdate`, data, req)
            await dataAcaoAtualizacao(action_id)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const updateAcaoAtualizacao = async (action_id, data, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'form-data'
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalProcessesUpdate/${id}`, data, req)
            await dataAcaoAtualizacao(action_id)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const deleteAcaoAtualizacao = async (action_id, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'form-data'
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/legalProcessesUpdate/${id}`, req)
            await dataAcaoAtualizacao(action_id)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }


    //#endregion

    //#region Deferimentos 


    const dataProcessoDeferimentos = async (action_id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/motions/getWithLimit/${action_id}/${states.limitJuriProcessoDeferimento}&${states.offsetJuriProcessoDeferimento}`, req)
            setters.setDataJuriProcessoDeferimento(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriProcessoDeferimento.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriProcessoDeferimento(false)
                states.offsetJuriProcessoDeferimento > 0 && setters.setOffsetJuriProcessoDeferimento(states.offsetJuriProcessoDeferimento - states.limitJuriProcessoDeferimento)
                states.offsetJuriProcessoDeferimento < 0 && setters.setOffsetJuriProcessoDeferimento(states.offsetJuriProcessoDeferimento + states.limitJuriProcessoDeferimento)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const getProcessosDeferimentosQuery = async (action_id, query) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/motions/${action_id}/${query}/${states.limitJuriProcessoDeferimento}&${states.offsetJuriProcessoDeferimento}`, req)
            setters.setDataJuriProcessoDeferimento(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const createDeferimento = async (action_id, body) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'multipart/form-data'
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/motions`, body, req)
            setters.setModalDeferimento(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            await dataProcessoDeferimentos(action_id)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const updateDeferimento = async (action_id, body, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'multipart/form-data'
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/motions/${id}`, body, req)
            setters.setModalDeferimento(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            await dataProcessoDeferimentos(action_id)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }
    }

    const deleteDeferimento = async (action_id, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/motions/${id}`, req)
            setters.setModalDeferimento(false)
            setters.setIsEditJuri(false)
            await dataProcessoDeferimentos(action_id)
            toast.success(response.data.msg)
        } catch (err) {
            setters.setJuriOutSourced({})
            setters.setConfirmModal(false)
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const actionDeferimento = async (action_id, body, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/motions/deferment/${id}`, body, req)
            setters.setJuriProcessoDeferimento({})
            setters.setModalDeferimentoAction(false)
            setters.setIsEditJuri(false)
            toast.success(response.data.msg)
            setters.setIsLoading(false)
            await dataProcessoDeferimentos(action_id)
        } catch (err) {
            setters.setJuriProcessoDeferimento({})
            setters.setConfirmModal(false)
            setters.setIsEditJuri(false)
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    //#endregion

    //AssociadoProcesso     

    const dataSystemProcessosAssociados = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/dataSystem/processosAssociados`, req)
            setters.setDataSystemJuriAssociadoProcesso(response.data.data.statistic.associados)
            setters.setIsLoading(false)
            setters.setInsertCode(true)
            setters.setIsSignedJuri(true)
            states.dataSystemJuriAcoes.length === 0 && toast.success(response.data.msg)
        } catch (err) {
            if (err.response?.status === 403) {
                hist('/perfil')
                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setInsertCode(false)
            setters.setIsLoading(false)
            toast.error(err.response?.data.msg)
        }

    }

    const dataAssociadoProcessos = async () => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/associate/${states.limitJuriAssociadoProcesso}&${states.offsetJuriAssociadoProcesso}`, req)
            setters.setDataJuriAssociadoProcesso(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAssociadoProcesso.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAssociadoProcesso(false)
                states.offsetJuriAssociadoProcesso > 0 && setters.setOffsetJuriAssociadoProcesso(states.offsetJuriAssociadoProcesso - states.limitJuriAssociadoProcesso)
                states.offsetJuriAssociadoProcesso < 0 && setters.setOffsetJuriAssociadoProcesso(states.offsetJuriAssociadoProcesso + states.limitJuriAssociadoProcesso)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const dataAssociadoProcessosQuery = async (cpf) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactions/cpf/associate/${cpf}`, req)
            setters.setDataJuriAssociadoProcesso(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
        } catch (err) {

            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const dataAssociadoCpf = async (cpf) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/associate/${cpf}`, req)
            setters.setJuriAssociadoProcesso(response.data.data)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setModalJuri(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }

    const associateAuth = async (body) => {
        setters.setIsLoading(true)
        setters.setOffsetJuriAcoes(0)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri'),
                'Content-Type': 'multipart/form-data'
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalProcessAssociate/associate`, body, req)
            setters.setModalAssociadoProcesso(false)
            dataAcoes()
            toast.success(response.data.msg)
            return true
        } catch (err) {
            setters.setModalAssociadoProcesso(false)
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }



    //#endregion

    //#region ArquivosAcao 


    // const [dataJuriAcoesArquivos, setDataJuriAcoesArquivos] = useState([])
    // const [JuriAcoesArquivos, setJuriAcoesArquivos] = useState({})
    // const [offsetJuriAcoesArquivos, setOffsetJuriAcoesArquivos] = useState(0)
    // const [limitJuriAcoesArquivos, setLimitJuriAcoesArquivos] = useState(jsonConfig.limitShow)
    // const [limitSearchJuriAcoesArquivos, setLimitSearchJuriAcoesArquivos] = useState(false)

    const dataAcaoArquivos = async (action_id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.get(`${jsonConfig.urlApiJuri}/legalactionsFiles/getWithLimit/${action_id}/${states.limitJuriAcoesArquivos}&${states.offsetJuriAcoesArquivos}`, req)
            setters.setDataJuriAcoesArquivos(response.data.data)
            setters.setIsLoading(false)
            states.dataJuriAcoesArquivos.length === 0 && toast.success(response.data.msg)
            return true
        } catch (err) {
            //console.log(err)
            if (err.response.status === 403) {
                hist('/perfil')
                setters.setIsSignedJuri(false)

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            if (err.response.status === 400) {
                setters.setLimitSearchJuriAcoesArquivos(true)
                states.offsetJuriAcoesArquivos > 0 && setters.setOffsetJuriAcoesArquivos(states.offsetJuriAcoesArquivos - states.limitJuriAcoesArquivos)
                states.offsetJuriAcoesArquivos < 0 && setters.setOffsetJuriAcoesArquivos(states.offsetJuriAcoesArquivos + states.limitJuriAcoesArquivos)

            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false
        }

    }


    const reprocessActionFile = async (action_id, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.put(`${jsonConfig.urlApiJuri}/legalactionsFiles/reprocess/${id}`, {}, req)
            setters.setModalDeferimento(false)
            setters.setIsEditJuri(false)
            await dataAcaoArquivos(action_id)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const processActionFile = async (action_id, data) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.post(`${jsonConfig.urlApiJuri}/legalactionsFiles`, data, req)
            setters.setModalDeferimento(false)
            setters.setIsEditJuri(false)
            await dataAcaoArquivos(action_id)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }

    const deleteAcaoArquivos = async (action_id, id) => {
        setters.setIsLoading(true)
        const req = {
            headers: {
                "proxyauthorization": jsonConfig.API_KEY,
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'authorizationjuri': 'Bearer ' + Cookies.get('tokenJuri')
            },
        };
        try {
            const response = await axios.delete(`${jsonConfig.urlApiJuri}/legalactionsFiles/${id}`, req)
            setters.setModalDeferimento(false)
            setters.setIsEditJuri(false)
            await dataAcaoArquivos(action_id)
            toast.success(response.data.msg)
        } catch (err) {
            if (err.response.status === 403) {
                hist('/perfil')

                setters.setIsLoading(false)
                Cookies.remove('tokenJuri')
                Cookies.remove('roleJuri')
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
        }

    }


    //#endregion



    return (
        <AuthContextJuri.Provider
            value={{
                processActionFile,
                reprocessActionFile,
                deleteAcaoArquivos,
                dataAcaoArquivos,
                trocaVaraAcao,
                dataAcaoAtualizacaoQuery,
                dataAcaoAtualizacao,
                createAcaoAtualizacao,
                updateAcaoAtualizacao,
                deleteAcaoAtualizacao,
                dataAcaoSecao,
                createAcaoSecao,
                updateAcaoSecao,
                deleteAcaoSecao,
                dataAcaoProcessoAssociados,
                dataAcaoProcessoAssociadosQuery,
                createLegalProcessCompleteAction,
                dataAcaoProcessoForm,
                dataAcaoProcesso,
                dataSystemAcaoProcesso,
                completeAcao,
                dataVaraForm,
                dataVaraAcaoCadastro,
                getLegalProcessVara,
                deleteVara,
                createVara,
                updateVara,
                dataVara,
                getVaraQuery,
                dataSystemTribunal,
                associateAuth,
                dataAssociadoCpf,
                dataSystemProcessosAssociados,
                dataAssociadoProcessos,
                dataAssociadoProcessosQuery,
                dataAcoesForm,
                dataAcaoById,
                createAcao,
                updateAcao,
                deleteAcao,
                dataAcoes,
                dataAcoesQuery,
                dataSystemAcoes,
                dataTribunalForm,
                dataTribunalId,
                dataSystemTribunais,
                dataTribunal,
                getTribualQuery,
                createRegisterTribunal,
                updateRegisterTribunal,
                deleteRegisterTribunal,
                getLegalActionTribunal,
                lockUnlockLegalProcess,
                deleteLegalProcess,
                updateLegalProcess,
                getProcessosShowById,
                deleteSecao,
                updateSecao,
                createSecao,
                updateAuthorization,
                dataProcessoSecoes,
                getProcessosAssociado,
                dataProcessoAssociados,
                actionDeferimento,
                deleteDeferimento,
                updateDeferimento,
                createDeferimento,
                dataProcessoDeferimentos,
                getProcessosDeferimentosQuery,
                dataSystemHomeProcess,
                getProcessosById,
                dataSystemHome,
                dataSystemProcessos,
                dataProcessos,
                getProcessosrQuery,
                dataSystemEscritorio,
                dataEscritorio,
                getEscritoriorQuery,
                createRegisterEscritorio,
                updateRegisterEscritorio,
                deleteRegisterEscritorio,
                getLegalActionsFirm,
                getLegalProcessLawyer,
                deleteRegisterLawyer,
                updateRegisterLawyer,
                loadOutSourcedCompany,
                getLawyerQuery,
                dataSystemLawyer,
                dataLawyer,
                blockUnlockRegister,
                sendCode,
                checkCode,
                checkToken,
                dataSystemUsers,
                dataUsers,
                loadUsers,
                createRegister,
                getUserRegiserEmail,
                editRegister,
                createRegisterLawyer,
                dataEscritorioForm,
                dataLawyerForm,
                createLegalProcess,
                createLegalProcessComplete

            }}>
            {children}
        </AuthContextJuri.Provider>
    )
}

export default AuthProviderApiJuri;