import { CogIcon, HomeIcon, BuildingLibraryIcon, BanknotesIcon, UserCircleIcon, BellAlertIcon, CommandLineIcon, ScaleIcon, SpeakerWaveIcon, BeakerIcon, CalculatorIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { createContext, useState, useRef } from "react";
import { jsonConfig } from '../Config/index'
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Axios from "axios";
import moment from 'moment';
import { useParams } from 'react-router-dom';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    // #region States


    const refNavControlTableLogs = useRef(null)

    const showNavigation = (origin) => {
        const permissao = localStorage.getItem('permission')
        let permission =
            permissao === '0' ? 'Admin' :
                permissao === '1' ? 'Financeiro' :
                    permissao === '2' ? 'Administrativo' :
                        permissao === '3' ? 'Operacional' :
                            permissao === '4' ? 'Tecnico' : ''

        if (permission === '' || permission === undefined || permission === null) {
            return false
        }
        else {
            if (permission === 'Admin') {
                return true
            }
            else if (permission === 'Administrativo') {
                if (origin === 'Administrativo' || origin === 'Alertas') {
                    return true
                }
                else {
                    return false
                }
            }
            else if (permission === 'Financeiro') {
                if (origin === 'Financeiro' || origin === 'Alertas' || origin === 'Administrativo') {
                    return true
                }
                else {
                    return false
                }
            }
            else if (permission === 'Operacional') {
                if (origin === 'Administrativo' || origin === 'Operacional') {
                    return true
                }
                else {
                    return false
                }
            }
            else if (permission === 'Tecnico') {
                if (origin === 'Tecnico') {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        }
    }

    const showSubNavigation = (origin) => {
        const permissao = localStorage.getItem('permission')
        let permission =
            permissao === '0' ? 'Admin' :
                permissao === '1' ? 'Financeiro' :
                    permissao === '2' ? 'Administrativo' :
                        permissao === '3' ? 'Operacional' :
                            permissao === '4' ? 'Tecnico' :
                                permissao === '5' ? 'Comunicacao' : ''

        if (permission === '' || permission === undefined || permission === null) {
            return false
        }
        else {
            if (permission === 'Admin') {
                return true
            }
            if (permission === 'Operacional' || permission === 'Comunicacao') {
                if (origin === 'Painel' || origin === 'Associados' || origin === 'Assinatura Temporaria' || origin === 'Assinaturas' || origin === 'debitosAssociados') {
                    return true
                }
                else {
                    return false
                }
            }
            if (permission === 'Financeiro') {
                if (origin === 'Painel' || origin === 'Associados' || origin === 'Assinatura Temporaria' || origin === 'Assinaturas' || origin === 'Planos' || origin === 'Negociacoes' || origin === 'Aprovacoes' || origin === 'Relatorios'
                    || origin === 'Alertas' || origin === 'Administrativo' || origin === 'Terceirizados' || origin === 'Fundos' || origin === 'debitosAssociados' || origin === 'AssociadosExport' || origin === 'FaturasExport') {
                    return true
                }
                else {
                    return false
                }
            }
            if (permission === 'Administrativo') {
                if (origin === 'Fundos' || origin === 'Painel' || origin === 'Associados' || origin === 'Assinatura Temporaria' || origin === 'Assinaturas' || origin === 'Planos' || origin === 'Negociacoes'
                    || origin === 'Aprovacoes' || origin === 'debitosAssociados' || origin === 'AssociadosExport') {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        }
    }

    const login = async () => {
        const req = {
            headers: { /*"apikey": API_KEY,*/ },
        }
        try {
            const Result = await Axios.post(`http://192.168.0.133:8383/api/user/login`, { email: states.email, password: states.password }, req);
            localStorage.setItem('token', Result.data.data.token);
            localStorage.setItem('user', Result.data.data.user);
            // setEmail('')
            setPassword('')
            setSigned(true)
            return true

        } catch (err) {
            // // 

            return false
        }
    }

    const getNav = () => {

        if (localStorage.getItem('permission') === '0') {
            if (localStorage.getItem('panelJuri') === 'true') {
                return [
                    { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                    { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                    { name: 'Alertas', href: '/alertaIndex', icon: BellAlertIcon, current: false, show: showNavigation('Alertas') },
                    { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Jurídico', href: '/juridicoIndex', icon: ScaleIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Usuários', href: '/usuarioIndex', icon: UserCircleIcon, current: false, show: showNavigation('Usuarios') },
                    { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Técnico', href: '/tecnicoIndex', icon: CommandLineIcon, current: false, show: showNavigation('Tecnico') },
                    { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                    { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }

                ]
            }
            return [
                { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                { name: 'Alertas', href: '/alertaIndex', icon: BellAlertIcon, current: false, show: showNavigation('Alertas') },
                { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Usuários', href: '/usuarioIndex', icon: UserCircleIcon, current: false, show: showNavigation('Usuarios') },
                { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Técnico', href: '/tecnicoIndex', icon: CommandLineIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
            ]

        }
        if (localStorage.getItem('permission') === '1') {
            if (localStorage.getItem('panelJuri') === 'true') {
                return [
                    { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                    { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                    { name: 'Alertas', href: '/alertaIndex', icon: BellAlertIcon, current: false, show: showNavigation('Alertas') },
                    { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Jurídico', href: '/juridicoIndex', icon: ScaleIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Técnico', href: '/tecnicoIndex', icon: CommandLineIcon, current: false, show: showNavigation('Tecnico') },
                    { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                    { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
                ]
            }
            return [
                { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                { name: 'Alertas', href: '/alertaIndex', icon: BellAlertIcon, current: false, show: showNavigation('Alertas') },
                { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Técnico', href: '/tecnicoIndex', icon: CommandLineIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
            ]

        }
        if (localStorage.getItem('permission') === '2') {
            if (localStorage.getItem('panelJuri') === 'true') {
                return [
                    { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                    { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                    { name: 'Alertas', href: '/alertaIndex', icon: BellAlertIcon, current: false, show: showNavigation('Alertas') },
                    { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Jurídico', href: '/juridicoIndex', icon: ScaleIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Técnico', href: '/tecnicoIndex', icon: CommandLineIcon, current: false, show: showNavigation('Tecnico') },
                    { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                    { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
                ]
            }
            return [
                { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                { name: 'Alertas', href: '/alertaIndex', icon: BellAlertIcon, current: false, show: showNavigation('Alertas') },
                { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Técnico', href: '/tecnicoIndex', icon: CommandLineIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
            ]

        }
        if (localStorage.getItem('permission') === '3') {
            if (localStorage.getItem('panelJuri') === 'true') {
                return [
                    { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                    { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                    { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Jurídico', href: '/juridicoIndex', icon: ScaleIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                    { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
                ]
            }
            return [
                { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
            ]

        }
        if (localStorage.getItem('permission') === '4') {
            return [
                { name: 'Técnico', href: '/tecnicoIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
            ]

        }
        if (localStorage.getItem('permission') === '5') {
            if (localStorage.getItem('panelJuri') === 'true') {
                return [
                    { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                    { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                    { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Jurídico', href: '/juridicoIndex', icon: ScaleIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                    { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                    { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
                ]
            }
            return [
                { name: 'Home', href: '/home', icon: HomeIcon, current: false, show: showNavigation('Home') },
                { name: 'Administrativo', href: '/adminIndex', icon: BuildingLibraryIcon, current: false, show: showNavigation('Administrativo') },
                { name: 'Financeiro', href: '/financeIndex', icon: BanknotesIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Contabil', href: '/contabilIndex', icon: CalculatorIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Comunicação', href: '/comunicacaoIndex', icon: SpeakerWaveIcon, current: false, show: showNavigation('Financeiro') },
                { name: 'Projetos', href: '/projetos', icon: BeakerIcon, current: false, show: showNavigation('Tecnico') },
                { name: 'Área do Associado', href: '/areaassociado', icon: UserGroupIcon, current: false, show: showNavigation('Tecnico') }
            ]

        }
    }

    const getNavFaturas = () => {

        if (localStorage.getItem('permission') === '0' || localStorage.getItem('permission') === '1') {
            return [
                { name: 'Painel', href: '/financeIndex/', current: false },
                { name: 'Painel Faturas', href: '/financeIndex/faturas/', current: false },
                { name: 'Faturas de Cobrança', href: '/financeIndex/faturas/cobranca', current: false },
                { name: 'Faturas de Pagamento', href: '/financeIndex/faturas/pagamento', current: false },
            ]
        } else {
            return [
                { name: 'Painel', href: '/financeIndex/', current: false },
                { name: 'Painel Faturas', href: '/financeIndex/faturas/', current: false },
                { name: 'Faturas de Cobrança', href: '/financeIndex/faturas/cobranca', current: false },
            ]
        }
    }

    const getNavImportacao = () => {

        return [
            { name: 'Painel', href: '/financeIndex/importacao', current: false },
            { name: 'Erros de importação', href: '/financeIndex/importacao/errosImportacoes', current: false },
            { name: 'Faturas de Importações', href: '/financeIndex/importacao/faturas', current: false },
            { name: 'Importações', href: '/financeIndex/importacao/importacoes', current: false },
        ]
    }


    const getNavFin = () => {

        if (localStorage.getItem('permission') === '0' || localStorage.getItem('permission') === '1') {
            return [
                { name: 'Painel', href: '/financeIndex/', current: false },
                { name: 'Painel Folha de Pagamento', href: '/financeIndex/folhaPagamento', current: false },
                { name: 'Assinaturas', href: '/financeIndex/folhaPagamento/funcefAssinaturas', current: false },
                { name: 'Remessas', href: '/financeIndex/folhaPagamento/remessa', current: false },
                { name: 'Remessas por Associado', href: '/financeIndex/folhaPagamento/remessaAssociado', current: false },
                { name: 'Remessas com Excedente/Débito', href: '/financeIndex/folhaPagamento/remessaAssociadoTransacao', current: false },
            ]
        }
        if (localStorage.getItem('permission') === '2') {
            return [
                { name: 'Painel', href: '/financeIndex/', current: false },
                { name: 'Painel Folha de Pagamento', href: '/financeIndex/folhaPagamento', current: false },
                { name: 'Assinaturas', href: '/financeIndex/folhaPagamento/funcefAssinaturas', current: false },
            ]
        }
    }

    const getNavJuridico = () => {

        const permission = Cookies.get('roleJuri')

        if (permission === "ADMIN") {
            return [
                { name: 'Painels', href: '/juridicoIndex/', current: false },
                { name: 'Painel de Usuários', href: '/juridicoIndex/usuarios', current: false },
                { name: 'Painel de Escritórios', href: '/juridicoIndex/escritorios', current: false },
                { name: 'Painel de tribunais', href: '/juridicoIndex/tribunais', current: false },
                { name: 'Painel de Ações', href: '/juridicoIndex/acoes', current: false }
            ]
        } else if (Cookies.get('roleJuri') === 'LAWYER' || Cookies.get('roleJuri') === 'AUXLAWYER') {
            return [
                { name: 'Painels', href: '/juridicoIndex/', current: false },
                { name: 'Painel de Escritórios', href: '/juridicoIndex/escritorios', current: false },
                { name: 'Painel de tribunais', href: '/juridicoIndex/tribunais', current: false },
                { name: 'Painel de Ações', href: '/juridicoIndex/acoes', current: false }
            ]

        } else {
            return [
                { name: 'Painel', href: '/juridicoIndex/', current: false },
                { name: 'Painel de tribunais', href: '/juridicoIndex/tribunais', current: false },
                { name: 'Painel de Ações', href: '/juridicoIndex/acoes', current: false }
            ]
        }

    }

    const { id } = useParams();
    const getNavJuridicoProcesso = () => {


        return [
            { name: 'Painel', href: `/juridicoIndex/processo/${id}`, current: false },
            { name: 'Painel de Usuários', href: `/juridicoIndex/processo/${id}/deferimentos`, current: false },
        ]
    }


    const getNavComunicacao = () => {

        if (localStorage.getItem('permission') === '0' || localStorage.getItem('permission') === '5') {
            return [
                { name: 'Painel', href: '/comunicacaoIndex', current: false },
                { name: 'Notícias', href: '/comunicacaoIndex/noticias', current: false },
                { name: 'Perguntas e Respostas', href: '/comunicacaoIndex/faq', current: false },
                { name: 'Associados Registrados', href: '/comunicacaoIndex/associados', current: false },
                { name: 'Associados Logados', href: '/comunicacaoIndex/associadosLogados', current: false },
                { name: 'Link de recuperação e senha', href: '/comunicacaoIndex/recuperacaoSenha', current: false },
            ]

        } else {
            return [
                { name: 'Painel', href: '/comunicacaoIndex', current: false },
                { name: 'Associados Registrados', href: '/comunicacaoIndex/associados', current: false },
                { name: 'Associados Logados', href: '/comunicacaoIndex/associadosLogados', current: false },
                { name: 'Link de recuperação e senha', href: '/comunicacaoIndex/recuperacaoSenha', current: false },
            ]
        }
    }

    const navigation = getNav()
    const navigationFinanceiroFaturas = getNavFaturas();
    const navigationFinanceiroFolha = getNavFin();
    const navigationImportacao = getNavImportacao();
    const navigationJuridico = getNavJuridico();
    const navigationJuridicoProcesso = getNavJuridicoProcesso();

    // navigationAdministrator.sort((a, b) => a.name.localeCompare(b.name));

    const secondaryNavigation = [
        { name: 'Perfil', href: '/perfil', icon: CogIcon, current: false },
    ]

    const navigationAdministrator = [
        { name: 'Painel', href: '/adminIndex', current: false, show: showSubNavigation('Painel') },
        { name: 'Assinaturas', href: '/adminIndex/assinaturas', current: false, show: showSubNavigation('Assinaturas') },
        { name: 'Assinatura Temporária', href: '/adminIndex/tempSign', current: false, show: showSubNavigation('Assinatura Temporaria') },
        { name: 'Associados', href: '/adminIndex/associados', current: false, show: showSubNavigation('Associados') },
        { name: 'Ações', href: '/adminIndex/acoesIndex', current: false, show: showSubNavigation('Aprovacoes') },
        { name: 'Fundos', href: '/adminIndex/fundos', current: false, show: showSubNavigation('Fundos') },
        { name: 'Negociações', href: '/adminIndex/negociacoes', current: false, show: showSubNavigation('Negociacoes') },
        { name: 'Planos', href: '/adminIndex/planos', current: false, show: showSubNavigation('Planos') },
        { name: 'Relatórios', href: '/adminIndex/report', current: false, show: showSubNavigation('Relatorios') },
        { name: 'Terceirizados', href: '/adminIndex/terceirizados', icon: BellAlertIcon, current: false, show: showSubNavigation('Terceirizados') },
    ]

    const navigationContabil = [
        { name: 'Painel', href: '/contabilIndex', current: false, show: showSubNavigation('Painel') },
        { name: 'Débitos', href: '/contabilIndex/debitos', current: false, show: showSubNavigation('debitosAssociados') },
        { name: 'Associados', href: '/contabilIndex/associados', current: false, show: showSubNavigation('AssociadosExport') },
        { name: 'Faturas', href: '/contabilIndex/faturas', current: false, show: showSubNavigation('FaturasExport') },
    ]

    const navigationFinanceiro = [
        { name: 'Painel', href: '/financeIndex', current: false },
        { name: 'Conta', href: '/financeIndex/balanco', current: false },
        { name: 'Custos', href: '/financeIndex/custos', current: false },
        { name: 'Entradas', href: '/financeIndex/entradas', current: false },
        { name: 'Faturas', href: '/financeIndex/faturas', current: false },
        { name: 'Folha de Pagamento', href: '/financeIndex/folhaPagamento', current: false },
        { name: 'Importações', href: '/financeIndex/importacao', current: false },
        { name: 'Produtos de Investimento', href: '/financeIndex/produtosinvestimento', current: false },
        { name: 'Saídas', href: '/financeIndex/saidas', current: false },
    ]

    const navigationUsuarios = [
        { name: 'Painel', href: '/usuarioIndex', current: false },
        { name: 'Férias', href: '/usuarioIndex/feriasIndex', current: false },
        { name: 'Histórico dos Usuários', href: '/usuarioIndex/historicousuarios', current: false },
        { name: 'Ponto Eletrônico', href: '/usuarioIndex/pontoEletronicoIndex', current: false },
        { name: 'Usuários', href: '/usuarioIndex/usuarios', current: false }
    ]

    const navigationFerias = [
        { name: 'Painel', href: '/usuarioIndex', current: false },
        { name: 'Painel Férias', href: '/usuarioIndex/feriasIndex', current: false },
        { name: 'Férias dos Funcionários', href: '/usuarioIndex/feriasIndex/feriasFuncionarios', current: false },
        { name: 'Painel de Férias', href: '/usuarioIndex/feriasIndex/painelFerias', current: false },
        { name: 'Painel de Venda de Férias', href: '/usuarioIndex/feriasIndex/painelVendaFerias', current: false },
    ]

    const navigationPonto = [
        { name: 'Painel', href: '/usuarioIndex', current: false },
        { name: 'Painel Ponto Eletrônico', href: '/usuarioIndex/pontoEletronicoIndex', current: false },
        { name: 'Configuração do Ponto', href: '/usuarioIndex/pontoEletronicoIndex/configuracaoPonto', current: false },
        { name: 'Dias Atrasados', href: '/usuarioIndex/pontoEletronicoIndex/diasAtrasados', current: false },
        { name: 'Dias Trabalhados', href: '/usuarioIndex/pontoEletronicoIndex/diasTrabalhados', current: false },
        { name: 'Solicitações de troca', href: '/usuarioIndex/pontoEletronicoIndex/solicitacaoDeTroca', current: false },
        { name: 'Histórico de troca', href: '/usuarioIndex/pontoEletronicoIndex/historicooDeTroca', current: false }
    ]

    const navigationAlerta = [
        { name: 'Painel', href: '/alertaIndex', current: false },
        { name: 'Alertas Gerais', href: '/alertaIndex/alertas', current: false },
        { name: 'Alerta de Remessa', href: '/alertaIndex/alertasremessa', current: false },
    ]

    const navigationTecnico = [
        { name: 'Painel', href: '/tecnicoIndex', current: false },
        { name: 'Comunicação em Tempo Teal', href: '/tecnicoIndex/hooks', current: false },
        { name: 'Registro de Cron', href: '/tecnicoIndex/registrocron', current: false },
        { name: 'Registro de Logs', href: '/tecnicoIndex/logsSistema', current: false },
        { name: 'Logs Área Associado', href: '/tecnicoIndex/logsSistemaAssociado', current: false },
        { name: 'Logs Jurídico', href: '/tecnicoIndex/logsSistemaJuridico', current: false },
        { name: 'Transações/Associado', href: '/tecnicoIndex/transacaoassociadoremessa', current: false },
        { name: 'Saúde do sistema', href: '/tecnicoIndex/health', current: false },
    ]

    const navigationComunicacao = getNavComunicacao();

    const navigationAcoes = [
        { name: 'Painel', href: '/adminIndex/', current: false },
        { name: 'Painel Ações', href: '/adminIndex/acoesIndex/', current: false },
        { name: 'Aprovações', href: '/adminIndex/acoesIndex/aprove', current: false },
        { name: 'Assinaturas Pendentes', href: '/adminIndex/acoesIndex/assinaturasPendentes', current: false },
        { name: 'Faturas Pendentes', href: '/adminIndex/acoesIndex/faturasPendentes', current: false },
    ]

    const buscarCep = async (cep) => {
        try {
            const { data } = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            if (data.erro === undefined) {
                return { status: true, content: data }
            }
            else {
                return { status: false, msg: 'CEP não encontrado' }
            }
        }
        catch (err) {
            return { status: false, msg: 'Erro: ' + err }
        }
    }

    const validarCpf = (cpf) => {
        var soma = 0;
        var resto;
        var inputCPF = cpf.replaceAll(`-`, ``).replaceAll(`.`, ``).replaceAll(`_`, ``)

        if (inputCPF === '00000000000') return { status: false };

        for (let i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) resto = 0;
        if (resto !== parseInt(inputCPF.substring(9, 10))) return { status: false };

        soma = 0;
        for (let i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) resto = 0;
        if (resto !== parseInt(inputCPF.substring(10, 11))) return { status: false };

        return { status: true };
    }

    function validarCnpj(cnpjBruto) {
        const cnpj = cnpjBruto.replace(/[^\d]+/g, '');

        if (cnpj === '') return { status: false };

        if (cnpj.length !== 14)
            return { status: false };

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999")
            return { status: false };

        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== digitos.charAt(0))
            return { status: false };

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== digitos.charAt(1))
            return { status: false };

        return { status: true };

    }

    const listarFaturas = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/invoices/getWithLimit/${limit}&${offset}`, req);

                setFaturas(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/invoices/${id}`);
                setFaturas(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {

            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarUsuarios = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/users/getWithLimit/${limit}&${offset}`, req);
                setUsuarios(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/users/${id}`);
                setUsuarios(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarPlanos = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/plans/getWithLimit/${limit}&${offset}`, req);
                setPlanos(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/plans/${id}`);
                setPlanos(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarHistoricoUsuarios = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/historyUser/getWithLimit/${limit}&${offset}`, req);
                setHistoricoUsuarios(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/historyUser/${id}`);
                setHistoricoUsuarios(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarAssociados = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/associate/getWithLimit/${limit}&${offset}`, req);
                setAssociados(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/associate/${id}`);
                setAssociados(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarAssinaturas = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/signature/getWithLimit/${limit}&${offset}`, req);
                setAssinaturas(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/signature/${id}`);
                setAssinaturas(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarTerceirizados = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/outsourced/getWithLimit/${limit}&${offset}`, req);

                setTerceirizados(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/outsourced/${id}`);
                setTerceirizados(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarNegociacoes = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/negotiation/getWithLimit/${limit}&${offset}`, req);
                setNegociacoes(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/negotiation/${id}`);
                setNegociacoes(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarAssinaturasAprovadas = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/auth/auth/signature/getWithLimit/${limit}&${offset}`, req);
                setAssinaturasAprovadas(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/auth/auth/signature/${id}`);
                setAssinaturasAprovadas(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarAssinaturasPendentes = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/auth/signature/getWithLimit/${limit}&${offset}`, req);
                setAssinaturasPendentes(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/auth/signature/${id}`);
                setAssinaturasPendentes(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarFaturasAprovadas = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/auth/auth/invoices/getWithLimit/${limit}&${offset}`, req);
                setFaturasAprovadas(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/auth/auth/invoices/${id}`);
                setFaturasAprovadas(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const listarFaturasPendentes = async (id, limit, offset) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem('token') },
        }

        try {
            if (id === '' || id === undefined || id === null) {
                const Result = await Axios.get(`${jsonConfig.urlApi}/auth/invoices/getWithLimit/${limit}&${offset}`, req);
                setFaturasPendentes(Result.data.data)
                return { status: true }
            }
            else {
                const Result = await Axios.get(`${jsonConfig.urlApi}/auth/invoices/${id}`);
                setFaturasPendentes(Result.data.data)
                return { status: true }
            }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const acaoAssinaturasPendentes = async (status, id) => {
        try {
            // setters.setIsLoading(true)
            const req = {

                headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem("token") },
            };
            await Axios.post(`${jsonConfig.urlApi}/auth/auth/signature`, { status: status ? 1 : 0, auth_id: id }, req)
            // toast.success(response.data.msg)

        } catch (err) {

            if (err.response.status === 403) {
                // // localstorage.clear()
                // setters.setSigned(false)
                // toast.error(err.response.data.msg)
                // setters.setIsLoading(false)
            } else {
                // toast.error(err.response.data.msg)
                // setters.setIsLoading(false)
            }
        }
    }

    const acaoFaturasPendentes = async (status, id) => {
        try {
            // setters.setIsLoading(true)
            const req = {

                headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem("token") },
            };
            await Axios.post(`${jsonConfig.urlApi}/auth/auth/invoice`, { status: status ? 1 : 0, auth_id: id }, req)
            // toast.success(response.data.msg)

        } catch (err) {
            if (err.response.status === 403) {
                // // localstorage.clear()
                // setters.setSigned(false)
                // toast.error(err.response.data.msg)
                // setters.setIsLoading(false)
            } else {
                // toast.error(err.response.data.msg)
                // setters.setIsLoading(false)
            }
        }
    }

    const acaoFormularioAssociado = async (id, isEditar, associado) => {
        try {
            setIsLoading(true)
            // setModalIsOpen(false)
            const req = {
                headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem("token") },
            }

            if (!isEditar) {
                await Axios.post(`${jsonConfig.urlApi}/associate`, { ...associado }, req)
                setIsLoading(false)
                return { status: true }
            }
            else {
                await Axios.put(`${jsonConfig.urlApi}/associate/${id}`, { ...associado }, req)
                setIsLoading(false)
                return { status: true }
            }
        }
        catch (err) {
            setIsLoading(false)
            if (err.response.status === 403) {
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                return { status: false, }
            } else {
                toast.error(err.response.data.msg)
                setters.setIsLoading(false)
                return { status: false }
            }
        }
    }

    const listarAssociadosPorCpf = async (cpf) => {
        const req = {
            headers: { "proxyauthorization": jsonConfig.API_KEY, "authorization": "Bearer " + localStorage.getItem("token") },
        }

        try {
            const Result = await Axios.get(`${jsonConfig.urlApi}/associate/cpf/${cpf}`, req);
            setAssociados([Result.data.associate])
            return { status: true }
        }
        catch (err) {
            const statusErro = err.response.status

            if (statusErro === 403) {
                // deslogar
            }
            else // rever ao modificar API os status de erro
            {
                return { status: false, msg: err.response.data.msg }
            }
        }
    }

    const [index, setIndex] = useState(10)
    const [offSet, setOffSet] = useState(0)
    const [faturas, setFaturas] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const [planos, setPlanos] = useState([])
    const [historicoUsuarios, setHistoricoUsuarios] = useState([])
    const [associados, setAssociados] = useState([])
    const [assinaturas, setAssinaturas] = useState([])
    const [terceirizados, setTerceirizados] = useState([])
    const [negociacoes, setNegociacoes] = useState([])
    const [assinaturasAprovadas, setAssinaturasAprovadas] = useState([])
    const [assinaturasPendentes, setAssinaturasPendentes] = useState([])
    const [faturasAprovadas, setFaturasAprovadas] = useState([])
    const [faturasPendentes, setFaturasPendentes] = useState([])
    const [systemAssinaturaFuncef, setSystemAssinaturaFuncef] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)//
    const [modalBaixa, setModalBaixa] = useState(false)//
    const [modalShowTecnico, setModalShowTecnico] = useState(false)//
    const [modalShowPontosRegistrados, setModalShowPontosRegistrados] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [modalTable, setModalTable] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isSuspend, setIsSuspend] = useState(false)
    const [modalFuncef, setModalFuncef] = useState(false)

    const [logData, setLogData] = useState([])
    const [log, setLog] = useState({})//
    const [offsetLog, setOffsetLog] = useState(0)//
    const [limitLog, setLimitLog] = useState(jsonConfig.limitShow)//
    const [limitSearchLog, setLimitSearchLog] = useState(false)//
    const [modalLogIsOpen, setModalLogIsOpen] = useState(false)//

    const [dataSystemHooks, setDataSystemHooks] = useState([])
    const [HooksData, setHooksData] = useState([])
    const [Hooks, setHooks] = useState({})//
    const [offsetHooks, setOffsetHooks] = useState(0)//
    const [limitHooks, setLimitHooks] = useState(jsonConfig.limitShow)//
    const [limitSearchHooks, setLimitSearchHooks] = useState(false)//
    const [modalHooksIsOpen, setModalHooksIsOpen] = useState(false)//

    const [tableLog, setTableLog] = useState([])//
    const [cronData, setCronData] = useState([])
    const [cron, setCron] = useState({})
    const [offsetCron, setOffsetCron] = useState(0)//
    const [limitCron, setLimitCron] = useState(jsonConfig.limitShow)//
    const [limitSearchCron, setLimitSearchCron] = useState(false)//
    const [modalCronIsOpen, setModalCronIsOpen] = useState(false)//
    const [tableCron, setTableCron] = useState([])//
    const [transactionAssociateRemittenceAlerAlertData, settransactionAssociateRemittenceAlerData] = useState([])
    const [transactionAssociateRemittenceAler, settransactionAssociateRemittenceAler] = useState({})
    const [offsettransactionAssociateRemittenceAler, setOffsettransactionAssociateRemittenceAler] = useState(0)
    const [limittransactionAssociateRemittenceAler, setLimittransactionAssociateRemittenceAler] = useState(jsonConfig.limitShow)
    const [limitSearchtransactionAssociateRemittenceAler, setLimitSearchtransactionAssociateRemittenceAler] = useState(false)
    const [modaltransactionAssociateRemittenceAlerIsOpen, setModaltransactionAssociateRemittenceAlerIsOpen] = useState(false)
    const [tabletransactionAssociateRemittenceAler, setTabletransactionAssociateRemittenceAler] = useState([])
    const [alertData, setAlertData] = useState([])
    const [alert, setAlert] = useState({})
    const [offsetAlert, setOffsetAlert] = useState(0)
    const [limitAlert, setLimitAlert] = useState(jsonConfig.limitShow)
    const [limitSearchAlert, setLimitSearchAlert] = useState(false)
    const [modalAlertIsOpen, setModalAlertIsOpen] = useState(false)
    const [tableAlert, setTableAlert] = useState([])
    const [alertRemittenceData, setAlertRemittenceData] = useState([])
    const [alertRemittence, setAlertRemittence] = useState({})
    const [offsetAlertRemittence, setOffsetAlertRemittence] = useState(0)
    const [limitAlertRemittence, setLimitAlertRemittence] = useState(jsonConfig.limitShow)
    const [limitSearchAlertRemittence, setLimitSearchAlertRemittence] = useState(false)
    const [modalAlertRemittenceIsOpen, setModalAlertRemittenceIsOpen] = useState(false)
    const [tableAlertRemittence, setTableAlertRemittence] = useState([])
    const [dataGraphicAccount, setDataGraphicAccount] = useState([])
    const [initialDateGraphicAccount, setInitialDateGraphicAccount] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateGraphicAccount, setFinalDateGraphicAccount] = useState(moment().format('YYYY-MM-DD'))
    const [dataContaExtrato, setDataContaExtrato] = useState([])
    const [contaExtrato, setContaExtrato] = useState({})
    const [offsetContaExtrato, setOffsetContaExtrato] = useState(0)
    const [limitContaExtrato, setLimitContaExtrato] = useState(jsonConfig.limitShow)
    const [limitSearchContaExtrato, setLimitSearchContaExtrato] = useState(false)
    const [tableContaExtrato, setTableContaExtrato] = useState([])
    const [initialDateExtrato, setInitialDateExtrato] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateExtrato, setFinalDateExtrato] = useState(moment().format('YYYY-MM-DD'))
    const [titleReport, setTitleReport] = useState('')
    const [descriptionReport, setDescriptionReport] = useState('')
    const [modalInfo, setModalInfo] = useState(false)
    const [modalInfoTitle, setModalInfoTitle] = useState('')
    const [modalInfoMessage, setModalInfoMessage] = useState('')
    const [error, setError] = useState(false)
    const [systemFolha, setSystemFolha] = useState([])
    const [dataGraphicEntradas, setDataGraphicEntradas] = useState([])
    const [initialDateEntradas, setInitialDateEntradas] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateEntradas, setFinalDateEntradas] = useState(moment().format('YYYY-MM-DD'))
    const [dataEntradas, setDataEntradas] = useState([])
    const [Entradas, setEntradas] = useState({})
    const [offsetEntradas, setOffsetEntradas] = useState(0)
    const [limitEntradas, setLimitEntradas] = useState(jsonConfig.limitShow)
    const [limitSearchEntradas, setLimitSearchEntradas] = useState(false)
    const [tableEntradas, setTableEntradas] = useState([])
    const [dataGraphicSaidas, setDataGraphicSaidas] = useState([])
    const [initialDateSaidas, setInitialDateSaidas] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateSaidas, setFinalDateSaidas] = useState(moment().format('YYYY-MM-DD'))
    const [dataSaidas, setDataSaidas] = useState([])
    const [Saidas, setSaidas] = useState({})
    const [offsetSaidas, setOffsetSaidas] = useState(0)
    const [limitSaidas, setLimitSaidas] = useState(jsonConfig.limitShow)
    const [limitSearchSaidas, setLimitSearchSaidas] = useState(false)
    const [tableSaidas, setTableSaidas] = useState([])
    const [dataGraphicCobranca, setDataGraphicCobranca] = useState([])
    const [dataGraphicCobrancaNotPaid, setDataGraphicCobrancaNotPaid] = useState([])
    const [initialDateCobranca, setInitialDateCobranca] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateCobranca, setFinalDateCobranca] = useState(moment().format('YYYY-MM-DD'))
    const [dataCobranca, setDataCobranca] = useState([])
    const [Cobranca, setCobranca] = useState({})
    const [statusCobranca, setStatusCobranca] = useState('all')
    const [offsetCobranca, setOffsetCobranca] = useState(0)
    const [limitCobranca, setLimitCobranca] = useState(jsonConfig.limitShow)
    const [limitSearchCobranca, setLimitSearchCobranca] = useState(false)
    const [tableCobranca, setTableCobranca] = useState([])
    const [isCanceled, setIsCanceled] = useState(false)
    const [dataSystemCobranca, setDataSystemCobranca] = useState([])
    const [queryCobranca, setQueryCobranca] = useState('')
    const [offsetFiltroCobrancas, setOffsetFiltroCobrancas] = useState(0)
    const [limitFiltroCobrancas, setLimitFiltroCobrancas] = useState(jsonConfig.limitShow)
    const [dataTableFiltroCobrancas, setDataTableFiltroCobrancas] = useState([])
    const [limitSearchFiltroCobrancas, setLimitSearchFiltroCobrancas] = useState(false)
    const [infoFilterCobranca, setInfoFilterCobranca] = useState({})





    //FEATURE SENDO IMPLEMENTADA ACIMA
    const [dataGraphicPagamento, setDataGraphicPagamento] = useState([])
    const [dataGraphicPagamentoNotPaid, setDataGraphicPagamentoNotPaid] = useState([])
    const [initialDatePagamento, setInitialDatePagamento] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDatePagamento, setFinalDatePagamento] = useState(moment().format('YYYY-MM-DD'))
    const [dataPagamento, setDataPagamento] = useState([])
    const [Pagamento, setPagamento] = useState({})
    const [offsetPagamento, setOffsetPagamento] = useState(0)
    const [limitPagamento, setLimitPagamento] = useState(20)
    const [limitSearchPagamento, setLimitSearchPagamento] = useState(false)
    const [tablePagamento, setTablePagamento] = useState([])
    const [dataSystemPagamento, setDataSystemPagamento] = useState([])
    const [queryPagamento, setQueryPagamento] = useState('')
    const [filePagamento, setFilePagamento] = useState(null)
    const [tableConciliacao, setTableConciliacao] = useState([])
    const [sendConc, setSendConc] = useState(false)
    const [dataGraphicRemessa, setDataGraphicRemessa] = useState([])
    const [initialDateRemessa, setInitialDateRemessa] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateRemessa, setFinalDateRemessa] = useState(moment().format('YYYY-MM-DD'))
    const [dataRemessa, setDataRemessa] = useState([])
    const [Remessa, setRemessa] = useState({})
    const [offsetRemessa, setOffsetRemessa] = useState(0)
    const [limitRemessa, setLimitRemessa] = useState(jsonConfig.limitShow)
    const [limitSearchRemessa, setLimitSearchRemessa] = useState(false)
    const [tableRemessa, setTableRemessa] = useState([])
    const [modalRemessa, setModalRemessa] = useState(false)
    const [postRemessa, setPostRemessa] = useState(false)
    const [RemessaFile, setRemessaFile] = useState(null)
    const [dataGraphicRemessaAssociado, setDataGraphicRemessaAssociado] = useState([])
    const [initialDateRemessaAssociado, setInitialDateRemessaAssociado] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateRemessaAssociado, setFinalDateRemessaAssociado] = useState(moment().format('YYYY-MM-DD'))
    const [dataRemessaAssociado, setDataRemessaAssociado] = useState([])
    const [RemessaAssociado, setRemessaAssociado] = useState({})
    const [offsetRemessaAssociado, setOffsetRemessaAssociado] = useState(0)
    const [limitRemessaAssociado, setLimitRemessaAssociado] = useState(jsonConfig.limitShow)
    const [limitSearchRemessaAssociado, setLimitSearchRemessaAssociado] = useState(false)
    const [tableRemessaAssociado, setTableRemessaAssociado] = useState([])
    const [queryRemessaAssociado, setQueryRemessaAssociado] = useState('')
    const [dataGraphicRemessaAssociadoTransacaoSolved, setDataGraphicRemessaAssociadoTransacaoSolved] = useState([])
    const [dataGraphicRemessaAssociadoTransacaoUnsolved, setDataGraphicRemessaAssociadoTransacaoUnsolved] = useState([])
    const [initialDateRemessaAssociadoTransacao, setInitialDateRemessaAssociadoTransacao] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateRemessaAssociadoTransacao, setFinalDateRemessaAssociadoTransacao] = useState(moment().format('YYYY-MM-DD'))
    const [dataRemessaAssociadoTransacao, setDataRemessaAssociadoTransacao] = useState([])
    const [RemessaAssociadoTransacao, setRemessaAssociadoTransacao] = useState({})
    const [offsetRemessaAssociadoTransacao, setOffsetRemessaAssociadoTransacao] = useState(0)
    const [limitRemessaAssociadoTransacao, setLimitRemessaAssociadoTransacao] = useState(jsonConfig.limitShow)
    const [limitSearchRemessaAssociadoTransacao, setLimitSearchRemessaAssociadoTransacao] = useState(false)
    const [tableRemessaAssociadoTransacao, setTableRemessaAssociadoTransacao] = useState([])
    const [querySearchRemessaAssociadoTransacao, setQuerySearchRemessaAssociadoTransacao] = useState('')
    const [dataFuncefAssinatura, setDataFuncefAssinatura] = useState([])
    const [FuncefAssinatura, setFuncefAssinatura] = useState({})
    const [offsetFuncefAssinatura, setOffsetFuncefAssinatura] = useState(0)
    const [limitFuncefAssinatura, setLimitFuncefAssinatura] = useState(jsonConfig.limitShow)
    const [limitSearchFuncefAssinatura, setLimitSearchFuncefAssinatura] = useState(false)
    const [tableFuncefAssinatura, setTableFuncefAssinatura] = useState([])
    const [querySearchFuncefAssinatura, setQuerySearchFuncefAssinatura] = useState('')
    const [terceirizadoFatura, setTerceirizadoFatura] = useState({})
    const [associadoFatura, setAssociadoFatura] = useState({})
    const [codeRegister, setCodeRegister] = useState('')
    const [systemAssociado, setSystemAssociado] = useState([])
    const [dataAssociadoss, setDataAssociadoss] = useState([])
    const [dataAssociadossFatura, setDataAssociadossFatura] = useState([])
    const [dataTerceirizadosFatura, setDataTerceirizadosFatura] = useState([])
    const [dataAssociadossCartoes, setDataAssociadossCartoes] = useState([])
    const [CartaoAssociado, setCartaoAssociado] = useState({})
    const [confirmModalCartao, setConfirmModalCartao] = useState(false)
    const [Associadoss, setAssociadoss] = useState({})
    const [offsetAssociadoss, setOffsetAssociadoss] = useState(0)
    const [limitAssociadoss, setLimitAssociadoss] = useState(jsonConfig.limitShow)
    const [limitSearchAssociadoss, setLimitSearchAssociadoss] = useState(false)
    const [tableAssociadoss, setTableAssociadoss] = useState([])
    const [isFiliate, setIsFiliate] = useState(true)
    const [initialDataAssociados, setInitialDataAssociados] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDataAssociados, setFinalDataAssociados] = useState(moment().format('YYYY-MM-DD'))
    const [modalFiltro, setModalFiltro] = useState(false)
    const [offsetFiltroAssociados, setOffsetFiltroAssociados] = useState(0)
    const [limitFiltroAssociados, setLimitFiltroAssociados] = useState(jsonConfig.limitShow)
    const [dataTableFiltroAssociados, setDataTableFiltroAssociados] = useState([])
    const [limitSearchFiltroAssociados, setLimitSearchFiltroAssociados] = useState(false)
    const [infoFilterAssociado, setInfoFilterAssociado] = useState({})
    const [confirmUnfiliate, setConfirmUnfiliate] = useState(false)
    ///Feature sendo implementada acima
    const [dataGraficAssociadosFundo, setDataGraficAssociadosFundo] = useState([])
    const [dataAssociadosAposentado, setDataAssociadosAposentado] = useState([])
    const [querySearchAssociados, setQuerySearchAssociados] = useState('')
    const [dataQuery, setDataQuery] = useState([])
    const [sumOffset, setSumOffset] = useState(0)
    const [systemPlans, setSystemPlans] = useState([])
    const [dataPlans, setDataPlans] = useState([])
    const [Plans, setPlans] = useState({})
    const [offsetPlans, setOffsetPlans] = useState(0)
    const [limitPlans, setLimitPlans] = useState(jsonConfig.limitShow)
    const [limitSearchPlans, setLimitSearchPlans] = useState(false)
    const [tablePlans, setTablePlans] = useState([])
    const [querySearchPlans, setQuerySearchPlans] = useState('')
    const [systemAssinatura, setSystemAssinatura] = useState([])
    const [dataAssinatura, setDataAssinatura] = useState([])
    const [Assinatura, setAssinatura] = useState({})
    const [offsetAssinatura, setOffsetAssinatura] = useState(0)
    const [limitAssinatura, setLimitAssinatura] = useState(jsonConfig.limitShow)
    const [limitSearchAssinatura, setLimitSearchAssinatura] = useState(false)
    const [tableAssinatura, setTableAssinatura] = useState([])
    const [initialDataAssinatura, setInitialDataAssinatura] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDataAssinatura, setFinalDataAssinatura] = useState(moment().format('YYYY-MM-DD'))
    const [dataGraficAssinaturaMethod, setDataGraficAssinaturaMethod] = useState([])
    const [dataAssinaturaStatus, setDataAssinaturaStatus] = useState([])
    const [querySearchAssinatura, setQuerySearchAssinatura] = useState('')
    const [offsetFiltroAssinaturas, setOffsetFiltroAssinaturas] = useState(0)
    const [limitFiltroAssinaturas, setLimitFiltroAssinaturas] = useState(jsonConfig.limitShow)
    const [dataTableFiltroAssinaturas, setDataTableFiltroAssinaturas] = useState([])
    const [limitSearchFiltroAssinaturas, setLimitSearchFiltroAssinaturas] = useState(false)
    const [infoFilterAssinatura, setInfoFilterAssinatura] = useState({})
    const [dataNegociacao, setDataNegociacao] = useState([])
    const [systemNegoaciacao, setSystemNegoaciacao] = useState([])
    const [Negociacao, setNegociacao] = useState({})
    const [offsetNegociacao, setOffsetNegociacao] = useState(0)
    const [limitNegociacao, setLimitNegociacao] = useState(jsonConfig.limitShow)
    const [limitSearchNegociacao, setLimitSearchNegociacao] = useState(false)
    const [tableNegociacao, setTableNegociacao] = useState([])
    const [querySearchNegociacao, setQuerySearchNegociacao] = useState('')
    const [modalNegociacao, setModalNegociacao] = useState(false)
    const [initialDateNegociacao, setInitialDateNegociacao] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDateNegociacao, setFinalDateNegociacao] = useState(moment().format('YYYY-MM-DD'))
    const [dataGraphicNegociacao, setDataGraphicNegociacao] = useState([])
    const [systemTerceirizado, setSystemTerceirizado] = useState([])
    const [dataTerceirizado, setDataTerceirizado] = useState([])
    const [Terceirizado, setTerceirizado] = useState({})
    const [offsetTerceirizado, setOffsetTerceirizado] = useState(0)
    const [limitTerceirizado, setLimitTerceirizado] = useState(jsonConfig.limitShow)
    const [limitSearchTerceirizado, setLimitSearchTerceirizado] = useState(false)
    const [tableTerceirizado, setTableTerceirizado] = useState([])
    const [querySearchTerceirizado, setQuerySearchTerceirizado] = useState('')
    const [systemAprove, setSystemAprove] = useState([])
    const [dataAprove, setDataAprove] = useState([])
    const [Aprove, setAprove] = useState({})
    const [offsetAprove, setOffsetAprove] = useState(0)
    const [limitAprove, setLimitAprove] = useState(jsonConfig.limitShow)
    const [limitSearchAprove, setLimitSearchAprove] = useState(false)
    const [tableAprove, setTableAprove] = useState([])
    const [querySearchAprove, setQuerySearchAprove] = useState('')
    const [systemRequestAssinatura, setSystemRequestAssinatura] = useState([])
    const [dataRequestAssinatura, setDataRequestAssinatura] = useState([])
    const [RequestAssinatura, setRequestAssinatura] = useState({})
    const [offsetRequestAssinatura, setOffsetRequestAssinatura] = useState(0)
    const [limitRequestAssinatura, setLimitRequestAssinatura] = useState(jsonConfig.limitShow)
    const [limitSearchRequestAssinatura, setLimitSearchRequestAssinatura] = useState(false)
    const [tableRequestAssinatura, setTableRequestAssinatura] = useState([])
    const [querySearchRequestAssinatura, setQuerySearchRequestAssinatura] = useState('')
    const [systemRequestFatura, setSystemRequestFatura] = useState([])
    const [dataRequestFatura, setDataRequestFatura] = useState([])
    const [RequestFatura, setRequestFatura] = useState({})
    const [offsetRequestFatura, setOffsetRequestFatura] = useState(0)
    const [limitRequestFatura, setLimitRequestFatura] = useState(jsonConfig.limitShow)
    const [limitSearchRequestFatura, setLimitSearchRequestFatura] = useState(false)
    const [tableRequestFatura, setTableRequestFatura] = useState([])
    const [querySearchRequestFatura, setQuerySearchRequestFatura] = useState('')
    const [dataSystemAccount, setDataSystemAccount] = useState([])
    const [dataSystemConciliacao, setDataSystemConciliacao] = useState([])
    const [dataSystemAssinaturaTemporaria, setDataSystemAssinaturaTemporaria] = useState([])
    const [dataAssinaturaTemporaria, setDataAssinaturaTemporaria] = useState([])
    const [AssinaturaTemporaria, setAssinaturaTemporaria] = useState({})
    const [offsetAssinaturaTemporaria, setOffsetAssinaturaTemporaria] = useState(0)
    const [limitAssinaturaTemporaria, setLimitAssinaturaTemporaria] = useState(jsonConfig.limitShow)
    const [limitSearchAssinaturaTemporaria, setLimitSearchAssinaturaTemporaria] = useState(false)
    const [tableAssinaturaTemporaria, setTableAssinaturaTemporaria] = useState([])
    const [querySearchAssinaturaTemporaria, setQuerySearchAssinaturaTemporaria] = useState('')
    const [dataSystemRelatorio, setDataSystemRelatorio] = useState([])
    const [dataRelatorio, setDataRelatorio] = useState([])
    const [Relatorio, setRelatorio] = useState({})
    const [offsetRelatorio, setOffsetRelatorio] = useState(0)
    const [limitRelatorio, setLimitRelatorio] = useState(jsonConfig.limitShow)
    const [limitSearchRelatorio, setLimitSearchRelatorio] = useState(false)
    const [tableRelatorio, setTableRelatorio] = useState([])
    const [dataSystemUsers, setDataSystemUsers] = useState([])
    const [dataUsers, setDataUsers] = useState([])
    const [Users, setUsers] = useState({})
    const [offsetUsers, setOffsetUsers] = useState(0)
    const [limitUsers, setLimitUsers] = useState(jsonConfig.limitShow)
    const [limitSearchUsers, setLimitSearchUsers] = useState(false)
    const [queryUsers, setQueryUsers] = useState('')
    const [modalUser, setModalUser] = useState(false)
    const [modalConfiguracaoPonto, setModalConfiguracaoPonto] = useState(false)
    const [dataHistorico, setDataHistorico] = useState([])
    const [Historico, setHistorico] = useState({})
    const [offsetHistorico, setOffsetHistorico] = useState(0)
    const [limitHistorico, setLimitHistorico] = useState(jsonConfig.limitShow)
    const [limitSearchHistorico, setLimitSearchHistorico] = useState(false)
    const [tableHistorico, setTableHistorico] = useState([])
    const [querySearchHistorico, setQuerySearchHistorico] = useState('')
    const [dataSystemHome, setDataSystemHome] = useState([])
    const [systemTecAlert, setSystemTecAlert] = useState([])
    const [modalData, setModalData] = useState(false)
    const [dataCustos, setDataCustos] = useState([])
    const [Custos, setCustos] = useState({})
    const [offsetCustos, setOffsetCustos] = useState(0)
    const [limitCustos, setLimitCustos] = useState(3)
    const [limitSearchCustos, setLimitSearchCustos] = useState(false)
    const [tableCustos, setTableCustos] = useState([])
    const [querySearchCustos, setQuerySearchCustos] = useState('')
    const [initialDataCustos, setInitialDataCustos] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    const [finalDataCustos, setFinalDataCustos] = useState(moment().format('YYYY-MM-DD'))
    const [dataCustosFatura, setDataCustosFatura] = useState([])
    const [CustosFatura, setCustosFatura] = useState({})
    const [offsetCustosFatura, setOffsetCustosFatura] = useState(0)
    const [limitCustosFatura, setLimitCustosFatura] = useState(jsonConfig.limitShow)
    const [limitSearchCustosFatura, setLimitSearchCustosFatura] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [permission, setPermission] = useState('')
    const [signed, setSigned] = useState(false)
    const [remainSigned, setRemainSigned] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(true)
    const [waitTimeout, setWaitTimeOut] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [idAssociadoSelected, setIdAssociadoSelected] = useState('');
    const [idContaSelected, setIdContaSelected] = useState('');
    const [idPlanoSelected, setIdPlanoSelected] = useState('');
    const [token, setToken] = useState('')
    const [dataSystem, setDataSystem] = useState([])

    const [dataInvestimentos, setDataInvestimentos] = useState([])
    const [Investimentos, setInvestimentos] = useState({})
    const [offsetInvestimentos, setOffsetInvestimentos] = useState(0)
    const [limitInvestimentos, setLimitInvestimentos] = useState(jsonConfig.limitShow)
    const [limitSearchInvestimentos, setLimitSearchInvestimentos] = useState(false)
    const [modalAport, setModalAport] = useState(false)

    const [dataAport, setDataAport] = useState([])
    const [Aport, setAport] = useState({})
    const [offsetAport, setOffsetAport] = useState(0)
    const [limitAport, setLimitAport] = useState(jsonConfig.limitShow)
    const [limitSearchAport, setLimitSearchAport] = useState(false)
    const [closeAport, setCloseAport] = useState(false)

    const [dataExtrato, setDataExtrato] = useState([])
    const [Extrato, setExtrato] = useState({})
    const [offsetExtrato, setOffsetExtrato] = useState(0)
    const [limitExtrato, setLimitExtrato] = useState(jsonConfig.limitShow)
    const [limitSearchExtrato, setLimitSearchExtrato] = useState(false)

    const [dataConfiguracaoPonto, setDataConfiguracaoPonto] = useState([])
    const [offsetConfiguracaoPonto, setOffSetConfiguracaoPonto] = useState(0)
    const [limitConfiguracaoPonto, setLimitConfiguracaoPonto] = useState(jsonConfig.limitShow)
    const [limitSearchConfiguracaoPonto, setLimitSearchConfiguracaoPonto] = useState(false)

    const [dataPontosRegistrados, setDataPontosRegistrados] = useState([])
    const [offsetPontosRegistrados, setOffSetPontosRegistrados] = useState(0)
    const [limitPontosRegistrados, setLimitPontosRegistrados] = useState(jsonConfig.limitShow)
    const [limitSearchPontosRegistrados, setLimitSearchPontosRegistrados] = useState(false)

    const [dataDiasTrabalhados, setDataDiasTrabalhados] = useState([])
    const [offsetDiasTrabalhados, setOffSetDiasTrabalhados] = useState(0)
    const [limitDiasTrabalhados, setLimitDiasTrabalhados] = useState(jsonConfig.limitShow)
    const [limitSearchDiasTrabalhados, setLimitSearchDiasTrabalhados] = useState(false)

    const [dataDiasAtrasados, setDataDiasAtrasados] = useState([])
    const [offsetDiasAtrasados, setOffSetDiasAtrasados] = useState(0)
    const [limitDiasAtrasados, setLimitDiasAtrasados] = useState(jsonConfig.limitShow)
    const [limitSearchDiasAtrasados, setLimitSearchDiasAtrasados] = useState(false)

    const [dataPontosUsuario, setDataPontosUsuario] = useState([])
    const [offsetPontosUsuario, setOffSetPontosUsuario] = useState(0)
    const [limitPontosUsuario, setLimitPontosUsuario] = useState(10)
    const [limitSearchPontosUsuario, setLimitSearchPontosUsuario] = useState(false)

    const [diaTrabalhoAberto, setDiaTrabalhoAberto] = useState(false)
    const [idDiaTrabalho, setIdDiaTrabalho] = useState('')
    const [dataUsuarioDiasTrabalho, setDataUsuarioDiasTrabalho] = useState([])
    const [offsetUsuarioDiasTrabalho, setOffSetUsuarioDiasTrabalho] = useState(0)
    const [limitUsuarioDiasTrabalho, setLimitUsuarioDiasTrabalho] = useState(jsonConfig.limitShow)
    const [limitSearchUsuarioDiasTrabalho, setLimitSearchUsuarioDiasTrabalho] = useState(false)

    const [modalPonto, setModalPonto] = useState(false)
    const [titleModalPonto, setTitleModalPonto] = useState('')
    const [descriptionModalPontos, setDescriptionModalPontos] = useState([])

    const [dataFeriasFuncionarios, setDataFeriasFuncionarios] = useState([])
    const [offsetFeriasFuncionarios, setOffSetFeriasFuncionarios] = useState(0)
    const [limitFeriasFuncionarios, setLimitFeriasFuncionarios] = useState(jsonConfig.limitShow)
    const [limitSearchFeriasFuncionarios, setLimitSearchFeriasFuncionarios] = useState(false)

    const [dataPainelFerias, setDataPainelFerias] = useState([])
    const [offsetPainelFerias, setOffSetPainelFerias] = useState(0)
    const [limitPainelFerias, setLimitPainelFerias] = useState(jsonConfig.limitShow)
    const [limitSearchPainelFerias, setLimitSearchPainelFerias] = useState(false)

    const [dataPainelVendaFerias, setDataPainelVendaFerias] = useState([])
    const [offsetPainelVendaFerias, setOffSetPainelVendaFerias] = useState(0)
    const [limitPainelVendaFerias, setLimitPainelVendaFerias] = useState(jsonConfig.limitShow)
    const [limitSearchPainelVendaFerias, setLimitSearchPainelVendaFerias] = useState(false)

    const [modalFeriasFuncionarios, setModalFeriasFuncionarios] = useState(false)
    const [modalPainelFerias, setModalPainelFerias] = useState(false)

    const [dataSystemImport, setDataSystemImport] = useState([])

    const [dataImport, setDataImport] = useState([])
    const [Import, setImport] = useState({})
    const [offsetImport, setOffsetImport] = useState(0)
    const [limitImport, setLimitImport] = useState(jsonConfig.limitShow)
    const [limitSearchImport, setLimitSearchImport] = useState(false)
    const [modalImport, setModalImport] = useState(false)

    const [dataSystemImportFaturas, setDataSystemImportFaturas] = useState([])
    const [dataImportFaturas, setDataImportFaturas] = useState([])
    const [ImportFaturas, setImportFaturas] = useState({})
    const [offsetImportFaturas, setOffsetImportFaturas] = useState(0)
    const [limitImportFaturas, setLimitImportFaturas] = useState(jsonConfig.limitShow)
    const [limitSearchImportFaturas, setLimitSearchImportFaturas] = useState(false)
    const [modalImportFaturas, setModalImportFaturas] = useState(false)

    const [dataImportFalhas, setDataImportFalhas] = useState([])
    const [ImportFalhas, setImportFalhas] = useState({})
    const [offsetImportFalhas, setOffsetImportFalhas] = useState(0)
    const [limitImportFalhas, setLimitImportFalhas] = useState(jsonConfig.limitShow)
    const [limitSearchImportFalhas, setLimitSearchImportFalhas] = useState(false)
    const [modalImportFalhas, setModalImportFalhas] = useState(false)

    const [dataAssinaturaExtrato, setDataAssinaturaExtrato] = useState([])
    const [AssinaturaExtrato, setAssinaturaExtrato] = useState({})
    const [offsetAssinaturaExtrato, setOffsetAssinaturaExtrato] = useState(0)
    const [limitAssinaturaExtrato, setLimitAssinaturaExtrato] = useState(10)
    const [limitSearchAssinaturaExtrato, setLimitSearchAssinaturaExtrato] = useState(false)
    //Aqui deve ser falso
    const [modalAssinaturaExtrato, setModalAssinaturaExtrato] = useState(false)

    const [dataSystemHealth, setDataSystemHealth] = useState([])
    const [dataHealth, setDataHealth] = useState([])
    const [Health, setHealth] = useState({})
    const [offsetHealth, setOffsetHealth] = useState(0)
    const [limitHealth, setLimitHealth] = useState(jsonConfig.limitShow)
    const [limitSearchHealth, setLimitSearchHealth] = useState(false)
    const [modalHealth, setModalHealth] = useState(false)
    const [modalHealthConfirm, setModalHealthConfirm] = useState(false)

    const [dataHealthRegister, setDataHealthRegister] = useState([])
    const [HealthRegister, setHealthRegister] = useState({})
    const [offsetHealthRegister, setOffsetHealthRegister] = useState(0)
    const [limitHealthRegister, setLimitHealthRegister] = useState(10)
    const [limitSearchHealthRegister, setLimitSearchHealthRegister] = useState(false)

    const [dataAssinaturaCard, setDataAssinaturaCard] = useState([])
    const [offsetAssinaturaCard, setOffsetAssinaturaCard] = useState(0)
    const [limitAssinaturaCard, setLimitAssinaturaCard] = useState(10)
    const [limitSearchAssinaturaCard, setLimitSearchAssinaturaCard] = useState(false)

    const [debitByFundo, setDebitByFundo] = useState([])


    const [insertCode, setInsertCode] = useState(false)
    const [isSignedJuri, setIsSignedJuri] = useState(false)

    const [dataSystemJuriUser, setDataSystemJuriUser] = useState([])
    const [dataJuriUser, setDataJuriUser] = useState([])
    const [dataJuriUsers, setDataJuriUsers] = useState([])
    const [JuriUser, setJuriUser] = useState({})
    const [offsetJuriUser, setOffsetJuriUser] = useState(0)
    const [limitJuriUser, setLimitJuriUser] = useState(jsonConfig.limitShow)
    const [limitSearchJuriUser, setLimitSearchJuriUser] = useState(false)

    const [dataSystemJuriLawyer, setDataSystemJuriLawyer] = useState([])
    const [dataJuriLawyer, setDataJuriLawyer] = useState([])
    const [JuriLawyer, setJuriLawyer] = useState({})
    const [offsetJuriLawyer, setOffsetJuriLawyer] = useState(0)
    const [limitJuriLawyer, setLimitJuriLawyer] = useState(jsonConfig.limitShow)
    const [limitSearchJuriLawyer, setLimitSearchJuriLawyer] = useState(false)


    const [dataJuriOutSourced, setDataJuriOutSourced] = useState([])
    const [offsetJuriOutSourced, setOfsettJuriOutSourced] = useState(0)
    const [JuriOutSourced, setJuriOutSourced] = useState({})
    const [limitJuriOutSourced, setLimitJuriOutSourced] = useState(8)
    const [limitSearchJuriOutSourced, setLimitSearchJuriOutSourced] = useState(false)

    const [modalJuri, setModalJuri] = useState(false)
    const [isEditJuri, setIsEditJuri] = useState(true)


    const [dataSystemJuriLegalProcess, setDataSystemJuriLegalProcess] = useState([])
    const [dataJuriLegalProcess, setDataJuriLegalProcess] = useState([])
    const [JuriLegalProcess, setJuriLegalProcess] = useState({})
    const [offsetJuriLegalProcess, setOffsetJuriLegalProcess] = useState(0)
    const [limitJuriLegalProcess, setLimitJuriLegalProcess] = useState(jsonConfig.limitShow)
    const [limitSearchJuriLegalProcess, setLimitSearchJuriLegalProcess] = useState(false)
    const [modalJuriLegalProcess, setModalJuriLegalProcess] = useState(false)


    const [dataSystemJuriEscritorio, setDataSystemJuriEscritorio] = useState([])
    const [dataJuriEscritorio, setDataJuriEscritorio] = useState([])
    const [JuriEscritorio, setJuriEscritorio] = useState({})
    const [offsetJuriEscritorio, setOffsetJuriEscritorio] = useState(0)
    const [limitJuriEscritorio, setLimitJuriEscritorio] = useState(jsonConfig.limitShow)
    const [limitSearchJuriEscritorio, setLimitSearchJuriEscritorio] = useState(false)

    const [dataSystemJuriProcessos, setDataSystemJuriProcessos] = useState([])
    const [dataJuriProcessos, setDataJuriProcessos] = useState([])
    const [JuriProcessos, setJuriProcessos] = useState({})
    const [offsetJuriProcessos, setOffsetJuriProcessos] = useState(0)
    const [limitJuriProcessos, setLimitJuriProcessos] = useState(jsonConfig.limitShow)
    const [limitSearchJuriProcessos, setLimitSearchJuriProcessos] = useState(false)

    const [dataSystemJuriHome, setDataSystemJuriHome] = useState([])

    const [dataSystemJuriProcess, setDataSystemJuriProcess] = useState([])

    //setDataSystemJuriProcess
    const [dataSystemJuriProcessoDeferimento, setDataSystemJuriProcessoDeferimento] = useState([])
    const [dataJuriProcessoDeferimento, setDataJuriProcessoDeferimento] = useState([])
    const [JuriProcessoDeferimento, setJuriProcessoDeferimento] = useState({})
    const [offsetJuriProcessoDeferimento, setOffsetJuriProcessoDeferimento] = useState(0)
    const [limitJuriProcessoDeferimento, setLimitJuriProcessoDeferimento] = useState(jsonConfig.limitShow)
    const [limitSearchJuriProcessoDeferimento, setLimitSearchJuriProcessoDeferimento] = useState(false)
    const [modalDeferimento, setModalDeferimento] = useState(false)
    const [modalDeferimentoAction, setModalDeferimentoAction] = useState(false)

    const [dataSystemJuriProcessoAssociate, setDataSystemJuriProcessoAssociate] = useState([])
    const [dataJuriProcessoAssociate, setDataJuriProcessoAssociate] = useState([])
    const [JuriProcessoAssociate, setJuriProcessoAssociate] = useState({})
    const [offsetJuriProcessoAssociate, setOffsetJuriProcessoAssociate] = useState(0)
    const [limitJuriProcessoAssociate, setLimitJuriProcessoAssociate] = useState(jsonConfig.limitShow)
    const [limitSearchJuriProcessoAssociate, setLimitSearchJuriProcessoAssociate] = useState(false)

    const [dataSystemJuriProcessoAtualizacoes, setDataSystemJuriProcessoAtualizacoes] = useState([])
    const [dataJuriProcessoAtualizacoes, setDataJuriProcessoAtualizacoes] = useState([])
    const [JuriProcessoAtualizacoes, setJuriProcessoAtualizacoes] = useState({})
    const [offsetJuriProcessoAtualizacoes, setOffsetJuriProcessoAtualizacoes] = useState(0)
    const [limitJuriProcessoAtualizacoes, setLimitJuriProcessoAtualizacoes] = useState(jsonConfig.limitShow)
    const [limitSearchJuriProcessoAtualizacoes, setLimitSearchJuriProcessoAtualizacoes] = useState(false)

    const [dataSystemJuriProcessoSecoes, setDataSystemJuriProcessoSecoes] = useState([])
    const [dataJuriProcessoSecoes, setDataJuriProcessoSecoes] = useState([])
    const [JuriProcessoSecoes, setJuriProcessoSecoes] = useState({})
    const [offsetJuriProcessoSecoes, setOffsetJuriProcessoSecoes] = useState(0)
    const [limitJuriProcessoSecoes, setLimitJuriProcessoSecoes] = useState(jsonConfig.limitShow)
    const [limitSearchJuriProcessoSecoes, setLimitSearchJuriProcessoSecoes] = useState(false)

    const [JuriProcessoAuth, setJuriProcessoAuth] = useState({})

    //Tribunais 
    const [dataSystemJuriTribunais, setDataSystemJuriTribunais] = useState([])
    const [dataSystemJuriTribunal, setDataSystemJuriTribunal] = useState([])
    const [dataJuriTribunais, setDataJuriTribunais] = useState([])
    const [JuriTribunais, setJuriTribunais] = useState({})
    const [offsetJuriTribunais, setOffsetJuriTribunais] = useState(0)
    const [limitJuriTribunais, setLimitJuriTribunais] = useState(jsonConfig.limitShow)
    const [limitSearchJuriTribunais, setLimitSearchJuriTribunais] = useState(false)


    //Varas
    const [dataJuriVaras, setDataJuriVaras] = useState([])
    const [JuriVaras, setJuriVaras] = useState({})
    const [offsetJuriVaras, setOffsetJuriVaras] = useState(0)
    const [limitJuriVaras, setLimitJuriVaras] = useState(jsonConfig.limitShow)
    const [limitSearchJuriVaras, setLimitSearchJuriVaras] = useState(false)
    const [modalJuriVara, setModalJuriVara] = useState(false)

    //Varas processos
    const [dataJuriVaraProcesso, setDataJuriVaraProcesso] = useState([])
    const [offsetJuriVaraProcesso, setOffsetJuriVaraProcesso] = useState(0)
    const [limitJuriVaraProcesso, setLimitJuriVaraProcesso] = useState(jsonConfig.limitShow)
    const [limitSearchJuriVaraProcesso, setLimitSearchJuriVaraProcesso] = useState(false)
    const [modalJuriVaraProcesso, setModalJuriVaraProcesso] = useState(false)

    const [dataSystemFundo, setDataSystemFundo] = useState([])
    const [dataFundo, setDataFundo] = useState([])
    const [Fundo, setFundo] = useState({})
    const [offsetFundo, setOffsetFundo] = useState(0)
    const [limitFundo, setLimitFundo] = useState(jsonConfig.limitShow)
    const [limitSearchFundo, setLimitSearchFundo] = useState(false)

    //Acoes 
    const [dataSystemJuriAcoes, setDataSystemJuriAcoes] = useState([])
    const [dataJuriAcoes, setDataJuriAcoes] = useState([])
    const [JuriAcoes, setJuriAcoes] = useState({})
    const [offsetJuriAcoes, setOffsetJuriAcoes] = useState(0)
    const [limitJuriAcoes, setLimitJuriAcoes] = useState(jsonConfig.limitShow)
    const [limitSearchJuriAcoes, setLimitSearchJuriAcoes] = useState(false)
    const [modalAcoes, setModalAcoes] = useState(false)

    const [dataSystemJuriAcoesProcesso, setDataSystemJuriAcoesProcesso] = useState([])
    const [dataJuriAcoesProcessoForm, setDataJuriAcoesProcessoForm] = useState([])
    const [dataJuriAcoesProcesso, setDataJuriAcoesProcesso] = useState([])
    const [JuriAcoesProcesso, setJuriAcoesProcesso] = useState({})
    const [offsetJuriAcoesProcesso, setOffsetJuriAcoesProcesso] = useState(0)
    const [limitJuriAcoesProcesso, setLimitJuriAcoesProcesso] = useState(jsonConfig.limitShow)
    const [limitSearchJuriAcoesProcesso, setLimitSearchJuriAcoesProcesso] = useState(false)
    const [modalAcoesProcesso, setModalAcoesProcesso] = useState(false)

    const [dataJuriAcoesProcessoAssociados, setDataJuriAcoesProcessoAssociados] = useState([])
    const [JuriAcoesProcessoAssociados, setJuriAcoesProcessoAssociados] = useState({})
    const [offsetJuriAcoesProcessoAssociados, setOffsetJuriAcoesProcessoAssociados] = useState(0)
    const [limitJuriAcoesProcessoAssociados, setLimitJuriAcoesProcessoAssociados] = useState(jsonConfig.limitShow)
    const [limitSearchJuriAcoesProcessoAssociados, setLimitSearchJuriAcoesProcessoAssociados] = useState(false)

    const [dataJuriAcoesProcessoSecoes, setDataJuriAcoesProcessoSecoes] = useState([])
    const [JuriAcoesProcessoSecoes, setJuriAcoesProcessoSecoes] = useState({})
    const [offsetJuriAcoesProcessoSecoes, setOffsetJuriAcoesProcessoSecoes] = useState(0)
    const [limitJuriAcoesProcessoSecoes, setLimitJuriAcoesProcessoSecoes] = useState(jsonConfig.limitShow)
    const [limitSearchJuriAcoesProcessoSecoes, setLimitSearchJuriAcoesProcessoSecoes] = useState(false)
    const [modalAcoesProcessoSecoes, setModalAcoesProcessoSecoes] = useState(false)

    const [dataJuriAcoesProcessoAtualizacoes, setDataJuriAcoesProcessoAtualizacoes] = useState([])
    const [JuriAcoesProcessoAtualizacoes, setJuriAcoesProcessoAtualizacoes] = useState({})
    const [offsetJuriAcoesProcessoAtualizacoes, setOffsetJuriAcoesProcessoAtualizacoes] = useState(0)
    const [limitJuriAcoesProcessoAtualizacoes, setLimitJuriAcoesProcessoAtualizacoes] = useState(jsonConfig.limitShow)
    const [limitSearchJuriAcoesProcessoAtualizacoes, setLimitSearchJuriAcoesProcessoAtualizacoes] = useState(false)
    const [modalAcoesProcessoAtualizacoes, setModalAcoesProcessoAtualizacoes] = useState(false)


    //AssociadoProcesso 
    const [dataSystemJuriAssociadoProcesso, setDataSystemJuriAssociadoProcesso] = useState([])
    const [dataJuriAssociadoProcesso, setDataJuriAssociadoProcesso] = useState([])
    const [JuriAssociadoProcesso, setJuriAssociadoProcesso] = useState({})
    const [offsetJuriAssociadoProcesso, setOffsetJuriAssociadoProcesso] = useState(0)
    const [limitJuriAssociadoProcesso, setLimitJuriAssociadoProcesso] = useState(jsonConfig.limitShow)
    const [limitSearchJuriAssociadoProcesso, setLimitSearchJuriAssociadoProcesso] = useState(false)
    const [modalAssociadoProcesso, setModalAssociadoProcesso] = useState(false)



    const [dataNoticiasComunicacao, setDataNoticiasComunicacao] = useState([])
    const [NoticiasComunicacao, setNoticiasComunicacao] = useState({})
    const [offsetNoticiasComunicacao, setOffsetNoticiasComunicacao] = useState(0)
    const [limitNoticiasComunicacao, setLimitNoticiasComunicacao] = useState(jsonConfig.limitShow)
    const [limitSearchNoticiasComunicacao, setLimitSearchNoticiasComunicacao] = useState(false)
    const [modalNoticiasComunicacao, setModalNoticiasComunicacao] = useState(false)
    const [totalNoticiasComunicacao, setTotalNoticiasComunicacao] = useState(0)

    const [dataFaqsComunicacao, setDataFaqsComunicacao] = useState([])
    const [FaqsComunicacao, setFaqsComunicacao] = useState({})
    const [totalFaqsComunicacao, setTotalFaqsComunicacao] = useState(0)
    const [modalFaqComunicacao, setModalFaqComunicacao] = useState(false)

    const [dataAssociateComunicacao, setDataAssociateComunicacao] = useState([])
    const [AssociateComunicacao, setAssociateComunicacao] = useState({})
    const [offsetAssociateComunicacao, setOffsetAssociateComunicacao] = useState(0)
    const [limitAssociateComunicacao, setLimitAssociateComunicacao] = useState(jsonConfig.limitShow)
    const [limitSearchAssociateComunicacao, setLimitSearchAssociateComunicacao] = useState(false)
    const [totalAssociateComunicacao, setTotalAssociateComunicacao] = useState(0)

    const [totalLogs, setTotalLogs] = useState(0)

    const [dataSystemProjetos, setDataSystemProjetos] = useState([])
    const [dataProjetos, setDataProjetos] = useState([])
    const [Projetos, setProjetos] = useState({})
    const [offsetProjetos, setOffsetProjetos] = useState(0)
    const [limitProjetos, setLimitProjetos] = useState(jsonConfig.limitShow)
    const [limitSearchProjetos, setLimitSearchProjetos] = useState(false)
    const [dataInicialProjetos, setDataInicialProjetos] = useState('')
    const [dataFinalProjetos, setDataFinalProjetos] = useState('')
    const [queryProjetos, setQueryProjetos] = useState('')
    const [modalProjetos, setModalProjetos] = useState(false)
    const [modalApprove, setModalApprove] = useState(false)
    const [modalInit, setModalInit] = useState(false)
    const [modalAddStep, setModalAddStep] = useState(false)
    const [modalApproveProject, setModalApproveProject] = useState(false)

    const [modalEditPontos, setModalEditPontos] = useState(false)
    const [ponto, setPonto] = useState({})


    const [dataSystemRequisicoesAtualizacaoPonto, setDataSystemRequisicoesAtualizacaoPonto] = useState([])
    const [dataRequisicoesAtualizacaoPonto, setDataRequisicoesAtualizacaoPonto] = useState([])
    const [RequisicoesAtualizacaoPonto, setRequisicoesAtualizacaoPonto] = useState({})
    const [offsetRequisicoesAtualizacaoPonto, setOffsetRequisicoesAtualizacaoPonto] = useState(0)
    const [limitRequisicoesAtualizacaoPonto, setLimitRequisicoesAtualizacaoPonto] = useState(jsonConfig.limitShow)
    const [limitSearchRequisicoesAtualizacaoPonto, setLimitSearchRequisicoesAtualizacaoPonto] = useState(false)
    const [modalRequisicoesAtualizacaoPonto, setModalRequisicoesAtualizacaoPonto] = useState(false)

    const [dataHistoricoAtualizacaoPonto, setDataHistoricoAtualizacaoPonto] = useState([])
    const [HistoricoAtualizacaoPonto, setHistoricoAtualizacaoPonto] = useState({})
    const [offsetHistoricoAtualizacaoPonto, setOffsetHistoricoAtualizacaoPonto] = useState(0)
    const [limitHistoricoAtualizacaoPonto, setLimitHistoricoAtualizacaoPonto] = useState(jsonConfig.limitShow)
    const [limitSearchHistoricoAtualizacaoPonto, setLimitSearchHistoricoAtualizacaoPonto] = useState(false)
    const [modalHistoricoAtualizacaoPonto, setModalHistoricoAtualizacaoPonto] = useState(false)

    const [modalMsg, setModalMsg] = useState(false)
    const [msgModalMsg, setMsgModalMsg] = useState('')
    const [descModalMsg, setDescModalMsg] = useState('')
    const [titleButtonModalMsg, setTitleButtonModalMsg] = useState('Retornar')
    const [titleButtonCloseModalMsg, setTitleButtonCloseModalMsg] = useState('Fechar')
    const [perigoModalMsg, setPerigoModalMsg] = useState(false)
    const [functionModalMsg, setFunctionModalMsg] = useState(() => { })
    const [functionBtnModalMsg, setFunctionBtnModalMsg] = useState(() => { })

    const [modalTrocaDeTribunal, setModalTrocaDeTribunal] = useState(false)


    const [dataJuriAcoesArquivos, setDataJuriAcoesArquivos] = useState([])
    const [JuriAcoesArquivos, setJuriAcoesArquivos] = useState({})
    const [offsetJuriAcoesArquivos, setOffsetJuriAcoesArquivos] = useState(0)
    const [limitJuriAcoesArquivos, setLimitJuriAcoesArquivos] = useState(jsonConfig.limitShow)
    const [limitSearchJuriAcoesArquivos, setLimitSearchJuriAcoesArquivos] = useState(false)

    const [dataSystemDebitos, setDataSystemDebitos] = useState([])
    const [dataDebitos, setDataDebitos] = useState([])
    const [Debitos, setDebitos] = useState({})
    const [offsetDebitos, setOffsetDebitos] = useState(0)
    const [limitDebitos, setLimitDebitos] = useState(jsonConfig.limitShow)
    const [limitSearchDebitos, setLimitSearchDebitos] = useState(false)
    const [modalDebitos, setModalDebitos] = useState(false)

    const [dataSystemAssociateStatus, setDataSystemAssociateStatus] = useState([])
    const [dataAssociateStatus, setDataAssociateStatus] = useState([])
    const [AssociateStatus, setAssociateStatus] = useState({})
    const [offsetAssociateStatus, setOffsetAssociateStatus] = useState(0)
    const [limitAssociateStatus, setLimitAssociateStatus] = useState(jsonConfig.limitShow)
    const [limitSearchAssociateStatus, setLimitSearchAssociateStatus] = useState(false)
    const [modalAssociateStatus, setModalAssociateStatus] = useState(false)

    const [dataSystemBalanco, setDataSystemBalanco] = useState([])
    const [modalBalanco, setModalBalanco] = useState(false)
    const [initial_dateBalanco, setInitial_dateBalanco] = useState('')
    const [final_dateBalanco, setFinal_dateBalanco] = useState('')


    const states =
    {
        initial_dateBalanco,
        final_dateBalanco,
        dataSystemBalanco,
        modalBalanco,
        dataSystemAssociateStatus,
        dataAssociateStatus,
        AssociateStatus,
        offsetAssociateStatus,
        limitAssociateStatus,
        limitSearchAssociateStatus,
        modalAssociateStatus,
        modalDebitos,
        dataSystemDebitos,
        dataDebitos,
        Debitos,
        offsetDebitos,
        limitDebitos,
        limitSearchDebitos,
        dataJuriAcoesArquivos,
        JuriAcoesArquivos,
        offsetJuriAcoesArquivos,
        limitJuriAcoesArquivos,
        limitSearchJuriAcoesArquivos,
        modalTrocaDeTribunal,
        modalDeferimentoAction,
        dataJuriAcoesProcessoAtualizacoes,
        JuriAcoesProcessoAtualizacoes,
        offsetJuriAcoesProcessoAtualizacoes,
        limitJuriAcoesProcessoAtualizacoes,
        limitSearchJuriAcoesProcessoAtualizacoes,
        modalAcoesProcessoAtualizacoes,
        modalMsg,
        msgModalMsg,
        descModalMsg,
        titleButtonModalMsg,
        titleButtonCloseModalMsg,
        perigoModalMsg,
        functionModalMsg,
        functionBtnModalMsg,
        dataJuriAcoesProcessoSecoes,
        JuriAcoesProcessoSecoes,
        offsetJuriAcoesProcessoSecoes,
        limitJuriAcoesProcessoSecoes,
        limitSearchJuriAcoesProcessoSecoes,
        modalAcoesProcessoSecoes,
        dataJuriAcoesProcessoAssociados,
        JuriAcoesProcessoAssociados,
        offsetJuriAcoesProcessoAssociados,
        limitJuriAcoesProcessoAssociados,
        limitSearchJuriAcoesProcessoAssociados,
        dataSystemJuriAcoesProcesso,
        dataJuriAcoesProcessoForm,
        dataJuriAcoesProcesso,
        JuriAcoesProcesso,
        offsetJuriAcoesProcesso,
        limitJuriAcoesProcesso,
        limitSearchJuriAcoesProcesso,
        modalAcoesProcesso,
        dataJuriVaraProcesso,
        offsetJuriVaraProcesso,
        limitJuriVaraProcesso,
        limitSearchJuriVaraProcesso,
        modalJuriVaraProcesso,
        modalJuriVara,
        dataJuriVaras,
        JuriVaras,
        offsetJuriVaras,
        limitJuriVaras,
        limitSearchJuriVaras,
        dataSystemJuriTribunal,
        dataHistoricoAtualizacaoPonto,
        HistoricoAtualizacaoPonto,
        offsetHistoricoAtualizacaoPonto,
        limitHistoricoAtualizacaoPonto,
        limitSearchHistoricoAtualizacaoPonto,
        modalHistoricoAtualizacaoPonto,
        dataSystemRequisicoesAtualizacaoPonto,
        dataRequisicoesAtualizacaoPonto,
        RequisicoesAtualizacaoPonto,
        offsetRequisicoesAtualizacaoPonto,
        limitRequisicoesAtualizacaoPonto,
        limitSearchRequisicoesAtualizacaoPonto,
        modalRequisicoesAtualizacaoPonto,
        ponto,
        modalEditPontos,
        modalApproveProject,
        modalAddStep,
        modalInit,
        modalApprove,
        modalProjetos,
        dataSystemProjetos,
        dataProjetos,
        Projetos,
        offsetProjetos,
        limitProjetos,
        limitSearchProjetos,
        dataInicialProjetos,
        dataFinalProjetos,
        queryProjetos,
        totalLogs,
        totalAssociateComunicacao,
        dataAssociateComunicacao,
        AssociateComunicacao,
        offsetAssociateComunicacao,
        limitAssociateComunicacao,
        limitSearchAssociateComunicacao,
        FaqsComunicacao,
        modalFaqComunicacao,
        totalFaqsComunicacao,
        dataFaqsComunicacao,
        totalNoticiasComunicacao,
        dataNoticiasComunicacao,
        NoticiasComunicacao,
        offsetNoticiasComunicacao,
        limitNoticiasComunicacao,
        limitSearchNoticiasComunicacao,
        modalNoticiasComunicacao,
        modalAssociadoProcesso,
        dataSystemJuriAssociadoProcesso,
        dataJuriAssociadoProcesso,
        JuriAssociadoProcesso,
        offsetJuriAssociadoProcesso,
        limitJuriAssociadoProcesso,
        limitSearchJuriAssociadoProcesso,
        modalAcoes,
        dataSystemJuriAcoes,
        dataJuriAcoes,
        JuriAcoes,
        offsetJuriAcoes,
        limitJuriAcoes,
        limitSearchJuriAcoes,
        dataSystemFundo,
        dataFundo,
        Fundo,
        offsetFundo,
        limitFundo,
        limitSearchFundo,
        dataSystemJuriTribunais,
        dataJuriTribunais,
        JuriTribunais,
        offsetJuriTribunais,
        limitJuriTribunais,
        limitSearchJuriTribunais,
        JuriProcessoAuth,
        dataSystemJuriProcessoSecoes,
        dataJuriProcessoSecoes,
        JuriProcessoSecoes,
        offsetJuriProcessoSecoes,
        limitJuriProcessoSecoes,
        limitSearchJuriProcessoSecoes,
        dataSystemJuriProcessoAtualizacoes,
        dataJuriProcessoAtualizacoes,
        JuriProcessoAtualizacoes,
        offsetJuriProcessoAtualizacoes,
        limitJuriProcessoAtualizacoes,
        limitSearchJuriProcessoAtualizacoes,
        dataSystemJuriProcessoAssociate,
        dataJuriProcessoAssociate,
        JuriProcessoAssociate,
        offsetJuriProcessoAssociate,
        limitJuriProcessoAssociate,
        limitSearchJuriProcessoAssociate,
        modalDeferimento,
        dataSystemJuriProcessoDeferimento,
        dataJuriProcessoDeferimento,
        JuriProcessoDeferimento,
        offsetJuriProcessoDeferimento,
        limitJuriProcessoDeferimento,
        limitSearchJuriProcessoDeferimento,
        navigationJuridicoProcesso,
        dataSystemJuriProcess,
        dataSystemJuriHome,
        dataSystemJuriProcessos,
        dataJuriProcessos,
        JuriProcessos,
        offsetJuriProcessos,
        limitJuriProcessos,
        limitSearchJuriProcessos,
        dataSystemJuriEscritorio,
        dataJuriEscritorio,
        JuriEscritorio,
        offsetJuriEscritorio,
        limitJuriEscritorio,
        limitSearchJuriEscritorio,
        modalJuriLegalProcess,
        dataSystemJuriLegalProcess,
        dataJuriLegalProcess,
        JuriLegalProcess,
        offsetJuriLegalProcess,
        limitJuriLegalProcess,
        limitSearchJuriLegalProcess,
        JuriOutSourced,
        limitSearchJuriOutSourced,
        limitJuriOutSourced,
        offsetJuriOutSourced,
        dataSystemJuriLawyer,
        dataJuriLawyer,
        dataJuriOutSourced,
        JuriLawyer,
        offsetJuriLawyer,
        limitJuriLawyer,
        limitSearchJuriLawyer,
        isEditJuri,
        dataJuriUsers,
        modalJuri,
        dataJuriUser,
        JuriUser,
        offsetJuriUser,
        dataSystemJuriUser,
        limitJuriUser,
        limitSearchJuriUser,
        navigationJuridico,
        isSignedJuri,
        insertCode,
        debitByFundo,
        dataAssinaturaCard,
        offsetAssinaturaCard,
        limitAssinaturaCard,
        limitSearchAssinaturaCard,
        modalHealthConfirm,
        offsetHealthRegister,
        limitHealthRegister,
        limitSearchHealthRegister,
        dataHealthRegister,
        HealthRegister,
        dataSystemHealth,
        dataHealth,
        Health,
        offsetHealth,
        limitHealth,
        limitSearchHealth,
        modalHealth,
        dataAssinaturaExtrato,
        AssinaturaExtrato,
        offsetAssinaturaExtrato,
        limitAssinaturaExtrato,
        limitSearchAssinaturaExtrato,
        modalAssinaturaExtrato,
        dataImportFalhas,
        ImportFalhas,
        offsetImportFalhas,
        limitImportFalhas,
        limitSearchImportFalhas,
        modalImportFalhas,
        dataImportFaturas,
        ImportFaturas,
        offsetImportFaturas,
        limitImportFaturas,
        limitSearchImportFaturas,
        modalImportFaturas,
        dataSystemImportFaturas,
        modalImport,
        dataImport,
        Import,
        offsetImport,
        limitImport,
        limitSearchImport,
        dataSystemImport,
        diaTrabalhoAberto,
        idDiaTrabalho,
        dataUsuarioDiasTrabalho,
        offsetUsuarioDiasTrabalho,
        limitUsuarioDiasTrabalho,
        limitSearchUsuarioDiasTrabalho,
        limitSearchFiltroAssociados,
        offsetFiltroAssociados,
        limitFiltroAssociados,
        modalFiltro,
        dataFeriasFuncionarios,
        offsetFeriasFuncionarios,
        limitFeriasFuncionarios,
        limitSearchFeriasFuncionarios,
        dataPainelFerias,
        offsetPainelFerias,
        limitPainelFerias,
        limitSearchPainelFerias,
        dataPainelVendaFerias,
        offsetPainelVendaFerias,
        limitPainelVendaFerias,
        limitSearchPainelVendaFerias,
        dataDiasAtrasados,
        offsetDiasAtrasados,
        limitDiasAtrasados,
        limitSearchDiasAtrasados,
        dataPontosUsuario,
        offsetPontosUsuario,
        limitPontosUsuario,
        limitSearchPontosUsuario,
        limitSearchConfiguracaoPonto,
        offsetConfiguracaoPonto,
        limitConfiguracaoPonto,
        dataConfiguracaoPonto,
        statusCobranca,
        modalPonto,
        titleModalPonto,
        descriptionModalPontos,
        dataSystemHooks,
        HooksData,
        Hooks,
        offsetHooks,
        limitHooks,
        limitSearchHooks,
        modalHooksIsOpen,
        CartaoAssociado,
        confirmModalCartao,
        dataAssociadossCartoes,
        dataAssociadossFatura,
        dataTerceirizadosFatura,
        dataExtrato,
        Extrato,
        offsetExtrato,
        limitExtrato,
        limitSearchExtrato,
        modalFeriasFuncionarios,
        modalPainelFerias,
        closeAport,
        dataAport,
        Aport,
        offsetAport,
        limitAport,
        limitSearchAport,
        modalAport,
        dataInvestimentos,
        Investimentos,
        offsetInvestimentos,
        limitInvestimentos,
        limitSearchInvestimentos,
        dataCustosFatura,
        CustosFatura,
        offsetCustosFatura,
        limitCustosFatura,
        limitSearchCustosFatura,
        initialDataCustos,
        finalDataCustos,
        offsetCustos,
        limitCustos,
        limitSearchCustos,
        tableCustos,
        querySearchCustos,
        Custos,
        dataCustos,
        modalData,
        systemTecAlert,
        dataSystemHome,
        permission,
        dataHistorico,
        Historico,
        offsetHistorico,
        limitHistorico,
        limitSearchHistorico,
        tableHistorico,
        querySearchHistorico,
        modalUser,
        modalConfiguracaoPonto,
        dataUsers,
        Users,
        offsetUsers,
        limitUsers,
        limitSearchUsers,
        queryUsers,
        dataSystemUsers,
        dataSystemRelatorio,
        dataRelatorio,
        Relatorio,
        offsetRelatorio,
        limitRelatorio,
        limitSearchRelatorio,
        tableRelatorio,
        querySearchAssinaturaTemporaria,
        dataSystemAssinaturaTemporaria,
        dataAssinaturaTemporaria,
        AssinaturaTemporaria,
        offsetAssinaturaTemporaria,
        limitAssinaturaTemporaria,
        limitSearchAssinaturaTemporaria,
        tableAssinaturaTemporaria,
        faturasAprovadas,
        faturasPendentes,
        assinaturasAprovadas,
        assinaturasPendentes,
        negociacoes,
        terceirizados,
        associados,
        historicoUsuarios,
        usuarios,
        email,
        password,
        signed,
        remainSigned,
        sidebarOpen,
        navigation,
        secondaryNavigation,
        navigationAdministrator,
        navigationFinanceiro,
        idAssociadoSelected,
        waitTimeout,
        idContaSelected,
        navigationFinanceiroFaturas,
        navigationImportacao,
        planos,
        idPlanoSelected,
        index,
        offSet,
        modalIsOpen,
        assinaturas,
        faturas,
        token,
        navigationUsuarios,
        navigationFerias,
        navigationPonto,
        navigationAlerta,
        navigationTecnico,
        dataSystem,
        logData,
        tableLog,
        offsetLog,
        limitLog,
        limitSearchLog,
        modalLogIsOpen,
        navigationFinanceiroFolha,
        log,
        confirmModal,
        isLoading,
        refNavControlTableLogs,
        cronData,
        cron,
        offsetCron,
        limitCron,
        limitSearchCron,
        modalCronIsOpen,
        tableCron,
        modalShowTecnico,
        modalShowPontosRegistrados,
        transactionAssociateRemittenceAlerAlertData,
        transactionAssociateRemittenceAler,
        offsettransactionAssociateRemittenceAler,
        limittransactionAssociateRemittenceAler,
        limitSearchtransactionAssociateRemittenceAler,
        modaltransactionAssociateRemittenceAlerIsOpen,
        tabletransactionAssociateRemittenceAler,
        alertData,
        alert,
        offsetAlert,
        limitAlert,
        limitSearchAlert,
        modalAlertIsOpen,
        tableAlert,
        alertRemittenceData,
        alertRemittence,
        offsetAlertRemittence,
        limitAlertRemittence,
        limitSearchAlertRemittence,
        modalAlertRemittenceIsOpen,
        tableAlertRemittence,
        dataGraphicAccount,
        initialDateGraphicAccount,
        finalDateGraphicAccount,
        dataContaExtrato,
        contaExtrato,
        offsetContaExtrato,
        limitContaExtrato,
        limitSearchContaExtrato,
        tableContaExtrato,
        initialDateExtrato,
        finalDateExtrato,
        titleReport,
        descriptionReport,
        modalInfo,
        modalInfoTitle,
        modalInfoMessage,
        dataGraphicEntradas,
        initialDateEntradas,
        finalDateEntradas,
        dataEntradas,
        Entradas,
        offsetEntradas,
        limitEntradas,
        limitSearchEntradas,
        tableEntradas,
        dataGraphicSaidas,
        initialDateSaidas,
        finalDateSaidas,
        dataSaidas,
        Saidas,
        offsetSaidas,
        limitSaidas,
        limitSearchSaidas,
        tableSaidas,
        dataGraphicCobranca,
        initialDateCobranca,
        finalDateCobranca,
        dataCobranca,
        Cobranca,
        offsetCobranca,
        limitCobranca,
        limitSearchCobranca,
        tableCobranca,
        dataGraphicCobrancaNotPaid,
        isCanceled,
        sendConc,
        navigationAcoes,
        dataGraphicRemessa,
        initialDateRemessa,
        finalDateRemessa,
        dataRemessa,
        Remessa,
        offsetRemessa,
        limitRemessa,
        limitSearchRemessa,
        tableRemessa,
        modalRemessa,
        postRemessa,
        RemessaFile,
        dataGraphicRemessaAssociado,
        initialDateRemessaAssociado,
        finalDateRemessaAssociado,
        dataRemessaAssociado,
        RemessaAssociado,
        offsetRemessaAssociado,
        limitRemessaAssociado,
        limitSearchRemessaAssociado,
        tableRemessaAssociado,
        dataGraphicRemessaAssociadoTransacaoSolved,
        dataGraphicRemessaAssociadoTransacaoUnsolved,
        initialDateRemessaAssociadoTransacao,
        finalDateRemessaAssociadoTransacao,
        dataRemessaAssociadoTransacao,
        RemessaAssociadoTransacao,
        offsetRemessaAssociadoTransacao,
        limitRemessaAssociadoTransacao,
        limitSearchRemessaAssociadoTransacao,
        tableRemessaAssociadoTransacao,
        dataFuncefAssinatura,
        FuncefAssinatura,
        offsetFuncefAssinatura,
        limitFuncefAssinatura,
        limitSearchFuncefAssinatura,
        tableFuncefAssinatura,
        tableConciliacao,
        terceirizadoFatura,
        associadoFatura,
        codeRegister,
        dataAssociadoss,
        Associadoss,
        offsetAssociadoss,
        limitAssociadoss,
        limitSearchAssociadoss,
        tableAssociadoss,
        isFiliate,
        initialDataAssociados,
        finalDataAssociados,
        dataGraficAssociadosFundo,
        dataAssociadosAposentado,
        systemAssociado,
        querySearchAssociados,
        dataQuery,
        sumOffset,
        modalTable,
        systemAssinatura,
        dataAssinatura,
        Assinatura,
        offsetAssinatura,
        limitAssinatura,
        limitSearchAssinatura,
        tableAssinatura,
        initialDataAssinatura,
        finalDataAssinatura,
        dataGraficAssinaturaMethod,
        dataAssinaturaStatus,
        querySearchAssinatura,
        systemPlans,
        dataPlans,
        Plans,
        offsetPlans,
        limitPlans,
        limitSearchPlans,
        tablePlans,
        querySearchPlans,
        isEdit,
        isSuspend,
        modalFuncef,
        dataNegociacao,
        Negociacao,
        offsetNegociacao,
        limitNegociacao,
        limitSearchNegociacao,
        tableNegociacao,
        querySearchNegociacao,
        modalNegociacao,
        systemAssinaturaFuncef,
        initialDateNegociacao,
        finalDateNegociacao,
        systemNegoaciacao,
        dataGraphicNegociacao,
        systemTerceirizado,
        dataTerceirizado,
        Terceirizado,
        offsetTerceirizado,
        limitTerceirizado,
        limitSearchTerceirizado,
        tableTerceirizado,
        querySearchTerceirizado,
        systemAprove,
        dataAprove,
        Aprove,
        offsetAprove,
        limitAprove,
        limitSearchAprove,
        tableAprove,
        querySearchAprove,
        systemRequestAssinatura,
        dataRequestAssinatura,
        RequestAssinatura,
        offsetRequestAssinatura,
        limitRequestAssinatura,
        limitSearchRequestAssinatura,
        tableRequestAssinatura,
        querySearchRequestAssinatura,
        systemRequestFatura,
        dataRequestFatura,
        RequestFatura,
        offsetRequestFatura,
        limitRequestFatura,
        limitSearchRequestFatura,
        tableRequestFatura,
        querySearchRequestFatura,
        dataSystemCobranca,
        modalBaixa,
        error,
        queryCobranca,
        dataGraphicPagamento,
        dataGraphicPagamentoNotPaid,
        initialDatePagamento,
        finalDatePagamento,
        dataPagamento,
        Pagamento,
        offsetPagamento,
        limitPagamento,
        limitSearchPagamento,
        tablePagamento,
        dataSystemPagamento,
        queryPagamento,
        filePagamento,
        dataSystemAccount,
        dataSystemConciliacao,
        systemFolha,
        querySearchFuncefAssinatura,
        querySearchRemessaAssociadoTransacao,
        queryRemessaAssociado,
        dataPontosRegistrados,
        offsetPontosRegistrados,
        limitPontosRegistrados,
        limitSearchPontosRegistrados,
        dataDiasTrabalhados,
        offsetDiasTrabalhados,
        limitDiasTrabalhados,
        limitSearchDiasTrabalhados,
        infoFilterAssociado,
        dataTableFiltroAssociados,
        offsetFiltroAssinaturas,
        limitFiltroAssinaturas,
        dataTableFiltroAssinaturas,
        limitSearchFiltroAssinaturas,
        infoFilterAssinatura,
        offsetFiltroCobrancas,
        limitFiltroCobrancas,
        dataTableFiltroCobrancas,
        limitSearchFiltroCobrancas,
        infoFilterCobranca,
        confirmUnfiliate,
        navigationComunicacao,
        navigationContabil
    }

    const setters =
    {
        setFinal_dateBalanco,
        setInitial_dateBalanco,
        setDataSystemBalanco,
        setModalBalanco,
        setDataSystemAssociateStatus,
        setDataAssociateStatus,
        setAssociateStatus,
        setOffsetAssociateStatus,
        setLimitAssociateStatus,
        setLimitSearchAssociateStatus,
        setModalAssociateStatus,
        setModalDebitos,
        setDataSystemDebitos,
        setDataDebitos,
        setDebitos,
        setOffsetDebitos,
        setLimitDebitos,
        setLimitSearchDebitos,
        setDataJuriAcoesArquivos,
        setJuriAcoesArquivos,
        setOffsetJuriAcoesArquivos,
        setLimitJuriAcoesArquivos,
        setLimitSearchJuriAcoesArquivos,
        setModalTrocaDeTribunal,
        setModalDeferimentoAction,
        setDataJuriAcoesProcessoAtualizacoes,
        setJuriAcoesProcessoAtualizacoes,
        setOffsetJuriAcoesProcessoAtualizacoes,
        setLimitJuriAcoesProcessoAtualizacoes,
        setLimitSearchJuriAcoesProcessoAtualizacoes,
        setModalAcoesProcessoAtualizacoes,
        setModalMsg,
        setMsgModalMsg,
        setDescModalMsg,
        setTitleButtonModalMsg,
        setTitleButtonCloseModalMsg,
        setPerigoModalMsg,
        setFunctionModalMsg,
        setFunctionBtnModalMsg,
        setDataJuriAcoesProcessoSecoes,
        setJuriAcoesProcessoSecoes,
        setOffsetJuriAcoesProcessoSecoes,
        setLimitJuriAcoesProcessoSecoes,
        setLimitSearchJuriAcoesProcessoSecoes,
        setModalAcoesProcessoSecoes,
        setDataJuriAcoesProcessoAssociados,
        setJuriAcoesProcessoAssociados,
        setOffsetJuriAcoesProcessoAssociados,
        setLimitJuriAcoesProcessoAssociados,
        setLimitSearchJuriAcoesProcessoAssociados,
        setDataSystemJuriAcoesProcesso,
        setDataJuriAcoesProcessoForm,
        setDataJuriAcoesProcesso,
        setJuriAcoesProcesso,
        setOffsetJuriAcoesProcesso,
        setLimitJuriAcoesProcesso,
        setLimitSearchJuriAcoesProcesso,
        setModalAcoesProcesso,
        setDataJuriVaraProcesso,
        setOffsetJuriVaraProcesso,
        setLimitJuriVaraProcesso,
        setLimitSearchJuriVaraProcesso,
        setModalJuriVaraProcesso,
        setModalJuriVara,
        setDataJuriVaras,
        setJuriVaras,
        setOffsetJuriVaras,
        setLimitJuriVaras,
        setLimitSearchJuriVaras,
        setDataSystemJuriTribunal,
        setDataHistoricoAtualizacaoPonto,
        setHistoricoAtualizacaoPonto,
        setOffsetHistoricoAtualizacaoPonto,
        setLimitHistoricoAtualizacaoPonto,
        setLimitSearchHistoricoAtualizacaoPonto,
        setModalHistoricoAtualizacaoPonto,
        setDataSystemRequisicoesAtualizacaoPonto,
        setDataRequisicoesAtualizacaoPonto,
        setRequisicoesAtualizacaoPonto,
        setOffsetRequisicoesAtualizacaoPonto,
        setLimitRequisicoesAtualizacaoPonto,
        setLimitSearchRequisicoesAtualizacaoPonto,
        setModalRequisicoesAtualizacaoPonto,
        setPonto,
        setModalEditPontos,
        setModalApproveProject,
        setModalAddStep,
        setModalInit,
        setModalApprove,
        setModalProjetos,
        setDataSystemProjetos,
        setDataProjetos,
        setProjetos,
        setOffsetProjetos,
        setLimitProjetos,
        setLimitSearchProjetos,
        setDataInicialProjetos,
        setDataFinalProjetos,
        setQueryProjetos,
        setTotalLogs,
        setTotalAssociateComunicacao,
        setDataAssociateComunicacao,
        setAssociateComunicacao,
        setOffsetAssociateComunicacao,
        setLimitAssociateComunicacao,
        setLimitSearchAssociateComunicacao,
        setFaqsComunicacao,
        setModalFaqComunicacao,
        setTotalFaqsComunicacao,
        setDataFaqsComunicacao,
        setTotalNoticiasComunicacao,
        setDataNoticiasComunicacao,
        setNoticiasComunicacao,
        setOffsetNoticiasComunicacao,
        setLimitNoticiasComunicacao,
        setLimitSearchNoticiasComunicacao,
        setModalNoticiasComunicacao,
        setModalAssociadoProcesso,
        setDataSystemJuriAssociadoProcesso,
        setDataJuriAssociadoProcesso,
        setJuriAssociadoProcesso,
        setOffsetJuriAssociadoProcesso,
        setLimitJuriAssociadoProcesso,
        setLimitSearchJuriAssociadoProcesso,
        setModalAcoes,
        setDataSystemJuriAcoes,
        setDataJuriAcoes,
        setJuriAcoes,
        setOffsetJuriAcoes,
        setLimitJuriAcoes,
        setLimitSearchJuriAcoes,
        setDataSystemFundo,
        setDataFundo,
        setFundo,
        setOffsetFundo,
        setLimitFundo,
        setLimitSearchFundo,
        setDataSystemJuriTribunais,
        setDataJuriTribunais,
        setJuriTribunais,
        setOffsetJuriTribunais,
        setLimitJuriTribunais,
        setLimitSearchJuriTribunais,
        setJuriProcessoAuth,
        setDataSystemJuriProcessoSecoes,
        setDataJuriProcessoSecoes,
        setJuriProcessoSecoes,
        setOffsetJuriProcessoSecoes,
        setLimitJuriProcessoSecoes,
        setLimitSearchJuriProcessoSecoes,
        setDataSystemJuriProcessoAtualizacoes,
        setDataJuriProcessoAtualizacoes,
        setJuriProcessoAtualizacoes,
        setOffsetJuriProcessoAtualizacoes,
        setLimitJuriProcessoAtualizacoes,
        setLimitSearchJuriProcessoAtualizacoes,
        setDataSystemJuriProcessoAssociate,
        setDataJuriProcessoAssociate,
        setJuriProcessoAssociate,
        setOffsetJuriProcessoAssociate,
        setLimitJuriProcessoAssociate,
        setLimitSearchJuriProcessoAssociate,
        setModalDeferimento,
        setDataSystemJuriProcessoDeferimento,
        setDataJuriProcessoDeferimento,
        setJuriProcessoDeferimento,
        setOffsetJuriProcessoDeferimento,
        setLimitJuriProcessoDeferimento,
        setLimitSearchJuriProcessoDeferimento,
        setDataSystemJuriProcess,
        setDataSystemJuriHome,
        setDataSystemJuriProcessos,
        setDataJuriProcessos,
        setJuriProcessos,
        setOffsetJuriProcessos,
        setLimitJuriProcessos,
        setLimitSearchJuriProcessos,
        setDataSystemJuriEscritorio,
        setDataJuriEscritorio,
        setJuriEscritorio,
        setOffsetJuriEscritorio,
        setLimitJuriEscritorio,
        setLimitSearchJuriEscritorio,
        setModalJuriLegalProcess,
        setDataSystemJuriLegalProcess,
        setDataJuriLegalProcess,
        setJuriLegalProcess,
        setOffsetJuriLegalProcess,
        setLimitJuriLegalProcess,
        setLimitSearchJuriLegalProcess,
        setJuriOutSourced,
        setLimitSearchJuriOutSourced,
        setLimitJuriOutSourced,
        setOfsettJuriOutSourced,
        setDataSystemJuriLawyer,
        setDataJuriLawyer,
        setDataJuriOutSourced,
        setJuriLawyer,
        setOffsetJuriLawyer,
        setLimitJuriLawyer,
        setLimitSearchJuriLawyer,
        setIsEditJuri,
        setDataJuriUsers,
        setModalJuri,
        setDataJuriUser,
        setJuriUser,
        setOffsetJuriUser,
        setLimitJuriUser,
        setLimitSearchJuriUser,
        setDataSystemJuriUser,
        setIsSignedJuri,
        setInsertCode,
        setDebitByFundo,
        setDataAssinaturaCard,
        setOffsetAssinaturaCard,
        setLimitAssinaturaCard,
        setLimitSearchAssinaturaCard,
        setModalHealthConfirm,
        setDataHealthRegister,
        setHealthRegister,
        setOffsetHealthRegister,
        setLimitHealthRegister,
        setLimitSearchHealthRegister,
        setDataSystemHealth,
        setDataHealth,
        setHealth,
        setOffsetHealth,
        setLimitHealth,
        setLimitSearchHealth,
        setModalHealth,
        setDataAssinaturaExtrato,
        setAssinaturaExtrato,
        setOffsetAssinaturaExtrato,
        setLimitAssinaturaExtrato,
        setLimitSearchAssinaturaExtrato,
        setModalAssinaturaExtrato,
        setDataImportFalhas,
        setImportFalhas,
        setOffsetImportFalhas,
        setLimitImportFalhas,
        setLimitSearchImportFalhas,
        setModalImportFalhas,
        setDataImportFaturas,
        setImportFaturas,
        setOffsetImportFaturas,
        setLimitImportFaturas,
        setLimitSearchImportFaturas,
        setModalImportFaturas,
        setDataSystemImportFaturas,
        setModalImport,
        setDataImport,
        setImport,
        setOffsetImport,
        setLimitImport,
        setLimitSearchImport,
        setDataSystemImport,
        setDiaTrabalhoAberto,
        setIdDiaTrabalho,
        setDataUsuarioDiasTrabalho,
        setOffSetUsuarioDiasTrabalho,
        setLimitUsuarioDiasTrabalho,
        setLimitSearchUsuarioDiasTrabalho,
        setConfirmUnfiliate,
        setInfoFilterCobranca,
        setLimitSearchFiltroCobrancas,
        setDataTableFiltroCobrancas,
        setLimitFiltroCobrancas,
        setOffsetFiltroCobrancas,
        setInfoFilterAssinatura,
        setLimitSearchFiltroAssinaturas,
        setDataTableFiltroAssinaturas,
        setLimitFiltroAssinaturas,
        setOffsetFiltroAssinaturas,
        setInfoFilterAssociado,
        setLimitSearchFiltroAssociados,
        setDataTableFiltroAssociados,
        setOffsetFiltroAssociados,
        setLimitFiltroAssociados,
        setModalFiltro,
        setDataFeriasFuncionarios,
        setOffSetFeriasFuncionarios,
        setLimitFeriasFuncionarios,
        setLimitSearchFeriasFuncionarios,
        setDataPainelFerias,
        setOffSetPainelFerias,
        setLimitPainelFerias,
        setLimitSearchPainelFerias,
        setDataPainelVendaFerias,
        setOffSetPainelVendaFerias,
        setLimitPainelVendaFerias,
        setLimitSearchPainelVendaFerias,
        setDataDiasAtrasados,
        setOffSetDiasAtrasados,
        setLimitDiasAtrasados,
        setLimitSearchDiasAtrasados,
        setDataPontosUsuario,
        setOffSetPontosUsuario,
        setLimitPontosUsuario,
        setLimitSearchPontosUsuario,
        setDataDiasTrabalhados,
        setOffSetDiasTrabalhados,
        setLimitDiasTrabalhados,
        setLimitSearchDiasTrabalhados,
        setDataPontosRegistrados,
        setOffSetPontosRegistrados,
        setLimitPontosRegistrados,
        setLimitSearchPontosRegistrados,
        setLimitSearchConfiguracaoPonto,
        setOffSetConfiguracaoPonto,
        setLimitConfiguracaoPonto,
        setDataConfiguracaoPonto,
        setStatusCobranca,
        setDataSystemHooks,
        setHooksData,
        setHooks,
        setOffsetHooks,
        setLimitHooks,
        setLimitSearchHooks,
        setModalHooksIsOpen,
        setModalPonto,
        setTitleModalPonto,
        setDescriptionModalPontos,
        setCartaoAssociado,
        setConfirmModalCartao,
        setDataAssociadossCartoes,
        setDataAssociadossFatura,
        setDataTerceirizadosFatura,
        setDataExtrato,
        setExtrato,
        setOffsetExtrato,
        setLimitExtrato,
        setLimitSearchExtrato,
        setCloseAport,
        setDataAport,
        setAport,
        setOffsetAport,
        setLimitAport,
        setLimitSearchAport,
        setModalAport,
        setDataInvestimentos,
        setInvestimentos,
        setOffsetInvestimentos,
        setLimitInvestimentos,
        setLimitSearchInvestimentos,
        setDataCustosFatura,
        setCustosFatura,
        setOffsetCustosFatura,
        setLimitCustosFatura,
        setLimitSearchCustosFatura,
        setInitialDataCustos,
        setFinalDataCustos,
        setOffsetCustos,
        setLimitCustos,
        setLimitSearchCustos,
        setTableCustos,
        setQuerySearchCustos,
        setCustos,
        setDataCustos,
        setModalData,
        setSystemTecAlert,
        setDataSystemHome,
        setPermission,
        setDataHistorico,
        setHistorico,
        setOffsetHistorico,
        setLimitHistorico,
        setLimitSearchHistorico,
        setTableHistorico,
        setQuerySearchHistorico,
        setModalUser,
        setModalConfiguracaoPonto,
        setDataUsers,
        setUsers,
        setOffsetUsers,
        setLimitUsers,
        setLimitSearchUsers,
        setQueryUsers,
        setDataSystemUsers,
        setDataSystemRelatorio,
        setDataRelatorio,
        setRelatorio,
        setOffsetRelatorio,
        setLimitRelatorio,
        setLimitSearchRelatorio,
        setTableRelatorio,
        setQuerySearchAssinaturaTemporaria,
        setDataSystemAssinaturaTemporaria,
        setDataAssinaturaTemporaria,
        setAssinaturaTemporaria,
        setOffsetAssinaturaTemporaria,
        setLimitAssinaturaTemporaria,
        setLimitSearchAssinaturaTemporaria,
        setTableAssinaturaTemporaria,
        setQueryRemessaAssociado,
        setQuerySearchRemessaAssociadoTransacao,
        setQuerySearchFuncefAssinatura,
        setSystemFolha,
        setDataSystemConciliacao,
        setFaturasAprovadas,
        setDataSystemAccount,
        setFaturasPendentes,
        setAssinaturasAprovadas,
        setAssinaturasPendentes,
        setNegociacoes,
        setTerceirizados,
        setAssociados,
        setHistoricoUsuarios,
        setUsuarios,
        setEmail,
        setPassword,
        setSigned,
        setRemainSigned,
        setSidebarOpen,
        setIdAssociadoSelected,
        setWaitTimeOut,
        setIdContaSelected,
        setPlanos,
        setIdPlanoSelected,
        setIndex,
        setModalFeriasFuncionarios,
        setModalPainelFerias,
        setOffSet,
        setModalIsOpen,
        setAssinaturas,
        setFaturas,
        setToken,
        setDataSystem,
        setLogData,
        setOffsetLog,
        setLimitLog,
        setLimitSearchLog,
        setModalLogIsOpen,
        setLog,
        setTableLog,
        setConfirmModal,
        setIsLoading,
        setCronData,
        setCron,
        setOffsetCron,
        setLimitCron,
        setLimitSearchCron,
        setModalCronIsOpen,
        setTableCron,
        setModalShowTecnico,
        setModalShowPontosRegistrados,
        settransactionAssociateRemittenceAlerData,
        settransactionAssociateRemittenceAler,
        setOffsettransactionAssociateRemittenceAler,
        setLimittransactionAssociateRemittenceAler,
        setLimitSearchtransactionAssociateRemittenceAler,
        setModaltransactionAssociateRemittenceAlerIsOpen,
        setTabletransactionAssociateRemittenceAler,
        setAlertData,
        setAlert,
        setOffsetAlert,
        setLimitAlert,
        setLimitSearchAlert,
        setModalAlertIsOpen,
        setTableAlert,
        setAlertRemittenceData,
        setAlertRemittence,
        setOffsetAlertRemittence,
        setLimitAlertRemittence,
        setLimitSearchAlertRemittence,
        setModalAlertRemittenceIsOpen,
        setTableAlertRemittence,
        setDataGraphicAccount,
        setInitialDateGraphicAccount,
        setFinalDateGraphicAccount,
        setDataContaExtrato,
        setContaExtrato,
        setOffsetContaExtrato,
        setLimitContaExtrato,
        setLimitSearchContaExtrato,
        setTableContaExtrato,
        setInitialDateExtrato,
        setFinalDateExtrato,
        setTitleReport,
        setDescriptionReport,
        setModalInfo,
        setModalInfoTitle,
        setModalInfoMessage,
        setDataGraphicEntradas,
        setInitialDateEntradas,
        setFinalDateEntradas,
        setDataEntradas,
        setEntradas,
        setOffsetEntradas,
        setLimitEntradas,
        setLimitSearchEntradas,
        setTableEntradas,
        setDataGraphicSaidas,
        setInitialDateSaidas,
        setFinalDateSaidas,
        setDataSaidas,
        setSaidas,
        setOffsetSaidas,
        setLimitSaidas,
        setLimitSearchSaidas,
        setTableSaidas,
        setDataGraphicCobranca,
        setInitialDateCobranca,
        setFinalDateCobranca,
        setDataCobranca,
        setCobranca,
        setOffsetCobranca,
        setLimitCobranca,
        setLimitSearchCobranca,
        setTableCobranca,
        setDataGraphicCobrancaNotPaid,
        setIsCanceled,
        setSendConc,
        setDataGraphicRemessa,
        setInitialDateRemessa,
        setFinalDateRemessa,
        setDataRemessa,
        setRemessa,
        setOffsetRemessa,
        setLimitRemessa,
        setLimitSearchRemessa,
        setTableRemessa,
        setModalRemessa,
        setPostRemessa,
        setRemessaFile,
        setDataGraphicRemessaAssociado,
        setInitialDateRemessaAssociado,
        setFinalDateRemessaAssociado,
        setDataRemessaAssociado,
        setRemessaAssociado,
        setOffsetRemessaAssociado,
        setLimitRemessaAssociado,
        setLimitSearchRemessaAssociado,
        setTableRemessaAssociado,
        setDataGraphicRemessaAssociadoTransacaoSolved,
        setDataGraphicRemessaAssociadoTransacaoUnsolved,
        setInitialDateRemessaAssociadoTransacao,
        setFinalDateRemessaAssociadoTransacao,
        setDataRemessaAssociadoTransacao,
        setRemessaAssociadoTransacao,
        setOffsetRemessaAssociadoTransacao,
        setLimitRemessaAssociadoTransacao,
        setLimitSearchRemessaAssociadoTransacao,
        setTableRemessaAssociadoTransacao,
        setDataFuncefAssinatura,
        setFuncefAssinatura,
        setOffsetFuncefAssinatura,
        setLimitFuncefAssinatura,
        setLimitSearchFuncefAssinatura,
        setTableFuncefAssinatura,
        setTableConciliacao,
        setTerceirizadoFatura,
        setAssociadoFatura,
        setCodeRegister,
        setDataAssociadoss,
        setAssociadoss,
        setOffsetAssociadoss,
        setLimitAssociadoss,
        setLimitSearchAssociadoss,
        setTableAssociadoss,
        setIsFiliate,
        setInitialDataAssociados,
        setFinalDataAssociados,
        setDataGraficAssociadosFundo,
        setDataAssociadosAposentado,
        setSystemAssociado,
        setQuerySearchAssociados,
        setDataQuery,
        setSumOffset,
        setModalTable,
        setSystemAssinatura,
        setQuerySearchAssinatura,
        setDataAssinatura,
        setAssinatura,
        setOffsetAssinatura,
        setLimitAssinatura,
        setLimitSearchAssinatura,
        setTableAssinatura,
        setInitialDataAssinatura,
        setFinalDataAssinatura,
        setDataGraficAssinaturaMethod,
        setDataAssinaturaStatus,
        setSystemPlans,
        setQuerySearchPlans,
        setDataPlans,
        setPlans,
        setOffsetPlans,
        setLimitPlans,
        setLimitSearchPlans,
        setTablePlans,
        setIsEdit,
        setIsSuspend,
        setModalFuncef,
        setDataNegociacao,
        setNegociacao,
        setOffsetNegociacao,
        setLimitNegociacao,
        setLimitSearchNegociacao,
        setTableNegociacao,
        setModalNegociacao,
        setQuerySearchNegociacao,
        setSystemAssinaturaFuncef,
        setInitialDateNegociacao,
        setFinalDateNegociacao,
        setSystemNegoaciacao,
        setDataGraphicNegociacao,
        setDataTerceirizado,
        setTerceirizado,
        setOffsetTerceirizado,
        setLimitTerceirizado,
        setLimitSearchTerceirizado,
        setTableTerceirizado,
        setQuerySearchTerceirizado,
        setSystemTerceirizado,
        setSystemAprove,
        setQuerySearchAprove,
        setDataAprove,
        setAprove,
        setOffsetAprove,
        setLimitAprove,
        setLimitSearchAprove,
        setTableAprove,
        setDataRequestAssinatura,
        setRequestAssinatura,
        setOffsetRequestAssinatura,
        setLimitRequestAssinatura,
        setLimitSearchRequestAssinatura,
        setTableRequestAssinatura,
        setQuerySearchRequestAssinatura,
        setSystemRequestAssinatura,
        setDataRequestFatura,
        setRequestFatura,
        setOffsetRequestFatura,
        setLimitRequestFatura,
        setLimitSearchRequestFatura,
        setTableRequestFatura,
        setQuerySearchRequestFatura,
        setSystemRequestFatura,
        setDataSystemCobranca,
        setModalBaixa,
        setError,
        setQueryCobranca,
        setDataGraphicPagamento,
        setDataGraphicPagamentoNotPaid,
        setInitialDatePagamento,
        setFinalDatePagamento,
        setDataPagamento,
        setPagamento,
        setOffsetPagamento,
        setLimitPagamento,
        setLimitSearchPagamento,
        setTablePagamento,
        setDataSystemPagamento,
        setQueryPagamento,
        setFilePagamento,

    }

    return (
        <AuthContext.Provider
            value={{
                states,
                setters,
                listarAssociadosPorCpf,
                acaoFormularioAssociado,
                acaoAssinaturasPendentes,
                acaoFaturasPendentes,
                listarFaturasAprovadas,
                listarFaturasPendentes,
                listarAssinaturasAprovadas,
                listarAssinaturasPendentes,
                listarNegociacoes,
                listarTerceirizados,
                listarAssinaturas,
                listarAssociados,
                listarHistoricoUsuarios,
                listarUsuarios,
                login,
                buscarCep,
                listarPlanos,
                listarFaturas,
                validarCpf,
                validarCnpj,
            }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
