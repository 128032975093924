import './index.css'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import {
    MagnifyingGlassIcon, PencilSquareIcon, CheckCircleIcon, ArrowPathIcon, PlusIcon, XCircleIcon, ArrowLeftIcon, ArrowRightIcon, WalletIcon,
    IdentificationIcon
} from '@heroicons/react/24/outline'

const TableAssinaturas = ({ form, states, show, headers, dataTable, next, previous, infoTable, title, description, edit, add, active, funcef, gerarNegociacao, extrato }) => {

    const nextPage = () => {
        next()
    }

    const previousPage = () => {
        previous()
    }

    const formatCPF = (cpf) => {
        if (!cpf) {
            return '';
        }

        const cleanedCPF = cpf.replace(/\D/g, '');

        if (cleanedCPF.length !== 11) {
            return cpf; // Retorna o valor original se não for possível formatar
        }

        const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
        const formattedCPF = cleanedCPF.replace(cpfRegex, '$1.$2.$3-$4');

        return formattedCPF;
    }

    const trGen = () => {
        if (states.isLoading) {

            return (
                <tr className="hover:bg-gray-100">
                    {
                        dataTable.length > 0 && Object.keys(dataTable[0]).map((key, index) => {
                            return (

                                <td className="px-6 py-4 bg-white px-8 py-6 whitespace-pre-wrap text-sm text-gray-500" >
                                    <div className="flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                    </div>
                                </td>
                            )
                        })
                    }
                </tr>
            )

        } else {

            return (
                dataTable.length > 0 ? (
                    dataTable.map((logs) => {
                        return (
                            <tr key={logs.id} className="hover:bg-gray-200">
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.emailAssociate}
                                </td>
                                <td className="py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {formatCPF(logs.cpf)}
                                </td>
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.name}
                                </td>
                                <td className="px-12 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.dayCharge}
                                </td>
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.method === 'credit_card' ? (
                                            <span className="px-2 inline-flex text-xs leading-5 text-center font-semibold rounded-full bg-green-100 text-green-800">
                                                Cartão de crédito
                                            </span>
                                        ) : logs.method == 'bank_slip' ? (
                                            <span className="px-2 inline-flex text-xs leading-5 text-center font-semibold rounded-full bg-orange-100 text-red-800">
                                                {
                                                    logs.method === 'bank_slip' ? (
                                                        'Boleto'
                                                    ) : logs.method
                                                }
                                            </span>
                                        ) : (
                                            <span className="px-2 inline-flex text-xs leading-5 text-center font-semibold rounded-full bg-blue-100 text-blue-800">
                                                {
                                                    logs.method === 'account_debitFuncef' ? (
                                                        'Débito em conta Funcef'
                                                    ) : `${logs.method}`
                                                }
                                            </span>
                                        )
                                    }
                                </td>
                                <td className="px-7 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.plan_title}
                                </td>
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.status === 1 ? (
                                            <span className="px-2 inline-flex text-xs text-center leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Ativo
                                            </span>
                                        ) : (
                                            <span className="px-2 inline-flex text-xs text-center leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                Inativo
                                            </span>
                                        )
                                    }
                                </td>
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                                    {logs.statusPaid}
                                </td>
                                <td className="px-2 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                                    {
                                        logs.idIuguSignature !== '0' && logs.idIuguSignature !== '' && logs.isFuncef !== '1' ? (
                                            <span className="px-2 inline-flex text-xs leading-5 text-center font-semibold rounded-full bg-green-100 text-green-800">
                                                Assinatura no Gateway
                                            </span>
                                        ) : (logs.idIuguSignature === '0' || logs.idIuguSignature === '') && logs.isFuncef !== 1 ? (
                                            <span className="px-2 inline-flex text-xs leading-5 text-center font-semibold rounded-full bg-orange-100 text-orange-800">
                                                Assinatura interna
                                            </span>
                                        ) : logs.idIuguSignature === '0' && logs.isFuncef === 1 ? (
                                            <span className="px-2 inline-flex text-xs leading-5 text-center font-semibold rounded-full bg-purple-100 text-purple-800">
                                                Assinatura FUNCEF
                                            </span>
                                        ) : (
                                            <span className="px-2 inline-flex text-xs leading-5 text-center font-semibold rounded-full bg-green-100 text-green-800">
                                                Not found
                                            </span>
                                        )
                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.createdAt).format('DD/MM/YYYY')}
                                </td>

                                <td className="px-8 py-6 whitespace-nowrap text-right flex items-center space-x-2">
                                    <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => show(logs)}>
                                        <MagnifyingGlassIcon className="h-7 w-7" />
                                    </button>
                                    <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => extrato(logs)}>
                                        <IdentificationIcon className="h-7 w-7" />
                                    </button>
                                    {
                                        logs.isFuncef === 0 && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') && (
                                            <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => edit(logs.id)}>
                                                <PencilSquareIcon className="h-7 w-7" />
                                            </button>
                                        )
                                    }
                                    {
                                        logs.status === 1 && logs.isFuncef === 0 && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') && (
                                            <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => form(logs)}>
                                                <XCircleIcon className="h-7 w-7" />
                                            </button>
                                        )
                                    }
                                    {
                                        logs.status === 0 && logs.isFuncef === 0 && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') && (
                                            <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => active(logs)}>
                                                <CheckCircleIcon className="h-7 w-7" />
                                            </button>
                                        )
                                    }
                                    {
                                        logs.status === 1 && logs.isFuncef === 0 && (localStorage.getItem('permission') === '0' || localStorage.getItem('permission') === '1' || localStorage.getItem('permission') === '2' || localStorage.getItem('permission') === '3') && (
                                            <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => funcef(logs)}>
                                                <ArrowPathIcon className="h-7 w-7" />
                                            </button>
                                        )
                                    }
                                </td>
                            </tr>
                        )
                    }
                    )
                ) : (
                    <tr>
                        <td className="px-8 py-6 whitespace-nowrap text-sm text-gray-500" colSpan="5">
                            Sem registro de dados
                        </td>
                    </tr>
                )
            )

        }
    }

    const scrollContainerRef = useRef(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);

    const scrollTable = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 200; // A quantidade de pixels para rolar
            const currentScroll = scrollContainerRef.current.scrollLeft;
            scrollContainerRef.current.scrollLeft = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount;
        }
    };

    useEffect(() => {
        const checkOverflow = () => {
            const container = scrollContainerRef.current;
            if (container) {
                const isOverflowing = container.scrollWidth > container.clientWidth;
                setShowScrollButtons(isOverflowing);
            }
        };

        // Verifica o overflow quando o componente é montado
        checkOverflow();

        // Adiciona um ouvinte para verificar o overflow quando a janela é redimensionada
        window.addEventListener('resize', checkOverflow);

        // Remove o ouvinte quando o componente é desmontado
        return () => window.removeEventListener('resize', checkOverflow);
    }, [dataTable]);


    return (
        <div className="mt-4">
            {showScrollButtons && (
                <div className="flex justify-between">
                    <button
                        onClick={() => scrollTable('left')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowLeftIcon className="h-7 w-7 text-blue-500" />
                    </button>
                    <button
                        onClick={() => scrollTable('right')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowRightIcon className="h-7 w-7 text-blue-500" />
                    </button>
                </div>
            )}
            <div className="mt-4 overflow-x-auto" ref={scrollContainerRef}>
                <div className="inline-block min-w-full  rounded-md border border-gray-200">

                    <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                        <div className="flex flex-1 items-center">
                            {
                                (localStorage.getItem('permission') !== '5') &&
                                <button
                                    onClick={() => add()}
                                    className="flex items-center justify-center h-10 w-10 text-gray-500 rounded-md border hover:bg-gray-200 focus-visible:outline-offset-0"
                                >
                                    <PlusIcon className="h-12 w-12" />
                                </button>
                            }
                            <div className="flex flex-col ml-4">
                                <h1 className="text-xl font-bold text-gray-700">{title}</h1>
                                <p className="text-gray-500">{description}</p>
                            </div>
                        </div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                {
                                    headers.map((headers, index) => (
                                        <th key={index} scope="col" className="px-8 py-4 text-left text-sm text-center font-semibold text-gray-900">
                                            {headers}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                trGen()
                            }
                        </tbody>
                    </table>
                    <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-6 px-8">
                        <div className="flex flex-1 justify-between sm:justify-start">
                            <div className="hidden sm:block mt-2">
                                <p className="text-sm text-gray-700">
                                    Mostrando registros de {infoTable.offset > 0 ? infoTable.offset : 1} a {infoTable.offset + infoTable.limit}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-1 justify-start sm:justify-end">
                            <button
                                className="border border-gray-200 relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => previousPage()}
                            >
                                Anterior
                            </button>
                            <button
                                href="#"
                                className="border border-gray-200 relative ml-3 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => nextPage()}
                            >
                                Próximo
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )

}

export default TableAssinaturas;