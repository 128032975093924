import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';




const ModalExportacaoFaturas = ({ states, setters, action: acao, fundos }) => {




    const [statusFatura, setStatusFatura] = useState('');
    const [errorStatusFatura, setErrorStatusFatura] = useState(false);

    const [tipoFatura, setTipoFatura] = useState('');
    const [errorTipoFatura, setErrorTipoFatura] = useState(false);




    const action = () => {
        let error = false;

        if (statusFatura === '') {
            setErrorStatusFatura(true);
            error = true;
        } else {
            setErrorStatusFatura(false);
        }

        if (tipoFatura === '') {
            setErrorTipoFatura(true);
            error = true;
        } else {
            setErrorTipoFatura(false);
        }



        if (error) {
            error = false;
            return;
        }

        acao({
            status: statusFatura,
            type: tipoFatura
        });
    }

    const close = () => {
        setters.setModalBalanco(false);
    }


    useEffect(() => {
        if (states.modalBalanco === false) {
            setStatusFatura('');
            setTipoFatura('');
            setErrorStatusFatura(false);
            setErrorTipoFatura(false);
        }
    }, [states.modalBalanco])



    return (
        <Transition.Root show={states.modalBalanco} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={setters.setModalBalanco}>
                <div className="flex min-h-screen items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-4"
                    >
                        <Dialog.Panel className="w-full max-w-6xl p-4 rounded-lg bg-white shadow-xl border-2">
                            <div className='flex flex-col items-center p-5'>
                                <h1 className="text-2xl font-bold">Exportação de faturas</h1>
                                <p className="ml-2 text-gray-500">Preencha os campos abaixo para realizar a exportação das faturas</p>
                            </div>
                            <div className='flex flex-col  p-8 rounded-[15px] shadow-sm hover:shadow-md  '>


                                {/* HEADERS PARA DEFINICAO DE FILTROS */}
                                <div className="flex sm:flex-row flex-col items-between justify-between">
                                    <div className="flex flex-col mt-4 sm:mt-0 sm:w-[50%] w-[100%]">
                                        <div className="flex flex-row">
                                            <label className="text-sm font-semibold">Tipo da Fatura</label>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <select className="mt-2"
                                                onChange={(e) => {
                                                    setTipoFatura(e.target.value);
                                                }}
                                                value={tipoFatura}
                                            >
                                                {/* type !== 'credit' && type !== 'debit' */}
                                                <option value="">Selecione um campo</option>
                                                <option value="credit">Crédito</option>
                                                <option value="debit">Débito</option>
                                            </select>
                                            {
                                                errorTipoFatura && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-4 sm:mt-0 sm:w-[50%] w-[100%] sm:ml-4">
                                        <div className="flex flex-row">
                                            <label className="text-sm font-semibold">Status da Fatura</label>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            <select className="mt-2"
                                                onChange={(e) => {
                                                    setStatusFatura(e.target.value);
                                                }}
                                                value={statusFatura}
                                            >
                                                <option value="">Selecione um campo</option>
                                                <option value="paid">Pago</option>
                                                <option value="pending">Pendente</option>
                                                <option value="expired">Expirado</option>
                                                <option value="canceled">Cancelado</option>
                                                <option value="refunded">Reembolsado</option>
                                                <option value="all">Todos</option>
                                            </select>
                                            {
                                                errorStatusFatura && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                            }
                                        </div>
                                    </div>

                                </div>



                                <div className="mt-4 flex justify-end">
                                    <button
                                        className={"ml-2 bg-blue-600 text-white px-4 py-2 rounded"}
                                        onClick={() => action()}
                                    >
                                        Gerar exportação
                                    </button>
                                    <button
                                        className=" ml-2 bg-red-600 text-white px-4 py-2 rounded"
                                        onClick={() => close()}
                                    >
                                        Fechar
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div >
            </Dialog >
        </Transition.Root >
    );
}

export default ModalExportacaoFaturas;
